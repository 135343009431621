<div class="page_inner_div">
  <div class="land_cnt_main">
    <a class="land_back_btn" routerLink="/instructions">&lt; Back</a>
    <div class="land_cnt_inner">
      <div class="row">
        <div class="col-sm-12">
          <p class="short_msg short_inner_msg">
            Use this
            <span>
              <a
                href="itms-services://?action=download-manifest&url=https://mallcitymechanical-staging.web.app/assets/instructions/resources/ios/manifest.plist"
                >download link</a
              ></span
            >
            to install the app following the instructions below.<br />
          </p>
        </div>
      </div>
      <div class="inner_cnt_block">
        <div class="row">
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 1</h2>
              <p>
                Click <b>Install</b>, then go to your home screen and find the
                app.
              </p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_1.png'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 2</h2>
              <p>
                Wait for the app to finish installing then click on the icon to
                open it.
              </p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_2.png'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 3</h2>
              <p>
                To use the app, first we need to trust the developer. Click
                <b>Cancel</b>
              </p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_3.png'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 4</h2>
              <p>Open the <b>Settings</b> app</p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_4.png'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 5</h2>
              <p>Scroll down and click on <b>General.</b></p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_5.png'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 6</h2>
              <p>Scroll down and click on <b>Device Management.</b></p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_6.png'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 7</h2>
              <p>Select Elite Technology Group, LLC</p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_7.png'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 8</h2>
              <p>Select Trust “Elite Technology Group, LLC”</p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_8.png'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 9</h2>
              <p>Select <b>Trust</b></p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_9.png'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 10</h2>
              <p>Close "Settings" and go back to your Home screen</p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_10.png'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 11</h2>
              <p>Click the app icon to open the app.</p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_11.png'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 12</h2>
              <p>Success! You may now login and use the app.</p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step_12.png'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
