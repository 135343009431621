import * as AttachmentActions from './attachments.actions';
import {
  initAttachmentState,
  AttachmentStateInterface,
  adapterAttachment,
} from './attachments.state';

export function AttachmentReducer(
  state = initAttachmentState,
  action
): AttachmentStateInterface {
  switch (action.type) {
    case AttachmentActions.ATTACHMENT_GET_RECEIVE: {
      const payload = action.payload.AttachmentList.data;
      const isScrolled = action.payload.isScrolled;
      if (isScrolled) {
        return adapterAttachment.addMany(payload, {
          ...state,
        });
      } else {
        return adapterAttachment.setAll(payload, state);
      }
    }
    default:
      return state;
  }
}
