import { JobLocalModel } from './../backend/time/model/job-local.model';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as TimeActions from './time.actions';
import * as PayperiodLockoutActions from '../payperiod-lockout/payperiod-lockout.actions';

import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { TimeService } from '../backend/time/time.service';
import { TimeModel } from '../backend/time/model/time.model';
import { AlertMessageCommonService } from '../../shared/alert-message-common/alert-message-common.service';
import { from, Observable, of } from 'rxjs';
import { HideSpinner, ShowSpinner } from '../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../error/error.actions';
import { ErrorType } from '../error/model/error-type';

type showSpinnerTypes =
  | TimeActions.TimeEntryRequest
  | TimeActions.TimeEntryAddRequest
  | TimeActions.TimeEntryNoteRequest
  | TimeActions.TimeEntryEditRequest
  | TimeActions.TimeEntryDeleteRequest
  | TimeActions.TimeEntryContainerDeleteRequest
  | TimeActions.TimeEntryNoteRemoveRequest;

const showSpinnerActions = [
  TimeActions.TIME_ENTRY_REQUEST,
  TimeActions.TIME_ENTRY_ADD_REQUEST,
  TimeActions.TIME_ENTRY_NOTE_REQUEST,
  TimeActions.TIME_ENTRY_EDIT_REQUEST,
  TimeActions.TIME_ENTRY_DELETE_REQUEST,
  TimeActions.TIME_ENTRY_CONTAINER_DELETE_REQUEST,
  TimeActions.TIME_ENTRY_NOTE_REMOVE_REQUEST,
];

type hideSpinnerTypes =
  | TimeActions.TimeEntryReceive
  | TimeActions.TimeEntryAddReceive
  | TimeActions.TimeEntryAddReceiveError
  | TimeActions.TimeEntryNoteReceive
  | TimeActions.TimeEntryEditReceive
  | TimeActions.TimeEntryDeleteReceive
  | TimeActions.TimeEntryContainerDeleteReceive
  | TimeActions.TimeEntryNoteRemoveReceive;

const hideSpinnerActions = [
  TimeActions.TIME_ENTRY_RECEIVE,
  TimeActions.TIME_ENTRY_ADD_RECEIVE,
  TimeActions.TIME_ENTRY_ADD_RECEIVE_ERROR,
  TimeActions.TIME_ENTRY_NOTE_RECEIVE,
  TimeActions.TIME_ENTRY_EDIT_RECEIVE,
  TimeActions.TIME_ENTRY_DELETE_RECEIVE,
  TimeActions.TIME_ENTRY_CONTAINER_DELETE_RECEIVE,
  TimeActions.TIME_ENTRY_NOTE_REMOVE_RECEIVE,
];

@Injectable()
export class TimeEffects {
  constructor(
    private actions$: Actions,
    private timeService: TimeService,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnTimeEntryRequest$ = this.actions$.pipe(
    ofType<TimeActions.TimeEntryRequest>(TimeActions.TIME_ENTRY_REQUEST),
    map((action) => action.payload),
    switchMap((postData) =>
      from(this.timeService.getAllTimeEntry(postData)).pipe(
        mergeMap((time_entry: TimeModel[]) => [
          new TimeActions.TimeEntryReceive(time_entry),
          new PayperiodLockoutActions.PayPeriodLockoutStatusReceive({
            data: time_entry,
          }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnAddTimeEntryRequest$ = this.actions$.pipe(
    ofType<TimeActions.TimeEntryAddRequest>(TimeActions.TIME_ENTRY_ADD_REQUEST),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((timeEntry) =>
      from(this.timeService.addTimeEntry(timeEntry)).pipe(
        mergeMap((updatedTimeEntry) =>
          updatedTimeEntry.status == 'success'
            ? [new TimeActions.TimeEntryAddReceive(updatedTimeEntry.data)]
            : [new TimeActions.TimeEntryAddReceiveError(updatedTimeEntry)]
        ),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnTimeEntryAddReceiveError$ = this.actions$.pipe(
    ofType<TimeActions.TimeEntryAddReceiveError>(
      TimeActions.TIME_ENTRY_ADD_RECEIVE_ERROR
    ),
    map((action) => {
      if (action.payload == 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.message
        );
      } else {
        this.alertMessageCommonService.error('Error', action.payload.message);
      }
    })
  );

  @Effect()
  OnDeleteTimeEntryRequest$ = this.actions$.pipe(
    ofType<TimeActions.TimeEntryDeleteRequest>(
      TimeActions.TIME_ENTRY_DELETE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((timeEntryId: string) =>
      from(this.timeService.deleteTimeEntry(timeEntryId)).pipe(
        mergeMap(() => [new TimeActions.TimeEntryDeleteReceive(timeEntryId)]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnLocalByJobRequest$ = this.actions$.pipe(
    ofType<TimeActions.LocalByJobRequest>(TimeActions.LOCAL_BY_JOB_REQUEST),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((jobLocalModel: JobLocalModel) =>
      from(this.timeService.getLocalByJob(jobLocalModel)).pipe(
        mergeMap((data) => [new TimeActions.LocalByJobReceive(data)]),
        catchError((data) => [new TimeActions.LocalByJobReceive(data)])
      )
    )
  );

  @Effect()
  OnTimeEntryNoteRequest$ = this.actions$.pipe(
    ofType<TimeActions.TimeEntryNoteRequest>(
      TimeActions.TIME_ENTRY_NOTE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((noteModel: any) =>
      from(this.timeService.updateTimeEntryNote(noteModel)).pipe(
        mergeMap((data) => [new TimeActions.TimeEntryNoteReceive(data)]),
        catchError((data) => [new TimeActions.TimeEntryNoteReceive(data)])
      )
    )
  );

  @Effect()
  OnTimeEntryNoteRemoveRequest$ = this.actions$.pipe(
    ofType<TimeActions.TimeEntryNoteRemoveRequest>(
      TimeActions.TIME_ENTRY_NOTE_REMOVE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((noteModel: any) =>
      from(this.timeService.updateTimeEntryNoteRemove(noteModel)).pipe(
        mergeMap((data) => [new TimeActions.TimeEntryNoteRemoveReceive(data)]),
        catchError((data) => [new TimeActions.TimeEntryNoteRemoveReceive(data)])
      )
    )
  );

  @Effect()
  OnTimeEntryContainerRemoveRequest$ = this.actions$.pipe(
    ofType<TimeActions.TimeEntryContainerDeleteRequest>(
      TimeActions.TIME_ENTRY_CONTAINER_DELETE_REQUEST
    ),
    map((action) => action.payload),
    switchMap((timeEntryModel: any) =>
      from(
        this.timeService.deleteTimeEntryContainerRemove(timeEntryModel)
      ).pipe(
        mergeMap((data) => [
          new TimeActions.TimeEntryContainerDeleteReceive(
            timeEntryModel.timeEntryIds
          ),
        ]),
        catchError((data) => [
          new TimeActions.TimeEntryContainerDeleteReceive(
            timeEntryModel.user_id
          ),
        ])
      )
    )
  );

  @Effect()
  OnEditTimeEntryRequest$ = this.actions$.pipe(
    ofType<TimeActions.TimeEntryEditRequest>(
      TimeActions.TIME_ENTRY_EDIT_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((timeEntry: TimeModel) =>
      from(this.timeService.editTimeEntry(timeEntry)).pipe(
        mergeMap((updatedTimeEntry) => [
          new TimeActions.TimeEntryEditReceive(updatedTimeEntry),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
}
