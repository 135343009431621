import * as TruckStockActions from './truck-stock.actions';
import {
  initTruckStockState,
  TruckStockStateInterface,
  adapterTruckStock,
} from './truck-stock.state';

export function TruckStockReducer(
  state = initTruckStockState,
  action
): TruckStockStateInterface {
  switch (action.type) {
    case TruckStockActions.TRUCK_STOCK_GET_RECEIVE: {
      const payload = action.payload.TruckStockList.data;
      const isScrolled = action.payload.isScrolled;
      if (isScrolled) {
        return adapterTruckStock.addMany(payload, {
          ...state,
        });
      } else {
        return adapterTruckStock.setAll(payload, state);
      }
    }
    default:
      return state;
  }
}
