import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { map } from 'rxjs/operators';
import { WellLogsBackend } from './well-logs.backend';
import { WellLogsModel } from './model/well-logs.model';

@Injectable()
export class WellLogsService extends BaseService {
  constructor(private wellLogsBackend: WellLogsBackend) {
    super();
  }

  getAllWellLogs(requestObj): Promise<WellLogsModel[] | ErrorMessage> {
    return this.wellLogsBackend
      .getAllWellLogs(requestObj)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getWellLogDetailsById(id): Promise<any | ErrorMessage> {
    return this.wellLogsBackend
      .getWellLogDetailsById(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getAllNotes(payload): Promise<any | ErrorMessage> {
    return this.wellLogsBackend
      .getAllNotes(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  downloadFile(
    data: unknown,
    filename = 'data',
    headerData: string[] = [],
    headerKeys?: string[]
  ) {
    const csvData = this.ConvertToCSV(data, headerData, headerKeys);
    const blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      downloadLink.setAttribute('target', '_blank');
    }
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', filename + '.csv');
    downloadLink.style.visibility = 'hidden';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  ConvertToCSV(objArray, headerDisplayColumn, headerKeys?) {
    const array =
      typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let line = [' '];
    line = [line, ...headerDisplayColumn];
    line.push('\r\n');
    const headerKeyList = headerKeys ? headerKeys : headerDisplayColumn;
    for (let i = 0; i < array.length; i++) {
      for (const header of headerKeyList) {
        let columnValue = array[i][header]?.toString();
        if (columnValue) {
          if (columnValue.includes(',')) {
            columnValue = columnValue.split(',').join('');
          }
          line.push(columnValue);
        } else {
          line.push(' ');
        }
      }
      line.push('\r\n');
    }
    line = line.filter((value) => value.length > 0);
    str = line.join();
    return str;
  }
}
