import { Action, createAction, props } from '@ngrx/store';
import { JobModel } from '../../backend/job/model/job.model';
import { createActionType } from '../../../shared/action/utils';

export const DEPARTMENT_REQUEST = createActionType('[Departments1] Request');
export const DEPARTMENT_RECEIVE = createActionType('[Departments1] Receive');

export const CLASSES_REQUEST = createActionType('[Classes1] Request');
export const CLASSES_RECEIVE = createActionType('[Classes1] Receive');

export const LOCALS_REQUEST = createActionType('[Locals1] Request');
export const LOCALS_RECEIVE = createActionType('[Locals1] Receive');

export class DepartmentsRequest implements Action {
  readonly type = DEPARTMENT_REQUEST;
  constructor(
    public payload: {
      // department_id: number;
      // entry_type_id: number;
      company_number: number;
    }
  ) {}
}
export class DepartmentsReceive implements Action {
  readonly type = DEPARTMENT_RECEIVE;
  constructor(
    public payload: {
      departments: [];
    }
  ) {}
}

export class ClassesRequest implements Action {
  readonly type = CLASSES_REQUEST;
  constructor() {}
}
export class ClassesReceive implements Action {
  readonly type = CLASSES_RECEIVE;
  constructor(
    public payload: {
      classes: [];
    }
  ) {}
}

export class LocalsRequest implements Action {
  readonly type = LOCALS_REQUEST;
  constructor() {}
}
export class LocalsReceive implements Action {
  readonly type = LOCALS_RECEIVE;
  constructor(
    public payload: {
      locals: [];
    }
  ) {}
}
