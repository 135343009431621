import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  groupBy,
  map,
  mergeMap,
  takeUntil,
  toArray,
} from 'rxjs/operators';
import { WorkorderStoreService } from '../../core/workorder/workorder.service';
import * as moment from 'moment';
import { Constants } from '../../constants/constant';
import { UserModel } from '../../core/backend/user/model/user.model';
import { from, Observable, Subject, fromEvent } from 'rxjs';
import { WorkorderScheduleReceiveListModel } from '../../core/backend/service-order/model/workorder-schedule-receive-list.model';
import { LaborWorkorderReceiveListModel } from '../../core/backend/service-order/model/labor-workorder-receive-list.model';
import { PurchaseOrderReceiveListModel } from '../../core/backend/service-order/model/purchase-order-receive-list.model';
import { TruckStockReceiveListModel } from '../../core/backend/service-order/model/truck-stock-receive-list.model';
import { AttachmentReceiveListModel } from '../../core/backend/service-order/model/attachment-receive-list.model';

import { TimeStoreService } from '../../core/time/time.service';
import { ExportService } from '../../shared/export/export.service';
import { SettingStoreService } from '../../core/setting/setting.service';
import { ServiceOrderService } from '../../core/backend/service-order/service-order.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { SiteInfo } from './model/siteInfo.model';
@Component({
  selector: 'app-edit-workorder',
  templateUrl: './edit-workorder.component.html',
  styleUrls: ['./edit-workorder.component.scss'],
})
export class EditWorkorderComponent implements OnInit {
  workorderScheduleByEmployee: any[] = [];
  editWorkOrderId: number;
  createScheduleEmployeeId: number;
  scheduledDates: any[] = [];
  contactName: string;
  contactNumber: number;
  isTechDivExpanded = null;
  workorderScheduleListCompleted = false;
  laborWorkorderListCompleted = false;
  purchaseOrderListCompleted = false;
  truckStockListCompleted = false;
  attachmentListCompleted = false;
  workorderScheduleListPageIndex = 1;
  laborWorkorderListPageIndex = 1;
  purchaseOrderListPageIndex = 1;
  truckStockListPageIndex = 1;
  attachmentListPageIndex = 1;
  CONSTANTS = Constants.WORKORDER;
  isLaborScrolled = false;
  laborWorkorder$: Observable<LaborWorkorderReceiveListModel[]>;
  laborWorkorder: any = [];

  purchaseOrder$: Observable<PurchaseOrderReceiveListModel[]>;
  purchaseOrder: any = [];

  truckStock$: Observable<TruckStockReceiveListModel[]>;
  truckStock: any = [];

  attachment$: Observable<AttachmentReceiveListModel[]>;
  attachment: any = [];

  ngUnsubscribe$: Subject<any> = new Subject<any>();
  @ViewChild('closebutton') closebutton;
  @ViewChild('detailsTb') detailsTb;
  @ViewChild('techniciansTb') techniciansTb;
  @ViewChild('calender') calender;
  @ViewChild('techNote') techNote;
  @ViewChild('contact_name') contact_name;
  @ViewChild('contact_number') contact_number;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('siteSearchTxt') siteSearchTxt;

  users$: Observable<UserModel[]>;
  users: UserModel[] = [];

  sites$: Observable<any>;
  sites: any[] = [];

  siteDetails$: Observable<any>;
  siteDetails: any[] = [];

  costCodes$: Observable<any>;
  costCodes: any[] = [];

  workorderTypes$: Observable<any>;
  workorderTypes: any[] = [];

  workorderSchedule$: Observable<WorkorderScheduleReceiveListModel[]>;
  workorderSchedule: WorkorderScheduleReceiveListModel[] = [];

  @Input()
  fromBillingTab$: boolean;

  selectedWorkorder: any;
  serviceFee = false;
  signatureImg: any;
  noDataText = 'No Records found!';
  updatedWorkOrders: any = [];
  selectedTechnician = '';
  isModalFromSchedule = false;
  workorderScheduleId: any;
  invoiceStatus: any;
  invoiceDetail: any;
  detailTabForm: FormGroup;
  isQuoteRequested: boolean;
  invoiceStatusList: any;
  billingGroup: any;
  siteInfo: SiteInfo = new SiteInfo();
  scheduledDate: string;
  isSiteListShow = false;

  constructor(
    private workorderStoreService: WorkorderStoreService,
    private timeStoreService: TimeStoreService,
    private exportService: ExportService,
    private settingStoreService: SettingStoreService,
    private serviceOrderService: ServiceOrderService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.createDetailTabForm();
    // Details tab API
    this.settingStoreService.requestUsers();
    this.workorderStoreService.requestGetServiceOrderTypes();
    // for Export Tab
    this.getInvoiceStatusList();
  }

  ngAfterViewInit() {
    // make update API call after >3 sec of user input in ElementRef controls
    fromEvent(this.techNote.nativeElement, 'input')
      .pipe(debounceTime(2000))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.updateWorkorderById('techNote');
      });

    fromEvent(this.contact_name.nativeElement, 'input')
      .pipe(debounceTime(2000))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.updateWorkorderById('contactName');
      });

    fromEvent(this.contact_number.nativeElement, 'input')
      .pipe(debounceTime(2000))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.updateWorkorderById('contactNumber');
      });

    fromEvent(this.siteSearchTxt.nativeElement, 'input')
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.getFilterdSiteList();
      });
  }

  createDetailTabForm() {
    this.detailTabForm = new FormGroup({
      siteId: new FormControl(undefined),
      costCodeId: new FormControl(undefined),
      workOrderTypeId: new FormControl(undefined),
      dateRequested: new FormControl(undefined),
      techNote: new FormControl(''),
      statusId: new FormControl(undefined),
      siteDes: new FormControl(''),
    });
  }

  getSelectedWorkorder(selectedWorkorder, scheduledBoard) {
    this.selectedTechnician = '';
    let workOrderId: number;
    if (scheduledBoard == '') {
      this.isModalFromSchedule = false;
      this.workorderScheduleId = selectedWorkorder.work_order_schedule_id;
      workOrderId = selectedWorkorder.id;
    } else {
      this.isModalFromSchedule = true;
      this.workorderScheduleId = selectedWorkorder.id;
      workOrderId = selectedWorkorder.work_order_id;
    }
    this.editWorkOrderId = workOrderId;

    if (selectedWorkorder.activeTab) {
      this.isTechDivExpanded = selectedWorkorder.activeTachnician;
      this.selectedTechnician = selectedWorkorder.activeTachnician;
      this.techniciansTb.nativeElement.click();
    } else {
      // on byEmployee click
      this.detailsTb.nativeElement.click();
      // modifying selectedWorkorder same as other api response for details model
      let wo = selectedWorkorder?.work_order;
      if (wo) {
        selectedWorkorder = {
          ...selectedWorkorder,
          followup_recommendations: wo?.followup_recommendations,
          is_quote_requested: wo?.is_quote_requested,
          work_order_equipment: wo?.work_order_equipment,
        };
      }
    }

    this.selectedWorkorder = selectedWorkorder;

    this.getDataForModal();
    this.getDataFromStore();
    this.setDetailsTabValues(selectedWorkorder);
  }

  getDataForModal() {
    // API call for all tabs
    this.workorderStoreService.requestGetWorkorderSchedule(
      this.getSchedulePostObj(false)
    );
    this.timeStoreService.requestCostCodesByDepartment(
      this.selectedWorkorder.job_id,
      0
    );

    const sitePostObj = {
      searchText: this.siteSearchTxt.nativeElement.value,
    };
    this.workorderStoreService.requestGetServiceOrderSites(sitePostObj);

    this.workorderStoreService.requestGetLaborWorkorder(
      this.getLaborWorkorderPostobj(false)
    );

    this.workorderStoreService.requestGetPurchaseOrder(
      this.getPurchaseOrderPostobj(false)
    );

    this.workorderStoreService.requestGetTruckStock(
      this.getTruckStockPostobj(false)
    );

    this.workorderStoreService.requestGetAttachment(
      this.getAttachmentPostobj(false)
    );

    const postObj = {
      work_order_id: this.editWorkOrderId,
    };

    this.serviceOrderService
      .getInvoiceDetail(postObj)
      .then((res) => {
        this.invoiceDetail = res.data[0];
        this.invoiceStatus = this.invoiceDetail?.invoice_status ?? undefined;
        this.billingGroup = this.invoiceDetail?.billing_group ?? 'N/A';
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getDataFromStore() {
    // get value from store
    this.users$ = this.settingStoreService.getAllUsers();
    this.workorderSchedule$ = this.workorderStoreService.getWorkorderSchedule();
    this.sites$ = this.workorderStoreService.getCeSites();
    this.costCodes$ = this.timeStoreService.getSelectedCostCodes();
    this.workorderTypes$ = this.workorderStoreService.getWorkorderTypes();

    this.sites$
      .pipe(
        filter((data) => !!data),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((sites) => {
        this.sites = sites;
        this.ref.detectChanges();
        this.detailTabForm.patchValue({
          siteId: this.selectedWorkorder?.ce_site_id ?? undefined,
        });
        this.detailTabForm.patchValue({
          siteDes: this.selectedWorkorder?.ce_site_id
            ? this.selectedWorkorder?.ceSite_code +
              '-' +
              this.selectedWorkorder?.ceSite_name
            : '',
        });
        this.detailTabForm.updateValueAndValidity();
        this.setSiteInfo();
      });

    this.costCodes$
      .pipe(
        filter((data) => !!data),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((costCodes) => {
        this.costCodes = costCodes;
        this.ref.detectChanges();
        this.detailTabForm.patchValue({
          costCodeId:
            this.selectedWorkorder?.default_ce_category_id ?? undefined,
        });
        this.detailTabForm.updateValueAndValidity();
      });

    this.workorderTypes$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((workorderTypes) => {
        this.workorderTypes = workorderTypes;
        this.ref.detectChanges();
        this.detailTabForm.patchValue({
          workOrderTypeId:
            this.selectedWorkorder?.work_order_type_id ?? undefined,
        });
        this.detailTabForm.updateValueAndValidity();
      });

    var status_str = '';

    if (this.selectedWorkorder?.status || this.selectedWorkorder?.status == 0) {
      status_str = this.selectedWorkorder?.status.toString();
    } else {
      status_str = this.selectedWorkorder?.work_order_status.toString();
    }

    this.detailTabForm.patchValue({
      statusId: status_str,
    });
    this.detailTabForm.updateValueAndValidity();

    if (!this.selectedWorkorder?.tech_notes) {
      this.detailTabForm.patchValue({
        techNote: '',
      });
    }
    this.detailTabForm.updateValueAndValidity();

    if (!this.selectedWorkorder?.contact_name) {
      // this.detailTabForm.patchValue({
      //   techNote: "",
      // });
    }
    this.detailTabForm.updateValueAndValidity();

    if (!this.selectedWorkorder?.contact_number) {
      // this.detailTabForm.patchValue({
      //   techNote: "",
      // });
    }
    this.detailTabForm.updateValueAndValidity();

    this.users$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((users) => (this.users = users));

    this.workorderSchedule$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((workorderSchedule) => {
        this.groupWorkorderScheduleByEmployeeId(workorderSchedule);
        if (this.workorderScheduleListPageIndex >= 1) {
          if (
            workorderSchedule.length < this.CONSTANTS.SCHEDULELIST.PAGE_ITEM
          ) {
            this.workorderScheduleListCompleted = true;
          }
        }
      });

    this.laborWorkorder$ = this.workorderStoreService.getLaborWorkorder();
    this.laborWorkorder$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((laborWorkorder) => {
        this.laborWorkorder = laborWorkorder;
        if (this.laborWorkorderListPageIndex >= 1) {
          if (laborWorkorder.length < this.CONSTANTS.SCHEDULELIST.PAGE_ITEM) {
            this.laborWorkorderListCompleted = true;
          }
        }
      });

    this.purchaseOrder$ = this.workorderStoreService.getPurchaseOrder();
    this.purchaseOrder$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((purchaseOrder) => {
        this.purchaseOrder = purchaseOrder;
        if (this.purchaseOrderListPageIndex >= 1) {
          if (purchaseOrder.length < this.CONSTANTS.SCHEDULELIST.PAGE_ITEM) {
            this.purchaseOrderListCompleted = true;
          }
        }
      });

    this.truckStock$ = this.workorderStoreService.gettruckStock();
    this.truckStock$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((truckStock) => {
        this.truckStock = truckStock;
        if (this.truckStockListPageIndex >= 1) {
          if (truckStock.length < this.CONSTANTS.SCHEDULELIST.PAGE_ITEM) {
            this.truckStockListCompleted = true;
          }
        }
      });

    this.attachment$ = this.workorderStoreService.getAttachment();
    this.attachment$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((attachment) => {
        this.attachment = attachment;
        if (this.attachmentListPageIndex >= 1) {
          if (attachment.length < this.CONSTANTS.SCHEDULELIST.PAGE_ITEM) {
            this.attachmentListCompleted = true;
          }
        }
      });
  }

  getFilterdSiteList() {
    // API call
    const sitePostObj = {
      searchText: this.siteSearchTxt.nativeElement.value,
    };
    this.workorderStoreService.requestGetServiceOrderSites(sitePostObj);

    // set data from store
    this.sites$ = this.workorderStoreService.getCeSites();
  }

  setSiteInfo() {
    let data = this.selectedWorkorder;
    this.siteInfo.name = data.ceSite_name;
    (this.siteInfo.address1 = data.ceSite_address1),
      (this.siteInfo.address2 = data.ceSite_address2),
      (this.siteInfo.address3 = data.ceSite_address3),
      (this.siteInfo.phone = data.ceSite_phone),
      (this.siteInfo.notes = data.ceSite_notes);
  }

  setSiteInfoModel() {
    let data = this.siteDetails[0];
    (this.siteInfo.name = data?.name),
      (this.siteInfo.address1 = data?.address1),
      (this.siteInfo.address2 = data?.address2),
      (this.siteInfo.address3 = data?.address3),
      (this.siteInfo.address4 = data?.address4),
      (this.siteInfo.phone = data?.phone),
      (this.siteInfo.notes = data?.notes);
  }

  setDetailsTabValues(workorder) {
    this.ref.detectChanges();
    let dateRequested = workorder?.date_requested ?? undefined;
    // format date according to date input field
    if (dateRequested) {
      dateRequested =
        dateRequested.split('/')[2] +
        '-' +
        dateRequested.split('/')[0] +
        '-' +
        dateRequested.split('/')[1];
    }
    this.detailTabForm.patchValue({
      dateRequested: dateRequested,
    });
    this.detailTabForm.patchValue({
      techNote: workorder.tech_notes,
    });
    this.detailTabForm.updateValueAndValidity();
    this.contactName = workorder.contact_name;
    this.contactNumber = workorder.contact_number;
    // completed WO fields
    this.serviceFee = workorder?.charge_service_fee == 1 ? true : false;
    this.signatureImg = workorder?.signature_link;
    this.isQuoteRequested = workorder?.is_quote_requested == 1 ? true : false;

    this.detailTabForm.updateValueAndValidity();
  }

  getSelectedSiteDetails() {
    // API call to get site info
    let sitePostObj = {
      site_id: this.detailTabForm.value.siteId,
    };
    this.workorderStoreService.requestGetServiceOrderSites(sitePostObj);

    // get data from store
    this.siteDetails$ = this.workorderStoreService.getCeSiteInfo();
    this.siteDetails$
      .pipe(
        filter((data) => !!data),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((sites: any) => {
        // on siteChange get site info by site_id
        this.siteDetails = sites;
        this.ref.detectChanges();
        this.detailTabForm.patchValue({
          siteId: this.siteDetails[0]?.id ?? undefined,
        });
        this.detailTabForm.patchValue({
          siteDes: this.siteDetails[0]?.id
            ? this.siteDetails[0]?.code + ' - ' + this.siteDetails[0]?.name
            : '',
        });

        this.detailTabForm.updateValueAndValidity();
        if (this.siteDetails) this.setSiteInfoModel();
      });
  }

  onSiteSearch(evt) {
    if (evt.target.value.length > 2 || evt.target.value.length == 0) {
      this.getFilterdSiteList();
    }
  }

  updateWorkorderById(from, siteObj?) {
    this.isModalFromSchedule = true;
    let postData = {
      work_order_id: this.editWorkOrderId,
    };
    switch (from) {
      case 'siteId':
        this.isSiteListShow = false; // Hide the site list
        this.detailTabForm.patchValue({
          siteId: siteObj ? siteObj.id : undefined,
        });
        this.detailTabForm.patchValue({
          siteDes: siteObj?.id ? siteObj?.code + ' - ' + siteObj?.name : '',
        });
        this.detailTabForm.updateValueAndValidity();

        postData['ce_site_id'] = this.detailTabForm.value.siteId;
        this.getSelectedSiteDetails();
        this.siteSearchTxt.nativeElement.value = '';
        break;
      case 'costCodeId':
        postData[
          'default_ce_category_id'
        ] = this.detailTabForm.value.costCodeId;
        break;
      case 'workOrderTypeId':
        postData[
          'work_order_type_id'
        ] = this.detailTabForm.value.workOrderTypeId;
        break;
      case 'dateRequested':
        postData['date_requested'] = this.detailTabForm.value.dateRequested;
        break;
      case 'techNote':
        postData['tech_notes'] = this.detailTabForm.value.techNote;
        break;
      case 'contactName':
        postData['contact_name'] = this.contactName;
        break;
      case 'contactNumber':
        postData['contact_number'] = this.contactNumber;
        break;
      case 'statusId':
        postData['status'] = this.detailTabForm.value.statusId;
        break;
      default:
        break;
    }
    // API call
    this.workorderStoreService.requestUpdateServiceOrder(
      postData,
      false,
      this.isModalFromSchedule
    );
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      // "clicked inside";
      if (event.target.name == 'siteDes') {
        this.isSiteListShow = true; // Show the site list
        const element = this.siteSearchTxt.nativeElement;
        setTimeout(() => element.focus(), 0);
        // const elementEdit = this.jobSearchEditTxt.nativeElement;
        // setTimeout(() => elementEdit.focus(), 0);
        // this.findLastIndex(this.jobs, 0, '1');
      } else {
        this.isSiteListShow = false; // Hide the site list
        if (this.myScrollContainer != undefined) {
          this.myScrollContainer.nativeElement.scrollTop =
            this.myScrollContainer.nativeElement.scrollTop -
            this.myScrollContainer.nativeElement.scrollHeight;
        }
      }
    }
  }

  getInvoiceStatusList() {
    this.serviceOrderService
      .getInvoiceStatus()
      .then((res: any) => {
        this.invoiceStatusList = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getLaborWorkorderPostobj(isLaborScrolled) {
    const postObj = {
      searchPage: isLaborScrolled
        ? this.laborWorkorderListPageIndex
        : this.CONSTANTS.SCHEDULELIST.PAGE_INDEX,
      pageItems: this.CONSTANTS.SCHEDULELIST.PAGE_ITEM,
      work_order_id: this.editWorkOrderId,
      isScrolled: isLaborScrolled,
    };
    return postObj;
  }

  getPurchaseOrderPostobj(isScrolled) {
    const postObj = {
      searchPage: isScrolled
        ? this.purchaseOrderListPageIndex
        : this.CONSTANTS.SCHEDULELIST.PAGE_INDEX,
      pageItems: this.CONSTANTS.SCHEDULELIST.PAGE_ITEM,
      work_order_id: this.editWorkOrderId,
      isScrolled: isScrolled,
    };
    return postObj;
  }

  getTruckStockPostobj(isScrolled) {
    const postObj = {
      searchPage: isScrolled
        ? this.truckStockListPageIndex
        : this.CONSTANTS.SCHEDULELIST.PAGE_INDEX,
      pageItems: this.CONSTANTS.SCHEDULELIST.PAGE_ITEM,
      work_order_id: this.editWorkOrderId,
      isScrolled: isScrolled,
    };
    return postObj;
  }

  getAttachmentPostobj(isScrolled) {
    const postObj = {
      searchPage: isScrolled
        ? this.attachmentListPageIndex
        : this.CONSTANTS.SCHEDULELIST.PAGE_INDEX,
      pageItems: this.CONSTANTS.SCHEDULELIST.PAGE_ITEM,
      work_order_id: this.editWorkOrderId,
      isScrolled: isScrolled,
    };
    return postObj;
  }

  createWorkorderSchedule() {
    // API call
    const postData = {
      ce_employee_id: this.createScheduleEmployeeId,
      work_order_id: this.editWorkOrderId,
      date_scheduled: this.scheduledDates,
    };
    this.workorderStoreService.requestCreateWorkorderSchedule(postData);
  }

  onScheduleDate(event, workorderSchedule) {
    this.scheduledDates = [];
    this.createScheduleEmployeeId = workorderSchedule.employeeId;
    let scheduledDate = this.exportService.formatToYearMonthDay(event.value);
    this.scheduledDates.push(scheduledDate);
    this.createWorkorderSchedule();
  }

  get formControl() {
    return this.detailTabForm.controls;
  }

  onRemoveSchedule(scheduledEntry, removedDate?) {
    // removing technician from workorderScheduleByEmployee array
    if (!scheduledEntry.hasOwnProperty('workOrderId')) {
      this.workorderScheduleByEmployee = this.workorderScheduleByEmployee.filter(
        (item) => {
          return item.employeeId != scheduledEntry.employeeId;
        }
      );
      return;
    }
    const postData = {
      ce_employee_id: scheduledEntry.employeeId,
      work_order_id: scheduledEntry.workOrderId,
    };
    if (removedDate) {
      let dateArray = [];
      let formatedDate = this.exportService.formatToYearMonthDay(removedDate);
      dateArray.push(formatedDate);
      postData['date_scheduled'] = dateArray;
    }
    this.workorderStoreService.requestWorkorderScheduleDelete(postData);
  }

  formatDate(date) {
    return moment(date).format('MMMM DD, YYYY').toString();
  }

  formatToMonthDayYear(date) {
    return this.exportService.formatToMonthDayYear(date);
  }

  onScrollTechnicianListDiv(evt) {
    if (
      evt.target.offsetHeight + evt.target.scrollTop >=
      evt.target.scrollHeight
    ) {
      if (!this.workorderScheduleListCompleted) {
        this.workorderScheduleListPageIndex++;

        // API call
        this.workorderStoreService.requestGetWorkorderSchedule(
          this.getSchedulePostObj(true)
        );
      }
    }
  }

  onScrollLaborWorkorder(evt) {
    if (
      evt.target.offsetHeight + evt.target.scrollTop >=
      evt.target.scrollHeight
    ) {
      if (!this.laborWorkorderListCompleted) {
        this.laborWorkorderListPageIndex++;

        // API call
        this.workorderStoreService.requestGetLaborWorkorder(
          this.getLaborWorkorderPostobj(true)
        );
      }
    }
  }

  onScrollPurchaseOrder(evt) {
    if (
      evt.target.offsetHeight + evt.target.scrollTop >=
      evt.target.scrollHeight
    ) {
      if (!this.purchaseOrderListCompleted) {
        this.purchaseOrderListPageIndex++;

        // API call
        this.workorderStoreService.requestGetPurchaseOrder(
          this.getPurchaseOrderPostobj(true)
        );
      }
    }
  }

  onScrollTruckStock(evt) {
    if (
      evt.target.offsetHeight + evt.target.scrollTop >=
      evt.target.scrollHeight
    ) {
      if (!this.truckStockListCompleted) {
        this.truckStockListPageIndex++;

        // API call
        this.workorderStoreService.requestGetTruckStock(
          this.getTruckStockPostobj(true)
        );
      }
    }
  }

  onScrollAttachment(evt) {
    if (
      evt.target.offsetHeight + evt.target.scrollTop >=
      evt.target.scrollHeight
    ) {
      if (!this.attachmentListCompleted) {
        this.attachmentListPageIndex++;

        // API call
        this.workorderStoreService.requestGetAttachment(
          this.getAttachmentPostobj(true)
        );
      }
    }
  }

  getSchedulePostObj(isScrolled) {
    const postObj = {
      searchPage: isScrolled
        ? this.workorderScheduleListPageIndex
        : this.CONSTANTS.SCHEDULELIST.PAGE_INDEX,
      pageItems: this.CONSTANTS.SCHEDULELIST.PAGE_ITEM,
      ce_work_order_id: this.editWorkOrderId,
      isScrolled: isScrolled,
    };
    return postObj;
  }

  addEmployeeScheduleEntry(employeeId) {
    // prevent adding duplicate schedule entry groups
    if (
      this.workorderScheduleByEmployee.filter(
        (group) => group.employeeId.toString() === employeeId
      ).length > 0
    ) {
      return;
    }

    const employee = this.users.find(
      (user) => user['ce_employee_id'].toString() === employeeId
    );

    this.workorderScheduleByEmployee.push({
      employeeId,
      employeeName: `${employee.lastname}, ${employee.firstname}`,
    });
  }

  groupWorkorderScheduleByEmployeeId(
    scheduleEntries: WorkorderScheduleReceiveListModel[]
  ) {
    this.workorderScheduleByEmployee = [];

    from(scheduleEntries)
      .pipe(
        groupBy((person) => person.ce_employee_id.toString()),
        // return each item in group as array
        mergeMap((group) => group.pipe(toArray()))
      )
      .subscribe((WorkorderScheduleByEmployeeId: any) => {
        this.addScheduleEntryGroup(
          WorkorderScheduleByEmployeeId[0].ce_employee_id,
          `${WorkorderScheduleByEmployeeId[0].employee_last_name}, ${WorkorderScheduleByEmployeeId[0].employee_first_name}`,
          WorkorderScheduleByEmployeeId[0].work_order_id,
          WorkorderScheduleByEmployeeId
        );
      });
  }

  addScheduleEntryGroup(
    employeeId,
    employeeName,
    workOrderId,
    scheduledEntries
  ) {
    this.workorderScheduleByEmployee.push({
      employeeId,
      employeeName,
      workOrderId,
      scheduledEntries,
    });
  }

  onServiceFeeClick() {
    this.serviceFee = !this.serviceFee;

    const postData = {
      work_order_id: this.editWorkOrderId,
      charge_service_fee: this.serviceFee == true ? 1 : 0,
    };
    // API call
    this.workorderStoreService.requestUpdateServiceOrder(
      postData,
      false,
      this.isModalFromSchedule
    );
  }

  downloadImg(blob, isAttachment) {
    this.exportService.downloadImg(blob, isAttachment);
  }

  updateInvoiceStatus() {
    const postData = {
      work_order_id: this.editWorkOrderId,
      invoice_status: parseInt(this.invoiceStatus),
    };
    // API call
    this.workorderStoreService.requestUpdateServiceOrder(
      postData,
      false,
      this.isModalFromSchedule
    );
  }

  generateInvoice() {
    this.exportService.generateInvoice(this.editWorkOrderId);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.exportService.closeAllModals();
  }
}
