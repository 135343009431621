import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ServiceOrderReceiveListModel } from '../../backend/service-order/model/service-order-receive-list.model';

export interface ServiceOrderStateInterface
  extends EntityState<ServiceOrderReceiveListModel> {
  serviceOrder: ServiceOrderReceiveListModel;
  sites: [];
  siteInfo: [];
  workorderTypes: [];
  createdWorkorder: [];
}

export const adapterServiceOrder: EntityAdapter<ServiceOrderReceiveListModel> = createEntityAdapter<ServiceOrderReceiveListModel>();

export const initServiceOrderState: ServiceOrderStateInterface = adapterServiceOrder.getInitialState(
  {
    serviceOrder: null,
    sites: [],
    siteInfo: [],
    workorderTypes: [],
    createdWorkorder: [],
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterServiceOrder.getSelectors();
