import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule as RxStoreModule } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  NavigationActionTiming,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { RouterSerializer } from './store.utils';
import { StoreServicesModule } from './store-services.module';
import { APP_REDUCER_TOKEN, AppReducers, metaReducers } from './store.reducers';
import { RegisterEffects } from '../auth/register/register.effects';
import { TokenEffects } from '../auth/token/token.effects';
import { RouterEffects } from '../router/router.effect';
import { AuthenticatedUserEffects } from '../auth/authenticated-user/authenticated-user.effects';
import { JobEffects } from '../job/job.effects';
import { UserEffects } from '../setting/user/user.effects';

import { RoleEffects } from '../setting/role/role.effects';

import { SynclogEffects } from '../setting/synclog/synclog.effects';
import { TimeEffects } from '../time/time.effects';
import { TotalTimeEffects } from '../totalTime/totalTime.effects';
import { ForgotpasswordEffects } from '../auth/forgotpassword/forgotpassword.effects';
import { CostCodeEffects } from '../time/cost-code/cost-code.effects';
import { JobsByCompanyEffects } from '../time/jobs-company/jobs-company.effects';
import { ByEmployeeEffects } from '../time/by-employee/by-employee.effects';
import { ManpowerEffects } from '../manpower/manpower.effects';
import { ManpowerSettingsEffects } from '../setting/manpower/settingsmanpower.effects';
import { ErrorEffects } from '../error/error.effect';
import { PayperiodLockoutEffects } from '../payperiod-lockout/payperiod-lockout.effects';
import { ServiceOrderEffects } from '../workorder/service-order/service-order.effects';
import { WorkorderScheduleEffects } from '../workorder/workorder-schedule/workorder-schedule.effects';
import { WorkorderScheduleBydayEffects } from '../workorder/workorder-schedule-byday/workorder-schedule-byday.effects';
import { WorkorderScheduleByEmployeeEffects } from '../workorder/workorder-schedule-byEmployee/workorder-schedule-byEmployee.effects';
import { LaborWorkorderEffects } from '../workorder/labor-workorder/labor-workorder.effects';
import { PurchaseOrderEffects } from '../workorder/purchase-orders/purchase-orders.effects';
import { TruckStockEffects } from '../workorder/truck-stock/truck-stock.effects';
import { AttachmentEffects } from '../workorder/attachments/attachments.effects';
import { BillingRateEffects } from '../billing-rate/billing-rate.effects';
import { PermissionEffects } from '../setting/permission/permission.effects';
import { WellLogsEffects } from '../well-logs/well-logs.effects';

const EFFECTS = [
  RegisterEffects,
  TokenEffects,
  RouterEffects,
  AuthenticatedUserEffects,
  JobEffects,
  UserEffects,
  RoleEffects,
  SynclogEffects,
  TimeEffects,
  TotalTimeEffects,
  ForgotpasswordEffects,
  CostCodeEffects,
  JobsByCompanyEffects,
  ByEmployeeEffects,
  ManpowerEffects,
  ManpowerSettingsEffects,
  ErrorEffects,
  PayperiodLockoutEffects,
  ServiceOrderEffects,
  WorkorderScheduleEffects,
  WorkorderScheduleBydayEffects,
  WorkorderScheduleByEmployeeEffects,
  LaborWorkorderEffects,
  PurchaseOrderEffects,
  TruckStockEffects,
  AttachmentEffects,
  BillingRateEffects,
  PermissionEffects,
  WellLogsEffects,
];

@NgModule({
  imports: [
    EffectsModule.forRoot(EFFECTS),
    RxStoreModule.forRoot(APP_REDUCER_TOKEN, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
        })
      : [],
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      serializer: RouterSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    StoreServicesModule.forRoot(),
  ],
  providers: [{ provide: APP_REDUCER_TOKEN, useValue: AppReducers }],
})
export class StoreModule {}
