<div class="user_main_component">
  <div class="user_main_component_top">
    <div class="row">
      <div class="col-sm-7 user_main_component_mboile_box">
        <div class="Iuser_main_component_top_inner">
          <div class="f_p_searchk">
            <input
              type="text"
              class="form-control"
              placeholder="Search"
              (keyup)="doFilter($event)"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="UIright_main">
          <div class="UIright_main_info" id="roles_UIright_main_info"></div>
          <!-- <button
            class="btn btn-primary add_role_btn"
            (click)="openAddRoleModal()"
            (mouseup)="triggerOverlay('On')"
            data-toggle="modal"
            data-target="#add_role_modal"
          >
            Add
          </button> -->
          <div class="UIright_main_viewd" id="roles_UIright_main_viewd"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="dt_cooltable defaultcheckqbox">
    <!-- Mat Table -->
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td
          mat-cell
          *matCellDef="let role"
          matTooltip="{{ role.role }}"
          matTooltipPosition="left"
        >
          <a
            *ngIf="editRoles"
            href=""
            (click)="openEditRoleModal(role)"
            (mouseup)="triggerOverlay('On')"
            data-toggle="modal"
            data-target="#add_role_modal"
          >
            {{ role.role }}
          </a>
          <span *ngIf="!editRoles">
            {{ role.role }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="created_date_filtered">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</th>
        <td
          mat-cell
          *matCellDef="let role"
          matTooltip="{{ filterDate(role.created_date) }}"
          matTooltipPosition="left"
        >
          {{ filterDate(role.created_date) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="total_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Number of Users
        </th>
        <td
          mat-cell
          *matCellDef="let role"
          matTooltip="{{ role.total_user }}"
          matTooltipPosition="left"
        >
          {{ role.total_user }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>

    <!-- End of Mat Table -->
  </div>
</div>

<!-- Modal add Staff-->
<div
  class="modal fade"
  id="roleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit Role</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (mouseup)="triggerOverlay('Off')"
        >
          <img src="../../assets/close.png" class="" />
        </button>
      </div>
      <div class="modal-body padzero">
        <div class="modal-settopBar">
          <div class="modal_inner_set form_cool">
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label>Role Name</label>
                  <input
                    type="text"
                    class="form-control"
                    value="Superintendent"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal_inner_set form_cool ableExpo">
          <table width="100%" class="forcheckqbox">
            <thead>
              <tr>
                <th>View</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Dashboard</td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      value=""
                      class="group-checkable"
                      checked=""
                    />
                    <span></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Students</td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Schools</td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      value=""
                      class="group-checkable"
                      checked=""
                    />
                    <span></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Partners</td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      value=""
                      class="group-checkable"
                      checked=""
                    />
                    <span></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Programs</td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      value=""
                      class="group-checkable"
                      disabled=""
                      checked=""
                    />
                    <span></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Partners</td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      value=""
                      class="group-checkable"
                      checked=""
                    />
                    <span></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Programs</td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      value=""
                      class="group-checkable"
                      disabled=""
                      checked=""
                    />
                    <span></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Partners</td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      value=""
                      class="group-checkable"
                      checked=""
                    />
                    <span></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Programs</td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      value=""
                      class="group-checkable"
                      disabled=""
                      checked=""
                    />
                    <span></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Partners</td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      value=""
                      class="group-checkable"
                      checked=""
                    />
                    <span></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Programs</td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input type="checkbox" value="" class="group-checkable" />
                    <span></span>
                  </label>
                </td>
                <td class="dtr-control">
                  <label class="checkbox checkbox-single">
                    <input
                      type="checkbox"
                      value=""
                      class="group-checkable"
                      disabled=""
                      checked=""
                    />
                    <span></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-light-primary font-weight-bold cool_close"
          data-dismiss="modal"
          (mouseup)="triggerOverlay('Off')"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary form_brntnxd"
          (mouseup)="triggerOverlay('Off')"
        >
          Save & Close
        </button>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<!-- Modal for add/edit role -->
<div id="add_role_modal" class="modal fade add_edit_role" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ roleHeadingText }} Role</h4>
        <div class="forcloseserviceorder">
          <a
            href="javascript:void(0);"
            data-dismiss="modal"
            aria-label="Close"
            (mouseup)="triggerOverlay('Off')"
          >
            <img src="../../assets/closeRx.png" />
          </a>
        </div>
      </div>
      <div class="modal-body">
        <div class="form_row_set">
          <div class="row">
            <div class="col-sm-7">
              <div class="form-group relative-form-group">
                <label>Role Name</label>
                <input
                  type="text"
                  class="form-control cursor role_input"
                  [(ngModel)]="roleText"
                  (input)="onRoleChange($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <table
          style="width: 100%; background-color: #ffffff"
          class="table table-head-custom table-checkable nowrap foinnertable loadtablexs"
        >
          <thead>
            <tr>
              <th></th>
              <th>View</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of permissionsData; let i = index">
              <td>{{ data.name }}</td>
              <td>
                <select
                  id="{{ 'view_' + i }}"
                  class="form-control select2"
                  (change)="handlePermission($event, data.id, i, 'view')"
                  [ngModel]="
                    bindOptions(employeeRolePermission, data.name, 'view')
                  "
                >
                  <option value="undefined" selected disabled>Select</option>
                  <option
                    *ngFor="let option of data.view_options.split(',')"
                    [value]="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </td>
              <td>
                <select
                  id="{{ 'edit_' + i }}"
                  class="form-control select2"
                  (change)="handlePermission($event, data.id, i, 'edit')"
                  [ngModel]="
                    bindOptions(employeeRolePermission, data.name, 'edit')
                  "
                >
                  <option value="undefined" selected disabled>Select</option>
                  <option
                    *ngFor="let option of data.edit_options.split(',')"
                    [value]="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary cancel"
          data-dismiss="modal"
          #closebutton
          (mouseup)="triggerOverlay('Off')"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary saveRclose"
          data-toggle="modal"
          data-target="#event_serviceorder"
          (click)="savePermissionData()"
          (mouseup)="triggerOverlay('Off')"
        >
          Save & Close
        </button>
      </div>
    </div>
  </div>
</div>
