import { createSelector } from '@ngrx/store';
import { getPurchaseOrderState } from '../workorder.selectors';
import {
  PurchaseOrderStateInterface,
  selectAll,
} from '../purchase-orders/purchase-orders.state';

export const getPurchaseOrder = createSelector(
  getPurchaseOrderState,
  (state: PurchaseOrderStateInterface) => selectAll(state)
);
