export class UserBackendModel {
  id: number;
  // tslint:disable-next-line:variable-name
  employee_first_name: string;
  employee_last_name: string;
  employee_role_id: number;
  phone: number;
  email_address: string;
  username: string;
  password_reset_token: any;
  password: string;
}
