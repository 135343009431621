import * as RoleActions from './role.actions';
import { adapterRole, initRoleState, RoleStateInterface } from './role.state';

export function RoleReducer(state = initRoleState, action): RoleStateInterface {
  switch (action.type) {
    case RoleActions.ROLES_RECEIVE: {
      const payload = action.payload.data;

      return adapterRole.addMany(payload, {
        ...state,
      });
    }

    default:
      return state;
  }
}
