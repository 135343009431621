import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as RouterSelectors from './router.selectors';
import { CDSAppState } from '../store/store.state';
import { Observable } from 'rxjs';

@Injectable()
export class RouterStoreService {
  constructor(private store: Store<CDSAppState>) {}

  public getCurrentRoute(): Observable<any> {
    return this.store.pipe(
      select((state: any) => RouterSelectors.getCurrentRoute(state))
    );
  }
}
