import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { JobsModel } from '../backend/manpower/model/jobs.model';
export interface ManpowerInterface extends EntityState<JobsModel> {
  // additional entity state properties
  apprentice: number;
  journeymen: number;
  sm_unresolved_count: number;
  pl_unresolved_count: number;
  users: [];
  nonManpowerJobs: [];
  foremans: [];
  sm_foremans: [];
  pipe_foremans: [];
  projectManagers: [];
  performanceNotes: [];
  filters: {
    classes: [];
    locals: [];
    locations: [];
  };
  jobFilters: {
    project_managers: [];
    foremans: [];
    locations: [];
  };
  jobNotes: [];
  empJobDetails: {};
}

export const adapterManpowerJobs: EntityAdapter<JobsModel> = createEntityAdapter<JobsModel>();

export const initManpowerInterfaceState: ManpowerInterface = adapterManpowerJobs.getInitialState(
  {
    apprentice: 0,
    journeymen: 0,
    sm_unresolved_count: 0,
    pl_unresolved_count: 0,
    users: [],
    nonManpowerJobs: [],
    foremans: [],
    sm_foremans: [],
    pipe_foremans: [],
    projectManagers: [],
    performanceNotes: [],
    filters: {
      classes: [],
      locals: [],
      locations: [],
    },
    jobFilters: {
      project_managers: [],
      foremans: [],
      locations: [],
    },
    jobNotes: [],
    empJobDetails: {},
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterManpowerJobs.getSelectors();
