import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { map } from 'rxjs/operators';
import { TotalTimeBackend } from './totalTime.backend';
import { TotalTimeModel } from './model/totalTime.model';

@Injectable()
export class TotalTimeService extends BaseService {
  constructor(private totalTimeBackend: TotalTimeBackend) {
    super();
  }

  getAllTotalTimeEntry(): Promise<TotalTimeModel[] | ErrorMessage> {
    return this.totalTimeBackend
      .fetchAllTotalTimeEntry()
      .pipe(map((response) => response.body))
      .toPromise();
  }

  TotalTimeEntry(timeEntry: TotalTimeModel): Promise<any | ErrorMessage> {
    return this.totalTimeBackend
      .fetchTotalTimeEntry(timeEntry)
      .pipe(map((response) => response.body))
      .toPromise();
  }
}
