export interface PayPeriodLockoutStateInterface {
  timeEntryLockStatus: boolean;
  isDateLocked: boolean;
  payPeriodLockout: any;
}

export const initPayPeriodLockoutState: PayPeriodLockoutStateInterface = {
  timeEntryLockStatus: null,
  isDateLocked: null,
  payPeriodLockout: null,
};
