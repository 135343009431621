import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SynclogModel } from '../../backend/synclog/model/synclog.model';

export interface SynclogStateInterface extends EntityState<SynclogModel> {
  // additional entity state properties
  selectedSynclogId: number | null;
  newSynclog: SynclogModel;
}

export const adapterSynclog: EntityAdapter<SynclogModel> = createEntityAdapter<SynclogModel>();

export const initSynclogState: SynclogStateInterface = adapterSynclog.getInitialState(
  {
    selectedSynclogId: null,
    newSynclog: null,
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterSynclog.getSelectors();
