<div class="user_main_component">
  <div class="user_main_component_top">
    <div class="row">
      <div class="col-sm-7 user_main_component_mboile_box">
        <div class="Iuser_main_component_top_inner">
          <div class="f_p_searchk">
            <input
              type="text"
              class="form-control"
              placeholder="Search"
              (keyup)="doFilter($event)"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="UIright_main">
          <div class="UIright_main_info" id="user_UIright_main_info"></div>
          <div
            class="UIright_main_pagination"
            id="user_UIright_main_pagination"
          ></div>
          <div class="UIright_main_viewd" id="user_UIright_main_viewd"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="dt_cooltable defaultcheckqbox">
    <!-- Mat Table -->
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip=" {{ element?.lastname }}, {{ element?.firstname }}"
          matTooltipPosition="left"
        >
          <a
            *ngIf="editUsers"
            href="javascript:;"
            data-toggle="modal"
            data-target="#userModal"
            (click)="toggleUserEditModal('Edit', element.id)"
            (mouseup)="triggerOverlay('On')"
          >
            {{ element?.lastname }}, {{ element?.firstname }}
          </a>
          <span *ngIf="!editUsers">
            {{ element?.lastname }}, {{ element?.firstname }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.role }}"
          matTooltipPosition="left"
        >
          {{ element.role }}
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.phone }}"
          matTooltipPosition="left"
        >
          {{ element.phone }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.email }}"
          matTooltipPosition="left"
        >
          {{ element.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastLogin_filtered">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Login</th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ filterDate(element.lastlogin) }}"
          matTooltipPosition="left"
        >
          {{ element.last_login | date: "M/d/yy, h:mm a" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.status }}"
          matTooltipDisabled="true"
        >
          {{ element.status == 1 ? "Active" : "Inactive" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>

    <!-- End of Mat Table -->
  </div>
</div>
<!-- Modal add Staff -->
<div
  class="modal fade"
  id="userModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <form [formGroup]="userForm" *ngIf="userForm">
      <div class="modal-content jsUserForm">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit User</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (mouseup)="triggerOverlay('Off')"
          >
            <img src="../../assets/close.png" class="" />
          </button>
        </div>
        <div class="modal-body">
          <input
            type="hidden"
            *ngIf="mode == 'Edit'"
            class="form-control"
            name="id"
            formControlName="id"
          />
          <div class="modal_inner_set form_cool">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="firstname"
                    id="firstname"
                    formControlName="firstname"
                    readonly
                  />
                  <span
                    class="error"
                    *ngIf="
                      userForm.get('firstname').hasError('required') &&
                      userForm.get('firstname').touched
                    "
                  >
                    Please provide first name
                  </span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="lastname"
                    id="lastname"
                    name="lastname"
                    readonly
                  />
                  <span
                    class="error"
                    *ngIf="
                      userForm.get('lastname').hasError('required') &&
                      userForm.get('lastname').touched
                    "
                  >
                    Please provide last name
                  </span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Role</label>
                  <select
                    class="form-control select2"
                    formControlName="role"
                    [(ngModel)]="selectedRole"
                  >
                    <option value="">Select Role</option>
                    <option
                      *ngFor="let role of configuredRoles"
                      [value]="role?.id"
                    >
                      {{ role.role }}
                    </option>
                    <!-- <option value="1">Super Admin</option>
                    <option value="2">PM Supervisor</option>
                    <option value="3">Pipe Foreman</option>
                    <option value="4">SM Foreman</option>
                    <option value="5">Pipe User</option>
                    <option value="6">SM User</option>
                    <option value="7">General User</option>
                    <option value="8">Admin</option>
                    <option value="9">Manpower Admin</option>
                    <option value="10">Service User</option>
                    <option value="12">Service User Plus</option> -->
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <label>Status</label>
                  <select
                    class="form-control select2"
                    formControlName="status"
                    #status
                    (change)="updateForm('status', status.value)"
                    disabled
                  >
                    <option selected="selected">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                  <div
                    style="
                      width: 0 !important;
                      height: 0 !important;
                      overflow: hidden !important;
                    "
                  >
                    <input
                      type="text"
                      formControlName="status"
                      id="status"
                      (blur)="updateForm('status', status.value)"
                    />
                  </div>
                </div>
              </div>
              <!-- <div class="col-sm-3">
                <div class="form-group">
                  <label>Worker Class</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="class"
                    name="class"
                    readonly
                  />
                </div> 
              </div>-->
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="phone"
                    id="phone"
                    name="phone"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="username"
                    name="username"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="email"
                    name="email"
                    readonly
                  />
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Notes</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="note"
                    name="note"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light-primary font-weight-bold cool_close"
            data-dismiss="modal"
            (mouseup)="triggerOverlay('Off')"
          >
            Cancel
          </button>
          <span>
            Password Reset Link:
            <a href="javascript:void(0);" (click)="sendByEmail()">
              send by email
            </a>
            or
            <a href="javascript:void(0);">text message</a>
          </span>
          <button
            type="button"
            class="btn btn-primary form_brntnxd"
            (click)="onSubmit()"
            (mouseup)="triggerOverlay('Off')"
          >
            Save & Close
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
