import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { UserAccount } from './model/user-account.model';
import { ErrorMessage } from '../shared/model/error-message';
import { Observable, of } from 'rxjs';
import { UserCredential } from '../../auth/model/user-credential.model';
import { UserForgotPassword } from '../../auth/model/user-forgot-password.model';
import { UserForgotPasswordMobile } from '../../auth/model/user-forgot-password-mobile.model';
import { UserResetPassword } from '../../auth/model/user-reset-password.model';
import { CustomerBackend } from './customer.backend';
import { AuthenticationToken } from './model/token.model';
import { catchError, map, tap } from 'rxjs/operators';
import { BrowserStorageBackend } from '../browser-storage/browser-storage.backend';

@Injectable()
export class CustomerService extends BaseService {
  constructor(
    private customerBackend: CustomerBackend,
    private browserStorageBackend: BrowserStorageBackend
  ) {
    super();
  }

  // demo mock function
  registerUser(userAccount: UserAccount): Observable<boolean> {
    return of(true);
  }

  getAuthToken(
    userCredential: UserCredential
  ): Promise<AuthenticationToken | ErrorMessage> {
    const localStorageToken = this.browserStorageBackend.getAuthTokenFromLocalStorage();
    const sessionStorageToken = this.browserStorageBackend.getAuthTokenFromSessionStorage();

    let token;
    let tokenExpiry;
    if (localStorageToken) {
      tokenExpiry = this.browserStorageBackend.getAuthTokenExpiryFromLocalStorage();
      if (this.isAuthenticated(tokenExpiry)) {
        token = {
          data: {
            token: localStorageToken,
            tokenExpiry,
          },
        };
        return of(token).toPromise();
      }
    }

    if (sessionStorageToken) {
      tokenExpiry = this.browserStorageBackend.getAuthTokenExpiryFromSessionStorage();
      if (this.isAuthenticated(tokenExpiry)) {
        token = {
          data: {
            token: sessionStorageToken,
            tokenExpiry,
          },
        };
        return of(token).toPromise();
      }
    }

    return this.customerBackend
      .fetchLoginToken(userCredential)
      .pipe(
        map((response) => response.body),
        tap((tokenData) =>
          this.isRememberMe(tokenData, userCredential.rememberMe)
        )
      )
      .toPromise();
  }

  getUserData() {
    return this.customerBackend
      .fetchUserData()
      .pipe(map((response) => response.body))
      .toPromise();
  }

  isAuthenticated(expirationTimestamp: number) {
    const expirationDate = new Date(expirationTimestamp);

    if (expirationDate) {
      return expirationDate.getTime() > new Date().getTime();
    } else {
      return false;
    }
  }

  isRememberMe(tokenData: any, rememberMe: boolean) {
    if (tokenData.errors || !tokenData) {
      return;
    }

    this.browserStorageBackend.saveAuthToken(
      tokenData?.data?.token,
      tokenData?.data?.tokenExpiry,
      rememberMe
    );
  }

  deleteAuthToken() {
    this.browserStorageBackend.deleteAuthToken();
  }

  ForgotPasswordEntry(
    userForgotPassword: UserForgotPassword
  ): Promise<any | ErrorMessage> {
    return this.customerBackend
      .fetchForgotPasswordEntry(userForgotPassword)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  ForgotPasswordMobileEntry(
    userForgotPasswordMobile: UserForgotPasswordMobile
  ): Promise<any | ErrorMessage> {
    return this.customerBackend
      .fetchForgotPasswordMobileEntry(userForgotPasswordMobile)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  ResetPasswordEntry(
    userResetPassword: UserResetPassword
  ): Promise<any | ErrorMessage> {
    return this.customerBackend
      .fetchResetPasswordEntry(userResetPassword)
      .pipe(map((response) => response.body))
      .toPromise();
  }
}
