import * as ManpowerSettingActions from './settingsmanpower.actions';
import {
  initManpowerSettingsState,
  ManpowerSettingStateInterface,
} from './settingsmanpower.state';

export function ManpowerSettingReducer(
  state = initManpowerSettingsState,
  action
): ManpowerSettingStateInterface {
  switch (action.type) {
    case ManpowerSettingActions.SAVE_MANPOWER_SETTINGS_RECEIVE: {
      const data = action.payload;

      return {
        ...state,
        payload: data,
      };
    }
    case ManpowerSettingActions.GET_MANPOWER_SETTINGS_RECEIVE: {
      const data = action.payload;

      return {
        ...state,
        payload: data,
      };
    }
    default:
      return state;
  }
}
