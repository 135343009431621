import * as ManpowerActions from './manpower.actions';
import {
  ManpowerInterface,
  initManpowerInterfaceState,
  adapterManpowerJobs,
} from './manpower.state';

export function manpowerReducer(
  state = initManpowerInterfaceState,
  action
): ManpowerInterface {
  switch (action.type) {
    case ManpowerActions.JOB_RECEIVE: {
      const payload = action.payload.jobs.data;
      const isFilterApplied = action.payload.filter.isFilterApplied;
      const isUpdateAction = action.payload.filter.isUpdateAction;

      if (isUpdateAction && !isFilterApplied) {
        return adapterManpowerJobs.updateMany(
          payload.map((job) => Object.assign({}, { id: job.id, changes: job })),
          { ...state }
        );
      }
      if (!isFilterApplied) {
        return adapterManpowerJobs.addMany(payload, {
          ...state,
        });
      } else {
        return adapterManpowerJobs.setAll(payload, state);
      }
    }

    case ManpowerActions.REMOVE_JOB_RECEIVE: {
      const payload = action.payload.job_id;
      return adapterManpowerJobs.removeOne(payload, {
        ...state,
      });
    }

    case ManpowerActions.UPDATE_COUNT_RECEIVE: {
      return {
        ...state,
        apprentice: action.payload.apprentice_count_total,
        journeymen: action.payload.journeymen_count_total,
        sm_unresolved_count: action.payload.sm_unresolved_count_total,
        pl_unresolved_count: action.payload.pl_unresolved_count_total,
      };
    }

    case ManpowerActions.UPDATE_JOB_OBJECT: {
      const payload = action.payload.jobObject;
      return adapterManpowerJobs.updateOne(
        { id: payload.id, changes: payload },
        state
      );
    }

    case ManpowerActions.USER_RECEIVE: {
      const payload = action.payload.users;

      return {
        ...state,
        users: payload,
      };
    }

    case ManpowerActions.JOB_NOTES_RECEIVE: {
      const payload = action.payload.jobNotes;

      return {
        ...state,
        jobNotes: payload,
      };
    }

    case ManpowerActions.NON_MANPOWER_JOB_RECEIVE: {
      const payload = action.payload.jobs;

      return {
        ...state,
        nonManpowerJobs: payload,
      };
    }

    case ManpowerActions.FOREMAN_RECEIVE: {
      const payload = action.payload.Foremans;
      return {
        ...state,
        foremans: payload,
      };
    }

    case ManpowerActions.SM_FOREMAN_RECEIVE: {
      const payload = action.payload.Foremans;
      return {
        ...state,
        sm_foremans: payload,
      };
    }

    case ManpowerActions.PIPE_FOREMAN_RECEIVE: {
      const payload = action.payload.Foremans;
      return {
        ...state,
        pipe_foremans: payload,
      };
    }

    case ManpowerActions.PROJECT_MANAGER_RECEIVE: {
      const payload = action.payload.ProjectManagers;

      return {
        ...state,
        projectManagers: payload,
      };
    }

    case ManpowerActions.PERFORMANCE_NOTES_RECEIVE: {
      const payload = action.payload.data;

      return {
        ...state,
        performanceNotes: payload,
      };
    }

    case ManpowerActions.FILTER_RECEIVE: {
      const payload = action.payload.Filters;

      return {
        ...state,
        filters: payload,
      };
    }

    case ManpowerActions.JOB_FILTERS_RECEIVE: {
      const payload = action.payload.jobFilters;

      return {
        ...state,
        jobFilters: payload,
      };
    }

    case ManpowerActions.ASSIGN_JOB_DETAILS_RECEIVE: {
      const data = action.payload.empJobDetails[0];
      return {
        ...state,
        empJobDetails: data,
      };
    }

    default:
      return state;
  }
}
