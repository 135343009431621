import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WellLogsModel } from '../backend/well-logs/model/well-logs.model';

export interface WellLogsStateInterface extends EntityState<WellLogsModel> {
  // additional entity state properties
  selectedWellLogId: number | null;
  newWellLog: WellLogsModel;
}

export const adapterJob: EntityAdapter<WellLogsModel> = createEntityAdapter<WellLogsModel>();

export const initJobState: WellLogsStateInterface = adapterJob.getInitialState({
  selectedWellLogId: null,
  newWellLog: null,
});

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterJob.getSelectors();
