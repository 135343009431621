<div class="loginbg">
  <div class="login_wrap">
    <div class="login_right">
      <img src="../../assets/banner.png" />
    </div>
    <div class="login_left">
      <div class="login_box_euro">
        <div class="login_box_euro_inner">
          <div class="login_box_euro_oiner">
            <div class="logo text-center">
              <a href="javascript:void(0);">
                <img
                  src="../../assets/cds-logo.png"
                  class=""
                  style="height: 9.375rem; width: 9.375rem"
                />
              </a>
            </div>
            <div class="login_eop_set">
              <h5>Forgot password?</h5>
              <p>
                Enter your email address or phone number and we will send you
                instructions to reset your password.
              </p>
            </div>
            <div class="login_box_euro_inner_MX loginSec">
              <form action="" class="inner_pad">
                <div class="form-group mrbtm48 marzero">
                  <input
                    class="form-control valid"
                    name="email"
                    [formControl]="email"
                    id="email"
                    placeholder="Email"
                    type="text"
                    value=""
                    (keydown.enter)="onSubmit()"
                    [ngClass]="emailError == 'error' ? 'error' : ''"
                  />
                </div>
                <h6><span>OR</span></h6>
                <div class="form-group">
                  <input
                    class="form-control valid"
                    name="phonenumber"
                    [formControl]="phonenumber"
                    id="phonenumber"
                    placeholder="Mobile phone number"
                    type="text"
                    mask="(000) 000-0000"
                    (keydown.enter)="onSubmit()"
                    (keyup)="onKey($event)"
                    autocomplete="off"
                    [ngClass]="phoneError == 'error' ? 'error' : ''"
                  />
                </div>
                <div class="form-group marzero mar10">
                  <div class="row">
                    <div class="col-sm-6 cancel">
                      <input
                        type="button"
                        [routerLink]="'/login'"
                        class="btn_cls cancelbtn"
                        value="Cancel"
                      />
                    </div>
                    <div class="col-sm-6 submit">
                      <input
                        type="button"
                        (click)="onSubmit()"
                        class="btn_cls"
                        value="Submit"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
