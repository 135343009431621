import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { CDSAppState } from '../store/store.state';
import { ServiceOrderReceiveListModel } from '../backend/service-order/model/service-order-receive-list.model';
import * as ServiceOrderSelectors from './service-order/service-order.selectors';
import * as ServiceOrderActions from './service-order/service-order.actions';
import { ServiceOrderRequestListModel } from '../backend/service-order/model/service-order-request-list.model';
import { WorkorderScheduleReceiveListModel } from '../backend/service-order/model/workorder-schedule-receive-list.model';
import * as WorkorderScheduleSelectors from './workorder-schedule/workorder-schedule.selectors';
import * as WorkorderScheduleBydaySelectors from './workorder-schedule-byday/workorder-schedule-byday.selectors';
import * as WorkorderScheduleByEmployeeSelectors from './workorder-schedule-byEmployee/workorder-schedule-byEmployee.selectors';
import * as LaborWorkorderSelectors from './labor-workorder/labor-workorder.selectors';
import * as PurchaseOrderSelectors from './purchase-orders/purchase-orders.selectors';
import * as TruckStockSelectors from './truck-stock/truck-stock.selectors';
import * as AttachmentSelectors from './attachments/attachments.selectors';
import * as WorkorderScheduleActions from './workorder-schedule/workorder-schedule.actions';
import * as WorkorderScheduleBydayActions from './workorder-schedule-byday/workorder-schedule-byday.actions';
import * as WorkorderScheduleByEmployeeActions from './workorder-schedule-byEmployee/workorder-schedule-byEmployee.actions';
import * as LaborWorkorderActions from './labor-workorder/labor-workorder.actions';
import * as PurchaseOrderActions from './purchase-orders/purchase-orders.actions';
import * as TruckStockActions from './truck-stock/truck-stock.actions';
import * as AttachmentActions from './attachments/attachments.actions';
import { WorkOrderScheduleRequestListModel } from '../backend/service-order/model/workorder-schedule-request-list.model';
import { WorkOrderScheduleDeleteRequestModel } from '../backend/service-order/model/workorder-schedule-delete-request.model';
import { WorkOrderScheduleCreateRequestModel } from '../backend/service-order/model/workorder-schedule-create-request.model';
import { LaborWorkorderReceiveListModel } from '../backend/service-order/model/labor-workorder-receive-list.model';
import { PurchaseOrderReceiveListModel } from '../backend/service-order/model/purchase-order-receive-list.model';
import { TruckStockReceiveListModel } from '../backend/service-order/model/truck-stock-receive-list.model';
import { AttachmentReceiveListModel } from '../backend/service-order/model/attachment-receive-list.model';
import { WorkOrderScheduleByemployeeRequestListModel } from '../backend/service-order/model/workorder-schedule-byemployee-request-list.model';
import { WorkorderScheduleByemployeeReceiveListModel } from '../backend/service-order/model/workorder-schedule-byemployee-receive-list.model';
@Injectable()
export class WorkorderStoreService {
  constructor(private store: Store<CDSAppState>) {}

  // service order's selectors
  public getAllServiceOrder(): Observable<ServiceOrderReceiveListModel[]> {
    return this.store.pipe(
      select((state) => ServiceOrderSelectors.getServiceOrder(state))
    );
  }

  public getCeSites(): Observable<[]> {
    return this.store.pipe(
      select((state) => ServiceOrderSelectors.getSites(state))
    );
  }

  public getCeSiteInfo(): Observable<[]> {
    return this.store.pipe(
      select((state) => ServiceOrderSelectors.getSiteInfo(state))
    );
  }

  public getWorkorderTypes(): Observable<[]> {
    return this.store.pipe(
      select((state) => ServiceOrderSelectors.getWorkorderTypes(state))
    );
  }

  public getCreatedWorkorder(): Observable<[]> {
    return this.store.pipe(
      select((state) => ServiceOrderSelectors.getCreatedWorkorder(state))
    );
  }

  // workorder-schedule's selectors
  public getWorkorderSchedule(): Observable<
    WorkorderScheduleReceiveListModel[]
  > {
    return this.store.pipe(
      select((state) => WorkorderScheduleSelectors.getWorkorderSchedule(state))
    );
  }

  // workorder-schedule-byday's selectors
  public getWorkorderScheduleByday(): Observable<
    WorkorderScheduleReceiveListModel[]
  > {
    return this.store.pipe(
      select((state) =>
        WorkorderScheduleBydaySelectors.getWorkorderScheduleByday(state)
      )
    );
  }

  // workorder-schedule-byEmployee's selectors
  public getWorkorderScheduleByEmployee(): Observable<
    WorkorderScheduleByemployeeReceiveListModel[]
  > {
    return this.store.pipe(
      select((state) =>
        WorkorderScheduleByEmployeeSelectors.getWorkorderScheduleByEmployee(
          state
        )
      )
    );
  }

  // labor-workorder's selectors
  public getLaborWorkorder(): Observable<LaborWorkorderReceiveListModel[]> {
    return this.store.pipe(
      select((state) => LaborWorkorderSelectors.getLaborWorkorder(state))
    );
  }

  // Purchase Order selectors
  public getPurchaseOrder(): Observable<PurchaseOrderReceiveListModel[]> {
    return this.store.pipe(
      select((state) => PurchaseOrderSelectors.getPurchaseOrder(state))
    );
  }

  // Truck Stock's selectors
  public gettruckStock(): Observable<TruckStockReceiveListModel[]> {
    return this.store.pipe(
      select((state) => TruckStockSelectors.getTruckStock(state))
    );
  }

  // Attachment selectors
  public getAttachment(): Observable<AttachmentReceiveListModel[]> {
    return this.store.pipe(
      select((state) => AttachmentSelectors.getAttachment(state))
    );
  }

  // service order's actions
  public requestCreateServiceOrder(postData: any) {
    this.store.dispatch(
      new ServiceOrderActions.ServiceOrderCreateRequest(postData)
    );
  }

  public requestGetServiceOrder(postData: ServiceOrderRequestListModel) {
    this.store.dispatch(
      new ServiceOrderActions.ServiceOrderGetRequest(postData)
    );
  }

  public requestGetServiceOrderSites(postData: any) {
    this.store.dispatch(
      new ServiceOrderActions.ServiceOrderGetSitesRequest(postData)
    );
  }

  public requestGetServiceOrderTypes() {
    this.store.dispatch(new ServiceOrderActions.ServiceOrderGetTypesRequest());
  }

  public requestUpdateServiceOrder(
    postData: any,
    isWorkorderStatusUpdate: boolean,
    isModalFromSchedule: boolean
  ) {
    this.store.dispatch(
      new ServiceOrderActions.ServiceOrderUpdateRequest({
        postData,
        isWorkorderStatusUpdate,
        isModalFromSchedule,
      })
    );
  }

  // workorder-schedule's actions
  public requestCreateWorkorderSchedule(
    postData: WorkOrderScheduleCreateRequestModel
  ) {
    this.store.dispatch(
      new WorkorderScheduleActions.WorkorderScheduleCreateRequest(postData)
    );
  }

  public requestGetWorkorderSchedule(
    postData: WorkOrderScheduleRequestListModel
  ) {
    this.store.dispatch(
      new WorkorderScheduleActions.WorkorderScheduleGetRequest(postData)
    );
  }

  public requestWorkorderScheduleDelete(
    postData: WorkOrderScheduleDeleteRequestModel
  ) {
    this.store.dispatch(
      new WorkorderScheduleActions.WorkorderScheduleDeleteRequest(postData)
    );
  }

  // workorder-schedule-byday's actions
  public requestGetWorkorderScheduleByday(
    postData: WorkOrderScheduleRequestListModel
  ) {
    this.store.dispatch(
      new WorkorderScheduleBydayActions.WorkorderScheduleBydayGetRequest(
        postData
      )
    );
  }

  // workorder-schedule-byEmployee's actions
  public requestGetWorkorderScheduleByEmployee(
    postData: WorkOrderScheduleByemployeeRequestListModel
  ) {
    this.store.dispatch(
      new WorkorderScheduleByEmployeeActions.WorkorderScheduleByEmployeeGetRequest(
        postData
      )
    );
  }

  // labor-workorder's actions
  public requestGetLaborWorkorder(postData: any) {
    this.store.dispatch(
      new LaborWorkorderActions.LaborWorkorderGetRequest(postData)
    );
  }

  // purchase Order's actions
  public requestGetPurchaseOrder(postData: any) {
    this.store.dispatch(
      new PurchaseOrderActions.PurchaseOrderGetRequest(postData)
    );
  }

  // Truck Stock actions
  public requestGetTruckStock(postData: any) {
    this.store.dispatch(new TruckStockActions.TruckStockGetRequest(postData));
  }

  // Attachment actions
  public requestGetAttachment(postData: any) {
    this.store.dispatch(new AttachmentActions.AttachmentGetRequest(postData));
  }
}
