import * as SynclogActions from './synclog.actions';
import {
  adapterSynclog,
  initSynclogState,
  SynclogStateInterface,
} from './synclog.state';

export function SynclogReducer(
  state = initSynclogState,
  action
): SynclogStateInterface {
  switch (action.type) {
    case SynclogActions.SYNC_LOG_RECEIVE: {
      const payload = action.payload.data;

      return adapterSynclog.addMany(payload, {
        ...state,
      });
    }

    default:
      return state;
  }
}
