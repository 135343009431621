import * as PurchaseOrderActions from './purchase-orders.actions';
import {
  initPurchaseOrderState,
  PurchaseOrderStateInterface,
  adapterPurchaseOrder,
} from './purchase-orders.state';

export function PurchaseOrderReducer(
  state = initPurchaseOrderState,
  action
): PurchaseOrderStateInterface {
  switch (action.type) {
    case PurchaseOrderActions.PURCHASE_ORDERS_GET_RECEIVE: {
      const payload = action.payload.purchaseOrderList.data;
      const isScrolled = action.payload.isScrolled;
      if (isScrolled) {
        return adapterPurchaseOrder.addMany(payload, {
          ...state,
        });
      } else {
        return adapterPurchaseOrder.setAll(payload, state);
      }
    }
    default:
      return state;
  }
}
