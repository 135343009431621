import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { catchError, map, tap } from 'rxjs/operators';
import { SynclogBackend } from './synclog.backend';
import { SynclogModel } from './model/synclog.model';

@Injectable()
export class SynclogService extends BaseService {
  constructor(private roleBackend: SynclogBackend) {
    super();
  }

  getAllSynclogs(): Promise<SynclogModel[] | ErrorMessage> {
    return this.roleBackend
      .fetchAllSynclogs()
      .pipe(map((response) => response.body))
      .toPromise();
  }
}
