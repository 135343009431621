<div class="my_account_mnc_page">
  <div class="my_account_mnc_page_inner">
    <h5>My Account</h5>
    <div class="accout_box whitr_vobox">
      <div>
        <h3>Personal Information</h3>
        <div class="accout_box setpqwad">
          <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>First Name</label>
                      <input
                        formControlName="firstname"
                        id="firstname"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        value="{{ userData.firstname }}"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input
                        formControlName="lastname"
                        type="text"
                        class="form-control"
                        id="lastname"
                        autocomplete="off"
                        value="{{ userData.lastname }}"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        formControlName="email"
                        type="text"
                        class="form-control"
                        id="email"
                        autocomplete="off"
                        value="{{ userData.email }}"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input
                        formControlName="phone"
                        type="text"
                        class="form-control"
                        mask="(000) 000-0000"
                        id="phone"
                        autocomplete="off"
                        value="{{ userData.phone }}"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Role</label>
                      <select
                        formControlName="role_id"
                        class="custom-select form-control"
                        id="role_id"
                        disabled
                      >
                        <option value="">Select Role</option>
                        <option value="1">Super Admin</option>
                        <option value="2">PM Supervisor</option>
                        <option value="3">Pipe Foreman</option>
                        <option value="4">SM Foreman</option>
                        <option value="5">Pipe User</option>
                        <option value="6">SM User</option>
                        <option value="7">General User</option>
                        <option value="8">Admin</option>
                        <option value="10">Service User</option>
                        <option value="12">Service User Plus</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>Profile Photo</label>
                  <div class="pho_profile_mnxc">
                    <div class="pho_profile_mnxc_inner">
                      <div class="photo_up_view">
                        <img [src]="imgURL" height="200" *ngIf="imgURL" />
                      </div>
                      <div class="file btn btn-lg btn-primary">
                        <img src="../../assets/pncledit.png" />
                        <input
                          formControlName="profilePic"
                          id="profilePic"
                          type="file"
                          accept="image/*"
                          class="form-control"
                          (change)="onFileChange($event)"
                        />
                      </div>
                      <div class="anchor_setremove">
                        <a href="javascript:void(0);" (click)="onDeleteImage()"
                          ><img src="../../assets/cr_remove.png"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row buttonRow">
              <button type="submit" class="btn btn-primary form_brntnxd">
                Profile Save
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="accout_box whitr_vobox">
        <h3>Reset Password</h3>
        <div *ngIf="isAlertShow" class="alert {{ alertType }}" role="alert">
          <i class="fa {{ alertShape }}" aria-hidden="true"></i>
          <div class="alert-message">{{ alertMsg }}</div>
          <i class="fa fa-close" aria-hidden="true" (click)="hideAlert()"></i>
        </div>
        <div class="accout_box setpqwad">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label>Current Password</label>
                <input
                  type="password"
                  class="form-control"
                  autocomplete="off"
                  [(ngModel)]="oldPassword"
                  [ngClass]="oldPasswordError == 'error' ? 'error' : ''"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label>New Password</label>
                <input
                  type="password"
                  class="form-control"
                  autocomplete="off"
                  [(ngModel)]="newPassword"
                  [ngClass]="newPasswordError == 'error' ? 'error' : ''"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  class="form-control"
                  autocomplete="off"
                  [(ngModel)]="confirmPassword"
                  [ngClass]="confirmPasswordError == 'error' ? 'error' : ''"
                />
              </div>
            </div>
          </div>
          <div class="row buttonRow">
            <button
              type="button"
              class="btn btn-primary form_brntnxd"
              (click)="onChangePassword()"
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
</div>
