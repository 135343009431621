import { createSelector } from '@ngrx/store';
import { getWorkorderScheduleState } from '../workorder.selectors';
import {
  WorkorderScheduleStateInterface,
  selectAll,
} from '../workorder-schedule/workorder-schedule.state';

export const getWorkorderSchedule = createSelector(
  getWorkorderScheduleState,
  (state: WorkorderScheduleStateInterface) => selectAll(state)
);
