<div class="setting_inner_pages">
  <div class="row mobilesetgeneral">
    <div class="col-sm-6 mobilesetgeneral_inner">
      <div class="box_innner_coolP">
        <h6>Email Notifications</h6>
        <div class="box_innner_coolP_inner">
          <div class="box_inner_body">
            <form [formGroup]="emailForm">
              <div class="fomTgroup">
                <label>Project Assistants</label>

                <ngx-input-tag
                  [formControl]="emailForm.controls['projectAssistants']"
                >
                </ngx-input-tag>
                <br />
                <span
                  class="error"
                  *ngIf="
                    formControls.projectAssistants.errors
                      ?.inValidEmailAddress &&
                    formControls.projectAssistants.touched
                  "
                >
                  Please provide valid email address
                </span>
              </div>

              <div class="fomTgroup">
                <label>Payroll Managers</label>
                <ngx-input-tag
                  [formControl]="emailForm.controls['payrollManagers']"
                ></ngx-input-tag>
                <br />
                <span
                  class="error"
                  *ngIf="
                    formControls.payrollManagers.errors?.inValidEmailAddress &&
                    formControls.payrollManagers.touched
                  "
                >
                  Please provide valid email address
                </span>
              </div>

              <div>
                <button
                  type="submit"
                  class="btn btn-df"
                  (click)="onSave()"
                  [disabled]="
                    emailForm.value.payrollManagers.length == 0 &&
                    emailForm.value.projectAssistants.length == 0
                  "
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
