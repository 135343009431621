import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserModel } from './model/user.model';
import { environment } from '../../../../environments/environment';
import { UserEmployeeModel } from './model/userUpdate.model';

@Injectable()
export class UserBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });
  private FILE_HEADERS = new HttpHeaders({
    'content-type': 'multipart/form-data',
  });

  constructor(private http: HttpClient) {}

  fetchAllUsers(getAllUsersOverride: boolean): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/users`;
    return this.http.post<UserModel[]>(
      url,
      { getAllUsersOverride },
      this.getOptions()
    );
  }

  fetchUsersById(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/users/` + id;
    return this.http.get<UserModel>(url, this.getOptions());
  }

  fetchUserUpdateById(
    userModel: UserEmployeeModel
  ): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/users/update/` + userModel.id;
    return this.http.put<any>(url, userModel, this.getOptions());
  }

  async fetchUserProfileUpdate(
    userModel: FormData
  ): Promise<Observable<HttpResponse<any>>> {
    const url = `${this.API_ROOT}/users/profile/`;
    let data = await this.getHeroes(url, userModel);
    return data;
  }

  getHeroes(url, data): Promise<any> {
    return this.http
      .post(url, data)
      .toPromise()
      .then((response) => response as any)
      .catch((error) => error as any);
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${new Date().getTimezoneOffset()}`,
      },
    };
  }
}
