import { AuthenticationToken } from '../../backend/customer/model/token.model';

export interface TokenStateInterface {
  token: AuthenticationToken;
  tokenExpiration: number;
}

export const initTokenState: TokenStateInterface = {
  token: null,
  tokenExpiration: null,
};
