import * as PermissionActions from './permission.actions';
import {
  adapterPermission,
  initPermissionState,
  PermissionStateInterface,
} from './permission.state';

export function PermissionReducer(
  state = initPermissionState,
  action
): PermissionStateInterface {
  switch (action.type) {
    case PermissionActions.PERMISSIONS_RECEIVE: {
      const payload = action.payload.data;

      return adapterPermission.addMany(payload, {
        ...state,
      });
    }

    default:
      return state;
  }
}
