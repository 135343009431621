import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ManpowerActions from './manpower.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { ManpowerService } from '../backend/manpower/manpower.service';
import { AlertMessageCommonService } from './../../shared/alert-message-common/alert-message-common.service';
import { Constants } from '../../constants/constant';
import { HideSpinner, ShowSpinner } from '../../shared/spinner/spinner.action';
import { HttpError } from '../manpower/manpower.action.error';
import { Action } from '@ngrx/store';
import { delay } from 'rxjs/operators';
import * as ErrorActions from '../error/error.actions';
import { ErrorType } from '../error/model/error-type';
import { JobsModel } from '../backend/manpower/model/jobs.model';

type showSpinnerTypes =
  | ManpowerActions.NonManpowerJobRequest
  | ManpowerActions.AssignJobRequest
  | ManpowerActions.UnAssignJobRequest
  | ManpowerActions.AddNewJobRequest
  | ManpowerActions.ProjectManagerRequest
  | ManpowerActions.ForemanRequest
  | ManpowerActions.FiltersRequest
  | ManpowerActions.JobFiltersRequest
  | ManpowerActions.AssignJobDetailsRequest
  | ManpowerActions.UpdateJobRequest
  | ManpowerActions.RemoveJobRequest
  | ManpowerActions.PerformanceNotesRequest
  | ManpowerActions.AddPerformanceNoteRequest
  | ManpowerActions.FlagEmployeeRequest
  | ManpowerActions.PushNotificationRequest
  | ManpowerActions.SMforemanRequest
  | ManpowerActions.PipeforemanRequest;

const showSpinnerActions = [
  ManpowerActions.NON_MANPOWER_JOB_REQUEST,
  ManpowerActions.ASSIGN_JOB_REQUEST,
  ManpowerActions.UNASSIGN_JOB_REQUEST,
  ManpowerActions.ADD_NEW_JOB_REQUEST,
  ManpowerActions.PROJECT_MANAGER_REQUEST,
  ManpowerActions.FOREMAN_REQUEST,
  ManpowerActions.FILTER_REQUEST,
  ManpowerActions.JOB_FILTERS_REQUEST,
  ManpowerActions.ASSIGN_JOB_DETAILS_REQUEST,
  ManpowerActions.UPDATE_JOB_REQUEST,
  ManpowerActions.REMOVE_JOB_REQUEST,
  ManpowerActions.PERFORMANCE_NOTES_REQUEST,
  ManpowerActions.ADD_PERFORMANCE_NOTES_REQUEST,
  ManpowerActions.FLAG_EMPLOYEE_REQUEST,
  ManpowerActions.PUSH_NOTIFICATION_REQUEST,
  ManpowerActions.SM_FOREMAN_REQUEST,
  ManpowerActions.PIPE_FOREMAN_REQUEST,
];

type hideSpinnerTypes =
  | ManpowerActions.NonManpowerJobReceive
  | ManpowerActions.AssignJobReceive
  | ManpowerActions.UnAssignJobReceive
  | ManpowerActions.AddNewJobReceive
  | ManpowerActions.ProjectManagerReceive
  | ManpowerActions.ForemanReceive
  | ManpowerActions.FiltersReceive
  | ManpowerActions.JobFiltersReceive
  | ManpowerActions.AssignJobDetailsReceive
  | ManpowerActions.UpdateJobReceive
  | ManpowerActions.RemoveJobReceive
  | ManpowerActions.PerformanceNotesReceive
  | ManpowerActions.AddPerformanceNoteReceive
  | ManpowerActions.FlagEmployeeReceive
  | ManpowerActions.PushNotificationReceive
  | ManpowerActions.SMForemanReceive
  | ManpowerActions.PipeForemanReceive;

const hideSpinnerActions = [
  ManpowerActions.NON_MANPOWER_JOB_RECEIVE,
  ManpowerActions.ASSIGN_JOB_RECEIVE,
  ManpowerActions.UNASSIGN_JOB_RECEIVE,
  ManpowerActions.ADD_NEW_JOB_RECEIVE,
  ManpowerActions.PROJECT_MANAGER_RECEIVE,
  ManpowerActions.FOREMAN_RECEIVE,
  ManpowerActions.FILTER_RECEIVE,
  ManpowerActions.JOB_FILTERS_RECEIVE,
  ManpowerActions.ASSIGN_JOB_DETAILS_RECEIVE,
  ManpowerActions.UPDATE_JOB_RECEIVE,
  ManpowerActions.REMOVE_JOB_RECEIVE,
  ManpowerActions.PERFORMANCE_NOTES_RECEIVE,
  ManpowerActions.ADD_PERFORMANCE_NOTES_REQUEST,
  ManpowerActions.FLAG_EMPLOYEE_RECEIVE,
  ManpowerActions.PUSH_NOTIFICATION_RECEIVE,
  ManpowerActions.SM_FOREMAN_RECEIVE,
  ManpowerActions.PIPE_FOREMAN_RECEIVE,
];

@Injectable()
export class ManpowerEffects {
  constructor(
    private actions$: Actions,
    private manpowerService: ManpowerService,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  CONSTANTS = Constants.MANPOWER;

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    delay(1500),
    map(() => new HideSpinner())
  );

  // Fetch Jobs For Manpower
  @Effect()
  OnJobRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.JobRequest>(ManpowerActions.JOB_REQUEST),
    map((action) => action.payload),
    switchMap((data) =>
      from(
        this.manpowerService.fetchJobs(data.page, data.pageItem, data.jobFilter)
      ).pipe(
        mergeMap((jobs) => [
          new ManpowerActions.JobReceive({
            jobs: jobs,
            filter: data.jobFilter,
          }),
          new ManpowerActions.UpdateCount(jobs),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // Fetch Users For Labor Pool
  @Effect()
  OnUserRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.UsersRequest>(ManpowerActions.USER_REQUEST),
    map((action) => action.payload),
    switchMap((filters) =>
      from(this.manpowerService.fetchUsers(filters)).pipe(
        mergeMap((users: []) => [new ManpowerActions.UsersReceive({ users })]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // Add Job Note In Manpower
  @Effect()
  OnAddJobNoteRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.AddJobNoteRequest>(
      ManpowerActions.ADD_JOB_NOTE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((data) =>
      from(this.manpowerService.addJobNotes(data.addJobNote)).pipe(
        mergeMap((response) => [
          new ManpowerActions.AddJobNoteReceive({
            jobData: response,
          }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnupdateJobObject$ = this.actions$.pipe(
    ofType<ManpowerActions.updateJobObject>(ManpowerActions.UPDATE_JOB_OBJECT),
    map((action) => action.payload),
    mergeMap((data) => {
      return [new ManpowerActions.UpdateCount(data.manpowerCount)];
    }),
    catchError((data) =>
      of(
        new ErrorActions.ErrorReceive({
          message: data.error.message,
          type: ErrorType.token,
          statusCode: data.status,
        })
      )
    )
  );

  @Effect({ dispatch: false })
  OnupdateCount$ = this.actions$.pipe(
    ofType<ManpowerActions.UpdateCount>(ManpowerActions.UPDATE_COUNT_RECEIVE),
    map((action) => action.payload),
    catchError((data) =>
      of(
        new ErrorActions.ErrorReceive({
          message: data.error.message,
          type: ErrorType.token,
          statusCode: data.status,
        })
      )
    )
  );

  @Effect()
  OnAddJobNoteHandle$ = this.actions$.pipe(
    ofType<ManpowerActions.AddJobNoteReceive>(
      ManpowerActions.ADD_JOB_NOTE_RECEIVE
    ),
    map((action) => action.payload),
    mergeMap((result) => {
      return [
        new ManpowerActions.JobNotesRequest({
          jobId: result.jobData.job_id,
          filter: { type: 0 },
        }),
      ];
    }),
    catchError((data) =>
      of(
        new ErrorActions.ErrorReceive({
          message: data.error.message,
          type: ErrorType.token,
          statusCode: data.status,
        })
      )
    )
  );

  // Fetch All Notes of Job In Manpower
  @Effect()
  OnJobNotesRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.JobNotesRequest>(ManpowerActions.JOB_NOTES_REQUEST),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((data) =>
      from(this.manpowerService.fetchJobNotes(data.jobId, data.filter)).pipe(
        mergeMap((jobNotes: []) => [
          new ManpowerActions.JobNotesReceive({ jobNotes }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // Assing Employee To Job

  @Effect()
  OnAssignJobRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.AssignJobRequest>(
      ManpowerActions.ASSIGN_JOB_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((assignJobData) =>
      from(this.manpowerService.assingJob(assignJobData.assignJob)).pipe(
        mergeMap((updatedTimeEntry) => {
          return [
            new ManpowerActions.AssignJobReceive({
              jobReceiveData: updatedTimeEntry,
              jobFilter: assignJobData.jobFilter,
              employeeFilter: assignJobData.employeeFilter,
            }),
          ];
        }),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnAssignJobReceive$ = this.actions$.pipe(
    ofType<ManpowerActions.AssignJobReceive>(
      ManpowerActions.ASSIGN_JOB_RECEIVE
    ),
    map((action) => {
      if (action.payload.jobReceiveData.status === 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.jobReceiveData.message
        );
      } else {
        this.alertMessageCommonService.error(
          'Error',
          action.payload.jobReceiveData.message
        );
      }
    })
  );

  @Effect()
  OnAssignJobReceiveHandle$ = this.actions$.pipe(
    ofType<ManpowerActions.AssignJobReceive>(
      ManpowerActions.ASSIGN_JOB_RECEIVE
    ),
    map((action) => action.payload),
    mergeMap((result) => {
      return [
        new ManpowerActions.JobRequest({
          page: this.CONSTANTS.PAGE_INDEX,
          pageItem: this.CONSTANTS.PAGE_ITEM * result.jobFilter.jobPageIndex,
          jobFilter: result.jobFilter,
        }),
        new ManpowerActions.UsersRequest(result.employeeFilter),
      ];
    }),
    catchError((data) =>
      of(
        new ErrorActions.ErrorReceive({
          message: data.error.message,
          type: ErrorType.token,
          statusCode: data.status,
        })
      )
    )
  );

  // UnAssign Employee from Job
  @Effect()
  OnUnAssignJobRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.UnAssignJobRequest>(
      ManpowerActions.UNASSIGN_JOB_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((UnAssingJob) =>
      from(
        this.manpowerService.unAssingJob(
          UnAssingJob.job_id,
          UnAssingJob.employee_id,
          UnAssingJob.is_assigned
        )
      ).pipe(
        mergeMap((updatedTimeEntry) => {
          return [
            new ManpowerActions.UnAssignJobReceive({
              updatedTimeEntry,
              jobFilter: UnAssingJob.jobFilter,
              employeeFilter: UnAssingJob.employeeFilter,
            }),
          ];
        }),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnUnAssignJobReceive$ = this.actions$.pipe(
    ofType<ManpowerActions.UnAssignJobReceive>(
      ManpowerActions.UNASSIGN_JOB_RECEIVE
    ),
    map((action) => {
      if (action.payload.updatedTimeEntry.status === 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.updatedTimeEntry.message
        );
      } else {
        this.alertMessageCommonService.error(
          'Error',
          action.payload.updatedTimeEntry.message
        );
      }
    })
  );

  @Effect()
  OnUnAssignJobReceiveHandle$ = this.actions$.pipe(
    ofType<ManpowerActions.UnAssignJobReceive>(
      ManpowerActions.UNASSIGN_JOB_RECEIVE
    ),
    map((action) => action.payload),
    mergeMap((result) => {
      return [
        new ManpowerActions.JobRequest({
          page: this.CONSTANTS.PAGE_INDEX,
          pageItem: this.CONSTANTS.PAGE_ITEM * result.jobFilter.jobPageIndex,
          jobFilter: result.jobFilter,
        }),
        new ManpowerActions.UsersRequest(result.employeeFilter),
      ];
    }),
    catchError((data) =>
      of(
        new ErrorActions.ErrorReceive({
          message: data.error.message,
          type: ErrorType.token,
          statusCode: data.status,
        })
      )
    )
  );

  // Fetch Non-Manpower Jobs
  @Effect()
  OnNonManpowerJobRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.NonManpowerJobRequest>(
      ManpowerActions.NON_MANPOWER_JOB_REQUEST
    ),
    map((action) => action),
    throttleTime(2000),
    switchMap(() =>
      from(this.manpowerService.fetchNonManpowerJobs()).pipe(
        mergeMap((jobs: []) => [
          new ManpowerActions.NonManpowerJobReceive({ jobs }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // Add New Job In Manpower
  @Effect()
  OnAddNewJobRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.AddNewJobRequest>(
      ManpowerActions.ADD_NEW_JOB_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((jobData) =>
      from(
        this.manpowerService.addJob({
          job_id: jobData.job_id,
          jobPageIndex: jobData.jobFilter.jobPageIndex,
        })
      ).pipe(
        mergeMap((addJobData) => {
          return [
            new ManpowerActions.AddNewJobReceive({
              addJobData,
              jobFilter: jobData.jobFilter,
            }),
          ];
        }),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnAddJobReceive$ = this.actions$.pipe(
    ofType<ManpowerActions.AddNewJobReceive>(
      ManpowerActions.ADD_NEW_JOB_RECEIVE
    ),
    map((action) => {
      if (action.payload.addJobData.status === 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.addJobData.message
        );
      } else {
        this.alertMessageCommonService.error(
          'Error',
          action.payload.addJobData.message
        );
      }
    })
  );

  @Effect()
  OnAddJobReceiveHandle$ = this.actions$.pipe(
    ofType<ManpowerActions.AddNewJobReceive>(
      ManpowerActions.ADD_NEW_JOB_RECEIVE
    ),
    map((action) => action.payload),
    mergeMap((result) => {
      return [
        new ManpowerActions.JobRequest({
          page: this.CONSTANTS.PAGE_INDEX,
          pageItem: this.CONSTANTS.PAGE_ITEM * result.jobFilter.jobPageIndex,
          jobFilter: result.jobFilter,
        }),
        new ManpowerActions.NonManpowerJobRequest(),
      ];
    }),
    catchError((data) =>
      of(
        new ErrorActions.ErrorReceive({
          message: data.error.message,
          type: ErrorType.token,
          statusCode: data.status,
        })
      )
    )
  );

  // Fetch Project Managers

  @Effect()
  OnProjectManagersRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.ProjectManagerRequest>(
      ManpowerActions.PROJECT_MANAGER_REQUEST
    ),
    map((action) => action),
    throttleTime(2000),
    switchMap(() =>
      from(this.manpowerService.fetchProjectManagers()).pipe(
        mergeMap((ProjectManagers: []) => [
          new ManpowerActions.ProjectManagerReceive({ ProjectManagers }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // Fetch Foremans

  @Effect()
  OnForemansRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.ForemanRequest>(ManpowerActions.FOREMAN_REQUEST),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((type) =>
      from(this.manpowerService.fetchForemans(type)).pipe(
        mergeMap((Foremans: []) => [
          new ManpowerActions.ForemanReceive({ Foremans }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // Fetch SM Foremans
  @Effect()
  OnSMForemanRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.SMforemanRequest>(
      ManpowerActions.SM_FOREMAN_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((type) =>
      from(this.manpowerService.fetchForemans(this.CONSTANTS.SM_FOREMAN)).pipe(
        mergeMap((Foremans: []) => [
          new ManpowerActions.SMForemanReceive({ Foremans }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // Fetch Pipe Foremans
  @Effect()
  OnPipeForemanRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.PipeforemanRequest>(
      ManpowerActions.PIPE_FOREMAN_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((type) =>
      from(
        this.manpowerService.fetchForemans(this.CONSTANTS.PIPE_FOREMAN)
      ).pipe(
        mergeMap((Foremans: []) => [
          new ManpowerActions.PipeForemanReceive({ Foremans }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // Fetch Filters List

  @Effect()
  OnFiltersRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.FiltersRequest>(ManpowerActions.FILTER_REQUEST),
    map((action) => action),
    throttleTime(2000),
    switchMap(() =>
      from(this.manpowerService.fetchFilters()).pipe(
        mergeMap((Filters: []) => [
          new ManpowerActions.FiltersReceive({ Filters }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // request for job filters

  @Effect()
  OnJobFiltersRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.JobFiltersRequest>(
      ManpowerActions.JOB_FILTERS_REQUEST
    ),
    map((action) => action),
    throttleTime(2000),
    switchMap(() =>
      from(this.manpowerService.fetchJobFilters()).pipe(
        mergeMap((jobFilters: []) => [
          new ManpowerActions.JobFiltersReceive({ jobFilters }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // request for assign job details

  @Effect()
  OnAssignJobDetailsRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.AssignJobDetailsRequest>(
      ManpowerActions.ASSIGN_JOB_DETAILS_REQUEST
    ),
    map((action) => action.payload),
    switchMap((data) =>
      from(this.manpowerService.getEmployeeAssignJobDetails(data)).pipe(
        mergeMap((getDetails) => [
          new ManpowerActions.AssignJobDetailsReceive({
            empJobDetails: getDetails,
          }),
        ])
      )
    )
  );

  // Update Job Request
  @Effect()
  OnUpdateJobRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.UpdateJobRequest>(
      ManpowerActions.UPDATE_JOB_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((editJob) =>
      from(this.manpowerService.updateJob(editJob.jobData)).pipe(
        mergeMap((updatedTimeEntry) => {
          return [
            new ManpowerActions.UpdateJobReceive({
              jobData: updatedTimeEntry,
              filters: editJob.filters,
            }),
          ];
        }),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnUpdateJobReceive$ = this.actions$.pipe(
    ofType<ManpowerActions.UpdateJobReceive>(
      ManpowerActions.UPDATE_JOB_RECEIVE
    ),
    map((action) => {
      if (action.payload.jobData.status === 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.jobData.message
        );
      } else {
        this.alertMessageCommonService.error(
          'Error',
          action.payload.jobData.message
        );
      }
    })
  );

  @Effect()
  OnUpdateJobHandle$ = this.actions$.pipe(
    ofType<ManpowerActions.UpdateJobReceive>(
      ManpowerActions.UPDATE_JOB_RECEIVE
    ),
    map((action) => action.payload),
    mergeMap((actionData) => {
      return [
        new ManpowerActions.JobRequest({
          page: this.CONSTANTS.PAGE_INDEX,
          pageItem: this.CONSTANTS.PAGE_ITEM * actionData.filters.jobPageIndex,
          jobFilter: actionData.filters,
        }),
      ];
    }),
    catchError((data) =>
      of(
        new ErrorActions.ErrorReceive({
          message: data.error.message,
          type: ErrorType.token,
          statusCode: data.status,
        })
      )
    )
  );

  // Remove Job
  @Effect()
  OnRemovejobRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.RemoveJobRequest>(
      ManpowerActions.REMOVE_JOB_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((removeJob) =>
      from(this.manpowerService.removeJob(removeJob.jobData)).pipe(
        mergeMap(() => {
          return [new ManpowerActions.RemoveJobReceive(removeJob.jobData)];
        }),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // Performance Notes By Employee ID
  @Effect()
  OnPerformanceNotesRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.PerformanceNotesRequest>(
      ManpowerActions.PERFORMANCE_NOTES_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((emp_id) =>
      from(this.manpowerService.performanceNotes(emp_id)).pipe(
        mergeMap((performanceNotes) => {
          return [
            new ManpowerActions.PerformanceNotesReceive(performanceNotes),
          ];
        }),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  // Add Performance Note
  @Effect()
  OnAddPerformanceNoteRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.AddPerformanceNoteRequest>(
      ManpowerActions.ADD_PERFORMANCE_NOTES_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((AddPerformanceNote) =>
      from(
        this.manpowerService.addPerformanceNote(
          AddPerformanceNote.employee_id,
          AddPerformanceNote.text
        )
      ).pipe(
        mergeMap((performanceResponse) => {
          return [
            new ManpowerActions.AddPerformanceNoteReceive(performanceResponse),
          ];
        }),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnAddPerformanceNoteReceive$ = this.actions$.pipe(
    ofType<ManpowerActions.AddPerformanceNoteReceive>(
      ManpowerActions.ADD_PERFORMANCE_NOTES_RECEIVE
    ),
    map((action) => {
      if (action.payload.status === 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.message
        );
      } else {
        this.alertMessageCommonService.error('Error', action.payload.message);
      }
    })
  );

  @Effect()
  OnAddPerformanceNoteHandle$ = this.actions$.pipe(
    ofType<ManpowerActions.AddPerformanceNoteReceive>(
      ManpowerActions.ADD_PERFORMANCE_NOTES_RECEIVE
    ),
    map((action) => action.payload),
    mergeMap((result) => {
      return [
        new ManpowerActions.PerformanceNotesRequest({
          emp_id: result.data.employee_id,
          page: this.CONSTANTS.PAGE_INDEX,
          pageItems: this.CONSTANTS.PAGE_ITEM,
        }),
      ];
    }),
    catchError((data) =>
      of(
        new ErrorActions.ErrorReceive({
          message: data.error.message,
          type: ErrorType.token,
          statusCode: data.status,
        })
      )
    )
  );

  // Resolve Job Note In Manpower
  @Effect()
  OnResolveJobNoteRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.ResolveNoteRequest>(
      ManpowerActions.RESOLVE_NOTE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((data) =>
      from(this.manpowerService.resolveJobNote(data.noteId)).pipe(
        mergeMap((response) => [
          new ManpowerActions.ResolveNoteReceive({
            response,
          }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnResolveJobNoteHandle$ = this.actions$.pipe(
    ofType<ManpowerActions.ResolveNoteReceive>(
      ManpowerActions.RESOLVE_NOTE_RECEIVE
    ),
    map((action) => action.payload),
    mergeMap((result) => {
      return [
        new ManpowerActions.JobNotesRequest({
          jobId: result.response.job_id,
          filter: { type: 0 },
        }),
      ];
    }),
    catchError((data) =>
      of(
        new ErrorActions.ErrorReceive({
          message: data.error.message,
          type: ErrorType.token,
          statusCode: data.status,
        })
      )
    )
  );

  // request for flag performance note

  @Effect()
  OnFlagEmployeeNoteRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.FlagEmployeeRequest>(
      ManpowerActions.FLAG_EMPLOYEE_REQUEST
    ),
    map((action) => action.payload),
    switchMap((data) =>
      from(
        this.manpowerService.flagEmployeeNote(data.note_id, data.flagged)
      ).pipe(
        mergeMap(() => [
          new ManpowerActions.FlagEmployeeReceive({
            employeeId: data.employee_id,
            employeeFilter: data.employeeFilter,
          }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnFlagEmployeeNoteHandle$ = this.actions$.pipe(
    ofType<ManpowerActions.FlagEmployeeReceive>(
      ManpowerActions.FLAG_EMPLOYEE_RECEIVE
    ),
    map((action) => action.payload),
    mergeMap((result) => {
      return [
        new ManpowerActions.PerformanceNotesRequest({
          emp_id: result.employeeId,
          page: this.CONSTANTS.PAGE_INDEX,
          pageItems: this.CONSTANTS.PAGE_ITEM,
        }),
        new ManpowerActions.UsersRequest(result.employeeFilter),
      ];
    }),
    catchError((data) =>
      of(
        new ErrorActions.ErrorReceive({
          message: data.error.message,
          type: ErrorType.token,
          statusCode: data.status,
        })
      )
    )
  );

  // request for push notification to employees
  @Effect()
  OnPushNotificationRequest$ = this.actions$.pipe(
    ofType<ManpowerActions.PushNotificationRequest>(
      ManpowerActions.PUSH_NOTIFICATION_REQUEST
    ),
    map((action) => action.payload),
    switchMap((data) =>
      from(this.manpowerService.sendPushNotificationRequest(data)).pipe(
        mergeMap((pushNotificationRes) => [
          new ManpowerActions.PushNotificationReceive(pushNotificationRes),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnPushNotificationReceive$ = this.actions$.pipe(
    ofType<ManpowerActions.PushNotificationReceive>(
      ManpowerActions.PUSH_NOTIFICATION_RECEIVE
    ),
    map((action) => {
      if (action.payload.status === 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.message
        );
      } else {
        this.alertMessageCommonService.error('Error', action.payload.message);
      }
    })
  );
}
