import { combineReducers } from '@ngrx/store';
import { RegisterReducer } from './register/register.reducer';
import { AuthStateInterface } from './auth.state';
import { TokenReducer } from './token/token.reducer';
import { AuthenticatedUserReducer } from './authenticated-user/authenticated-user.reducer';
import { ForgotpasswordReducer } from './forgotpassword/forgotpassword.reducer';

export const reducers = combineReducers<AuthStateInterface>({
  register: RegisterReducer,
  token: TokenReducer,
  authenticatedUser: AuthenticatedUserReducer,
  forgot: ForgotpasswordReducer,
});
