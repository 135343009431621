import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SynclogModel } from '../../core/backend/synclog/model/synclog.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { SettingStoreService } from '../../core/setting/setting.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-setting-synclog',
  templateUrl: './synclog.component.html',
  styleUrls: ['./synclog.component.scss'],
})
export class SettingSynclogComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'syncedOn',
    'type',
    'jobsSynced',
    'usersSynced',
    'status',
  ];
  dataSource = new MatTableDataSource<SynclogModel>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ngUnsubscribe$: Subject<any> = new Subject<any>();
  isSynclogDataTableInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  @Input()
  synclogs$: Observable<SynclogModel[]>;

  synclogs: SynclogModel[] = [];

  constructor(private settingStoreService: SettingStoreService) {}

  ngOnInit() {
    this.synclogs$ = this.settingStoreService.getAllSynclogs();
  }

  ngAfterViewInit() {
    this.synclogs$
      .pipe(
        filter((data) => !!data && data.length > 0),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((fetchedSynclogs) => {
        this.synclogs = fetchedSynclogs;
        this.dataSource = new MatTableDataSource(this.synclogs);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.filterPredicate = (data: any, filter: string) => {
          let matchFound = false;
          for (const column of this.displayedColumns) {
            if (column in data) {
              if (data[column]) {
                if (column)
                  matchFound =
                    matchFound ||
                    data[column]
                      .toString()
                      .trim()
                      .toLowerCase()
                      .indexOf(filter.trim().toLowerCase()) !== -1;
              }
            }
          }
          return matchFound;
        };
        this.isSynclogDataTableInitialized$.next(true);
      });
    this.settingStoreService.requestSynclogs();
  }
}
