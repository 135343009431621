import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from '../../../shared/action/utils';
import { PurchaseOrderReceiveListModel } from '../../backend/service-order/model/purchase-order-receive-list.model';

export const PURCHASE_ORDERS_GET_REQUEST = createActionType(
  '[PurchaseOrder] Get Request'
);

export const PURCHASE_ORDERS_GET_RECEIVE = createActionType(
  '[PurchaseOrder] Get Receive'
);

export class PurchaseOrderGetRequest implements Action {
  readonly type = PURCHASE_ORDERS_GET_REQUEST;
  constructor(public payload: any) {}
}
export class PurchaseOrderGetReceive implements Action {
  readonly type = PURCHASE_ORDERS_GET_RECEIVE;
  constructor(
    public payload: {
      purchaseOrderList: PurchaseOrderReceiveListModel;
      isScrolled: boolean;
    }
  ) {}
}
