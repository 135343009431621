import { JobModel } from '../../backend/job/model/job.model';
import { CostCodeModel } from '../../backend/time/model/cost-code.model';

export interface JobsByCompanyInterface {
  jobs: JobModel[];
}

export const initJobsByCompanyState: JobsByCompanyInterface = {
  jobs: [],
};
