import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as PermissionActions from './permission.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { PermissionService } from '../../backend/permission/permission.service';
import { PermissionModel } from '../../backend/permission/model/permission.model';
import {
  HideSpinner,
  ShowSpinner,
} from '../../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../../error/error.actions';
import { ErrorType } from '../../error/model/error-type';
import { AlertMessageCommonService } from '../../../shared/alert-message-common/alert-message-common.service';

type showSpinnerTypes = PermissionActions.PermissionsRequest;

const showSpinnerActions = [PermissionActions.PERMISSIONS_REQUEST];

type hideSpinnerTypes = PermissionActions.PermissionsReceive;

const hideSpinnerActions = [PermissionActions.PERMISSIONS_RECEIVE];
@Injectable()
export class PermissionEffects {
  constructor(
    private actions$: Actions,
    private permissionService: PermissionService,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnPermissionsRequest$ = this.actions$.pipe(
    ofType<PermissionActions.PermissionsRequest>(
      PermissionActions.PERMISSIONS_REQUEST
    ),
    throttleTime(2000),
    switchMap(() =>
      from(this.permissionService.getAllPermissions()).pipe(
        mergeMap((permissions: PermissionModel[]) => [
          new PermissionActions.PermissionsReceive(permissions),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnPermissionCreateRequest$ = this.actions$.pipe(
    ofType<PermissionActions.PermissionCreateRequest>(
      PermissionActions.PERMISSION_CREATE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((payload) =>
      from(this.permissionService.savePermission(payload)).pipe(
        mergeMap((savedPermission) => [
          new PermissionActions.PermissionCreateReceive(savedPermission),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnPermissionCreateReceive$ = this.actions$.pipe(
    ofType<PermissionActions.PermissionCreateReceive>(
      PermissionActions.PERMISSION_CREATE_RECEIVE
    ),
    map((action) => {
      if (action.payload.status == 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.message
        );
      } else {
        this.alertMessageCommonService.error('Error', action.payload.message);
      }
    })
  );

  @Effect()
  OnPermissionUpdateRequest$ = this.actions$.pipe(
    ofType<PermissionActions.PermissionUpdateRequest>(
      PermissionActions.PERMISSION_UPDATE_REQUEST
    ),
    throttleTime(2000),
    map((action) => action.payload),
    switchMap((payload) =>
      from(
        this.permissionService.updatePermission(
          payload.postData,
          payload.roleId
        )
      ).pipe(
        mergeMap((updatedPermission: any) => [
          new PermissionActions.PermissionUpdateReceive(updatedPermission),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
  @Effect({ dispatch: false })
  OnPermissionUpdateReceive$ = this.actions$.pipe(
    ofType<PermissionActions.PermissionUpdateReceive>(
      PermissionActions.PERMISSION_UPDATE_RECEIVE
    ),
    map((action) => {
      if (action.payload.status == 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.message
        );
      } else {
        this.alertMessageCommonService.error('Error', action.payload.message);
      }
    })
  );
}
