import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ExpenseCategoryModel } from './model/expense-category.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ExpenseCategoryBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  fetchAllExpenseCategories(): Observable<
    HttpResponse<{ data: ExpenseCategoryModel[] }>
  > {
    const url = new URL(`${this.API_ROOT}/expense_categories`);

    return this.http.get<{ data: ExpenseCategoryModel[] }>(
      url.toString(),
      this.getOptions()
    );
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${new Date().getTimezoneOffset()}`,
      },
    };
  }
}
