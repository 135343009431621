import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class PayperiodLockoutBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  fetchUpsertPayPeriodLockoutEntry(postData): Observable<any> {
    const url = `${this.API_ROOT}/pay_period_lockouts/updateOrCreate`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  fetchPayPeriodLockStatus(postData): Observable<any> {
    const url = `${this.API_ROOT}/pay_period_lockouts/get_lock_status`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {},
    };
  }
}
