import { createSelector } from '@ngrx/store';
import { WorkorderStateInterface } from './workorder.state';
import { getWorkorderState } from '../store/store.selectors';

export const getSerivceOrderState = createSelector(
  getWorkorderState,
  (state: WorkorderStateInterface) => state.serviceOrder
);

export const getWorkorderScheduleState = createSelector(
  getWorkorderState,
  (state: WorkorderStateInterface) => state.workorderSchedule
);

export const getWorkorderScheduleBydayState = createSelector(
  getWorkorderState,
  (state: WorkorderStateInterface) => state.workorderScheduleByday
);

export const getWorkorderScheduleByEmployeeState = createSelector(
  getWorkorderState,
  (state: WorkorderStateInterface) => state.workorderScheduleByEmployee
);

export const getLaborWorkorderState = createSelector(
  getWorkorderState,
  (state: WorkorderStateInterface) => state.laborWorkorder
);

export const getPurchaseOrderState = createSelector(
  getWorkorderState,
  (state: WorkorderStateInterface) => state.purchaseOrder
);

export const getTruckStockState = createSelector(
  getWorkorderState,
  (state: WorkorderStateInterface) => state.truckStock
);

export const getAttachmentState = createSelector(
  getWorkorderState,
  (state: WorkorderStateInterface) => state.attachment
);
