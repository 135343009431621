import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TotalTimeModel } from './model/totalTime.model';
import { environment } from '../../../../environments/environment';
// TODO this is duplicate of time entry, will need to be cleaned up
@Injectable()
export class TotalTimeBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  fetchAllTotalTimeEntry(): Observable<HttpResponse<TotalTimeModel[]>> {
    const url = `${this.API_ROOT}/time_entry`;
    return this.http.get<TotalTimeModel[]>(url, this.getOptions());
  }

  fetchTotalTimeEntry(
    totalTimeEntry: TotalTimeModel
  ): Observable<HttpResponse<any>> {
    const data = totalTimeEntry;
    const url = `${this.API_ROOT}/time_entry/TimeByEmployee/9272`;
    return this.http.post<any>(url, data, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${new Date().getTimezoneOffset()}`,
      },
    };
  }
}
