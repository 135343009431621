import { createSelector } from '@ngrx/store';
import { getSerivceOrderState } from '../workorder.selectors';
import { ServiceOrderStateInterface, selectAll } from './service-order.state';

export const getServiceOrder = createSelector(
  getSerivceOrderState,
  (state: ServiceOrderStateInterface) => selectAll(state)
);

export const getSites = createSelector(
  getSerivceOrderState,
  (state: ServiceOrderStateInterface) => state.sites
);

export const getSiteInfo = createSelector(
  getSerivceOrderState,
  (state: ServiceOrderStateInterface) => state.siteInfo
);

export const getWorkorderTypes = createSelector(
  getSerivceOrderState,
  (state: ServiceOrderStateInterface) => state.workorderTypes
);

export const getCreatedWorkorder = createSelector(
  getSerivceOrderState,
  (state: ServiceOrderStateInterface) => state.createdWorkorder
);
