import {
  Directive,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { FORM_ERRORS } from '../injection-tokens/form-errors.injection-token';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import indexOf from 'lodash-es/indexOf';

@Directive({
  selector: '[appControlErrors]',
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<any> = new Subject<any>();

  @Output()
  formControlErrorEvent: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(
    private formControl: NgControl,
    @Inject(FORM_ERRORS) private errors
  ) {}

  ngOnInit() {
    this.formControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        const controlErrors = this.formControl.errors;

        if (controlErrors) {
          const keys = Object.keys(controlErrors);
          const getErrors = [];

          keys.forEach((key) => getErrors.push(this.errors[key]));

          const errorMessages: string[] = [];
          getErrors.forEach((errorFunction) => {
            errorMessages.push(
              // @ts-ignore
              errorFunction(controlErrors[keys[indexOf(errorFunction)]])
            );
          });
          this.formControlErrorEvent.emit(errorMessages);
        } else {
          this.formControlErrorEvent.emit(['']);
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
