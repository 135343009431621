import { Action } from '@ngrx/store';
import { createActionType } from '../../../app/shared/action/utils';

export const SPINNER_SHOW = createActionType('[Spinner Show1] Request');
export const SPINNER_HIDE = createActionType('[Spinner Hide1] Request');

export class HideSpinner implements Action {
  readonly type = SPINNER_HIDE;
}

export class ShowSpinner implements Action {
  readonly type = SPINNER_SHOW;
}
