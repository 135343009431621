import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { WorkorderStoreService } from '../../core/workorder/workorder.service';
import * as moment from 'moment';
import { from, Observable, Subject } from 'rxjs';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { WorkorderScheduleReceiveListModel } from '../../core/backend/service-order/model/workorder-schedule-receive-list.model';
import {
  delay,
  filter,
  groupBy,
  mergeMap,
  takeUntil,
  toArray,
} from 'rxjs/operators';
import { ExportService } from '../../shared/export/export.service';
import { WorkorderScheduleByemployeeReceiveListModel } from '../../core/backend/service-order/model/workorder-schedule-byemployee-receive-list.model';
import { Constants } from '../../constants/constant';
import { ServiceOrderService } from '../../core/backend/service-order/service-order.service';
@Component({
  selector: 'app-schedule-board',
  templateUrl: './schedule-board.component.html',
  styleUrls: ['./schedule-board.component.scss'],
})
export class ScheduleBoardComponent implements OnInit, OnDestroy {
  isHideDay = true;
  isHideEmployee = false;
  currentDate: any = new Date();
  dateOffset = 0;
  workorderScheduleByDay: any[];
  workorderScheduleByday$: Observable<WorkorderScheduleReceiveListModel[]>;
  workorderScheduleByemployee: any[];
  workorderScheduleByemployee$: Observable<
    WorkorderScheduleByemployeeReceiveListModel[]
  >;
  ngUnsubscribe$: Subject<any> = new Subject<any>();
  updatedWorkOrders: any = [];
  CONSTANTS = Constants.WORKORDER;
  expanded: boolean = false;
  employee_name = '';
  expandAllCkb = false;
  sortby = 'asc';
  isExpanded = -1;

  @Output()
  uploaded = new EventEmitter<any>();

  PO_CONSTANT = Constants.SERVICEORDER.PO_LIST;

  constructor(
    private workorderStoreService: WorkorderStoreService,
    private exportService: ExportService,
    private spinnerService: SpinnerService,
    private serviceOrderService: ServiceOrderService
  ) {}

  ngOnInit(): void {
    this.setLocalStorageVariable();
    this.spinnerService.manageSpinnerState();

    // By Day tab API calls
    this.getBydayWorkorder();
    this.getStoreValues();

    // By Employee tab API call
    this.getByEmployeeWorkorder();
  }

  setLocalStorageVariable() {
    localStorage.setItem('boardSelectedDate', this.currentDate);
  }

  expandAll() {
    if (this.expandAllCkb == false) {
      this.expandAllCkb = true;
      this.expanded = true;
    } else {
      this.expandAllCkb = false;
      this.expanded = false;
      this.isExpanded = -1;
    }
  }

  expandedIs(key) {
    this.expandAllCkb = false;
    this.expanded = false;
    if (this.isExpanded == key) {
      this.isExpanded = -1;
    } else {
      this.isExpanded = key;
    }
  }

  getBydayWorkorder() {
    this.workorderStoreService.requestGetWorkorderScheduleByday(
      this.getByDaySchedulePostObj()
    );
  }

  searchByname() {
    if (this.employee_name.length > 2 || this.employee_name.length == 0) {
      this.getByEmployeeWorkorder();
    }
  }

  getByEmployeeWorkorder() {
    this.workorderStoreService.requestGetWorkorderScheduleByEmployee(
      this.getByEmployeeSchedulePostObj()
    );
    this.isExpanded = -1;
  }

  getByEmployeeSchedulePostObj() {
    const postObj = {
      search_term: this.employee_name,
      sort: this.sortby,
    };
    return postObj;
  }

  closeByEmployeeDay() {
    this.isHideEmployee = !this.isHideEmployee;
    this.isHideDay = !this.isHideDay;
    this.isExpanded = -1;
  }

  onRemoveSchedule(workOrderEntry) {
    const postData = {
      ce_employee_id: workOrderEntry.ce_employee_id,
      work_order_id: workOrderEntry.work_order_id,
    };

    this.workorderStoreService.requestWorkorderScheduleDelete(postData);

    this.isExpanded = -1;
  }

  nextPrev(i) {
    this.workorderScheduleByDay = [];
    this.dateOffset = this.dateOffset + i;
    this.currentDate = moment().add(this.dateOffset, 'days');
    this.setLocalStorageVariable();
    this.workorderStoreService.requestGetWorkorderScheduleByday(
      this.getByDaySchedulePostObj()
    );
  }

  getByDaySchedulePostObj() {
    const postObj = {
      date_scheduled: this.exportService.formatToYearMonthDay(this.currentDate),
    };
    return postObj;
  }

  getStoreValues() {
    this.workorderScheduleByday$ = this.workorderStoreService.getWorkorderScheduleByday();
    this.workorderScheduleByday$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((workorderScheduleByday) => {
        this.groupWorkorderScheduleByEmployeeId(
          workorderScheduleByday,
          undefined
        );
      });

    this.workorderScheduleByemployee$ = this.workorderStoreService.getWorkorderScheduleByEmployee();
    this.workorderScheduleByemployee$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((workorderScheduleByemployee) => {
        this.workorderScheduleByemployee = workorderScheduleByemployee;
      });
  }

  groupWorkorderScheduleByEmployeeId(
    scheduleEntries: WorkorderScheduleReceiveListModel[],
    byEmployeeEntries: WorkorderScheduleByemployeeReceiveListModel[]
  ) {
    const entries = scheduleEntries ?? byEmployeeEntries;
    if (scheduleEntries) this.workorderScheduleByDay = [];
    else this.workorderScheduleByemployee = [];
    from(entries)
      .pipe(
        groupBy((person) => person?.ce_employee_id?.toString()),
        // return each item in group as array
        mergeMap((group) => group.pipe(toArray()))
      )
      .subscribe((WorkorderScheduleByEmployeeId: any) => {
        if (scheduleEntries) {
          this.addScheduleEntryGroup(
            WorkorderScheduleByEmployeeId[0].ce_employee_id,
            `${WorkorderScheduleByEmployeeId[0].employee_last_name}, ${WorkorderScheduleByEmployeeId[0].employee_first_name}`,
            WorkorderScheduleByEmployeeId[0].work_order_id,
            WorkorderScheduleByEmployeeId,
            true
          );
        } else {
          this.addScheduleEntryGroup(
            WorkorderScheduleByEmployeeId[0].ce_employee_id,
            `${WorkorderScheduleByEmployeeId[0].employee_last_name}, ${WorkorderScheduleByEmployeeId[0].employee_first_name}`,
            WorkorderScheduleByEmployeeId[0].id,
            WorkorderScheduleByEmployeeId,
            false
          );
        }
      });
  }

  addScheduleEntryGroup(
    employeeId,
    employeeName,
    workOrderId,
    scheduledEntries,
    isByday
  ) {
    this.isExpanded = -1;
    if (isByday) {
      this.workorderScheduleByDay.push({
        employeeId,
        employeeName,
        workOrderId,
        scheduledEntries,
      });
    } else {
      this.workorderScheduleByemployee.push({
        employeeId,
        employeeName,
        workOrderId,
        scheduledEntries,
      });
    }
  }

  onEditWorkOrder(workOrder, technician = '', ce_employee_id = null) {
    this.updatedWorkOrders = [];
    const filterStatus = this.CONSTANTS.FILTER_STATUS;
    const filterClass = this.CONSTANTS.FILTER_CLASS;
    let flCls = '';

    switch (workOrder.work_order_status) {
      case filterStatus.UNSCHEDULED:
        flCls = filterClass.UNSCHEDULED;
        break;

      case filterStatus.ONGOING:
        flCls = filterClass.ONGOING;
        break;

      case filterStatus.COMPLETED:
        flCls = filterClass.COMPLETED;
        break;

      case filterStatus.CLOSED:
        flCls = filterClass.CLOSED;
        break;

      default:
        break;
    }

    workOrder = {
      ...workOrder,
      filterclass: flCls,
      activeTab: technician,
      activeTachnician: ce_employee_id,
    };

    this.uploaded.emit(workOrder);
  }

  getPOListByDate() {
    let currentDate = this.exportService.formatToYearMonthDay(this.currentDate);
    let postObj = {
      po_date: currentDate,
    };
    this.serviceOrderService
      .getPOListByDate(postObj)
      .then((res) => {
        const headerList = Object.values(this.PO_CONSTANT);
        const headerKeys = Object.keys(this.PO_CONSTANT);
        this.exportService.downloadFile(
          res.data,
          'MCM-PO-List_' + currentDate,
          headerList,
          headerKeys
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ngOnDestroy(): void {
    localStorage.removeItem('boardSelectedDate');
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
