import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from '../../../shared/action/utils';
import { WorkOrderScheduleByemployeeRequestListModel } from '../../backend/service-order/model/workorder-schedule-byemployee-request-list.model';
import { WorkorderScheduleByemployeeReceiveListModel } from '../../backend/service-order/model/workorder-schedule-byemployee-receive-list.model';

export const WORKORDER_SCHEDULE_BYEMPLOYEE_GET_REQUEST = createActionType(
  '[WorkorderScheduleByEmployee] Get Request'
);

export const WORKORDER_SCHEDULE_BYEMPLOYEE_GET_RECEIVE = createActionType(
  '[WorkorderScheduleByEmployee] Get Receive'
);

export class WorkorderScheduleByEmployeeGetRequest implements Action {
  readonly type = WORKORDER_SCHEDULE_BYEMPLOYEE_GET_REQUEST;
  constructor(public payload?: WorkOrderScheduleByemployeeRequestListModel) {}
}
export class WorkorderScheduleByEmployeeGetReceive implements Action {
  readonly type = WORKORDER_SCHEDULE_BYEMPLOYEE_GET_RECEIVE;
  constructor(
    public payload: {
      scheduleList: WorkorderScheduleByemployeeReceiveListModel;
    }
  ) {}
}
