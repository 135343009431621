import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as WellLogsActions from './well-logs.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { WellLogsService } from '../backend/well-logs/well-logs.service';
import { JobModel } from '../backend/job/model/job.model';
import { HideSpinner, ShowSpinner } from '../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../error/error.actions';
import { ErrorType } from '../error/model/error-type';
import { WellLogsModel } from '../backend/well-logs/model/well-logs.model';

@Injectable()
export class WellLogsEffects {
  constructor(
    private actions$: Actions,
    private wellLogsService: WellLogsService
  ) {}

  @Effect()
  OnWellLogsRequest$ = this.actions$.pipe(
    ofType<WellLogsActions.WellLogsRequest>(WellLogsActions.WELL_LOGS_REQUEST),
    map((action) => action.payload),
    // throttleTime(2000),
    switchMap((data) =>
      from(this.wellLogsService.getAllWellLogs(data)).pipe(
        mergeMap((wellLogs: WellLogsModel[]) => [
          new WellLogsActions.WellLogsReceive(wellLogs),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnNotesRequest$ = this.actions$.pipe(
    ofType<WellLogsActions.NotesRequest>(WellLogsActions.NOTES_REQUEST),
    map((action) => action.payload),
    // throttleTime(2000),
    switchMap((data) =>
      from(this.wellLogsService.getAllNotes(data)).pipe(
        mergeMap((notes: any[]) => [new WellLogsActions.NotesReceive(notes)]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
}
