import { Action, createAction, props } from '@ngrx/store';
import { EditJob } from '../../manpower/model/editJob.model';
import { AssignJob } from './../../manpower/model/assignJob.model';
import { AddJobNote } from '../../manpower/model/addJobNote.model';
import { JobFilter } from '../../manpower/model/JobFilter.model';

import { createActionType } from '../../../app/shared/action/utils';
import { EmployeeFilter } from '../../manpower/model/employeeFilter.model';

export const SPINNER_SHOW = createActionType('[Spinner Show] Request');
export const SPINNER_HIDE = createActionType('[Spinner Hide] Request');

export const JOB_REQUEST = createActionType('[Jobs1] Request');
export const JOB_RECEIVE = createActionType('[Jobs1] Receive');
export const UPDATE_COUNT_RECEIVE = createActionType('[Update Count1] Receive');

export const USER_REQUEST = createActionType('[Users1] Request');
export const USER_RECEIVE = createActionType('[Users1] Receive');

export const ADD_JOB_NOTE_REQUEST = createActionType('[AddJobNote1] Request');
export const ADD_JOB_NOTE_RECEIVE = createActionType('[AddJobNote1] Receive');

export const JOB_NOTES_REQUEST = createActionType('[JobNotes1] Request');
export const JOB_NOTES_RECEIVE = createActionType('[JobNotes1] Receive');

export const ASSIGN_JOB_REQUEST = createActionType('[AssignJob1] Request');
export const ASSIGN_JOB_RECEIVE = createActionType('[AssignJob1] Receive');

export const NON_MANPOWER_JOB_REQUEST = createActionType(
  '[NonManpower1] Request'
);
export const NON_MANPOWER_JOB_RECEIVE = createActionType(
  '[NonManpower1] Receive'
);

export const UNASSIGN_JOB_REQUEST = createActionType('[UnAssignJob1] Request');
export const UNASSIGN_JOB_RECEIVE = createActionType('[UnAssignJob1] Receive');

export const ADD_NEW_JOB_REQUEST = createActionType('[AddNewJob1] Request');
export const ADD_NEW_JOB_RECEIVE = createActionType('[AddNewJob1] Receive');

export const PROJECT_MANAGER_REQUEST = createActionType(
  '[ProjectManager1] Request'
);
export const PROJECT_MANAGER_RECEIVE = createActionType(
  '[ProjectManager1] Receive'
);

export const FOREMAN_REQUEST = createActionType('[Foreman1] Request');
export const FOREMAN_RECEIVE = createActionType('[Foreman1] Receive');

export const SM_FOREMAN_REQUEST = createActionType('[SM_Foreman1] Request');
export const SM_FOREMAN_RECEIVE = createActionType('[SM_Foreman1] Receive');

export const PIPE_FOREMAN_REQUEST = createActionType('[PIPE_Foreman1] Request');
export const PIPE_FOREMAN_RECEIVE = createActionType('[PIPE_Foreman1] Receive');

export const FILTER_REQUEST = createActionType('[Filters1] Request');
export const FILTER_RECEIVE = createActionType('[Filters1] Receive');

export const JOB_FILTERS_REQUEST = createActionType('[JobFilters1] Request');
export const JOB_FILTERS_RECEIVE = createActionType('[JobFilters1] Receive');

export const ASSIGN_JOB_DETAILS_REQUEST = createActionType(
  '[AssignJob Details1] Request'
);
export const ASSIGN_JOB_DETAILS_RECEIVE = createActionType(
  '[AssignJob Details1] Receive'
);

export const UPDATE_JOB_REQUEST = createActionType('[UpdateJob1] Request');
export const UPDATE_JOB_RECEIVE = createActionType('[UpdateJob1] Receive');

export const REMOVE_JOB_REQUEST = createActionType('[RemoveJob1] Request');
export const REMOVE_JOB_RECEIVE = createActionType('[RemoveJob1] Receive');

export const PERFORMANCE_NOTES_REQUEST = createActionType('[Notes1] Request');
export const PERFORMANCE_NOTES_RECEIVE = createActionType('[Notes1] Receive');

export const ADD_PERFORMANCE_NOTES_REQUEST = createActionType(
  '[AddPerformanceNote1] Request'
);
export const ADD_PERFORMANCE_NOTES_RECEIVE = createActionType(
  '[AddPerformanceNote1] Receive'
);

export const RESOLVE_NOTE_REQUEST = createActionType('[ResolveNote1] Request');
export const RESOLVE_NOTE_RECEIVE = createActionType('[ResolveNote1] Receive');

export const FLAG_EMPLOYEE_REQUEST = createActionType(
  '[FlagEmployee1] Request'
);
export const FLAG_EMPLOYEE_RECEIVE = createActionType(
  '[FlagEmployee1] Receive'
);

export const PUSH_NOTIFICATION_REQUEST = createActionType(
  '[Push Notification1] Request'
);
export const PUSH_NOTIFICATION_RECEIVE = createActionType(
  '[Push Notification1] Receive'
);

export const UPDATE_JOB_OBJECT = createActionType(
  '[Update Job Object1] Request'
);

// Fetch Jobs For Manpower
export class JobRequest implements Action {
  readonly type = JOB_REQUEST;

  constructor(
    public payload: {
      page: number;
      pageItem: number;
      jobFilter: JobFilter;
    }
  ) {}
}
export class JobReceive implements Action {
  readonly type = JOB_RECEIVE;
  constructor(public payload: { jobs: any; filter: any }) {}
}

export class UpdateCount implements Action {
  readonly type = UPDATE_COUNT_RECEIVE;
  constructor(public payload: any) {}
}

export class updateJobObject implements Action {
  readonly type = UPDATE_JOB_OBJECT;
  constructor(
    public payload: {
      jobObject: any;
      manpowerCount: any;
    }
  ) {}
}

// Fetch Users For Labor Pool
export class UsersRequest implements Action {
  readonly type = USER_REQUEST;
  constructor(public payload: any) {}
}
export class UsersReceive implements Action {
  readonly type = USER_RECEIVE;
  constructor(public payload: { users: [] }) {}
}

// For Add Job All Notes
export class AddJobNoteRequest implements Action {
  readonly type = ADD_JOB_NOTE_REQUEST;
  constructor(
    public payload: {
      addJobNote: AddJobNote;
      jobFilter: JobFilter;
    }
  ) {}
}
export class AddJobNoteReceive implements Action {
  readonly type = ADD_JOB_NOTE_RECEIVE;
  constructor(
    public payload: {
      jobData: any;
      jobFilter?: JobFilter;
    }
  ) {}
}

// For Get Job All Notes
export class JobNotesRequest {
  readonly type = JOB_NOTES_REQUEST;
  constructor(public payload: any) {}
}
export class JobNotesReceive implements Action {
  readonly type = JOB_NOTES_RECEIVE;
  constructor(
    public payload: {
      jobNotes: [];
    }
  ) {}
}

// Assgning Employee To Job
export class AssignJobRequest {
  readonly type = ASSIGN_JOB_REQUEST;
  constructor(
    public payload: {
      assignJob: AssignJob;
      jobFilter: JobFilter;
      employeeFilter: EmployeeFilter;
    }
  ) {}
}

export class AssignJobReceive {
  readonly type = ASSIGN_JOB_RECEIVE;
  constructor(
    public payload: {
      jobReceiveData: any;
      jobFilter: JobFilter;
      employeeFilter: EmployeeFilter;
    }
  ) {}
}

// Fetch Non-Manpower Jobs
export class NonManpowerJobRequest {
  readonly type = NON_MANPOWER_JOB_REQUEST;
  constructor() {}
}
export class NonManpowerJobReceive {
  readonly type = NON_MANPOWER_JOB_RECEIVE;
  constructor(public payload: { jobs: [] }) {}
}

// UnAssign Employee from Job
export class UnAssignJobRequest {
  readonly type = UNASSIGN_JOB_REQUEST;
  constructor(
    public payload: {
      job_id: number;
      employee_id: number;
      is_assigned: number;
      jobFilter: JobFilter;
      employeeFilter: EmployeeFilter;
    }
  ) {}
}
export class UnAssignJobReceive {
  readonly type = UNASSIGN_JOB_RECEIVE;
  constructor(public payload: any) {}
}

// Add New Job For Manpower
export class AddNewJobRequest {
  readonly type = ADD_NEW_JOB_REQUEST;
  constructor(
    public payload: {
      job_id: number;
      jobFilter: JobFilter;
    }
  ) {}
}
export class AddNewJobReceive {
  readonly type = ADD_NEW_JOB_RECEIVE;
  constructor(public payload: any) {}
}

// For Get Project manager
export class ProjectManagerRequest {
  readonly type = PROJECT_MANAGER_REQUEST;
  constructor() {}
}
export class ProjectManagerReceive {
  readonly type = PROJECT_MANAGER_RECEIVE;
  constructor(public payload: { ProjectManagers: [] }) {}
}

// For Get Foremans
export class ForemanRequest {
  readonly type = FOREMAN_REQUEST;
  constructor(public payload?: string) {}
}
export class ForemanReceive {
  readonly type = FOREMAN_RECEIVE;
  constructor(public payload: { Foremans: [] }) {}
}

// For Get SM Foremans
export class SMforemanRequest {
  readonly type = SM_FOREMAN_REQUEST;
  constructor(public payload?: string) {}
}
export class SMForemanReceive {
  readonly type = SM_FOREMAN_RECEIVE;
  constructor(public payload: { Foremans: [] }) {}
}

// For Get Pipe Foremans

export class PipeforemanRequest {
  readonly type = PIPE_FOREMAN_REQUEST;
  constructor(public payload?: string) {}
}
export class PipeForemanReceive {
  readonly type = PIPE_FOREMAN_RECEIVE;
  constructor(public payload: { Foremans: [] }) {}
}

// For Get Employee Filters List
export class FiltersRequest {
  readonly type = FILTER_REQUEST;
  constructor() {}
}
export class FiltersReceive {
  readonly type = FILTER_RECEIVE;
  constructor(public payload: { Filters: [] }) {}
}

// For Get Job Filters List
export class JobFiltersRequest {
  readonly type = JOB_FILTERS_REQUEST;
  constructor() {}
}
export class JobFiltersReceive {
  readonly type = JOB_FILTERS_RECEIVE;
  constructor(public payload: { jobFilters: [] }) {}
}

// for Get Assign Job Details
export class AssignJobDetailsRequest {
  readonly type = ASSIGN_JOB_DETAILS_REQUEST;
  constructor(public payload: any) {}
}
export class AssignJobDetailsReceive {
  readonly type = ASSIGN_JOB_DETAILS_RECEIVE;
  constructor(public payload: { empJobDetails: any }) {}
}

// Edit Job
export class UpdateJobRequest {
  readonly type = UPDATE_JOB_REQUEST;
  constructor(
    public payload: {
      jobData: EditJob;
      filters?: JobFilter;
    }
  ) {}
}
export class UpdateJobReceive {
  readonly type = UPDATE_JOB_RECEIVE;
  constructor(
    public payload: {
      jobData: any;
      filters?: JobFilter;
      // jobPageIndex?: number;
    }
  ) {}
}

// Remove Job
export class RemoveJobRequest {
  readonly type = REMOVE_JOB_REQUEST;
  constructor(public payload: any) {}
}
export class RemoveJobReceive {
  readonly type = REMOVE_JOB_RECEIVE;
  constructor(public payload: any) {}
}

// Get All Employee Performance Notes By Employee ID
export class PerformanceNotesRequest {
  readonly type = PERFORMANCE_NOTES_REQUEST;
  constructor(
    public payload: {
      emp_id: number;
      page: number;
      pageItems: number;
    }
  ) {}
}
export class PerformanceNotesReceive {
  readonly type = PERFORMANCE_NOTES_RECEIVE;
  constructor(public payload: { performanceNotes: [] }) {}
}

// Add Performance Note
export class AddPerformanceNoteRequest {
  readonly type = ADD_PERFORMANCE_NOTES_REQUEST;
  constructor(
    public payload: {
      employee_id: number;
      text: string;
    }
  ) {}
}
export class AddPerformanceNoteReceive {
  readonly type = ADD_PERFORMANCE_NOTES_RECEIVE;
  constructor(public payload: any) {}
}

// For Resolve Job Note
export class ResolveNoteRequest {
  readonly type = RESOLVE_NOTE_REQUEST;
  constructor(
    public payload: {
      noteId: number;
      jobFilter: JobFilter;
      // jobPageIndex: number;
    }
  ) {}
}
export class ResolveNoteReceive {
  readonly type = RESOLVE_NOTE_RECEIVE;
  constructor(public payload: any) {}
}

// For Flag Employee perfomance Note
export class FlagEmployeeRequest {
  readonly type = FLAG_EMPLOYEE_REQUEST;
  constructor(
    public payload: {
      note_id: number;
      flagged: number;
      employee_id: number;
      employeeFilter: EmployeeFilter;
    }
  ) {}
}
export class FlagEmployeeReceive {
  readonly type = FLAG_EMPLOYEE_RECEIVE;
  constructor(
    public payload: {
      employeeId: number;
      employeeFilter: EmployeeFilter;
    }
  ) {}
}

// for Push notification to employees
export class PushNotificationRequest {
  readonly type = PUSH_NOTIFICATION_REQUEST;
  constructor(public payload: any) {}
}
export class PushNotificationReceive {
  readonly type = PUSH_NOTIFICATION_RECEIVE;
  constructor(public payload: any) {}
}
