import * as AuthenticatedUserActions from './authenticated-user.actions';
import {
  AuthenticatedUserStateInterface,
  initAuthenticatedUserStateInterface,
} from './authenticated-user.state';

export function AuthenticatedUserReducer(
  state = initAuthenticatedUserStateInterface,
  action
): AuthenticatedUserStateInterface {
  switch (action.type) {
    case AuthenticatedUserActions.CURRENT_USER_INFO_RECEIVE: {
      const payload = action.payload.data.user;

      return {
        ...state,
        user: payload,
      };
    }
    case AuthenticatedUserActions.CURRENT_USER_INFO_DELETE: {
      return {
        ...state,
        user: null,
      };
    }

    default:
      return state;
  }
}
