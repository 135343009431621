import { createSelector } from '@ngrx/store';
import { PayPeriodLockoutStateInterface } from './payperiod-lockout.state';
import { getPayPeriodLockState } from '../store/store.selectors';

export const getPayperiodLockout = createSelector(
  getPayPeriodLockState,
  (state: PayPeriodLockoutStateInterface) => state.timeEntryLockStatus
);

export const getPayPeriodLockStatusOnDateChange = createSelector(
  getPayPeriodLockState,
  (state: PayPeriodLockoutStateInterface) => state.isDateLocked
);

export const getPayPeriodLockEntry = createSelector(
  getPayPeriodLockState,
  (state: PayPeriodLockoutStateInterface) => state.payPeriodLockout
);
