import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { catchError, map, tap } from 'rxjs/operators';
import { ServiceOrderBackend } from './service-order.backend';
@Injectable()
export class ServiceOrderService extends BaseService {
  constructor(private serviceOrderBackend: ServiceOrderBackend) {
    super();
  }

  createWorkOrder(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .createWorkOrder(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getAllWorkOrder(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getAllWorkOrder(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getWorkOrderById(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getWorkOrderById(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  updateWorkorder(postData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .updateWorkorder(postData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  createWorkOrderSchedule(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .createWorkOrderSchedule(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getWorkOrderSchedule(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getWorkOrderSchedule(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getWorkOrderScheduleByEmployee(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getWorkOrderScheduleByEmployee(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  deleteWorkOrderSchedule(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .deleteWorkOrderSchedule(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  serviceOrderGetSites(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getSites(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  serviceOrderGetTypes(): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getWorkorderTypes()
      .pipe(map((response) => response.body))
      .toPromise();
  }

  workOrderGetLabor(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getWorkorderLabor(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  workOrderGetPurchaseOrders(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getWorkorderPurchaseOrders(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  workOrderGetTruckStock(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getWorkorderTruckStock(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  workOrderGetAttachment(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getWorkorderAttachment(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getInvoiceDetail(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getInvoiceDetail(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getGeneratedInvoice(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getGeneratedInvoice(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getInvoiceStatus(): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getInvoiceStatus()
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getPOListByDate(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .getPOListByDate(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  createInvoice(jsonData: any): Promise<any | ErrorMessage> {
    return this.serviceOrderBackend
      .createInvoice(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }
}
