import { createSelector } from '@ngrx/store';
import { getExpenseCategoryState } from '../store/store.selectors';
import { selectAll } from './expense-category.state';

import type { ExpenseCategoryStateInterface } from './expense-category.state';

export const getAllCategories = createSelector(
  getExpenseCategoryState,
  (state: ExpenseCategoryStateInterface) => selectAll(state)
);
