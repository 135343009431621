import * as JobActions from './job.actions';
import { adapterJob, initJobState, JobStateInterface } from './job.state';

export function JobReducer(state = initJobState, action): JobStateInterface {
  switch (action.type) {
    case JobActions.JOBS_RECEIVE: {
      const payload = action.payload;
      return adapterJob.setAll(payload, state);
      // return adapterJob.addMany(payload, {
      //   ...state,
      // });
    }

    case JobActions.JOB_TOGGLE_FAVOURITE_RECEIVE: {
      const payload = action.payload;
      return adapterJob.updateOne({ id: payload.id, changes: payload }, state);
    }

    default:
      return state;
  }
}
