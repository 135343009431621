import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WorkorderScheduleReceiveListModel } from '../../backend/service-order/model/workorder-schedule-receive-list.model';

export interface WorkorderScheduleStateInterface
  extends EntityState<WorkorderScheduleReceiveListModel> {
  workorderSchedule: any;
}

export const adapterWorkorderSchedule: EntityAdapter<WorkorderScheduleReceiveListModel> = createEntityAdapter<WorkorderScheduleReceiveListModel>();

export const initWorkorderScheduleState: WorkorderScheduleStateInterface = adapterWorkorderSchedule.getInitialState(
  {
    workorderSchedule: null,
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterWorkorderSchedule.getSelectors();
