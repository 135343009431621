import { Action, createAction, props } from '@ngrx/store';
import { CostCodeModel } from '../../backend/time/model/cost-code.model';
import { createActionType } from '../../../shared/action/utils';

export const COST_CODE_REQUEST = createActionType('[Cost Code1] Request');
export const COST_CODE_RECEIVE = createActionType('[Cost Code1] Receive');

export class CostCodeRequest implements Action {
  readonly type = COST_CODE_REQUEST;
  constructor(
    public payload: {
      job_id: number;
      is_travel: any;
    }
  ) {}
}
export class CostCodeReceive implements Action {
  readonly type = COST_CODE_RECEIVE;
  constructor(
    public payload: {
      costCodes: CostCodeModel[];
    }
  ) {}
}
