import * as CostCodeActions from './cost-code.actions';
import { CostCodeStateInterface, initCostCodeState } from './cost-code.state';

export function costCodesReducer(
  state = initCostCodeState,
  action
): CostCodeStateInterface {
  switch (action.type) {
    case CostCodeActions.COST_CODE_RECEIVE: {
      const payload = action.payload;

      return {
        ...state,
        costCodes: payload.costCodes,
      };
    }

    default:
      return state;
  }
}
