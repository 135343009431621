import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { iif, of, from, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthStoreService } from '../auth/auth.service';
import { CustomerService } from '../backend/customer/customer.service';
import { UserCredential } from '../auth/model/user-credential.model';
import { UserResetPassword } from '../auth/model/user-reset-password.model';
import * as RouterActions from './router.actions';
import { AlertMessageCommonService } from '../../shared/alert-message-common/alert-message-common.service';
import { HideSpinner, ShowSpinner } from '../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';

type showSpinnerTypes =
  | RouterActions.RouterLoginRequest
  | RouterActions.ResetPasswordRequest;

const showSpinnerActions = [
  RouterActions.ROUTER_LOGIN_REQUEST,
  RouterActions.RESET_PASSWORD_REQUEST,
];

type hideSpinnerTypes =
  | RouterActions.RouterLoginSuccess
  | RouterActions.RouterLoginFail
  | RouterActions.ResetPasswordReceive;

const hideSpinnerActions = [
  RouterActions.ROUTER_LOGIN_SUCCESS,
  RouterActions.ROUTER_LOGIN_FAIL,
  RouterActions.RESET_PASSWORD_RECEIVE,
];
@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private authStoreService: AuthStoreService,
    private customerService: CustomerService,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnLoginRequest$ = this.actions$.pipe(
    ofType<RouterActions.RouterLoginRequest>(
      RouterActions.ROUTER_LOGIN_REQUEST
    ),
    map((action) => action.payload),
    map((cred: UserCredential) => this.authStoreService.requestToken(cred)),
    switchMap(() =>
      this.authStoreService.getTokenExpiration().pipe(
        filter((expirationDate) => !!expirationDate),
        mergeMap((tokenExpiration) =>
          iif(
            () => this.customerService.isAuthenticated(tokenExpiration),
            of(new RouterActions.RouterLoginSuccess()),
            of(new RouterActions.RouterLoginFail())
          )
        )
      )
    )
  );

  // TODO update this so it can be usable from login modal when that is implemented
  @Effect({ dispatch: false })
  OnLoginSuccess$ = this.actions$.pipe(
    ofType<RouterActions.RouterLoginSuccess>(
      RouterActions.ROUTER_LOGIN_SUCCESS
    ),
    switchMap(() => this.router.navigate(['/dashboard']))
  );

  @Effect({ dispatch: false })
  OnLoginFail$ = this.actions$.pipe(
    ofType<RouterActions.RouterLoginFail>(RouterActions.ROUTER_LOGIN_FAIL),
    switchMap(() => this.router.navigate(['/login']))
  );

  @Effect()
  OnResetPasswordRequest$ = this.actions$.pipe(
    ofType<RouterActions.ResetPasswordRequest>(
      RouterActions.RESET_PASSWORD_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((userResetPassword: UserResetPassword) =>
      from(this.customerService.ResetPasswordEntry(userResetPassword)).pipe(
        mergeMap((data) => [new RouterActions.ResetPasswordReceive(data)]),
        catchError((data) => of(new RouterActions.ResetPasswordReceive(data)))
      )
    )
  );

  @Effect({ dispatch: false })
  OnResetPasswordRecieve$ = this.actions$.pipe(
    ofType<RouterActions.ResetPasswordReceive>(
      RouterActions.RESET_PASSWORD_RECEIVE
    ),
    map((action) => {
      if (action.payload.status && action.payload.status == 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.message
        );
        this.router.navigate(['/login']);
      } else {
        this.alertMessageCommonService.error('Error', action.payload.message);
      }
    })
  );
}
