import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ServiceOrderActions from './service-order.actions';
import * as WorkorderScheduleBydayActions from '../workorder-schedule-byday/workorder-schedule-byday.actions';
import * as WorkorderScheduleByEmployeeActions from '../workorder-schedule-byEmployee/workorder-schedule-byEmployee.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import {
  HideSpinner,
  ShowSpinner,
} from '../../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../../error/error.actions';
import { ErrorType } from '../../error/model/error-type';
import { AlertMessageCommonService } from '../../../shared/alert-message-common/alert-message-common.service';
import { ServiceOrderService } from '../../backend/service-order/service-order.service';
import { ExportService } from '../../../shared/export/export.service';

type showSpinnerTypes = ServiceOrderActions.ServiceOrderCreateRequest;

const showSpinnerActions = [ServiceOrderActions.SERVICE_ORDER_CREATE_REQUEST];

type hideSpinnerTypes = ServiceOrderActions.ServiceOrderCreateReceive;

const hideSpinnerActions = [ServiceOrderActions.SERVICE_ORDER_CREATE_RECEIVE];

@Injectable()
export class ServiceOrderEffects {
  constructor(
    private actions$: Actions,
    private serviceOrderService: ServiceOrderService,
    private alertMessageCommonService: AlertMessageCommonService,
    private exportService: ExportService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnServiceOrderCreateRequest$ = this.actions$.pipe(
    ofType<ServiceOrderActions.ServiceOrderCreateRequest>(
      ServiceOrderActions.SERVICE_ORDER_CREATE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((payload) =>
      from(this.serviceOrderService.createWorkOrder(payload)).pipe(
        mergeMap((createdWorkOrder) => [
          new ServiceOrderActions.ServiceOrderCreateReceive(createdWorkOrder),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnServiceOrderCreateReceive$ = this.actions$.pipe(
    ofType<ServiceOrderActions.ServiceOrderCreateReceive>(
      ServiceOrderActions.SERVICE_ORDER_CREATE_RECEIVE
    ),
    map((action) => {
      if (action.payload.status == 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.message
        );
      } else {
        this.alertMessageCommonService.error('Error', action.payload.message);
      }
    })
  );

  @Effect()
  OnServiceOrderGetRequest$ = this.actions$.pipe(
    ofType<ServiceOrderActions.ServiceOrderGetRequest>(
      ServiceOrderActions.SERVICE_ORDER_GET_REQUEST
    ),
    map((action) => action.payload),
    switchMap((payload) =>
      from(this.serviceOrderService.getAllWorkOrder(payload)).pipe(
        mergeMap((workOrderList) => [
          new ServiceOrderActions.ServiceOrderGetReceive({
            workOrders: workOrderList,
            filter: payload.isFilterApplied,
          }),
          new ServiceOrderActions.ServiceOrderSetCurrentFilter(payload),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnServiceOrderGetSitesRequest$ = this.actions$.pipe(
    ofType<ServiceOrderActions.ServiceOrderGetSitesRequest>(
      ServiceOrderActions.SERVICE_ORDER_GET_SITES_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((payload) =>
      from(this.serviceOrderService.serviceOrderGetSites(payload)).pipe(
        mergeMap((siteRes) =>
          !payload.hasOwnProperty('site_id')
            ? [new ServiceOrderActions.ServiceOrderGetSitesReceive(siteRes)]
            : [new ServiceOrderActions.ServiceOrderGetSiteInfo(siteRes)]
        ),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnServiceOrderGetTypesRequest$ = this.actions$.pipe(
    ofType<ServiceOrderActions.ServiceOrderGetTypesRequest>(
      ServiceOrderActions.SERVICE_ORDER_GET_TYPES_REQUEST
    ),
    throttleTime(2000),
    switchMap(() =>
      from(this.serviceOrderService.serviceOrderGetTypes()).pipe(
        mergeMap((typeList) => [
          new ServiceOrderActions.ServiceOrderGetTypesReceive(typeList),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnServiceOrderUpdateRequest$ = this.actions$.pipe(
    ofType<ServiceOrderActions.ServiceOrderUpdateRequest>(
      ServiceOrderActions.SERVICE_ORDER_UPDATE_REQUEST
    ),
    map((action) => action.payload),
    switchMap((payload) =>
      from(this.serviceOrderService.updateWorkorder(payload.postData)).pipe(
        mergeMap((updatedWorkorder) => [
          new ServiceOrderActions.ServiceOrderUpdateReceive({
            updatedEntry: updatedWorkorder,
            from: 'afterUpdateWorkorder',
          }),
          new ServiceOrderActions.ServiceOrderDeleteReceive({
            res: updatedWorkorder,
            isWorkorderStatusUpdate: payload.isWorkorderStatusUpdate,
          }),
          new WorkorderScheduleBydayActions.WorkorderScheduleBydayGetRequest({
            date_scheduled: this.exportService.formatToYearMonthDay(
              localStorage.getItem('boardSelectedDate')
            ),
          }),
          new WorkorderScheduleByEmployeeActions.WorkorderScheduleByEmployeeGetRequest(),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnServiceOrderUpdateReceive$ = this.actions$.pipe(
    ofType<ServiceOrderActions.ServiceOrderUpdateReceive>(
      ServiceOrderActions.SERVICE_ORDER_UPDATE_RECEIVE
    ),
    map((action) => {
      if (action.payload.updatedEntry.status == 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.updatedEntry.message
        );
      }
    })
  );
}
