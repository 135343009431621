import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SettingGeneralComponent } from './general/general.component';
import { SettingUsersComponent } from './users/users.component';
import { SettingRolesComponent } from './roles/roles.component';
import { SettingSynclogComponent } from './synclog/synclog.component';
import { SettingComponent } from './setting.component';
import { SettingManpowerComponent } from './setting-manpower/setting-manpower.component';

const settingRoutes: Routes = [
  {
    path: '',
    component: SettingComponent,
    children: [
      {
        path: 'general',
        component: SettingGeneralComponent,
      },
      {
        path: 'users',
        component: SettingUsersComponent,
      },
      {
        path: 'roles',
        component: SettingRolesComponent,
      },
      {
        path: 'sync-log',
        component: SettingSynclogComponent,
      },
      {
        path: 'manpower',
        component: SettingManpowerComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(settingRoutes)],
  exports: [RouterModule],
})
export class SettingRoutingModule {}
