export interface ByEmployeeInterface {
  departments: [];
  classes: [];
  locals: [];
}

export const initByEmployeeInterfaceState: ByEmployeeInterface = {
  departments: [],
  classes: [],
  locals: [],
};
