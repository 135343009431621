import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as TotalTimeActions from './totalTime.actions';
import { CDSAppState } from '../store/store.state';
import * as TotalTimeSelectors from './totalTime.selectors';
import { TotalTimeModel } from '../backend/totalTime/model/totalTime.model';
import { Observable } from 'rxjs';

@Injectable()
export class TotalTimeStoreService {
  constructor(private store: Store<CDSAppState>) {}

  public getAllTotalTimeEntry(): Observable<TotalTimeModel[]> {
    return this.store.pipe(
      select((state) => TotalTimeSelectors.getAllTotalTimeEntry(state))
    );
  }

  public requestTotalTimeEntry() {
    this.store.dispatch(new TotalTimeActions.TotalTimeRequest());
  }

  public requestAddTotalTimeEntry(totalTimeEntry: TotalTimeModel) {
    this.store.dispatch(
      new TotalTimeActions.TotalTimeAddRequest(totalTimeEntry)
    );
  }
}
