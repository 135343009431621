import { AlertMessageCommonService } from 'src/app/shared/alert-message-common/alert-message-common.service';
import { Component, OnInit } from '@angular/core';
import { AuthStoreService } from '../../core/auth/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { SpinnerService } from '../../shared/spinner/spinner.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  username: FormControl;
  password: FormControl;
  usernameError$: Subject<string> = new Subject<string>();
  passwordError$: Subject<string> = new Subject<string>();
  rememberMe: boolean;
  usernameError: string;
  passwordError: string;

  constructor(
    private authStoreService: AuthStoreService,
    private alertMessageCommonService: AlertMessageCommonService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.username = new FormControl('', {
      validators: [
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.required,
      ],
    });
    this.password = new FormControl('', {
      validators: [
        Validators.minLength(6),
        Validators.maxLength(50),
        Validators.required,
      ],
    });
  }

  onLoginSubmit() {
    if (this.isValidation()) {
      this.authStoreService.initLogin({
        username: this.username.value,
        password: this.password.value,
        rememberMe: !!this.rememberMe,
      });
      this.spinnerService.manageSpinnerState();
    }
  }

  isValidation() {
    this.usernameError = '';
    this.passwordError = '';

    if (this.username.value === '' && this.password.value === '') {
      this.usernameError = 'error';
      this.passwordError = 'error';
      this.alertMessageCommonService.error(
        'error',
        'Username and password required.'
      );
      return false;
    }
    if (this.username.value === '') {
      this.usernameError = 'error';
      this.alertMessageCommonService.error('error', 'Username required.');
      return false;
    }
    if (this.password.value === '') {
      this.passwordError = 'error';
      this.alertMessageCommonService.error('error', 'Password required.');
      return false;
    } else if (this.password.value.length < 6) {
      this.passwordError = 'error';
      this.alertMessageCommonService.error(
        'error',
        'Minimum 6 character password are required.'
      );
      return false;
    }
    return true;
  }
}
