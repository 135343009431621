import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from '../../../shared/action/utils';
import { LaborWorkorderReceiveListModel } from '../../backend/service-order/model/labor-workorder-receive-list.model';

export const LABOR_WORKORDER_GET_REQUEST = createActionType(
  '[LaborWorkorder] Get Request'
);

export const LABOR_WORKORDER_GET_RECEIVE = createActionType(
  '[LaborWorkorder] Get Receive'
);

export class LaborWorkorderGetRequest implements Action {
  readonly type = LABOR_WORKORDER_GET_REQUEST;
  constructor(public payload: any) {}
}
export class LaborWorkorderGetReceive implements Action {
  readonly type = LABOR_WORKORDER_GET_RECEIVE;
  constructor(
    public payload: {
      workorderList: LaborWorkorderReceiveListModel;
      isScrolled: boolean;
    }
  ) {}
}
