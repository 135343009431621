import { JobLocalModel } from './model/job-local.model';
import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { catchError, map } from 'rxjs/operators';
import { TimeBackend } from './time.backend';
import { TimeModel } from './model/time.model';
import { CostCodeModel } from './model/cost-code.model';
import { JobModel } from '../job/model/job.model';
import { Observable } from 'rxjs';

@Injectable()
export class TimeService extends BaseService {
  constructor(private timeBackend: TimeBackend) {
    super();
  }

  getAllTimeEntry(postData): Promise<TimeModel[] | ErrorMessage> {
    return this.timeBackend
      .fetchAllTimeEntry(postData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  addTimeEntry(timeEntry: TimeModel): Promise<any | ErrorMessage> {
    return this.timeBackend
      .fetchAddTimeEntry(timeEntry)
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise();
  }

  editTimeEntry(timeEntry: TimeModel): Promise<any | ErrorMessage> {
    return this.timeBackend
      .fetchEditTimeEntry(timeEntry)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  deleteTimeEntry(timeEntryId: string): Promise<any | ErrorMessage> {
    return this.timeBackend
      .fetchDeleteTimeEntry(timeEntryId)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getCostCodesByDepartment(
    job_id: number,
    is_travel: any
  ): Promise<CostCodeModel[] | ErrorMessage> {
    return this.timeBackend
      .fetchCostCodesByDepartment(job_id, is_travel)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getJobsByCompany(company: number): Promise<JobModel[] | ErrorMessage> {
    return this.timeBackend
      .fetchJobsByCompany(company)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getDepartment(company_number: number): Promise<[] | ErrorMessage> {
    return this.timeBackend
      .getDepartment(company_number)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getLocals(): Promise<[] | ErrorMessage> {
    return this.timeBackend
      .getLocals()
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getClasses(): Promise<[] | ErrorMessage> {
    return this.timeBackend
      .getClasses()
      .pipe(
        map((response) => {
          return response.body.data;
        })
      )
      .toPromise();
  }

  getLocalByJob(jobLocalModel: JobLocalModel): Promise<any | ErrorMessage> {
    return this.timeBackend
      .fetchLocalByJob(jobLocalModel)
      .pipe(
        map((response) => response.body),
        catchError(this.handleErrorResponse)
      )
      .toPromise();
  }

  updateTimeEntryNote(noteModel: any): Promise<any | ErrorMessage> {
    return this.timeBackend
      .fetchTimeEntryNote(noteModel)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  updateTimeEntryNoteRemove(noteModel: any): Promise<any | ErrorMessage> {
    return this.timeBackend
      .fetchTimeEntryNoteRemove(noteModel)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  deleteTimeEntryContainerRemove(
    timeEntryModel: any
  ): Promise<any | ErrorMessage> {
    return this.timeBackend
      .fetchTimeEntryContainerRemove(timeEntryModel)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getJobCost(dateObj): Promise<any | ErrorMessage> {
    return this.timeBackend
      .getJobCostEntries(dateObj)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }
  // get time entry by id
  getTimeEntrtById(id: any): Promise<any | ErrorMessage> {
    return this.timeBackend
      .fetchTimeEntryById(id)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  certifyTimeEntry(payload): Observable<any | ErrorMessage> {
    return this.timeBackend
      .certifyTimeEntry(payload)
      .pipe(map((response) => response.body));
  }
  getCertifiedTimeEntries(payload): Observable<any | ErrorMessage> {
    return this.timeBackend
      .getCertifiedTimeEntries(payload)
      .pipe(map((response) => response.body));
  }
}
