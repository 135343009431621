import { NgModule } from '@angular/core';
import { AuthStoreService } from '../auth/auth.service';
import { RouterStoreService } from '../router/router.service';
import { JobStoreService } from '../job/job.service';
import { SettingStoreService } from '../setting/setting.service';
import { TimeStoreService } from '../time/time.service';
import { TotalTimeStoreService } from '../totalTime/totalTime.service';
import { ManpowerStoreService } from '../manpower/manpower.service';
import { PayperiodLockoutService } from '../payperiod-lockout/payperiod-lockout.service';
import { WorkorderStoreService } from '../workorder/workorder.service';
import { BillingRateStoreService } from '../billing-rate/billing-rate.service';
import { WellLogsStoreService } from '../well-logs/well-logs.service';
import { OverlayStoreService } from '../overlay/overlay.service';
import { ExpenseCategoryStoreService } from '../expense-category/expense-category.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [],
})
export class StoreServicesModule {
  static forRoot() {
    return {
      ngModule: StoreServicesModule,
      providers: [
        AuthStoreService,
        RouterStoreService,
        JobStoreService,
        SettingStoreService,
        TimeStoreService,
        TotalTimeStoreService,
        ManpowerStoreService,
        PayperiodLockoutService,
        WorkorderStoreService,
        BillingRateStoreService,
        WellLogsStoreService,
        OverlayStoreService,
        ExpenseCategoryStoreService,
      ],
    };
  }
}
