export const Constants = {
  WORKORDER: {
    PAGE_ITEM: 20,
    PAGE_INDEX: 1,
    FILTER_STATUS: {
      UNSCHEDULED: 0,
      ONGOING: 1,
      COMPLETED: 2,
      CLOSED: 3,
    },
    FILTER_CLASS: {
      UNSCHEDULED: 'unscheduled',
      ONGOING: 'ongoing',
      COMPLETED: 'completed',
      CLOSED: 'closed',
    },
    CHECKBOX_COLOR: {
      GREEN: '#1ED175',
      ORANGE: '#ff5e00',
      BLUE: '#3077c5',
      GREY: '#000000',
    },
    COLOR_CODE: {
      '#c0d8fc': 'blue',
      '#bcf2d6': 'green',
      '#ffceb2': 'orange',
      '#ff9b9b': 'danger',
    },
    SCHEDULELIST: {
      PAGE_ITEM: 20,
      PAGE_INDEX: 1,
    },
  },
  MANPOWER: {
    PAGE_ITEM: 20,
    PAGE_INDEX: 1,
    SM_FOREMAN: 'sm_foreman',
    PIPE_FOREMAN: 'pipe_foreman',
  },
  TIME: {
    // don't change key name as its used to compare with API object
    FAB_JOB_COST: {
      empnum: 'Employee Number',
      paytype: 'Pay Type',
      entrydate: 'Entry Date',
      hours: 'Hours',
      department: 'Department',
      class: 'Class',
      description: 'Description',
      jobnum: 'Job',
      phasenum: 'Phase',
      catnum: 'Category',
      costcode: 'Import Cost Code',
      location: 'Location',
      wcomp1: 'WComp1',
      wcomp2: 'WComp2',
      state: 'State',
      notes: 'Notes',
    },
    TIME_ENTRIES: {
      empnum: 'Employee Number',
      paytype: 'Pay Type',
      entry_date: 'Entry Date',
      amount: 'Hours',
      department: 'Department',
      class: 'Class',
      jobstate: 'Description',
      job: 'Job',
      phase: 'Phase',
      catnum: 'Category',
      import: 'Import Cost Code',
      location: 'Location',
      wcomp1: 'WComp1',
      wcomp2: 'WComp2',
      state: 'State',
      notes: 'Notes',
    },
  },
  SERVICEORDER: {
    // don't change key name as its used to compare with API object
    PO_LIST: {
      used_date: 'Used Date',
      po_num: 'PO Number',
      vendornum: 'Vendor Number',
      vendor_name: 'Vendor Name',
      cost: 'Cost',
      po_item_description: 'Description',
      used_status: 'Used Status',
    },
  },
};
