import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ServiceOrderBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  createWorkOrder(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/createWorkOrder`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getAllWorkOrder(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/getAllWorkOrder`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getWorkOrderById(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/getOrderByid`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  updateWorkorder(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/updateWorkOrder`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  createWorkOrderSchedule(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/createSchedule`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getWorkOrderSchedule(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/getSchedule`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getWorkOrderScheduleByEmployee(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/getScheduleByEmployee`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  deleteWorkOrderSchedule(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/removeSchedule`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getSites(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/site_configurations/sites`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getWorkorderTypes(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/workOrderTypes`;
    return this.http.get<any>(url, this.getOptions());
  }

  getWorkorderLabor(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/laborWorkOrder/byWorkOrder`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getWorkorderPurchaseOrders(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/getPurchaseOrder`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getWorkorderTruckStock(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/truckStock/byWorkOrderId`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getWorkorderAttachment(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrderAttachment/byworkOrderId`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getInvoiceDetail(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/getInvoicedetailslist`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getGeneratedInvoice(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/generatepdf`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  getInvoiceStatus(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/getInvoiceStatus`;
    return this.http.get<any>(url, this.getOptions());
  }

  getPOListByDate(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/getPoListbydate`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  createInvoice(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/workOrder/CreateInvoice`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {},
    };
  }
}
