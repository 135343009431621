import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as TokenActions from './token.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { CustomerService } from '../../backend/customer/customer.service';
import { UserCredential } from '../model/user-credential.model';
import { AuthenticationToken } from '../../backend/customer/model/token.model';
import * as ErrorActions from '../../error/error.actions';
import { ErrorType } from '../../error/model/error-type';
import * as AuthenticatedUserActions from '../authenticated-user/authenticated-user.actions';
import { Router } from '@angular/router';
import {
  HideSpinner,
  ShowSpinner,
} from '../../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';

type showSpinnerTypes = TokenActions.TokenRequest;

const showSpinnerActions = [TokenActions.LOGIN_REQUEST];

type hideSpinnerTypes =
  | TokenActions.TokenReceive
  | TokenActions.TokenDeleteRequest;

const hideSpinnerActions = [
  TokenActions.LOGIN_RECEIVE,
  TokenActions.LOGOUT_REQUEST,
];

@Injectable()
export class TokenEffects {
  constructor(
    private actions$: Actions,
    private customerService: CustomerService,
    private router: Router
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnTokenRequest$ = this.actions$.pipe(
    ofType<TokenActions.TokenRequest>(TokenActions.LOGIN_REQUEST),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((cred: UserCredential) =>
      from(this.customerService.getAuthToken(cred)).pipe(
        mergeMap((token: AuthenticationToken) =>
          token.data.user.password_reset_token === null ||
          token.data.user.password_reset_token === ''
            ? [
                new TokenActions.TokenReceive(token),
                new AuthenticatedUserActions.RequestCurrentUserInfo(),
              ]
            : [new TokenActions.RedirectResetPassword(token)]
        ),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnTokenDeleteRequest$ = this.actions$.pipe(
    ofType<TokenActions.TokenDeleteRequest>(TokenActions.LOGOUT_REQUEST),
    throttleTime(2000),
    map(() => this.customerService.deleteAuthToken()),
    switchMap(() => of(new AuthenticatedUserActions.DeleteCurrentUserInfo()))
  );

  @Effect({ dispatch: false })
  OnRedirectResetPassword$ = this.actions$.pipe(
    ofType<TokenActions.RedirectResetPassword>(
      TokenActions.REDIRECT_RESET_PASSWORD_REQUEST
    ),
    map((action) => {
      this.router.navigateByUrl(
        '/login/reset-password?token=' +
          action.payload.data.user.password_reset_token +
          ''
      );
    })
  );
}
