import * as TimeActions from './time.actions';
import { adapterTime, initTimeState, TimeStateInterface } from './time.state';

export function timeReducer(state = initTimeState, action): TimeStateInterface {
  switch (action.type) {
    case TimeActions.TIME_ENTRY_RECEIVE: {
      const payload = action.payload.data.timeEntries;
      return adapterTime.setAll(payload, state);
    }

    case TimeActions.TIME_ENTRY_ADD_RECEIVE: {
      const payload = action.payload;

      return adapterTime.addOne(payload, {
        ...state,
      });
    }

    case TimeActions.TIME_ENTRY_EDIT_RECEIVE: {
      const payload = action.payload;
      return adapterTime.updateOne({ id: payload.id, changes: payload }, state);
    }

    case TimeActions.TIME_ENTRY_DELETE_RECEIVE: {
      const payload = action.payload;

      return adapterTime.removeOne(payload, {
        ...state,
      });
    }

    case TimeActions.TIME_ENTRY_CONTAINER_DELETE_RECEIVE: {
      const payload = action.payload;

      return adapterTime.removeMany(payload, {
        ...state,
      });
    }

    case TimeActions.TIME_ENTRY_NOTE_RECEIVE: {
      const payload = action.payload;
      return adapterTime.updateOne({ id: payload.id, changes: payload }, state);
    }

    case TimeActions.LOCAL_BY_JOB_RECEIVE: {
      const payload = action.payload;
      // TODO: check if need to update local
      return {
        ...state,
        local: payload,
      };
    }
    default:
      return state;
  }
}
