import { Action } from '@ngrx/store';
import { createActionType } from '../../shared/action/utils';

export const EXPENSE_CATEGORIES_REQUEST = createActionType(
  'EXPENSE_CATEGORIES_REQUEST'
);

export class ExpenseCategoriesRequest implements Action {
  readonly type = EXPENSE_CATEGORIES_REQUEST;
  constructor(public payload: unknown) {}
}
