import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from './store/store.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { BackendModule } from './backend/backend.module';

@NgModule({
  imports: [StoreModule, BackendModule],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
