import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { JobModel } from '../core/backend/job/model/job.model';
import { JobStoreService } from '../core/job/job.service';
import {
  delay,
  filter,
  groupBy,
  mergeMap,
  takeUntil,
  toArray,
} from 'rxjs/operators';
import { WorkorderStoreService } from '../core/workorder/workorder.service';
import { ServiceOrderReceiveListModel } from '../core/backend/service-order/model/service-order-receive-list.model';
import { Constants } from '../constants/constant';
import { EditWorkorderComponent } from './edit-workorder/edit-workorder.component';
import { SettingStoreService } from '../core/setting/setting.service';
import { UserModel } from '../core/backend/user/model/user.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { WorkorderScheduleReceiveListModel } from '../core/backend/service-order/model/workorder-schedule-receive-list.model';
import { ExportService } from '../shared/export/export.service';
import { ServiceOrderService } from '../core/backend/service-order/service-order.service';

@Component({
  selector: 'app-serviceorder',
  templateUrl: './serviceorder.component.html',
  styleUrls: ['./serviceorder.component.scss'],
})
export class ServiceorderComponent implements OnInit {
  displayedColumns: string[] = ['icon', 'jobno', 'name', 'type', 'symbol'];
  jobs$: Observable<JobModel[]>;
  ngUnsubscribe$: Subject<any> = new Subject<any>();
  jobs: JobModel[] = [];
  selectedJob = '';
  serviceOrders$: Observable<ServiceOrderReceiveListModel[]>;
  unscheduledCheck = false;
  ongoingCheck = false;
  completedCheck = false;
  closedCheck = false;
  workOrderSearchText = '';
  CONSTANTS = Constants.WORKORDER;
  isWorkOrderFilter = false;
  workOrderPageIndex = 1;
  workOrderListCompleted = false;
  workorderScheduleListPageIndex = 1;
  workorderScheduleListCompleted = false;
  updatedWorkOrders: any = [];
  editWorkOrderId: number;
  @ViewChild(EditWorkorderComponent)
  editWorkorderComponent: EditWorkorderComponent;
  @ViewChild('closebutton') closebutton;

  workorderSchedule$: Observable<WorkorderScheduleReceiveListModel[]>;
  workorderSchedule: WorkorderScheduleReceiveListModel[] = [];
  workorderScheduleByEmployee: any;

  users$: Observable<UserModel[]>;
  users: UserModel[] = [];

  currentScheduleDivTop: any;
  currentScheduleDivRight: string;
  createScheduleEmployeeId: number;
  isDatePicker = false;
  scheduleUser: any = '';
  time_off_note: string;

  datesSelected: NgbDateStruct[] = [];
  scheduledDates: any[] = [];
  timeOffformSubmited = false;
  listenerFnn: () => void;
  isJobListShow = false;
  @ViewChild('jobSearchTxt') jobSearchTxt;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  jobName = '';
  createWorkorder$: Observable<any>;

  constructor(
    private jobStoreService: JobStoreService,
    private workorderStoreService: WorkorderStoreService,
    private settingStoreService: SettingStoreService,
    private renderer: Renderer2,
    private exportService: ExportService,
    private eRef: ElementRef,
    private serviceOrderService: ServiceOrderService
  ) {}

  ngOnInit() {
    // Api calls
    this.settingStoreService.requestUsers();
    this.getWorkOrders(false);
    this.getFilterdJobList();

    // Get value from store
    this.getStoreValues();
  }

  formatDate(date) {
    return moment(date).format('MMMM DD, YYYY').toString();
  }

  getWorkOrders(isFilter: boolean) {
    const workOrderReqObj = this.getRequestedFilterObject(isFilter);
    this.workorderStoreService.requestGetServiceOrder(workOrderReqObj);
  }

  getStoreValues() {
    this.getServiceOrderFromStore();

    this.users$ = this.settingStoreService.getAllUsers();
    this.users$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((users) => (this.users = users));

    this.jobs$
      .pipe(
        filter((data) => !!data),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((fetchedJobs) => {
        this.jobs = fetchedJobs;
      });
  }

  getFilterdJobList() {
    // API call

    let reqObj = {
      company_number: 1,
      is_work_order: true,
    };
    let jobSearchVal = this.jobSearchTxt?.nativeElement?.value;
    if (jobSearchVal) {
      reqObj['search'] = jobSearchVal;
    }
    this.jobStoreService.requestJobs(reqObj);

    // get value from store
    this.jobs$ = this.jobStoreService.getAllJobs();
  }

  getServiceOrderFromStore() {
    this.serviceOrders$ = this.workorderStoreService.getAllServiceOrder();
    this.serviceOrders$
      .pipe(
        filter((data) => !!data),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((fetchedWorkOrders: any) => {
        if (this.workOrderPageIndex > 1) {
          if (fetchedWorkOrders.length < this.CONSTANTS.PAGE_ITEM) {
            this.workOrderListCompleted = true;
          }
        }
        this.formatAndSetValues(fetchedWorkOrders);
      });
  }

  formatAndSetValues(workOrders) {
    this.updatedWorkOrders = [];
    const filterStatus = this.CONSTANTS.FILTER_STATUS;
    const filterClass = this.CONSTANTS.FILTER_CLASS;
    const colorCode = this.CONSTANTS.COLOR_CODE;
    const colorCodeKeys = Object.keys(colorCode);

    workOrders.forEach((workOrder) => {
      const updatedObj = {
        // adding filter class
        ...(workOrder.status == filterStatus.UNSCHEDULED && {
          ...workOrder,
          filterclass: filterClass.UNSCHEDULED,
        }),
        ...(workOrder.status == filterStatus.ONGOING && {
          ...workOrder,
          filterclass: filterClass.ONGOING,
        }),
        ...(workOrder.status == filterStatus.COMPLETED && {
          ...workOrder,
          filterclass: filterClass.COMPLETED,
        }),
        ...(workOrder.status == filterStatus.CLOSED && {
          ...workOrder,
          filterclass: filterClass.CLOSED,
        }),

        // adding colorcode class

        ...(workOrder.work_orderType_color_code == colorCodeKeys[0] && {
          ...workOrder,
          colorclass: colorCode['#c0d8fc'],
        }),

        ...(workOrder.work_orderType_color_code == colorCodeKeys[1] && {
          ...workOrder,
          colorclass: colorCode['#bcf2d6'],
        }),

        ...(workOrder.work_orderType_color_code == colorCodeKeys[2] && {
          ...workOrder,
          colorclass: colorCode['#ffceb2'],
        }),

        ...(workOrder.work_orderType_color_code == colorCodeKeys[3] && {
          ...workOrder,
          colorclass: colorCode['#ff9b9b'],
        }),
      };
      this.updatedWorkOrders.push(updatedObj);
    });
  }

  getRequestedFilterObject(isFilter) {
    let filterArray = [];
    if (this.unscheduledCheck) {
      filterArray.push(this.CONSTANTS.FILTER_STATUS.UNSCHEDULED);
    }
    if (this.ongoingCheck) {
      filterArray.push(this.CONSTANTS.FILTER_STATUS.ONGOING);
    }
    if (this.completedCheck) {
      filterArray.push(this.CONSTANTS.FILTER_STATUS.COMPLETED);
    }
    if (this.closedCheck) {
      filterArray.push(this.CONSTANTS.FILTER_STATUS.CLOSED);
    }
    const postObj = {
      searchPage: this.isWorkOrderFilter
        ? this.CONSTANTS.PAGE_INDEX
        : this.workOrderPageIndex,
      pageItems: this.isWorkOrderFilter
        ? this.CONSTANTS.PAGE_ITEM * this.workOrderPageIndex
        : this.CONSTANTS.PAGE_ITEM,
      text: this.workOrderSearchText,
      status: filterArray,
      isFilterApplied: isFilter,
    };
    localStorage.setItem('currentWOFilterApplied', postObj.status.toString());
    return postObj;
  }

  onJobNameNoEnter(evt) {
    this.isWorkOrderFilter = true;
    if (evt.target.value.length > 2 || evt.target.value.length == 0) {
      // API call
      this.getWorkOrders(true);
    }
  }

  workOrderFilterChange(event, filterStatus) {
    this.isWorkOrderFilter = true;
    switch (filterStatus) {
      case 0:
        this.unscheduledCheck = event.currentTarget.checked;
        break;
      case 1:
        this.ongoingCheck = event.currentTarget.checked;
        break;
      case 2:
        this.completedCheck = event.currentTarget.checked;
        break;
      case 3:
        this.closedCheck = event.currentTarget.checked;
        break;
      default:
        break;
    }
    // API call
    this.getWorkOrders(true);
  }

  onScrollWorkOrderTable(evt) {
    if (
      evt.target.offsetHeight + evt.target.scrollTop >=
      evt.target.scrollHeight
    ) {
      if (!this.workOrderListCompleted) {
        this.workOrderPageIndex++;
        // API call
        this.getWorkOrders(false);
      }
    }
  }

  onEditWorkOrder(workorder, scheduledBoard = '') {
    // calling child component function
    this.editWorkorderComponent.getSelectedWorkorder(workorder, scheduledBoard);

    this.editWorkOrderId = workorder.id;
  }

  onJobChange(job) {
    this.isJobListShow = false; // Hide the site list
    this.jobName = job.job_number + ' ' + job.name;
    this.selectedJob = job.ce_job_id;
    //clear the search text
    this.jobSearchTxt.nativeElement.value = '';
  }

  createWorkOrder() {
    // API call
    const postData = {
      ce_job_id: this.selectedJob,
    };
    this.workorderStoreService.requestCreateServiceOrder(postData);
    this.getFilterdJobList();
    setTimeout(() => {
      this.getCreatedWorkorderFromStore();
    }, 1000);
  }

  getCreatedWorkorderFromStore() {
    this.createWorkorder$ = this.workorderStoreService.getCreatedWorkorder();

    this.createWorkorder$
      .pipe(
        filter((data) => !!data),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((workorder) => {
        if (workorder?.id) {
          console.log('workorderId', workorder.id);
          const postData = {
            work_order_id: workorder.id,
          };
          this.serviceOrderService
            .getWorkOrderById(postData)
            .then((res: any) => {
              let updatedDetails = this.exportService.addFilterClass(res.data);
              // calling child component function
              this.editWorkorderComponent.getSelectedWorkorder(
                updatedDetails,
                ''
              );
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  }

  addEmployeeScheduleEntry() {
    let employeeIds = [];
    employeeIds.push(this.scheduleUser);
    const postObj = {
      searchPage: this.CONSTANTS.SCHEDULELIST.PAGE_INDEX,
      pageItems: this.CONSTANTS.SCHEDULELIST.PAGE_ITEM,
      ce_employee_ids: employeeIds,
      is_time_off: true,
    };
    this.workorderStoreService.requestGetWorkorderSchedule(postObj);

    this.workorderSchedule$ = this.workorderStoreService.getWorkorderSchedule();

    this.workorderSchedule$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((workorderSchedule) => {
        this.groupWorkorderScheduleByEmployeeId(workorderSchedule);
        if (this.workorderScheduleListPageIndex > 1) {
          if (
            workorderSchedule.length < this.CONSTANTS.SCHEDULELIST.PAGE_ITEM
          ) {
            this.workorderScheduleListCompleted = true;
          }
        }
      });
  }

  groupWorkorderScheduleByEmployeeId(
    scheduleEntries: WorkorderScheduleReceiveListModel[]
  ) {
    this.workorderScheduleByEmployee = [];
    from(scheduleEntries)
      .pipe(
        groupBy((person) => person.ce_employee_id.toString()),
        // return each item in group as array
        mergeMap((group) => group.pipe(toArray()))
      )
      .subscribe((WorkorderScheduleByEmployeeId: any) => {
        this.addScheduleEntryGroup(
          WorkorderScheduleByEmployeeId[0].ce_employee_id,
          `${WorkorderScheduleByEmployeeId[0].employee_last_name} ${WorkorderScheduleByEmployeeId[0].employee_first_name}`,
          WorkorderScheduleByEmployeeId[0].work_order_id,
          WorkorderScheduleByEmployeeId
        );
      });
  }

  addScheduleEntryGroup(
    employeeId,
    employeeName,
    workOrderId,
    scheduledEntries
  ) {
    this.workorderScheduleByEmployee = {
      employeeId,
      employeeName,
      workOrderId,
      scheduledEntries,
    };
  }

  onAddToSchedule() {
    var offset = document
      .getElementById('addToScheuleDiv')
      .getBoundingClientRect();
    const pixel = 30;
    this.currentScheduleDivTop = offset.top + pixel + 'px';
    this.currentScheduleDivRight = offset.left + 'px';

    let spanId = 'addToScheuleSpn';

    if (!this.listenerFnn) {
      this.listenerFnn = this.renderer.listen('window', 'click', (e: Event) => {
        if (this.isDatePicker == true && e.target['id'] != spanId) {
          if (
            e.target['classList'][0] == 'custom-day' ||
            e.target['classList'][0] == 'ngb-dp-navigation-chevron' ||
            e.target['classList'][0] == 'custom-select' ||
            e.target['classList'][0] == 'btn' ||
            e.target['classList'][0] == 'ngb-dp-header' ||
            e.target['classList'][0] == 'ngb-dp-navigation-select' ||
            e.target['classList'][0] == 'ng-star-inserted' ||
            e.target['classList'][0] == 'ngb-dp-arrow' ||
            e.target['classList'][0] == 'ngb-dp-weekday'
          ) {
            this.isDatePicker = true;
          } else {
            this.isDatePicker = false;
          }
        } else {
          if (e.target['id'] == spanId) {
            this.isDatePicker = !this.isDatePicker;
          }
        }
      });
    }
  }

  change(value: NgbDateStruct[]) {
    this.datesSelected = value;
    this.scheduledDates = [];
    this.datesSelected.forEach((d) => {
      let date = d.year + '-' + d.month + '-' + d.day;
      this.scheduledDates.push(moment(date).format('YYYY-MM-DD').toString());
    });
  }

  createWorkorderSchedule() {
    this.timeOffformSubmited = true;
    // close multi select date picker if opened
    if (this.timeOffformSubmited && this.scheduleUser != '') {
      if (this.isDatePicker) {
        this.isDatePicker = !this.isDatePicker;
      }

      // API call
      const postData = {
        ce_employee_id: this.scheduleUser,
        date_scheduled: this.scheduledDates,
        is_time_off: true,
        time_off_note: this.time_off_note,
      };

      this.workorderStoreService.requestCreateWorkorderSchedule(postData);
      // remove selected dates
      this.timeOffformSubmited = false;
      this.scheduledDates = [];
      this.datesSelected = [];
      this.scheduleUser = '';
      this.time_off_note = '';

      this.closebutton.nativeElement.click();
    }
  }

  onRemoveSchedule(scheduledEntry, removedDate?) {
    const postData = {
      ce_employee_id: scheduledEntry.ce_employee_id,
      work_order_id: scheduledEntry.work_order_id,
    };
    if (removedDate) {
      let dateArray = [];
      let formatedDate = this.exportService.formatToYearMonthDay(removedDate);
      dateArray.push(formatedDate);
      postData['date_scheduled'] = dateArray;
    }
    this.workorderStoreService.requestWorkorderScheduleDelete(postData);
  }

  onCreateWorkorder() {
    this.selectedJob = '';
    this.jobName = '';
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      // "clicked inside";
      if (event.target.name == 'jobName') {
        this.isJobListShow = true; // Show the site list
        const element = this.jobSearchTxt.nativeElement;
        setTimeout(() => element.focus(), 0);
      } else {
        this.isJobListShow = false; // Hide the site list
        if (this.myScrollContainer != undefined) {
          this.myScrollContainer.nativeElement.scrollTop =
            this.myScrollContainer.nativeElement.scrollTop -
            this.myScrollContainer.nativeElement.scrollHeight;
        }
      }
    }
  }

  onJobSearch(evt) {
    if (evt.target.value.length > 2 || evt.target.value.length == 0) {
      this.getFilterdJobList();
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('currentWOFilterApplied');
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
