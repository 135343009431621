import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as UserActions from './user.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { UserService } from '../../backend/user/user.service';
import { UserModel } from '../../backend/user/model/user.model';
import { AlertMessageCommonService } from '../../../shared/alert-message-common/alert-message-common.service';
import {
  HideSpinner,
  ShowSpinner,
} from '../../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../../error/error.actions';
import { ErrorType } from '../../error/model/error-type';

type showSpinnerTypes =
  | UserActions.UsersRequest
  | UserActions.UserRequest
  | UserActions.UserUpdateRequest
  | UserActions.UserProfileUpdateRequest;

const showSpinnerActions = [
  UserActions.USERS_REQUEST,
  UserActions.USER_REQUEST,
  UserActions.USER_UPDATE_REQUEST,
  UserActions.USER_PROFILE_UPDATE_REQUEST,
];

type hideSpinnerTypes =
  | UserActions.UsersReceive
  | UserActions.UserReceive
  | UserActions.UserUpdateReceive
  | UserActions.UserProfileUpdateReceive;

const hideSpinnerActions = [
  UserActions.USERS_RECEIVE,
  UserActions.USER_RECEIVE,
  UserActions.USER_UPDATE_RECEIVE,
  UserActions.USER_PROFILE_UPDATE_RECEIVE,
];
@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnUsersRequest$ = this.actions$.pipe(
    ofType<UserActions.UsersRequest>(UserActions.USERS_REQUEST),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((payload) =>
      from(this.userService.getAllUsers(payload.getAllOverride)).pipe(
        mergeMap((users: UserModel[]) => [new UserActions.UsersReceive(users)]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnUserRequest$ = this.actions$.pipe(
    ofType<UserActions.UserRequest>(UserActions.USER_REQUEST),
    throttleTime(2000),
    switchMap((data) =>
      from(this.userService.getUsersById(data.payload.id)).pipe(
        mergeMap((user: any) => [new UserActions.UserReceive(user)]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnUserUpdateRequest$ = this.actions$.pipe(
    ofType<UserActions.UserUpdateRequest>(UserActions.USER_UPDATE_REQUEST),
    throttleTime(2000),
    switchMap((data) =>
      from(this.userService.getUserUpdateById(data.payload)).pipe(
        mergeMap((user: any) => [new UserActions.UserUpdateReceive(user)]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnUserProfileUpdateRequest$ = this.actions$.pipe(
    ofType<UserActions.UserProfileUpdateRequest>(
      UserActions.USER_PROFILE_UPDATE_REQUEST
    ),
    throttleTime(2000),
    switchMap((data) =>
      from(this.userService.getUserProfileUpdate(data.payload)).pipe(
        mergeMap((user: any) => [
          new UserActions.UserProfileUpdateReceive(user),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  UserProfileUpdateReceiveAction$ = this.actions$.pipe(
    ofType<UserActions.UserProfileUpdateReceive>(
      UserActions.USER_PROFILE_UPDATE_RECEIVE
    ),
    map((action) => {
      if (action.payload.data === undefined) {
        this.alertMessageCommonService.error('Error', action.payload.message);
      } else {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.message
        );
      }
    })
  );
}
