import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as PayperiodLockoutActions from './payperiod-lockout.actions';
import { CDSAppState } from '../store/store.state';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import * as PayperiodLockoutSelectors from './payperiod-lockout.selectors';

@Injectable()
export class PayperiodLockoutService {
  constructor(private store: Store<CDSAppState>, private _actions$: Actions) {}

  public getPayPeriodLockEntry(): Observable<any[]> {
    return this.store.pipe(
      select((state) => PayperiodLockoutSelectors.getPayPeriodLockEntry(state))
    );
  }

  public getPayPeriodLockStatus(): Observable<boolean> {
    return this.store.pipe(
      select((state) => PayperiodLockoutSelectors.getPayperiodLockout(state))
    );
  }

  public getPayPeriodLockStatusOnDateChange(): Observable<boolean> {
    return this.store.pipe(
      select((state) =>
        PayperiodLockoutSelectors.getPayPeriodLockStatusOnDateChange(state)
      )
    );
  }

  public requestUpsertPayPeriodEntry(postEntry: any, isShowMsg: boolean) {
    this.store.dispatch(
      new PayperiodLockoutActions.PayPeriodLockoutUpsertRequest({
        data: postEntry,
        isShowMsg: isShowMsg,
      })
    );
  }

  public requestPayPeriodLockStatus(postData: any, isFromDate: boolean) {
    this.store.dispatch(
      new PayperiodLockoutActions.PayPeriodLockoutStatusRequest({
        data: postData,
        isFromDate: isFromDate,
      })
    );
  }
}
