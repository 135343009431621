import * as LaborWorkorderActions from './labor-workorder.actions';
import {
  initLaborWorkorderState,
  LaborWorkorderStateInterface,
  adapterLaborWorkorder,
} from './labor-workorder.state';

export function LaborWorkorderReducer(
  state = initLaborWorkorderState,
  action
): LaborWorkorderStateInterface {
  switch (action.type) {
    case LaborWorkorderActions.LABOR_WORKORDER_GET_RECEIVE: {
      const payload = action.payload.workorderList.data;
      const isScrolled = action.payload.isScrolled;
      if (isScrolled) {
        return adapterLaborWorkorder.addMany(payload, {
          ...state,
        });
      } else {
        return adapterLaborWorkorder.setAll(payload, state);
      }
    }
    default:
      return state;
  }
}
