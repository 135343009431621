import { Action, createAction, props } from '@ngrx/store';
import { RoleModel } from '../../backend/role/model/role.model';
import { createActionType } from '../../../shared/action/utils';

export const ROLES_REQUEST = createActionType('[Role1] Request');
export const ROLES_RECEIVE = createActionType('[Role1] Receive');
export class RolesRequest implements Action {
  readonly type = ROLES_REQUEST;
  constructor() {}
}
export class RolesReceive implements Action {
  readonly type = ROLES_RECEIVE;
  constructor(public payload: RoleModel[]) {}
}
