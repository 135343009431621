import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from '../../../shared/action/utils';
import { WorkOrderScheduleCreateRequestModel } from '../../backend/service-order/model/workorder-schedule-create-request.model';
import { WorkorderScheduleReceiveListModel } from '../../backend/service-order/model/workorder-schedule-receive-list.model';
import { WorkOrderScheduleRequestListModel } from '../../backend/service-order/model/workorder-schedule-request-list.model';
import { WorkOrderScheduleDeleteRequestModel } from '../../backend/service-order/model/workorder-schedule-delete-request.model';

export const WORKORDER_SCHEDULE_CREATE_REQUEST = createActionType(
  '[WorkorderSchedule] Create Request'
);

export const WORKORDER_SCHEDULE_CREATE_RECEIVE = createActionType(
  '[WorkorderSchedule] Create Receive'
);

export const WORKORDER_SCHEDULE_GET_REQUEST = createActionType(
  '[WorkorderSchedule] Get Request'
);

export const WORKORDER_SCHEDULE_GET_RECEIVE = createActionType(
  '[WorkorderSchedule] Get Receive'
);

export const WORKORDER_SCHEDULE_DELETE_REQUEST = createActionType(
  '[WorkorderSchedule] Delete Request'
);

export const WORKORDER_SCHEDULE_DELETE_RECEIVE = createActionType(
  '[WorkorderSchedule] Delete Receive'
);

export class WorkorderScheduleCreateRequest implements Action {
  readonly type = WORKORDER_SCHEDULE_CREATE_REQUEST;
  constructor(public payload: WorkOrderScheduleCreateRequestModel) {}
}
export class WorkorderScheduleCreateReceive implements Action {
  readonly type = WORKORDER_SCHEDULE_CREATE_RECEIVE;
  constructor(public payload: any) {}
}

export class WorkorderScheduleGetRequest implements Action {
  readonly type = WORKORDER_SCHEDULE_GET_REQUEST;
  constructor(public payload: WorkOrderScheduleRequestListModel) {}
}
export class WorkorderScheduleGetReceive implements Action {
  readonly type = WORKORDER_SCHEDULE_GET_RECEIVE;
  constructor(
    public payload: {
      scheduleList: WorkorderScheduleReceiveListModel;
      isScrolled: boolean;
    }
  ) {}
}
export class WorkorderScheduleDeleteRequest implements Action {
  readonly type = WORKORDER_SCHEDULE_DELETE_REQUEST;
  constructor(public payload: WorkOrderScheduleDeleteRequestModel) {}
}
export class WorkorderScheduleDeleteReceive implements Action {
  readonly type = WORKORDER_SCHEDULE_DELETE_RECEIVE;
  constructor(public payload: { deleteRes: any }) {}
}
