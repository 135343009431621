import { createSelector } from '@ngrx/store';
import { getWorkorderScheduleBydayState } from '../workorder.selectors';
import {
  WorkorderScheduleBydayStateInterface,
  selectAll,
} from '../workorder-schedule-byday/workorder-schedule-byday.state';

export const getWorkorderScheduleByday = createSelector(
  getWorkorderScheduleBydayState,
  (state: WorkorderScheduleBydayStateInterface) => selectAll(state)
);
