import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CDSAppState } from '../store/store.state';
import { BehaviorSubject, Observable } from 'rxjs';
import * as OverlayActions from './overlay.actions';
import * as OverlaySelectors from './overlay.selectors';

@Injectable()
export class OverlayStoreService {
  constructor(private store: Store<CDSAppState>) {}

  public getOverlayStatus(): Observable<boolean> {
    return this.store.pipe(
      select((state) => OverlaySelectors.getOverlayStatus(state))
    );
  }

  public triggerOverlayOn() {
    this.store.dispatch(OverlayActions.OverlayOnAction());
  }

  public triggerOverlayOff() {
    this.store.dispatch(OverlayActions.OverlayOffAction());
  }
}
