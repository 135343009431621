import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { PermissionModel } from './model/permission.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class PermissionBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  fetchAllPermissions(): Observable<HttpResponse<PermissionModel[]>> {
    const url = `${this.API_ROOT}/getPermissions`;
    return this.http.get<PermissionModel[]>(url, this.getOptions());
  }

  savePermission(postData): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/roles/add`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  fetchPermissionsByRoleId(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/roles/` + id;
    return this.http.get<any>(url, this.getOptions());
  }
  updatePermission(postData, id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/roles/update/` + id;
    return this.http.put<any>(url, postData, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${new Date().getTimezoneOffset()}`,
      },
    };
  }
}
