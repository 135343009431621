import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { BillingComponent } from './billing/billing.component';
import { LoginGuard } from './login-guard.service';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'my-account',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./my-account/my-account.module').then((m) => m.MyAccountModule),
  },
  {
    path: 'refresh-list',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./refresh-list/refresh-list.module').then(
        (m) => m.RefreshListModule
      ),
  },
  {
    path: 'time',
    canActivate: [AuthGuard],
    loadChildren: () => import('./time/time.module').then((m) => m.TimeModule),
    data: { title: 'Time Entry' },
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./setting/setting.module').then((m) => m.SettingsModule),
    data: { title: 'Settings' },
  },
  {
    path: 'billing',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./billing/billing.module').then((m) => m.BillingModule),
  },
  {
    path: 'jobs',
    canActivate: [AuthGuard],
    loadChildren: () => import('./jobs/jobs.module').then((m) => m.JobsModule),
    data: { title: 'Jobs' },
  },
  {
    path: 'service-orders',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./serviceorder/serviceorder.module').then(
        (m) => m.ServiceorderModule
      ),
  },
  {
    path: 'instructions',
    loadChildren: () =>
      import('./instructions/instructions.module').then(
        (m) => m.InstructionsModule
      ),
  },
  {
    path: 'manpower',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./manpower/manpower.module').then((m) => m.ManpowerModule),
  },
  {
    path: 'well-logs',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./well-logs/well-logs.module').then((m) => m.WellLogsModule),
  },
  {
    path: 'receipt-capture',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./receipt-capture/receipt-capture.module').then(
        (m) => m.ReceiptCaptureModule
      ),
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
