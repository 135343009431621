import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RoleModel } from '../../backend/role/model/role.model';

export interface RoleStateInterface extends EntityState<RoleModel> {
  // additional entity state properties
  selectedRoleId: number | null;
  newRole: RoleModel;
}

export const adapterRole: EntityAdapter<RoleModel> = createEntityAdapter<RoleModel>();

export const initRoleState: RoleStateInterface = adapterRole.getInitialState({
  selectedRoleId: null,
  newRole: null,
});

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterRole.getSelectors();
