import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { JobModel } from './model/job.model';
import { environment } from '../../../../environments/environment';
import { TimeModel } from '../time/model/time.model';
import type { FavoriteJobParams } from './model/favoriteJobParams';

@Injectable()
export class JobBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  fetchAllJobs(requestObj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/jobs`;
    return this.http.post<JobModel[]>(url, requestObj, this.getOptions());
  }

  fetchToggleJobFavorite(
    job: JobModel,
    employeeId: number
  ): Observable<HttpResponse<any>> {
    const data = {
      jobId: job.id,
      employee_id: employeeId,
      is_job_favorite: job.is_job_favorite,
    };
    const url = `${this.API_ROOT}/jobs/favoriteJob/` + data.jobId;
    return this.http.post<any>(url, data, this.getOptions());
  }

  getCustomers(requestObj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/jobs/customers`;
    return this.http.post<JobModel[]>(url, requestObj, this.getOptions());
  }

  getCities(requestObj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/jobs/cities`;
    return this.http.post<JobModel[]>(url, requestObj, this.getOptions());
  }

  getStates(requestObj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/jobs/states`;
    return this.http.post<JobModel[]>(url, requestObj, this.getOptions());
  }

  getJobDepartments(requestObj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/jobs/departments`;
    return this.http.post<JobModel[]>(url, requestObj, this.getOptions());
  }

  getJobDetailsById(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/jobs` + '/' + id;
    return this.http.get<JobModel[]>(url, this.getOptions());
  }

  getFilterPacks(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/filterpacks`;
    return this.http.get<JobModel[]>(url, this.getOptions());
  }

  updateJobDetails(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/jobs/update` + '/' + obj.id;
    return this.http.put<any>(url, obj, this.getOptions());
  }

  getEmployeeDetailsByJob(payload): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/employee/by-job-assignments`;
    return this.http.post<JobModel[]>(url, payload, this.getOptions());
  }

  assignEmployeeToJob(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/employee/assign-job`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  getEmployeeDetailsByEmployeeId(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/employee/by-job-assignments/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  deleteEmployeeFromJob(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/employee/assign-job/${id}`;
    return this.http.delete<any>(url, this.getOptions());
  }

  saveNotes(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/notes/create`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  getAllNotes(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/notes`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  getProjectManagers(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/employee/project-managers`;
    return this.http.post<JobModel[]>(url, obj, this.getOptions());
  }

  getJobNotesByNoteId(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/notes/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  deleteJobNote(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/notes/${id}`;
    return this.http.delete<any>(url, this.getOptions());
  }

  updateJobNotes(obj, id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/notes` + '/' + id;
    return this.http.put<any>(url, obj, this.getOptions());
  }

  getDailyProductions(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/daily-production`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  getDailyProductionsById(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/daily-production/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  addDailyProductions(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/daily-production/create`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  updateDailyProductions(obj, id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/daily-production` + '/' + id;
    return this.http.put<any>(url, obj, this.getOptions());
  }

  getSiteContacts(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/site-contacts`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  getSiteContactById(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/site-contacts/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  addSiteContact(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/site-contacts/create`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  deleteSiteContact(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/site-contacts/${id}`;
    return this.http.delete<any>(url, this.getOptions());
  }

  updateSiteContact(obj, id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/site-contacts` + '/' + id;
    return this.http.put<any>(url, obj, this.getOptions());
  }

  getAllWellLogs(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/well-logs`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  getWellLogsDropdowns(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/well-logs/dropdowns`;
    return this.http.get<any>(url, this.getOptions());
  }

  copyWellLog(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/well-logs/copy`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  getWellLogDetailsById(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/well-logs/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  getWellLogSoilDropdown(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/well-logs/soil-dropdowns`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  createWellLog(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/well-logs/create`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  updateWellLog(obj, id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/well-logs` + '/' + id;
    return this.http.put<any>(url, obj, this.getOptions());
  }

  uploadFile(formdata): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/files/upload`;
    return this.http.post<any>(url, formdata);
  }

  getFiles(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/files`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  getFilesById(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/files/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  deleteFile(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/files/${id}`;
    return this.http.delete<any>(url, this.getOptions());
  }
  updateFileUpload(obj, id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/files/${id}`;
    return this.http.put<any>(url, obj, this.getOptions());
  }

  fetchAllEquipmentTimeEntry(postData): Observable<any> {
    const url = `${this.API_ROOT}/job-equipments`;
    return this.http.post<TimeModel[]>(url, postData, this.getOptions());
  }

  getAllEquipments(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/equipments`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  favoriteSingleEquipment(
    obj: FavoriteJobParams
  ): Observable<HttpResponse<any>> {
    if (!obj || !obj?.equipment_id) return;

    const { equipment_id } = obj;

    const url = new URL(
      `${this.API_ROOT}/equipments/favoriteEquipment/${equipment_id}`
    );

    return this.http.post<any>(url.toString(), obj, this.getOptions());
  }

  assignEquipmentToJob(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/job-equipments/create`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  addEquipmentTimeEntry(obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/job-equipments/add-time`;
    return this.http.post<any>(url, obj, this.getOptions());
  }

  getJobEquipmentTimeByEqupmentId(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/job-equipments/get-time/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  updateJobEquipmentTime(id, obj): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/job-equipments/update-time/${id}`;
    return this.http.put<any>(url, obj, this.getOptions());
  }

  deleteJobEquipmentTime(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/job-equipments/delete-time/${id}`;
    return this.http.delete<any>(url, this.getOptions());
  }

  updateJobBudget(obj, id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/jobs/update/${id}`;
    return this.http.put<any>(url, obj, this.getOptions());
  }

  getJobSummary(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/jobs/job-summary/${id}`;
    return this.http.get<any>(url, this.getOptions());
  }

  deleteWellLog(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/well-logs/${id}`;
    return this.http.delete<any>(url, this.getOptions());
  }

  getEmployeeFavoriteJobs(
    employeeId: number,
    request = {}
  ): Observable<HttpResponse<any>> {
    const url = new URL(`${this.API_ROOT}/jobs/favJob/${employeeId}`);
    return this.http.post<any>(url.toString(), request, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${new Date().getTimezoneOffset()}`,
      },
    };
  }
}
