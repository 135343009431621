import * as TotalTimeActions from './totalTime.actions';
import {
  adapterTotalTime,
  initTotalTimeState,
  TotalTimeStateInterface,
} from './totalTime.state';

export function TotalTimeReducer(
  state = initTotalTimeState,
  action
): TotalTimeStateInterface {
  switch (action.type) {
    case TotalTimeActions.TOTAL_TIME_RECEIVE: {
      const payload = action.payload.data;

      return adapterTotalTime.addMany(payload, {
        ...state,
      });
    }

    case TotalTimeActions.TOTAL_TIME_ADD_RECEIVE: {
      const payload = action.payload;

      return adapterTotalTime.addOne(payload, {
        ...state,
      });
    }

    default:
      return state;
  }
}
