import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CDSAppState } from '../store/store.state';
import { Observable } from 'rxjs';
import { ExpenseCategoryModel } from '../backend/expense-category/model/expense-category.model';
import * as ExpenseCategorySelectors from './expense-cateogry.selectors';
import * as ExpenseCategoryActions from './expense-category.actions';

@Injectable()
export class ExpenseCategoryStoreService {
  constructor(private store: Store<CDSAppState>) {}

  public getAllCategories(): Observable<ExpenseCategoryModel[]> {
    return this.store.pipe(
      select((state) => ExpenseCategorySelectors.getAllCategories(state))
    );
  }

  public requestExpenseCategories(requestObj: unknown) {
    this.store.dispatch(
      new ExpenseCategoryActions.ExpenseCategoriesRequest(requestObj)
    );
  }
}
