import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { BrowserStorageBackend } from '../browser-storage/browser-storage.backend';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private browserStorageBackend: BrowserStorageBackend) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const localStorageToken = this.browserStorageBackend.getAuthTokenFromLocalStorage();
    const sessionStorageToken = this.browserStorageBackend.getAuthTokenFromSessionStorage();

    let idToken;

    if (!!localStorageToken) {
      idToken = localStorageToken;
    }

    if (!!sessionStorageToken) {
      idToken = sessionStorageToken;
    }

    if (idToken) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + idToken),
      });

      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}
