import { Action, createAction, props } from '@ngrx/store';
import { UserModel } from '../../backend/user/model/user.model';
import { UserEmployeeModel } from '../../backend/user/model/userUpdate.model';
import { createActionType } from '../../../shared/action/utils';

export const USERS_REQUEST = createActionType('[User1] Request');
export const USERS_RECEIVE = createActionType('[User1] Receive');

export const USER_REQUEST = createActionType('[User By Id1] Request');
export const USER_RECEIVE = createActionType('[User By Id1] Receive');

export const USER_UPDATE_REQUEST = createActionType('[User1] Update Request');
export const USER_UPDATE_RECEIVE = createActionType('[User1] Update Receive');

export const USER_PROFILE_UPDATE_REQUEST = createActionType(
  '[User Profile1] Update Request'
);
export const USER_PROFILE_UPDATE_RECEIVE = createActionType(
  '[User Profile1] Update Receive'
);
export class UsersRequest implements Action {
  readonly type = USERS_REQUEST;
  constructor(public payload: { getAllOverride: boolean }) {}
}
export class UsersReceive implements Action {
  readonly type = USERS_RECEIVE;
  constructor(public payload: UserModel[]) {}
}
export class UserRequest implements Action {
  readonly type = USER_REQUEST;
  constructor(public payload: { id: number }) {}
}
export class UserReceive implements Action {
  readonly type = USER_RECEIVE;
  constructor(public payload: UserModel) {}
}

export class UserUpdateRequest implements Action {
  readonly type = USER_UPDATE_REQUEST;
  constructor(public payload: UserEmployeeModel) {}
}
export class UserUpdateReceive implements Action {
  readonly type = USER_UPDATE_RECEIVE;
  constructor(public payload: any) {}
}

export class UserProfileUpdateRequest implements Action {
  readonly type = USER_PROFILE_UPDATE_REQUEST;
  constructor(public payload: FormData) {}
}
export class UserProfileUpdateReceive implements Action {
  readonly type = USER_PROFILE_UPDATE_RECEIVE;
  constructor(public payload: any) {}
}
