import { Action, createAction, props } from '@ngrx/store';
import { PermissionModel } from '../../backend/permission/model/permission.model';
import { createActionType } from '../../../shared/action/utils';

export const PERMISSIONS_REQUEST = createActionType('[Permission1] Request');
export const PERMISSIONS_RECEIVE = createActionType('[Permission1] Receive');
export const PERMISSION_CREATE_REQUEST = createActionType(
  '[Permission1] Create Request'
);
export const PERMISSION_CREATE_RECEIVE = createActionType(
  '[Permission1] Create Receive'
);

export const PERMISSION_UPDATE_REQUEST = createActionType(
  '[Permission1] Update Request'
);
export const PERMISSION_UPDATE_RECEIVE = createActionType(
  '[Permission1] Update Receive'
);
export class PermissionsRequest implements Action {
  readonly type = PERMISSIONS_REQUEST;
  constructor() {}
}
export class PermissionsReceive implements Action {
  readonly type = PERMISSIONS_RECEIVE;
  constructor(public payload: PermissionModel[]) {}
}
export class PermissionCreateRequest implements Action {
  readonly type = PERMISSION_CREATE_REQUEST;
  constructor(public payload: any) {}
}

export class PermissionCreateReceive implements Action {
  readonly type = PERMISSION_CREATE_RECEIVE;
  constructor(public payload: any) {}
}

export class PermissionUpdateRequest implements Action {
  readonly type = PERMISSION_UPDATE_REQUEST;
  constructor(public payload: { postData: any; roleId: string }) {}
}
export class PermissionUpdateReceive implements Action {
  readonly type = PERMISSION_UPDATE_RECEIVE;
  constructor(public payload: any) {}
}
