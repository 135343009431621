import { createSelector } from '@ngrx/store';
import { getTruckStockState } from '../workorder.selectors';
import {
  TruckStockStateInterface,
  selectAll,
} from '../truck-stock/truck-stock.state';

export const getTruckStock = createSelector(
  getTruckStockState,
  (state: TruckStockStateInterface) => selectAll(state)
);
