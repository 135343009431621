import * as WorkorderScheduleByEmployeeActions from './workorder-schedule-byEmployee.actions';
import {
  initWorkorderScheduleByEmployeeState,
  WorkorderScheduleByEmployeeStateInterface,
  adapterWorkorderScheduleByday,
} from './workorder-schedule-byEmployee.state';

export function WorkorderScheduleByEmployeeReducer(
  state = initWorkorderScheduleByEmployeeState,
  action
): WorkorderScheduleByEmployeeStateInterface {
  switch (action.type) {
    case WorkorderScheduleByEmployeeActions.WORKORDER_SCHEDULE_BYEMPLOYEE_GET_RECEIVE: {
      const payload = action.payload.scheduleList.data;
      return adapterWorkorderScheduleByday.setAll(payload, state);
    }
    default:
      return state;
  }
}
