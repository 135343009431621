import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { map } from 'rxjs/operators';
import { UserBackend } from './user.backend';
import { UserModel } from './model/user.model';
import { UserEmployeeModel } from './model/userUpdate.model';

@Injectable()
export class UserService extends BaseService {
  constructor(private userBackend: UserBackend) {
    super();
  }

  getAllUsers(
    getAllUsersOverride: boolean
  ): Promise<UserModel[] | ErrorMessage> {
    return this.userBackend
      .fetchAllUsers(getAllUsersOverride)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getUsersById(id): Promise<any | ErrorMessage> {
    return this.userBackend
      .fetchUsersById(id)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getUserUpdateById(user: UserEmployeeModel): Promise<any | ErrorMessage> {
    return this.userBackend
      .fetchUserUpdateById(user)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getUserProfileUpdate(user: FormData): Promise<any | ErrorMessage> {
    return new Promise<any>((resolve) => setTimeout(resolve, 2000)).then(() =>
      this.userBackend.fetchUserProfileUpdate(user)
    );
  }
}
