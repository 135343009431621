import * as WellLogsActions from './well-logs.actions';
import {
  WellLogsStateInterface,
  adapterJob,
  initJobState,
} from './well-logs.state';

export function WellLogsReducer(
  state = initJobState,
  action
): WellLogsStateInterface {
  switch (action.type) {
    case WellLogsActions.WELL_LOGS__RECEIVE: {
      const payload = action.payload;
      return adapterJob.setAll(payload, state);
    }

    default:
      return state;
  }
}
