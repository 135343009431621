import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class SettingGeneralComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
