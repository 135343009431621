import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { catchError, map, tap } from 'rxjs/operators';
import { PayperiodLockoutBackend } from './payperiod-lockout.backend';
import { PayperiodLockoutModel } from './model/payperiod-lockout.model';

@Injectable()
export class PayperiodLockoutService extends BaseService {
  constructor(private payPeriodLayoutBackend: PayperiodLockoutBackend) {
    super();
  }
  upsertPayPeriodEntry(updateData: any): Promise<any | ErrorMessage> {
    return this.payPeriodLayoutBackend
      .fetchUpsertPayPeriodLockoutEntry(updateData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getPayPeriodLockStatus(postData: any): Promise<any | ErrorMessage> {
    return this.payPeriodLayoutBackend
      .fetchPayPeriodLockStatus(postData)
      .pipe(map((response) => response.body))
      .toPromise();
  }
}
