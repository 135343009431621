import { Action, createAction, props } from '@ngrx/store';
import { JobModel } from '../backend/job/model/job.model';
import { RequestJobModel } from '../backend/job/model/requestjob.model';
import { createActionType } from '../../shared/action/utils';

export const JOBS_REQUEST = createActionType('[Job1] Request');
export const JOBS_RECEIVE = createActionType('[Job1] Receive');
export const JOB_DETAILS_UPDATE_REQUEST = createActionType(
  '[Job1] Update Request'
);
export const JOB_DETAILS_UPDATE_RECEIVE = createActionType(
  '[Job1] Update Receive'
);

export const JOB_TOGGLE_FAVOURITE_REQUEST = createActionType(
  '[Job1] Toggle Favorite Request'
);
export const JOB_TOGGLE_FAVOURITE_RECEIVE = createActionType(
  '[Job1] Toggle Favorite Receive'
);

export const EMPLOYEE_DETAILS_ADD_REQUEST = createActionType(
  '[Employee1] Add Request'
);
export const EMPLOYEE_DETAILS_ADD_RECEIVE = createActionType(
  '[Employee1] Add Receive'
);

export const EMPLOYEE_DETAILS_UPDATE_REQUEST = createActionType(
  '[Employee1] Update Request'
);
export const EMPLOYEE_DETAILS_UPDATE_RECEIVE = createActionType(
  '[Employee1] Update Receive'
);

export const EMPLOYEE_NOTES_ADD_REQUEST = createActionType(
  '[EmployeeNotes1] Add Request'
);
export const EMPLOYEE_NOTES_ADD_RECEIVE = createActionType(
  '[EmployeeNotes1] Add Receive'
);

export const JOBS_EMPLOYEE_NOTES_REQUEST = createActionType(
  '[JobsEmployeeNotes1] Request'
);
export const JOBS_EMPLOYEE_NOTES_RECEIVE = createActionType(
  '[JobsEmployeeNotes1] Receive'
);

export class JobsRequest implements Action {
  readonly type = JOBS_REQUEST;
  constructor(public payload: RequestJobModel[]) {}
}
export class JobsReceive implements Action {
  readonly type = JOBS_RECEIVE;
  constructor(public payload: JobModel[]) {}
}

export class JobToggleFavoriteRequest implements Action {
  readonly type = JOB_TOGGLE_FAVOURITE_REQUEST;
  constructor(public payload: { job: JobModel; employeeId: number }) {}
}
export class JobToggleFavoriteReceive implements Action {
  readonly type = JOB_TOGGLE_FAVOURITE_RECEIVE;
  constructor(public payload: JobModel) {}
}

export class JobDetailsUpdateRequest implements Action {
  readonly type = JOB_DETAILS_UPDATE_REQUEST;
  constructor(public payload: any) {}
}
export class JobDetailsUpdateReceive implements Action {
  readonly type = JOB_DETAILS_UPDATE_RECEIVE;
  constructor(public payload: any[]) {}
}

export class EmployeeDetailsAddRequest implements Action {
  readonly type = EMPLOYEE_DETAILS_ADD_REQUEST;
  constructor(public payload: any) {}
}
export class EmployeeDetailsAddReceive implements Action {
  readonly type = EMPLOYEE_DETAILS_ADD_RECEIVE;
  constructor(public payload: any[]) {}
}

export class EmployeeDetailsUpdateRequest implements Action {
  readonly type = EMPLOYEE_DETAILS_UPDATE_REQUEST;
  constructor(public payload: any) {}
}
export class EmployeeDetailsUpdateReceive implements Action {
  readonly type = EMPLOYEE_DETAILS_UPDATE_RECEIVE;
  constructor(public payload: any[]) {}
}

export class EmployeeNotesAddRequest implements Action {
  readonly type = EMPLOYEE_NOTES_ADD_REQUEST;
  constructor(public payload: any) {}
}
export class EmployeeNotesAddReceive implements Action {
  readonly type = EMPLOYEE_NOTES_ADD_RECEIVE;
  constructor(public payload: any[]) {}
}

export class JobsEmployeeNotesRequest implements Action {
  readonly type = JOBS_EMPLOYEE_NOTES_REQUEST;
  constructor(public payload: RequestJobModel[]) {}
}
export class JobsEmployeeNotesReceive implements Action {
  readonly type = JOBS_EMPLOYEE_NOTES_RECEIVE;
  constructor(public payload: JobModel[]) {}
}
