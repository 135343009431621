import {
  ForgotpasswordStateInterface,
  initForgotpasswordStateInterface,
} from './forgotpassword.state';

export function ForgotpasswordReducer(
  state = initForgotpasswordStateInterface,
  action
): ForgotpasswordStateInterface {
  switch (action.type) {
    default:
      return state;
  }
}
