import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ExpenseCategoryModel } from '../backend/expense-category/model/expense-category.model';

export interface ExpenseCategoryStateInterface
  extends EntityState<ExpenseCategoryModel> {
  categories: ExpenseCategoryModel[];
}

export const adapterExpenseCategory: EntityAdapter<ExpenseCategoryModel> = createEntityAdapter<ExpenseCategoryModel>();

export const initExpenseCategoryState: ExpenseCategoryStateInterface = adapterExpenseCategory.getInitialState(
  {
    categories: [],
  }
);

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = adapterExpenseCategory.getSelectors();
