import { InjectionToken } from '@angular/core';

export const errors = {
  required: () => `This field is required`,
  minlength: ({ requiredLength }) =>
    `Minimum ${requiredLength} characters required`,
  maxlength: ({ requiredLength }) =>
    `Maximum ${requiredLength} characters limit`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => errors,
});
