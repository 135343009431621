import { createSelector } from '@ngrx/store';
import { getManpowerState } from '../store/store.selectors';
import { ManpowerInterface, selectAll } from './manpower.state';

export const getJobs = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => selectAll(state)
);

export const getUsers = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.users
);

export const getNonManpowerJobs = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.nonManpowerJobs
);

export const getProjectManagers = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.projectManagers
);

export const getForemans = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.foremans
);

export const getSMForemans = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.sm_foremans
);

export const getPipeForemans = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.pipe_foremans
);

export const getPerformanceNotes = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.performanceNotes
);

export const getFilters = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.filters
);

export const getJobFilters = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.jobFilters
);

export const getJobNotes = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.jobNotes
);

export const getApprentice = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.apprentice
);

export const getJourneymen = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.journeymen
);

export const getSMUnresolvedCount = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.sm_unresolved_count
);

export const getPipeUnresolvedCount = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.pl_unresolved_count
);

export const getEmpJobDetails = createSelector(
  getManpowerState,
  (state: ManpowerInterface) => state.empJobDetails
);
