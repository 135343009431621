<div class="user_main_component">
  <div class="user_main_component_top">
    <div class="row">
      <div class="col-sm-7 user_main_component_mboile_box">
        <div class="Iuser_main_component_top_inner"></div>
      </div>
      <div class="col-sm-5">
        <div class="UIright_main">
          <div class="UIright_main_info" id="sync_UIright_main_info"></div>
          <div
            class="UIright_main_pagination"
            id="sync_UIright_main_pagination"
          ></div>
          <div class="UIright_main_viewd" id="sync_UIright_main_viewd"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="dt_cooltable defaultcheckqbox">
    <div *ngIf="!(isSynclogDataTableInitialized$ | async)">
      <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    </div>

    <!-- Mat Table -->
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="syncedOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Synced on</th>
        <td
          mat-cell
          *matCellDef="let synclog"
          matTooltip=""
          matTooltipPosition="left"
        ></td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td
          mat-cell
          *matCellDef="let synclog"
          matTooltip=""
          matTooltipPosition="left"
        ></td>
      </ng-container>

      <ng-container matColumnDef="jobsSynced">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Jobs Synced</th>
        <td
          mat-cell
          *matCellDef="let synclog"
          matTooltip=""
          matTooltipPosition="left"
        ></td>
      </ng-container>

      <ng-container matColumnDef="usersSynced">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Users Synced</th>
        <td
          mat-cell
          *matCellDef="let synclog"
          matTooltip=""
          matTooltipPosition="left"
        ></td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td
          mat-cell
          *matCellDef="let synclog"
          matTooltip=""
          matTooltipPosition="left"
        ></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>

    <!-- End of Mat Table -->
  </div>
</div>
