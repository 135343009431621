import { NgModule } from '@angular/core';
import { InstructionsRoutingModule } from './instructions-routing.module';
import { InstructionsComponent } from './instructions.component';
import { IosDownloadComponent } from './ios/ios-download.component';
import { AndroidDownloadComponent } from './android/android-download.component';

@NgModule({
  declarations: [
    InstructionsComponent,
    IosDownloadComponent,
    AndroidDownloadComponent,
  ],
  imports: [InstructionsRoutingModule],
  exports: [],
})
export class InstructionsModule {}
