import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RouterStoreService } from './core/router/router.service';
import {
  filter,
  takeUntil,
  tap,
  withLatestFrom,
  map,
  mergeMap,
} from 'rxjs/operators';
import { AuthStoreService } from './core/auth/auth.service';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouterOutlet,
} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'cds-web';
  userLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  ngUnsubscribe$: Subject<any> = new Subject();
  loginModuleUrls = ['/login', '/login/forgot-password'];
  instructionModuleUrls = [
    '/instructions',
    '/instructions/ios',
    '/instructions/android',
  ];
  path: '';
  constructor(
    private routerStoreService: RouterStoreService,
    private authStoreService: AuthStoreService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  requestUserInfo() {
    if (sessionStorage.getItem('LOGIN_TOKEN_KEY') != null) {
      this.authStoreService.requestCurrentUserInfo();
    } else if (localStorage.getItem('LOGIN_TOKEN_KEY') != null) {
      this.authStoreService.requestCurrentUserInfo();
    }
  }

  ngOnInit() {
    this.authStoreService
      .getCurrentUserInfo()
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((userInfo) => {
        this.userLoggedIn$.next(!!userInfo);
      });
    this.routerStoreService
      .getCurrentRoute()
      .pipe(
        filter((data) => !!data),
        filter((data) => !this.instructionModuleUrls.includes(data.state.url)),
        withLatestFrom(this.authStoreService.getCurrentUserInfo())
      )
      .subscribe((data) => {
        if (!this.loginModuleUrls.includes(data[0].state.url)) {
          this.requestUserInfo();
        } else {
          this.userLoggedIn$.next(false);
        }
      });
  }
  onActivate(outlet: RouterOutlet) {
    outlet.activatedRoute.data
      .pipe(map((data) => (this.path = data.title)))
      .toPromise()
      .then();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
