import { UserAccount } from '../../backend/customer/model/user-account.model';

export interface RegisterStateInterface {
  userAccount: UserAccount;
  isRegisterSuccess: boolean;
}

export const initRegisterState: RegisterStateInterface = {
  userAccount: null,
  isRegisterSuccess: false,
};
