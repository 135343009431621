import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CDSAppState } from '../store/store.state';

import { Observable } from 'rxjs';

import * as ManpowerActions from './manpower.actions';

import * as ManpowerSelectors from './manpower.selectors';
import { EditJob } from '../../manpower/model/editJob.model';
import { Constants } from '../../constants/constant';
import { JobFilter } from '../../manpower/model/JobFilter.model';
import { JobsModel } from '../backend/manpower/model/jobs.model';

@Injectable()
export class ManpowerStoreService {
  CONSTANTS = Constants.MANPOWER;

  constructor(private store: Store<CDSAppState>) {}

  public getJobs(): Observable<JobsModel[]> {
    return this.store.pipe(select((state) => ManpowerSelectors.getJobs(state)));
  }

  public getApprentice(): Observable<any> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getApprentice(state))
    );
  }

  public getJourneymen(): Observable<any> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getJourneymen(state))
    );
  }

  public getSMUnresolvedCount(): Observable<any> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getSMUnresolvedCount(state))
    );
  }

  public getPipeUnresolvedCount(): Observable<any> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getPipeUnresolvedCount(state))
    );
  }

  public getUsers(): Observable<[]> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getUsers(state))
    );
  }

  public getNonManpowerJobs(): Observable<[]> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getNonManpowerJobs(state))
    );
  }

  public getProjectManagers(): Observable<[]> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getProjectManagers(state))
    );
  }

  public getForemans(): Observable<[]> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getForemans(state))
    );
  }

  public getSMForemans(): Observable<[]> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getSMForemans(state))
    );
  }

  public getPipeForemans(): Observable<[]> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getPipeForemans(state))
    );
  }

  public getPerformanceNotes(): Observable<[]> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getPerformanceNotes(state))
    );
  }

  public getFilterLists(): Observable<any> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getFilters(state))
    );
  }

  public getJobFilterLists(): Observable<any> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getJobFilters(state))
    );
  }

  public getJobNotes(): Observable<any> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getJobNotes(state))
    );
  }

  // actions for real time firebase events
  public updateJobObject(jobObject, manpowerCount) {
    this.store.dispatch(
      new ManpowerActions.updateJobObject({ jobObject, manpowerCount })
    );
  }

  // actions for real time firebase events
  public updateManpowerCounts(countObj) {
    this.store.dispatch(new ManpowerActions.UpdateCount(countObj));
  }

  public requestJobs(jobData) {
    this.store.dispatch(new ManpowerActions.JobRequest(jobData));
  }

  public requestNonManpowerJobs() {
    this.store.dispatch(new ManpowerActions.NonManpowerJobRequest());
  }

  public requestUsers(filter?: any) {
    this.store.dispatch(new ManpowerActions.UsersRequest(filter));
  }

  public requestProjectmanagers() {
    this.store.dispatch(new ManpowerActions.ProjectManagerRequest());
  }

  public requestAssignJobDetails(data) {
    this.store.dispatch(new ManpowerActions.AssignJobDetailsRequest(data));
  }

  public requestPushNotification(data) {
    this.store.dispatch(new ManpowerActions.PushNotificationRequest(data));
  }

  public requestforemans() {
    this.store.dispatch(new ManpowerActions.ForemanRequest());
  }

  public requestSMforemans() {
    this.store.dispatch(new ManpowerActions.SMforemanRequest());
  }

  public requestPipeforemans() {
    this.store.dispatch(new ManpowerActions.PipeforemanRequest());
  }

  public requestFilterLists() {
    this.store.dispatch(new ManpowerActions.FiltersRequest());
  }

  public requestForJobFilterLists() {
    this.store.dispatch(new ManpowerActions.JobFiltersRequest());
  }

  public assignEmployeeToJob(assignJob: any) {
    this.store.dispatch(new ManpowerActions.AssignJobRequest(assignJob));
  }

  public addNewJob(job_id: number, jobFilter: JobFilter) {
    this.store.dispatch(
      new ManpowerActions.AddNewJobRequest({ job_id, jobFilter })
    );
  }

  public unAssignEmployeeToJob(data) {
    this.store.dispatch(new ManpowerActions.UnAssignJobRequest(data));
  }

  public updateJob(updateData: EditJob, filters: JobFilter) {
    this.store.dispatch(
      new ManpowerActions.UpdateJobRequest({
        jobData: updateData,
        filters,
      })
    );
  }

  public removeJob(data: any) {
    this.store.dispatch(new ManpowerActions.RemoveJobRequest(data));
  }

  public requestPerformanceNotes(emp_id: number) {
    this.store.dispatch(
      new ManpowerActions.PerformanceNotesRequest({
        emp_id,
        page: this.CONSTANTS.PAGE_INDEX,
        pageItems: this.CONSTANTS.PAGE_ITEM,
      })
    );
  }

  public addPerformanceNote(employee_id, text) {
    this.store.dispatch(
      new ManpowerActions.AddPerformanceNoteRequest({
        employee_id,
        text,
      })
    );
  }

  public requestJobNotes(data) {
    this.store.dispatch(new ManpowerActions.JobNotesRequest(data));
  }

  public addJobNotes(jobData: any) {
    this.store.dispatch(new ManpowerActions.AddJobNoteRequest(jobData));
  }

  public resolveNote(jobData) {
    this.store.dispatch(new ManpowerActions.ResolveNoteRequest(jobData));
  }

  public requestFlagEmployee(flagData) {
    this.store.dispatch(new ManpowerActions.FlagEmployeeRequest(flagData));
  }

  public getEmpJobDetails(): Observable<{}> {
    return this.store.pipe(
      select((state) => ManpowerSelectors.getEmpJobDetails(state))
    );
  }
}
