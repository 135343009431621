import * as WorkorderScheduleBydayActions from './workorder-schedule-byday.actions';
import {
  initWorkorderScheduleBydayState,
  WorkorderScheduleBydayStateInterface,
  adapterWorkorderScheduleByday,
} from './workorder-schedule-byday.state';

export function WorkorderScheduleBydayReducer(
  state = initWorkorderScheduleBydayState,
  action
): WorkorderScheduleBydayStateInterface {
  switch (action.type) {
    case WorkorderScheduleBydayActions.WORKORDER_SCHEDULE_BYDAY_GET_RECEIVE: {
      const payload = action.payload.scheduleList.data;
      return adapterWorkorderScheduleByday.setAll(payload, state);
    }
    case WorkorderScheduleBydayActions.WORKORDER_SCHEDULE_BYDAY_UPDATE_RECEIVE: {
      const updatedData = action.payload.updatedWorkorder.data;
      const workorderScheduleId = action.payload.workOrderScheduleId;
      // replace workorderId with workorderScheduleId
      const payload = {
        ...updatedData,
        id: workorderScheduleId,
        work_order_type_hex_code: updatedData.work_orderType_color_code,
      };

      return adapterWorkorderScheduleByday.updateOne(
        { id: workorderScheduleId, changes: payload },
        state
      );
    }
    default:
      return state;
  }
}
