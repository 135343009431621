import { ErrorType } from './model/error-type';

export interface ErrorStateInterface {
  errorType: ErrorType;
  errorMessage: string;
  timestamp: number;
}

export const initErrorState: ErrorStateInterface = {
  errorType: null,
  errorMessage: null,
  timestamp: null,
};
