import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as billingRateActions from '../billing-rate/billing-rate.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { HideSpinner, ShowSpinner } from '../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../error/error.actions';
import { ErrorType } from '../error/model/error-type';
import { AlertMessageCommonService } from '../../shared/alert-message-common/alert-message-common.service';
import { BillingService } from '../backend/billing/billing.service';

type showSpinnerTypes = billingRateActions.BillingRateGetRequest;

const showSpinnerActions = [billingRateActions.BILLING_RATE_GET_REQUEST];

type hideSpinnerTypes = billingRateActions.BillingRateGetReceive;

const hideSpinnerActions = [billingRateActions.BILLING_RATE_GET_RECEIVE];

@Injectable()
export class BillingRateEffects {
  constructor(
    private actions$: Actions,
    private billingService: BillingService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnBillingRateGetRequest$ = this.actions$.pipe(
    ofType<billingRateActions.BillingRateGetRequest>(
      billingRateActions.BILLING_RATE_GET_REQUEST
    ),
    throttleTime(2000),
    switchMap(() =>
      from(this.billingService.fetchBillingRateList()).pipe(
        mergeMap((rateList) => [
          new billingRateActions.BillingRateGetReceive(rateList),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnBillingRateUpdateRequest$ = this.actions$.pipe(
    ofType<billingRateActions.BillingRateUpdateRequest>(
      billingRateActions.BILLING_RATE_UPDATE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((postData) =>
      from(this.billingService.updateBillingRate(postData)).pipe(
        mergeMap((updatedList) => [
          new billingRateActions.BillingRateUpdateReceive(updatedList),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
}
