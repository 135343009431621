import { createSelector } from '@ngrx/store';
import { getDepartmentByEntryType } from '../../store/store.selectors';
import { ByEmployeeInterface } from './by-employee.state';

export const getDepartments = createSelector(
  getDepartmentByEntryType,
  (state: ByEmployeeInterface) => state.departments
);

export const getLocals = createSelector(
  getDepartmentByEntryType,
  (state: ByEmployeeInterface) => state.locals
);

export const getClasses = createSelector(
  getDepartmentByEntryType,
  (state: ByEmployeeInterface) => state.classes
);
