import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { CustomerService } from '../../backend/customer/customer.service';
import { Router } from '@angular/router';
import * as ForgotpasswordActions from './forgotpassword.actions';
import { UserForgotPassword } from '../model/user-forgot-password.model';
import { UserForgotPasswordMobile } from '../model/user-forgot-password-mobile.model';
import { AlertMessageCommonService } from '../../../shared/alert-message-common/alert-message-common.service';
import * as ErrorActions from '../../error/error.actions';
import {
  HideSpinner,
  ShowSpinner,
} from '../../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import { ErrorType } from '../../error/model/error-type';

type showSpinnerTypes =
  | ForgotpasswordActions.ForgotPasswordRequest
  | ForgotpasswordActions.ForgotPasswordMobileRequest;

const showSpinnerActions = [
  ForgotpasswordActions.FORGOT_PASSWORD_REQUEST,
  ForgotpasswordActions.FORGOT_PASSWORD_MOBILE_REQUEST,
];

type hideSpinnerTypes =
  | ForgotpasswordActions.ForgotPasswordReceive
  | ForgotpasswordActions.ForgotPasswordUserReceive
  | ForgotpasswordActions.ForgotPasswordMobileReceive;

const hideSpinnerActions = [
  ForgotpasswordActions.FORGOT_PASSWORD_RECEIVE,
  ForgotpasswordActions.FORGOT_PASSWORD_USER_RECEIVE,
  ForgotpasswordActions.FORGOT_PASSWORD_MOBILE_RECEIVE,
];
@Injectable()
export class ForgotpasswordEffects {
  constructor(
    private actions$: Actions,
    private customerService: CustomerService,
    private router: Router,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnForgotPasswordRequest$ = this.actions$.pipe(
    ofType<ForgotpasswordActions.ForgotPasswordRequest>(
      ForgotpasswordActions.FORGOT_PASSWORD_REQUEST
    ),
    map((action) => {
      return action.payload;
    }),
    throttleTime(2000),
    switchMap((userForgotPassword: UserForgotPassword) =>
      from(this.customerService.ForgotPasswordEntry(userForgotPassword)).pipe(
        mergeMap((data) =>
          userForgotPassword?.isFromUserList
            ? [new ForgotpasswordActions.ForgotPasswordUserReceive(data)]
            : [new ForgotpasswordActions.ForgotPasswordReceive(data)]
        ),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnForgotPasswordMobileRequest$ = this.actions$.pipe(
    ofType<ForgotpasswordActions.ForgotPasswordMobileRequest>(
      ForgotpasswordActions.FORGOT_PASSWORD_MOBILE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((userForgotPasswordMobile: UserForgotPasswordMobile) =>
      from(
        this.customerService.ForgotPasswordMobileEntry(userForgotPasswordMobile)
      ).pipe(
        mergeMap((data) => [
          new ForgotpasswordActions.ForgotPasswordMobileReceive(data),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnForgotpasswordRecieve$ = this.actions$.pipe(
    ofType<ForgotpasswordActions.ForgotPasswordReceive>(
      ForgotpasswordActions.FORGOT_PASSWORD_RECEIVE
    ),
    map((action) => {
      if (action.payload.status == 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.message
        );
        this.router.navigate(['/login']);
      } else {
        this.alertMessageCommonService.error('Error', action.payload.message);
      }
    })
  );

  @Effect({ dispatch: false })
  OnForgotpasswordUserRecieve$ = this.actions$.pipe(
    ofType<ForgotpasswordActions.ForgotPasswordUserReceive>(
      ForgotpasswordActions.FORGOT_PASSWORD_USER_RECEIVE
    ),
    map((action) => {
      if (action.payload.status == 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.message
        );
      } else {
        this.alertMessageCommonService.error('Error', action.payload.message);
      }
    })
  );

  @Effect({ dispatch: false })
  OnForgotpasswordMobileRecieve$ = this.actions$.pipe(
    ofType<ForgotpasswordActions.ForgotPasswordMobileReceive>(
      ForgotpasswordActions.FORGOT_PASSWORD_MOBILE_RECEIVE
    ),
    map((action) => {
      if (action.payload.data === null) {
        this.alertMessageCommonService.error('Error', action.payload.message);
      } else {
        this.alertMessageCommonService.success(
          'Success',
          'You should receive a text in a minute with a temporary password'
        );
        this.router.navigate(['/login']);
      }
    })
  );
}
