import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TimeModel } from '../backend/time/model/time.model';

export interface TimeStateInterface extends EntityState<TimeModel> {
  // additional entity state properties
  selectedTimeId: number | null;
  newTime: TimeModel;
  local: any;
}

export const adapterTime: EntityAdapter<TimeModel> = createEntityAdapter<TimeModel>(
  {
    sortComparer: compareTimeEntries,
  }
);

export const initTimeState: TimeStateInterface = adapterTime.getInitialState({
  selectedTimeId: null,
  newTime: null,
  local: null,
});

export function compareTimeEntries(time1: TimeModel, time2: TimeModel) {
  if (time1.employee_last_name < time2.employee_last_name) {
    return -1;
  }
  if (time1.employee_last_name > time2.employee_last_name) {
    return 1;
  }
  return 0;
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterTime.getSelectors();
