import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TruckStockReceiveListModel } from '../../backend/service-order/model/truck-stock-receive-list.model';

export interface TruckStockStateInterface
  extends EntityState<TruckStockReceiveListModel> {
  TruckStock: TruckStockReceiveListModel;
}

export function TruckStockId(a: TruckStockReceiveListModel): number {
  return a.work_order_truck_stock_id;
}

export const adapterTruckStock: EntityAdapter<TruckStockReceiveListModel> = createEntityAdapter<TruckStockReceiveListModel>(
  {
    selectId: TruckStockId,
  }
);

export const initTruckStockState: TruckStockStateInterface = adapterTruckStock.getInitialState(
  {
    TruckStock: null,
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterTruckStock.getSelectors();
