import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as JobActions from './job.actions';
import { CDSAppState } from '../store/store.state';
import * as JobSelectors from './job.selectors';
import { JobModel } from '../backend/job/model/job.model';
import { Observable } from 'rxjs';
@Injectable()
export class JobStoreService {
  constructor(private store: Store<CDSAppState>) {}

  public getAllJobs(): Observable<JobModel[]> {
    return this.store.pipe(select((state) => JobSelectors.getAllJobs(state)));
  }

  public requestJobs(requestObj) {
    this.store.dispatch(new JobActions.JobsRequest(requestObj));
  }

  public requestJobToggleFavorite(job: JobModel, employeeId: number) {
    this.store.dispatch(
      new JobActions.JobToggleFavoriteRequest({ job, employeeId })
    );
  }

  public requestJobDetailsUpdate(jobDetails: any) {
    this.store.dispatch(new JobActions.JobDetailsUpdateRequest(jobDetails));
  }

  public requestEmployeeDetailsAdd(jobDetails: any) {
    this.store.dispatch(new JobActions.EmployeeDetailsAddRequest(jobDetails));
  }

  public requestEmployeeNotesAdd(jobDetails: any) {
    this.store.dispatch(new JobActions.EmployeeNotesAddRequest(jobDetails));
  }

  public requestJobEmployeeNotes(requestObj) {
    this.store.dispatch(new JobActions.JobsEmployeeNotesRequest(requestObj));
  }

  public requestEmployeeDetailsUpdate(jobDetails: any) {
    this.store.dispatch(
      new JobActions.EmployeeDetailsUpdateRequest(jobDetails)
    );
  }
}
