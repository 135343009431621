import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from 'src/app/constants/constant';
import { environment } from '../../../environments/environment';
import { ServiceOrderService } from '../../core/backend/service-order/service-order.service';
import { InvoiceComponent } from '../../serviceorder/edit-workorder/invoice/invoice.component';
import { AlertMessageCommonService } from '../alert-message-common/alert-message-common.service';
@Injectable({ providedIn: 'root' })
export class ExportService {
  private API_ROOT = environment.apiUrl;
  CONSTANTS = Constants.WORKORDER;

  constructor(
    private spinner: NgxSpinnerService,
    private serviceOrderService: ServiceOrderService,
    public dialog: MatDialog,
    private http: HttpClient,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  downloadFile(data, filename = 'data', headerData = [], headerKeys?) {
    const csvData = this.ConvertToCSV(data, headerData, headerKeys);
    const blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      // If Safari open in new window to save file with random filename.
      downloadLink.setAttribute('target', '_blank');
    }
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', filename + '.csv');
    downloadLink.style.visibility = 'hidden';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  downloadImg(blob, isAttachment) {
    let path = '';
    if (isAttachment) {
      path = 'service_order_attachments/';
    } else {
      path = 'service_order_signatures/';
    }
    let filename = blob.split(path)[1];
    this.http
      .get(`${this.API_ROOT}/workOrder/download?file=` + blob, {
        responseType: 'blob',
      })
      .subscribe((blob) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'MCM-file-' + filename;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  ConvertToCSV(objArray, headerDisplayColumn, headerKeys?) {
    const array =
      typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let line = [' '];
    line = [line, ...headerDisplayColumn];
    line.push('\r\n');
    const headerKeyList = headerKeys ? headerKeys : headerDisplayColumn;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < array.length; i++) {
      for (const header of headerKeyList) {
        let columnValue = array[i][header]?.toString();
        if (columnValue) {
          if (columnValue.includes(',')) {
            columnValue = columnValue.split(',').join('');
          }
          line.push(columnValue);
        } else {
          line.push(' ');
        }
      }
      line.push('\r\n');
    }
    line = line.filter((value) => value.length > 0);
    str = line.join();
    return str;
  }

  closeAllModals() {
    // get modals
    const modals = document.getElementsByClassName('modal');

    if (modals.length > 0) {
      // on every modal change state like in hidden modal
      for (let i = 0; i < modals.length; i++) {
        modals[i].classList.remove('show');
        modals[i].setAttribute('aria-hidden', 'true');
        modals[i].setAttribute('style', 'display: none');
      }
    }

    // get modal backdrops
    const modalsBackdrops = document.getElementsByClassName('modal-backdrop');

    if (modalsBackdrops.length > 0) {
      // remove every modal backdrop
      for (let i = 0; i < modalsBackdrops.length; i++) {
        document.body.removeChild(modalsBackdrops[i]);
      }
    }
  }

  formatToYearMonthDay(date) {
    return moment(date).format('YYYY-MM-DD').toString();
  }

  formatToMonthDayYear(date) {
    return moment(date).format('MM-DD-YYYY').toString();
  }

  generateInvoice(workorderId) {
    let postObj = {
      work_order_id: workorderId,
    };
    this.spinner.show();
    this.serviceOrderService
      .getGeneratedInvoice(postObj)
      .then((res) => {
        this.spinner.hide();
        if (res.status !== 'success') {
          this.alertMessageCommonService.error('Error', res.message);
        } else {
          this.dialog.open(InvoiceComponent, {
            data: {
              html: res.data.htmlfile,
              path: res.data.htmlfilepath,
              workorderId,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  addFilterClass(workorderDetails) {
    const filterStatus = this.CONSTANTS.FILTER_STATUS;
    const filterClass = this.CONSTANTS.FILTER_CLASS;
    switch (workorderDetails.status) {
      case filterStatus.UNSCHEDULED:
        workorderDetails = {
          ...workorderDetails,
          filterclass: filterClass.UNSCHEDULED,
        };
        break;
      case filterStatus.ONGOING:
        workorderDetails = {
          ...workorderDetails,
          filterclass: filterClass.ONGOING,
        };
        break;
      case filterStatus.COMPLETED:
        workorderDetails = {
          ...workorderDetails,
          filterclass: filterClass.COMPLETED,
        };
        break;
      case filterStatus.CLOSED:
        workorderDetails = {
          ...workorderDetails,
          filterclass: filterClass.CLOSED,
        };
        break;
      default:
        break;
    }
    return workorderDetails;
  }
}
