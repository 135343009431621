import * as ServiceOrderActions from './service-order.actions';
import {
  initServiceOrderState,
  ServiceOrderStateInterface,
  adapterServiceOrder,
} from './service-order.state';

export function ServiceOrderReducer(
  state = initServiceOrderState,
  action
): ServiceOrderStateInterface {
  switch (action.type) {
    case ServiceOrderActions.SERVICE_ORDER_GET_RECEIVE: {
      const payload = action.payload.workOrders.data;
      const isFilterApplied = action.payload.filter;
      if (!isFilterApplied) {
        return adapterServiceOrder.addMany(payload, {
          ...state,
        });
      } else {
        return adapterServiceOrder.setAll(payload, state);
      }
    }
    case ServiceOrderActions.SERVICE_ORDER_GET_SITES_RECEIVE: {
      const payload = action.payload.data;
      return {
        ...state,
        sites: payload,
      };
    }
    case ServiceOrderActions.SERVICE_ORDER_GET_SITE_INFO: {
      const payload = action.payload.data;
      return {
        ...state,
        siteInfo: payload,
      };
    }

    case ServiceOrderActions.SERVICE_ORDER_GET_TYPES_RECEIVE: {
      const payload = action.payload.data;
      return {
        ...state,
        workorderTypes: payload,
      };
    }

    case ServiceOrderActions.SERVICE_ORDER_CREATE_RECEIVE: {
      const payload = action.payload.data;

      return {
        ...state,
        createdWorkorder: payload,
      };
    }
    case ServiceOrderActions.SERVICE_ORDER_UPDATE_RECEIVE: {
      const payload = action.payload.updatedEntry.data;
      const from = action.payload.from;
      let updatedEntry, workorderId: any;

      switch (from) {
        case 'afterWOScheduled':
          let entry = payload[0];
          workorderId = entry.work_order_id;
          updatedEntry = {
            status: entry.work_order_status,
          };
          break;
        case 'afterWOScheduledDelete':
          let deleteEntry = action.payload.updatedEntry.data;
          workorderId = action.payload.workorderId;
          updatedEntry = {
            status: deleteEntry.work_order_status,
          };
          break;
        default:
          workorderId = payload.id;
          updatedEntry = payload;
          break;
      }

      return adapterServiceOrder.updateOne(
        { id: workorderId, changes: updatedEntry },
        state
      );
    }
    case ServiceOrderActions.SERVICE_ORDER_DELETE_RECEIVE: {
      const payload = action.payload.res.data;
      if (action.payload.isWorkorderStatusUpdate) {
        return adapterServiceOrder.removeOne(payload.id, {
          ...state,
        });
      }
    }
    case ServiceOrderActions.WORKORDER_REMOVE_ON_SCHEDULE_ACTION: {
      const from = action.payload.from;
      let currentWOFilterApplied: any = localStorage.getItem(
        'currentWOFilterApplied'
      );
      let payload, workororderStatus, workorderId;
      currentWOFilterApplied = currentWOFilterApplied.split(',');

      switch (from) {
        case 'deleteAction':
          payload = action.payload.res.data;
          workororderStatus = payload.work_order_status;
          workorderId = action.payload.workorderId;
          break;

        case 'createAction':
          payload = action.payload.res.data[0];
          workororderStatus = payload.work_order_status;
          workorderId = payload.work_order_id;
          break;

        default:
          break;
      }

      if (!currentWOFilterApplied.includes(workororderStatus?.toString())) {
        return adapterServiceOrder.removeOne(workorderId, {
          ...state,
        });
      }
    }
    default:
      return state;
  }
}
