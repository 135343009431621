import { Action, createAction, props } from '@ngrx/store';
import { TotalTimeModel } from '../backend/totalTime/model/totalTime.model';
import { createActionType } from '../../../app/shared/action/utils';

export const TOTAL_TIME_REQUEST = createActionType('[TotalTime1] Request');
export const TOTAL_TIME_RECEIVE = createActionType('[TotalTime1] Receive');

export const TOTAL_TIME_ADD_REQUEST = createActionType('[TotalTime1] Add');
export const TOTAL_TIME_ADD_RECEIVE = createActionType(
  '[TotalTime1] Receive Entry'
);

export class TotalTimeRequest implements Action {
  readonly type = TOTAL_TIME_REQUEST;
  constructor() {}
}
export class TotalTimeReceive implements Action {
  readonly type = TOTAL_TIME_RECEIVE;
  constructor(public payload: TotalTimeModel[]) {}
}
export class TotalTimeAddRequest implements Action {
  readonly type = TOTAL_TIME_ADD_REQUEST;
  constructor(public payload: TotalTimeModel) {}
}
export class TotalTimeAddReceive implements Action {
  readonly type = TOTAL_TIME_ADD_RECEIVE;
  constructor(public payload: TotalTimeModel) {}
}
