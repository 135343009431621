import { NgModule } from '@angular/core';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorSnackBarComponent } from './error/error-snack-bar';
import { ControlErrorsDirective } from './directives/ControlErrors.directive';
import { RouterModule } from '@angular/router';
import { ScrollDirective } from './directives/scroll.directive';

@NgModule({
  declarations: [
    NavbarComponent,
    ErrorSnackBarComponent,
    ControlErrorsDirective,
    ScrollDirective,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatSnackBarModule,
    RouterModule,
  ],
  exports: [
    NavbarComponent,
    ErrorSnackBarComponent,
    ControlErrorsDirective,
    ScrollDirective,
  ],
  providers: [ScrollDirective],
})
export class SharedModule {}
