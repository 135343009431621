import { createSelector } from '@ngrx/store';
import { getAttachmentState } from '../workorder.selectors';
import {
  AttachmentStateInterface,
  selectAll,
} from '../attachments/attachments.state';

export const getAttachment = createSelector(
  getAttachmentState,
  (state: AttachmentStateInterface) => selectAll(state)
);
