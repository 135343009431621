import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as WorkorderScheduleByEmployeeActions from './workorder-schedule-byEmployee.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import {
  HideSpinner,
  ShowSpinner,
} from '../../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../../error/error.actions';
import { ErrorType } from '../../error/model/error-type';
import { AlertMessageCommonService } from '../../../shared/alert-message-common/alert-message-common.service';
import { ServiceOrderService } from '../../backend/service-order/service-order.service';

type showSpinnerTypes = WorkorderScheduleByEmployeeActions.WorkorderScheduleByEmployeeGetRequest;

const showSpinnerActions = [
  WorkorderScheduleByEmployeeActions.WORKORDER_SCHEDULE_BYEMPLOYEE_GET_REQUEST,
];

type hideSpinnerTypes = WorkorderScheduleByEmployeeActions.WorkorderScheduleByEmployeeGetReceive;

const hideSpinnerActions = [
  WorkorderScheduleByEmployeeActions.WORKORDER_SCHEDULE_BYEMPLOYEE_GET_RECEIVE,
];

@Injectable()
export class WorkorderScheduleByEmployeeEffects {
  constructor(
    private actions$: Actions,
    private serviceOrderService: ServiceOrderService,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnWorkorderScheduleByEmployeeGetRequest$ = this.actions$.pipe(
    ofType<WorkorderScheduleByEmployeeActions.WorkorderScheduleByEmployeeGetRequest>(
      WorkorderScheduleByEmployeeActions.WORKORDER_SCHEDULE_BYEMPLOYEE_GET_REQUEST
    ),
    map((action) => action.payload),
    switchMap((payload) =>
      from(
        this.serviceOrderService.getWorkOrderScheduleByEmployee(payload)
      ).pipe(
        mergeMap((scheduledList) => [
          new WorkorderScheduleByEmployeeActions.WorkorderScheduleByEmployeeGetReceive(
            {
              scheduleList: scheduledList,
            }
          ),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
}
