import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from '../../../shared/action/utils';
import { TruckStockReceiveListModel } from '../../backend/service-order/model/truck-stock-receive-list.model';

export const TRUCK_STOCK_GET_REQUEST = createActionType(
  '[TruckStock] Get Request'
);

export const TRUCK_STOCK_GET_RECEIVE = createActionType(
  '[TruckStock] Get Receive'
);

export class TruckStockGetRequest implements Action {
  readonly type = TRUCK_STOCK_GET_REQUEST;
  constructor(public payload: any) {}
}
export class TruckStockGetReceive implements Action {
  readonly type = TRUCK_STOCK_GET_RECEIVE;
  constructor(
    public payload: {
      TruckStockList: TruckStockReceiveListModel;
      isScrolled: boolean;
    }
  ) {}
}
