import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from '../../shared/action/utils';

export const PAY_PERIOD_LOCKOUT_UPSERT_REQUEST = createActionType(
  '[PayPeriodLockout1] Upsert Request'
);

export const PAY_PERIOD_LOCKOUT_UPSERT_RECEIVE = createActionType(
  '[PayPeriodLockout1] Upsert Receive'
);

export const PAY_PERIOD_LOCKOUT_STATUS_REQUEST = createActionType(
  '[PayPeriodLockout1] Status Request'
);

export const PAY_PERIOD_LOCKOUT_STATUS_RECEIVE = createActionType(
  '[PayPeriodLockout1] Status Receive'
);

export class PayPeriodLockoutUpsertRequest implements Action {
  readonly type = PAY_PERIOD_LOCKOUT_UPSERT_REQUEST;
  constructor(public payload: { data: any; isShowMsg: boolean }) {}
}

export class PayPeriodLockoutUpsertReceive implements Action {
  readonly type = PAY_PERIOD_LOCKOUT_UPSERT_RECEIVE;
  constructor(public payload: { data: any; isShowMsg: boolean }) {}
}

export class PayPeriodLockoutStatusRequest implements Action {
  readonly type = PAY_PERIOD_LOCKOUT_STATUS_REQUEST;
  constructor(public payload: { data: any; isFromDate?: boolean }) {}
}

export class PayPeriodLockoutStatusReceive implements Action {
  readonly type = PAY_PERIOD_LOCKOUT_STATUS_RECEIVE;
  constructor(public payload: { data: any; isFromDate?: boolean }) {}
}
