import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { SettingComponent } from './setting.component';
import { SettingRoutingModule } from './setting-routing.module';
import { SettingGeneralComponent } from './general/general.component';
import { SettingUsersComponent } from './users/users.component';
import { SettingRolesComponent } from './roles/roles.component';
import { SettingSynclogComponent } from './synclog/synclog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SettingManpowerComponent } from './setting-manpower/setting-manpower.component';
import { DataTablesModule } from 'angular-datatables';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgxInputTagModule } from '@ngx-lite/input-tag';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    SettingComponent,
    SettingGeneralComponent,
    SettingUsersComponent,
    SettingRolesComponent,
    SettingSynclogComponent,
    SettingManpowerComponent,
  ],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    SettingRoutingModule,
    MatTooltipModule,
    MatProgressBarModule,
    DataTablesModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgxSpinnerModule,
    NgxInputTagModule.forRoot(),
  ],
  providers: [DatePipe],
  exports: [],
})
export class SettingsModule {}
