import { Action, createAction, props } from '@ngrx/store';
import { UserCredential } from '../model/user-credential.model';
import { AuthenticationToken } from '../../backend/customer/model/token.model';
import { createActionType } from '../../../shared/action/utils';

export const LOGIN_REQUEST = createActionType('[Login1] Token Request');
export const LOGIN_RECEIVE = createActionType('[Login1] Token Receive');

export const LOGOUT_REQUEST = createActionType(
  '[Logout1] Token Delete Request'
);
export const REDIRECT_RESET_PASSWORD_REQUEST = createActionType(
  '[RedirectResetPassword1] Redirect Reset Password Request'
);

export class TokenRequest implements Action {
  readonly type = LOGIN_REQUEST;
  constructor(public payload: UserCredential) {}
}
export class TokenReceive implements Action {
  readonly type = LOGIN_RECEIVE;
  constructor(public payload: AuthenticationToken) {}
}

export class TokenDeleteRequest implements Action {
  readonly type = LOGOUT_REQUEST;
  constructor() {}
}
export class RedirectResetPassword implements Action {
  readonly type = REDIRECT_RESET_PASSWORD_REQUEST;
  constructor(public payload: any) {}
}
