import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UserBackendModel } from '../../core/backend/customer/model/UserBackend.model';
import { RoleModel } from '../../core/backend/role/model/role.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { SettingStoreService } from '../../core/setting/setting.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { ExportService } from '../../shared/export/export.service';
import { _getOptionScrollPosition } from '@angular/material/core';
import { PermissionService } from '../../core/backend/permission/permission.service';
import { AuthStoreService } from '../../core/auth/auth.service';
import * as _ from 'lodash';
import { OverlayStoreService } from '../../core/overlay/overlay.service';
declare var jQuery: any;

@Component({
  selector: 'app-setting-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class SettingRolesComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['role', 'created_date_filtered', 'total_user'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('closebutton') closebutton;
  allowView = false;
  allowEdit = false;
  roleText = '';
  roleHeadingText = '';
  roleId = '';
  employeeRolePermission: any;
  permissionsData: any;
  permissionsData$: Observable<any>;
  roles$: Observable<RoleModel[]>;
  permissionspostData: any;
  permissions = [];
  view_option: any;
  edit_option: any;
  postData = {
    role: '',
    permissions: [],
  };
  permissionsOnEditMode: [];

  currentUser$: Observable<UserBackendModel>;
  userData: UserBackendModel = new UserBackendModel();
  userPermissions: {};
  userId: number;
  editRoles: Boolean;

  ngUnsubscribe$: Subject<any> = new Subject<any>();
  isRoleDataTableInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  @Input()
  roles: RoleModel[] = [];

  constructor(
    private settingStoreService: SettingStoreService,
    private datePipe: DatePipe,
    private spinnerService: SpinnerService,
    private exportService: ExportService,
    private permissionService: PermissionService,
    private authStoreService: AuthStoreService,
    private router: Router,
    private overlayStoreService: OverlayStoreService
  ) {}

  ngOnInit() {
    this.roles$ = this.settingStoreService.getAllRoles();
    this.permissionsData$ = this.settingStoreService.getAllPermissions();
    this.currentUser$ = this.authStoreService.getCurrentUserInfo();
    this.currentUser$
      .pipe(
        filter((data) => !!data),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((fetchedData) => {
        this.userData = fetchedData;
        this.userId = this.userData['id'];
        this.userPermissions = JSON.parse(this.userData.password);
        this.editRoles = false;
        for (let i = 0; i < Object.keys(this.userPermissions).length; i++) {
          var id = this.userPermissions[i].permission_id;
          var edit = this.userPermissions[i].permission_edit_option;

          switch (id) {
            case 18:
              if (edit === 'Yes') {
                this.editRoles = true;
              }
              break;
          }
        }
      });
  }

  ngAfterViewInit() {
    this.roles$
      .pipe(
        filter((data) => !!data && data.length > 0),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((fetchedJobs) => {
        this.roles = fetchedJobs;

        let updatedRoles = this.addKey(this.roles);
        this.dataSource = new MatTableDataSource(updatedRoles);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.filterPredicate = (data: any, filter: string) => {
          let matchFound = false;
          for (const column of this.displayedColumns) {
            if (column in data) {
              if (data[column]) {
                if (column)
                  matchFound =
                    matchFound ||
                    data[column]
                      .toString()
                      .trim()
                      .toLowerCase()
                      .indexOf(filter.trim().toLowerCase()) !== -1;
              }
            }
          }
          return matchFound;
        };
        this.isRoleDataTableInitialized$.next(true);
      });

    this.permissionsData$
      .pipe(
        filter((data) => !!data && data.length > 0),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((fetchedPermissions) => {
        this.permissionsData = fetchedPermissions;
      });

    this.settingStoreService.requestRoles();
    this.spinnerService.manageSpinnerState();
    this.settingStoreService.requestPermissions();
  }

  addKey(data) {
    return data.map((item) => ({
      ...item,
      created_date_filtered: this.filterDate(item['created_date']),
    }));
  }

  filterDate(date) {
    try {
      return this.datePipe.transform(date);
    } catch (eror) {}
  }

  public doFilter = (event) => {
    let value = event.target.value;
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  handlePermission(event, id, index, from) {
    this.view_option =
      $('#view_' + index).val() == null ? 'N/A' : $('#view_' + index).val();

    this.edit_option =
      $('#edit_' + index).val() == null ? 'N/A' : $('#edit_' + index).val();

    let permission_id = id;
    let obj = {};

    if (this.permissions[index] == 'undefined') {
      obj[index] = {
        permission_id: permission_id,
        view_option: this.view_option,
        edit_option: this.edit_option,
      };
    } else {
      this.permissions[index] = {
        permission_id: permission_id,
        view_option: this.view_option,
        edit_option: this.edit_option,
      };
    }

    if (Object.keys(obj).length != 0 && obj != null) this.permissions.push(obj);

    this.permissionspostData = this.permissions.filter(function (e) {
      return e != null;
    });

    this.postData.permissions = this.permissionspostData;
  }

  onRoleChange(event) {
    this.roleText = event.target.value;
  }

  openAddRoleModal() {
    this.employeeRolePermission = [];
    this.roleId = '';
    this.roleHeadingText = 'Add';
    this.roleText = '';
  }
  openEditRoleModal(role) {
    this.roleHeadingText = 'Edit';
    this.roleText = role.role;
    this.roleId = role.id;
    let arr = [];

    this.permissionService
      .getPermissionsByRoleId(this.roleId)
      .then((res: any) => {
        if (res && res.data[0] && res.data[0].employee_role_permissions) {
          this.employeeRolePermission = res?.data[0]?.employee_role_permissions;
        } else {
          this.employeeRolePermission = [];
        }
        this.postData.role = res?.data[0]?.role;
        this.permissionsOnEditMode = this.employeeRolePermission.map(
          this.selectRequiredPayload
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  selectRequiredPayload(show) {
    const { permission_id, view_option, edit_option } = show;
    return { permission_id, view_option, edit_option };
  }

  bindOptions(array, value, from) {
    for (var key in array) {
      if (array[key].permission_name == value) {
        if (from == 'view') return array[key].view_option;
        else if (from == 'edit') return array[key].edit_option;
        else return '';
      }
    }
  }

  savePermissionData() {
    let role = this.roleText;
    this.postData.role = role;
    if (this.roleId == '') {
      this.settingStoreService.requestCreatePermission(this.postData);
      this.closebutton.nativeElement.click();
      this.permissionspostData = [];
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    } else if (this.roleId != '') {
      const result = Object.values(
        []
          .concat(this.permissionsOnEditMode, this.postData.permissions)
          .reduce(
            (r, c) => (
              (r[c.permission_id] = Object.assign(r[c.permission_id] || {}, c)),
              r
            ),
            {}
          )
      );
      this.postData.permissions = result;
      this.settingStoreService.requestUpdatePermission(
        this.postData,
        this.roleId
      );
      this.permissionspostData = [];
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    }
  }
  ngOnDestroy() {
    this.exportService.closeAllModals();
  }

  triggerOverlay(status: string) {
    if (status === 'On') {
      this.overlayStoreService.triggerOverlayOn();
    } else {
      this.overlayStoreService.triggerOverlayOff();
    }
  }
}
