import { createSelector } from '@ngrx/store';
import { getWorkorderScheduleByEmployeeState } from '../workorder.selectors';
import {
  WorkorderScheduleByEmployeeStateInterface,
  selectAll,
} from '../workorder-schedule-byEmployee/workorder-schedule-byEmployee.state';

export const getWorkorderScheduleByEmployee = createSelector(
  getWorkorderScheduleByEmployeeState,
  (state: WorkorderScheduleByEmployeeStateInterface) => selectAll(state)
);
