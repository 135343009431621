import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CustomerService } from './core/backend/customer/customer.service';
import { Injectable } from '@angular/core';
import { BrowserStorageBackend } from './core/backend/browser-storage/browser-storage.backend';
import { AuthStoreService } from './core/auth/auth.service';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private browserStorageBackend: BrowserStorageBackend,
    private customerService: CustomerService,
    private router: Router,
    private authStoreService: AuthStoreService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let tokenExpiry;

    const localStorageTokenExpiry = this.browserStorageBackend.getAuthTokenExpiryFromLocalStorage();
    const sessionStorageTokenExpiry = this.browserStorageBackend.getAuthTokenExpiryFromSessionStorage();

    if (!!localStorageTokenExpiry) {
      tokenExpiry = localStorageTokenExpiry;
    }

    if (!!sessionStorageTokenExpiry) {
      tokenExpiry = sessionStorageTokenExpiry;
    }

    if (tokenExpiry) {
      if (!this.customerService.isAuthenticated(tokenExpiry)) {
        // to clear userInfo and login token from store then route to login screen
        this.authStoreService.initLogout();
        return true;
      }
      this.routeToDashboardPage();
      return;
    }

    return true;
  }

  routeToDashboardPage() {
    this.router.navigate(['/dashboard']);
  }
}
