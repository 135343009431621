import { Action, createAction, props } from '@ngrx/store';
import { JobModel } from '../../backend/job/model/job.model';
import { createActionType } from '../../../shared/action/utils';

export const JOBS_BY_COMPANY_REQUEST = createActionType(
  '[Jobs By Company1] Request'
);
export const JOBS_BY_COMPANY_RECEIVE = createActionType(
  '[Jobs By Company1] Receive'
);

export class JobsByCompanyRequest implements Action {
  readonly type = JOBS_BY_COMPANY_REQUEST;
  constructor(public payload: number) {}
}
export class JobsByCompanyReceive implements Action {
  readonly type = JOBS_BY_COMPANY_RECEIVE;
  constructor(public payload: { jobs: JobModel[] }) {}
}
