import { createSelector } from '@ngrx/store';
import { AuthStateInterface } from './auth.state';
import { getAuthState } from '../store/store.selectors';

export const getRegister = createSelector(
  getAuthState,
  (state: AuthStateInterface) => state.register
);

export const getToken = createSelector(
  getAuthState,
  (state: AuthStateInterface) => state.token
);

export const getAuthenticatedUser = createSelector(
  getAuthState,
  (state: AuthStateInterface) => state.authenticatedUser
);

export const getMessage = createSelector(
  getAuthState,
  (state: AuthStateInterface) => state.forgot
);
