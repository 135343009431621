import { JobLocalModel } from './model/job-local.model';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TimeModel } from './model/time.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class TimeBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  fetchAllTimeEntry(postData): Observable<HttpResponse<TimeModel[]>> {
    const url = `${this.API_ROOT}/time_entry`;
    return this.http.post<TimeModel[]>(url, postData, this.getOptions());
  }

  fetchAddTimeEntry(timeEntry: TimeModel): Observable<any> {
    const data = timeEntry;
    const url = `${this.API_ROOT}/time_entry/add`;
    return this.http.post<any>(url, data, this.getOptions());
  }

  fetchEditTimeEntry(timeEntry: TimeModel): Observable<any> {
    const data = timeEntry;
    const url = `${this.API_ROOT}/time_entry/update/${data.id}`;
    return this.http.put<any>(url, data, this.getOptions());
  }

  fetchTimeEntryNote(noteModel: any): Observable<any> {
    const data = { notes: noteModel.notes };
    const url =
      `${this.API_ROOT}/time_entry/updateNotes/` + noteModel.timeEntryId;
    return this.http.put<any>(url, data, this.getOptions());
  }

  fetchTimeEntryNoteRemove(noteModel: any): Observable<any> {
    const data = { notes: noteModel.notes };
    const url =
      `${this.API_ROOT}/time_entry/updateNotes/` + noteModel.timeEntryId;
    return this.http.put<any>(url, data, this.getOptions());
  }

  fetchTimeEntryContainerRemove(timeEntryModel: any): Observable<any> {
    const data = {
      user_id: timeEntryModel.user_id,
      start_date: timeEntryModel.start_date,
      end_date: timeEntryModel.end_date,
    };
    const url = `${this.API_ROOT}/time_entry/deleteUserEntriesByRange`;
    return this.http.delete<any>(url, this.getDeleteOptions(data));
  }

  fetchDeleteTimeEntry(timeEntryId: string): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/time_entry/delete/${timeEntryId}`;
    return this.http.delete<any>(url, this.getOptions());
  }

  fetchCostCodesByDepartment(job_id: number, is_travel: any): Observable<any> {
    const data = {
      job_id,
      is_travel,
    };
    const url = `${this.API_ROOT}/categories`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  fetchLocalByJob(jobLocalModel: JobLocalModel): Observable<any> {
    const data = jobLocalModel;
    const url = `${this.API_ROOT}/jobs/locals`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  fetchJobsByCompany(companyCode: number): Observable<any> {
    const data = { company_number: 1 };
    const url = `${this.API_ROOT}/jobs`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  getDepartment(
    //department_id: number,
    // entry_type_id: number,
    company_number: number
  ): Observable<any> {
    const data = {
      // department_id: department_id,
      // entry_type_id: entry_type_id,
      company_number: company_number,
    };
    const url = `${this.API_ROOT}/departments`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  getLocals(): Observable<any> {
    const data = {};
    const url = `${this.API_ROOT}/unionlocals`;
    return this.http.get<any[]>(url, this.getOptions());
  }

  getClasses(): Observable<any> {
    const data = {};
    const url = `${this.API_ROOT}/classes`;
    return this.http.get<any[]>(url, this.getOptions());
  }

  getJobCostEntries(dateObj): Observable<any> {
    const url = `${this.API_ROOT}/time_entry/timeEntryExport`;
    return this.http.post<any[]>(url, dateObj, this.getOptions());
  }

  // get time entry by id
  fetchTimeEntryById(id): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/time_entry/` + id;
    return this.http.get<any>(url, this.getOptions());
  }

  certifyTimeEntry(payload): Observable<any> {
    const url = `${this.API_ROOT}/time_entry/certify`;
    return this.http.post<any[]>(url, payload, this.getOptions());
  }

  getCertifiedTimeEntries(payload): Observable<any> {
    const url = `${this.API_ROOT}/time_entry/get-certify`;
    return this.http.post<any[]>(url, payload, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {},
    };
  }

  private getDeleteOptions(
    body
  ): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
    body: any;
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {},
      body,
    };
  }
}
