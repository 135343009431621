import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AssignJob } from '../../../manpower/model/assignJob.model';
import { EditJob } from '../../../manpower/model/editJob.model';
import { RemoveJob } from '../../../manpower/model/removeJob.model';
import { AddJobNote } from 'src/app/manpower/model/addJobNote.model';

@Injectable()
export class ManpowerBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  fetchJobs(
    page,
    pageItems,
    order?,
    foreman_ids?,
    project_manager_ids?,
    locations?,
    assigned?,
    search?,
    sheet_metal?,
    plumbing?
  ): Observable<any> {
    var data = {
      page,
      pageItems,
      order,
      foreman_ids,
      project_manager_ids,
      locations,
      assigned,
      search,
      sheet_metal,
      plumbing,
    };
    const url = `${this.API_ROOT}/manpowers/jobs`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  fetchUsers(filter: any): Observable<any> {
    const data = filter;
    const url = `${this.API_ROOT}/manpowers/employees`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  fetchNonManpowerJobs(): Observable<any> {
    const data = {};
    const url = `${this.API_ROOT}/manpowers/jobs/non-manpower`;
    return this.http.post<any[]>(url, this.getOptions());
  }

  assignJob(assignJobData: AssignJob): Observable<any> {
    const data = assignJobData;
    const url = `${this.API_ROOT}/manpowers/employee/assign-job`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  unAssignJob(
    job_id: number,
    employee_id: number,
    is_assigned: number
  ): Observable<any> {
    const data = { job_id, employee_id, is_assigned };
    const url = `${this.API_ROOT}/manpowers/employee/assign-job`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  addPerformanceNote(employee_id: number, text: string): Observable<any> {
    const data = { employee_id, text };
    const url = `${this.API_ROOT}/manpowers/employee/performance/notes/add`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  updateJob(editJobData: EditJob): Observable<any> {
    const data = editJobData;
    const url = `${this.API_ROOT}/manpowers/job/update`;
    return this.http.put<any[]>(url, data, this.getOptions());
  }

  removeJob(removeJobData: RemoveJob): Observable<any> {
    const data = removeJobData;
    const url = `${this.API_ROOT}/manpowers/remove-job`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  performanceNotes(datas): Observable<any> {
    const data = datas;
    const url =
      `${this.API_ROOT}/manpowers/employee/performance/notes/` + datas.emp_id;
    return this.http.post<any[]>(url, datas, this.getOptions());
  }

  addJob(jobData: any): Observable<any> {
    const url = `${this.API_ROOT}/manpowers/add-job`;
    return this.http.post<any[]>(url, jobData, this.getOptions());
  }

  fetchForemans(from?): Observable<any> {
    const url = `${this.API_ROOT}/manpowers/foremans`;
    const data = { type: from };
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  fetchProjectManagers(): Observable<any> {
    const data = {};
    const url = `${this.API_ROOT}/manpowers/project-managers`;
    return this.http.post<any[]>(url, this.getOptions());
  }

  fetchFilters(): Observable<any> {
    const url = `${this.API_ROOT}/manpowers/employees/filter-list`;
    return this.http.get<any[]>(url, this.getOptions());
  }

  fetchJobFilters(): Observable<any> {
    const url = `${this.API_ROOT}/manpowers/jobs/filter-list`;
    return this.http.get<any[]>(url, this.getOptions());
  }

  fetchJobNotes(jobId, filter?): Observable<any> {
    let timeZone = new Date().getTimezoneOffset();
    const url = `${this.API_ROOT}/manpowers/job/notes/${jobId}?t=${timeZone}`;
    if (filter) {
      return this.http.post<any[]>(url, filter, this.getOptions());
    } else {
      return this.http.post<any[]>(url, this.getOptions());
    }
  }

  addJobNotes(addJobNote: AddJobNote): Observable<any> {
    const data = addJobNote;
    const url = `${this.API_ROOT}/manpowers/job/notes/add`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  resolveJobNote(noteId): Observable<any> {
    const url = `${this.API_ROOT}/manpowers/job/note/resolve/${noteId}`;
    return this.http.post<any[]>(url, this.getOptions());
  }

  flagEmployeeNote(note_id, flagged): Observable<any> {
    const data = { note_id, flagged };
    const url = `${this.API_ROOT}/manpowers/employee/performance/note-flag`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  getJobDetails(data): Observable<any> {
    const url = `${this.API_ROOT}/manpowers/employee/assign-job/details`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  addPushNotification(data): Observable<any> {
    const url = `${this.API_ROOT}/manpowers/push-notifications/send`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  saveManpowerSettings(project_assistants, payroll_managers): Observable<any> {
    const data = { project_assistants, payroll_managers };
    const url = `${this.API_ROOT}/manpowers/setting/add`;
    return this.http.post<any[]>(url, data, this.getOptions());
  }

  getManpowerSettings(): Observable<any> {
    const url = `${this.API_ROOT}/manpowers/setting/get`;
    return this.http.post<any[]>(url, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {},
    };
  }
}
