import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as SynclogActions from './synclog.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { SynclogService } from '../../backend/synclog/synclog.service';
import { SynclogModel } from '../../backend/synclog/model/synclog.model';
import {
  HideSpinner,
  ShowSpinner,
} from '../../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../../error/error.actions';
import { ErrorType } from '../../error/model/error-type';

type showSpinnerTypes = SynclogActions.SynclogsRequest;

const showSpinnerActions = [SynclogActions.SYNC_LOG_REQUEST];

type hideSpinnerTypes = SynclogActions.SynclogsReceive;

const hideSpinnerActions = [SynclogActions.SYNC_LOG_RECEIVE];

@Injectable()
export class SynclogEffects {
  constructor(
    private actions$: Actions,
    private synclogService: SynclogService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnSynclogsRequest$ = this.actions$.pipe(
    ofType<SynclogActions.SynclogsRequest>(SynclogActions.SYNC_LOG_REQUEST),
    throttleTime(2000),
    switchMap(() =>
      from(this.synclogService.getAllSynclogs()).pipe(
        mergeMap((synclogs: SynclogModel[]) => [
          new SynclogActions.SynclogsReceive(synclogs),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
}
