import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AttachmentReceiveListModel } from '../../backend/service-order/model/attachment-receive-list.model';

export interface AttachmentStateInterface
  extends EntityState<AttachmentReceiveListModel> {
  Attachment: AttachmentReceiveListModel;
}

export function AttachmentId(a: AttachmentReceiveListModel): number {
  return a.id;
}

export const adapterAttachment: EntityAdapter<AttachmentReceiveListModel> = createEntityAdapter<AttachmentReceiveListModel>(
  {
    selectId: AttachmentId,
  }
);

export const initAttachmentState: AttachmentStateInterface = adapterAttachment.getInitialState(
  {
    Attachment: null,
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterAttachment.getSelectors();
