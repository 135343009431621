import { combineReducers } from '@ngrx/store';
import { SynclogReducer } from './synclog/synclog.reducer';
import { SettingStateInterface } from './setting.state';
import { RoleReducer } from './role/role.reducer';
import { UserReducer } from './user/user.reducer';
import { ManpowerSettingReducer } from './manpower/settingsmanpower.reducer';
import { PermissionReducer } from './permission/permission.reducer';

export const reducers = combineReducers<SettingStateInterface>({
  synclog: SynclogReducer,
  role: RoleReducer,
  user: UserReducer,
  manpower: ManpowerSettingReducer,
  permission: PermissionReducer,
});
