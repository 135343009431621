import { createSelector } from '@ngrx/store';
import { getToken } from '../auth.selectors';
import { TokenStateInterface } from './token.state';

export const getAuthToken = createSelector(
  getToken,
  (state: TokenStateInterface) => state.token
);

export const getAuthTokenExpiration = createSelector(
  getToken,
  (state: TokenStateInterface) => state.tokenExpiration
);
