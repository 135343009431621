import * as ExpenseCategoryActions from './expense-category.actions';

import {
  adapterExpenseCategory,
  initExpenseCategoryState,
} from './expense-category.state';
import type { ExpenseCategoryStateInterface } from './expense-category.state';

export const ExpenseCategoryReducer = (
  state = initExpenseCategoryState,
  action
): ExpenseCategoryStateInterface => {
  switch (action.type) {
    case ExpenseCategoryActions.EXPENSE_CATEGORIES_REQUEST: {
      const payload = action.payload;

      return adapterExpenseCategory.setAll(payload, state);
    }

    default:
      return state;
  }
};
