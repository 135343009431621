import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PermissionModel } from '../../backend/permission/model/permission.model';

export interface PermissionStateInterface extends EntityState<PermissionModel> {
  // additional entity state properties
  selectedPermissionId: number | null;
  newPermission: PermissionModel;
}

export const adapterPermission: EntityAdapter<PermissionModel> = createEntityAdapter<PermissionModel>();

export const initPermissionState: PermissionStateInterface = adapterPermission.getInitialState(
  {
    selectedPermissionId: null,
    newPermission: null,
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterPermission.getSelectors();
