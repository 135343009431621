import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AuthenticatedUserActions from './authenticated-user.actions';
import {
  catchError,
  filter,
  map,
  mergeMap,
  skip,
  switchMap,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { CustomerService } from '../../backend/customer/customer.service';
import * as ErrorActions from '../../error/error.actions';
import { ErrorType } from '../../error/model/error-type';
import { UserBackendModel } from '../../backend/customer/model/UserBackend.model';
import { Router } from '@angular/router';
import { CDSAppState } from '../../store/store.state';
import { select, Store } from '@ngrx/store';
import { getAuthenticatedUser } from '../auth.selectors';

@Injectable()
export class AuthenticatedUserEffects {
  constructor(
    private actions$: Actions,
    private customerService: CustomerService,
    private router: Router,
    private store: Store<CDSAppState>
  ) {}

  @Effect()
  OnCurrentUserInfoRequest$ = this.actions$.pipe(
    ofType<AuthenticatedUserActions.RequestCurrentUserInfo>(
      AuthenticatedUserActions.CURRENT_USER_INFO_REQUEST
    ),
    skip(1),
    withLatestFrom(this.store.pipe(select(getAuthenticatedUser))),
    filter((data) => !data[1].user),
    throttleTime(2000),
    switchMap(() =>
      from(this.customerService.getUserData()).pipe(
        mergeMap((user: UserBackendModel) => [
          new AuthenticatedUserActions.ReceiveCurrentUserInfo(user),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnDeleteCurrentUserInfoRequest$ = this.actions$.pipe(
    ofType<AuthenticatedUserActions.DeleteCurrentUserInfo>(
      AuthenticatedUserActions.CURRENT_USER_INFO_DELETE
    ),
    map(() => this.router.navigate(['/login']))
  );
}
