import { AlertMessageCommonService } from 'src/app/shared/alert-message-common/alert-message-common.service';
import { Component, OnInit } from '@angular/core';
import { AuthStoreService } from '../../core/auth/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { RouterModule, Routes, ActivatedRoute } from '@angular/router';
import { ofType, Actions } from '@ngrx/effects';
import * as RouterActions from '../../core/router/router.actions';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit {
  new_password: FormControl;
  re_enter_password: FormControl;
  id: string;
  token: string;
  newPasswordError: string;
  reEnterPasswordError: string;

  constructor(
    private authStoreService: AuthStoreService,
    private route: ActivatedRoute,
    private alertMessageCommonService: AlertMessageCommonService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      this.token = params['token'];
    });

    localStorage.removeItem('LOGIN_TOKEN_KEY');
    localStorage.removeItem('LOGIN_TOKEN_EXPIRATION');
    sessionStorage.removeItem('LOGIN_TOKEN_KEY');
    sessionStorage.removeItem('LOGIN_TOKEN_EXPIRATION');
  }

  ngOnInit(): void {
    this.new_password = new FormControl('', {
      validators: [Validators.required],
    });
    this.re_enter_password = new FormControl('', {
      validators: [Validators.required],
    });
  }

  onSubmit() {
    if (this.isValidation()) {
      this.authStoreService.requestResetPassword({
        password: this.new_password.value,
        cpassword: this.re_enter_password.value,
        token: this.token,
      });
    }
  }

  isValidation() {
    this.newPasswordError = '';
    this.reEnterPasswordError = '';

    if (this.new_password.value === '' && this.re_enter_password.value === '') {
      this.newPasswordError = 'error';
      this.reEnterPasswordError = 'error';
      this.alertMessageCommonService.error(
        'error',
        'Password and Re-enter password are required.'
      );
      return false;
    }
    if (this.new_password.value === '') {
      this.newPasswordError = 'error';
      this.alertMessageCommonService.error('error', 'New password required.');
      return false;
    }
    if (this.re_enter_password.value === '') {
      this.reEnterPasswordError = 'error';
      this.alertMessageCommonService.error(
        'error',
        'Re-enter password required.'
      );
      return false;
    } else if (this.new_password.value.length < 6) {
      this.newPasswordError = 'error';
      this.alertMessageCommonService.error(
        'error',
        'Minimum 6 character password are required.'
      );
      return false;
    } else if (this.new_password.value != this.re_enter_password.value) {
      this.newPasswordError = 'error';
      this.reEnterPasswordError = 'error';
      this.alertMessageCommonService.error(
        'error',
        'New password and re-enter password are not match.'
      );
      return false;
    }
    return true;
  }
}
