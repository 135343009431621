import * as PayperiodLockoutActions from './payperiod-lockout.actions';
import {
  initPayPeriodLockoutState,
  PayPeriodLockoutStateInterface,
} from './payperiod-lockout.state';

export function PayperiodLockoutReducer(
  state = initPayPeriodLockoutState,
  action
): PayPeriodLockoutStateInterface {
  switch (action.type) {
    case PayperiodLockoutActions.PAY_PERIOD_LOCKOUT_UPSERT_RECEIVE: {
      const payload = action.payload.data;
      return {
        ...state,
        payPeriodLockout: payload.data,
      };
    }

    case PayperiodLockoutActions.PAY_PERIOD_LOCKOUT_STATUS_RECEIVE: {
      const payload = action.payload.data;
      const fromDateChange = action.payload?.isFromDate;
      if (fromDateChange) {
        return {
          ...state,
          isDateLocked: payload.data,
        };
      } else {
        let data = {
          ...state,
          timeEntryLockStatus: payload.data,
        };
        return data;
      }
    }

    default:
      return state;
  }
}
