<div class="loginbg">
  <div class="login_wrap">
    <div class="login_right">
      <img src="../../assets/banner.png" />
    </div>
    <div class="login_left">
      <div class="login_box_euro">
        <div class="login_box_euro_inner">
          <div class="login_box_euro_oiner">
            <div class="logo text-center">
              <a href="javascript:void(0);">
                <img
                  src="../../assets/cds-logo.png"
                  class=""
                  style="height: 9.375rem; width: 9.375rem"
                />
              </a>
            </div>
            <div class="login_eop_set">
              <h5>Sign in</h5>
            </div>
            <div class="login_box_euro_inner_MX loginSec">
              <form action="" class="inner_pad">
                <div class="form-group mrbtm48">
                  <input
                    class="form-control valid"
                    autocomplete="username"
                    name="username"
                    [formControl]="username"
                    id="username"
                    placeholder="Username"
                    type="text"
                    value=""
                    (keydown.enter)="onLoginSubmit()"
                    [ngClass]="usernameError == 'error' ? 'error' : ''"
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control valid"
                    name="password"
                    [formControl]="password"
                    autocomplete="off"
                    id="password"
                    placeholder="Password"
                    type="password"
                    value=""
                    (keydown.enter)="onLoginSubmit()"
                    [ngClass]="passwordError == 'error' ? 'error' : ''"
                  />
                </div>
                <div class="form-group set_paddw">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="rememberMe"
                      name="rememberMe"
                      [(ngModel)]="rememberMe"
                    />
                    <label class="custom-control-label" for="rememberMe">
                      Remember me
                    </label>
                  </div>
                  <a class="fgt_pass" routerLink="forgot-password">
                    Forgot your password?
                  </a>
                </div>
                <div class="form-group marzero mar10">
                  <input
                    type="button"
                    class="btn_cls"
                    value="Sign in"
                    (click)="onLoginSubmit()"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
