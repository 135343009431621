import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import saveAs from 'file-saver';

import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import type { ExpenseBody } from './model/expense-body';
import type { ExpenseModel } from './model/expense.model';
import type { Observable } from 'rxjs';

@Injectable()
export class ExpenseBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  getToken(): string {
    const storedToken = sessionStorage.getItem('LOGIN_TOKEN_KEY') ?? '';
    return JSON.parse(storedToken);
  }

  fetchAllExpenses(): Observable<HttpResponse<{ data: ExpenseModel[] }>> {
    const url = new URL(`${this.API_ROOT}/expenses`);

    return this.http.post<{ data: ExpenseModel[] }>(
      url.toString(),
      {},
      this.getOptions()
    );
  }

  fetchExpenseById(
    id: number
  ): Observable<HttpResponse<{ data: ExpenseModel }>> {
    const token = this.getToken();

    const url = new URL(`${this.API_ROOT}/expenses/${id}`);

    const headers = this.getOptions().headers;
    headers.append('Authorization', `Bearer ${token}`);

    const options = {
      ...this.getOptions(),
      headers,
    };

    return this.http.get<{ data: ExpenseModel }>(url.toString(), options);
  }

  async postNewExpense(url: string, params: RequestInit): Promise<boolean> {
    if (!url) return;

    const response = await fetch(url, params);

    const res = await response.json();

    return res.status === 'success';
  }

  async removeExpenseById(id: number) {
    const token = this.getToken();

    const url = new URL(`${this.API_ROOT}/expenses/${id}`);

    const headers = this.getOptions().headers;
    headers.append('Authorization', `Bearer ${token}`);

    const options = {
      ...this.getOptions(),
      headers,
    };

    return this.http.delete<{ data: undefined }>(url.toString(), options);
  }

  async downloadExpensePdf(params: ExpenseBody): Promise<void> {
    const token = this.getToken();

    const url = new URL(`${this.API_ROOT}/expenses/downloadPDF`);

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');

    const options = {
      headers,
      method: 'POST',
      body: JSON.stringify(params),
    };

    const request = await fetch(url.toString(), options);

    const pdf = await request.blob();

    const file = new File(
      [pdf],
      `PDF Expenses Download ${moment().format('YYYY-MM-DD')}.pdf`,
      {
        type: 'application/pdf;charset=utf-8',
      }
    );

    return await saveAs(file);
  }

  async downloadExpenseCsv(params: ExpenseBody): Promise<void> {
    const token = this.getToken();

    const url = new URL(`${this.API_ROOT}/expenses/downloadCSV`);

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');

    const options = {
      headers,
      method: 'POST',
      body: JSON.stringify(params),
    };

    const request = await fetch(url.toString(), options);

    const csv = await request.text();

    const file = new File(
      [csv],
      `CSV Expenses Download ${moment().format('YYYY-MM-DD')}.csv`,
      {
        type: 'text/csv;charset=utf-8',
      }
    );

    return await saveAs(file);
  }

  async convertPdfToPng(file: File): Promise<File> {
    const token = this.getToken();

    const url = new URL(`${this.API_ROOT}/media/pdfToImage`);

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);

    const form = new FormData();
    form.append('input_files', file, file.name);

    const options = {
      headers,
      method: 'POST',
      body: form,
    };

    const request = await fetch(url.toString(), options);

    const img = await request.blob();

    const image = new File([img], file.name, {
      type: 'image/png;charset=utf-8',
    });

    return image;
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${new Date().getTimezoneOffset()}`,
      },
    };
  }
}
