import * as BillingRateActions from './billing-rate.actions';
import {
  adapterBillingRate,
  BillingRateStateInterface,
  initBillingRateState,
} from './billing-rate.state';

export function BillingRateReducer(
  state = initBillingRateState,
  action
): BillingRateStateInterface {
  switch (action.type) {
    case BillingRateActions.BILLING_RATE_GET_RECEIVE: {
      const payload = action.payload.data;
      return adapterBillingRate.setAll(payload, state);
    }
    case BillingRateActions.BILLING_RATE_UPDATE_RECEIVE: {
      const payload = action.payload.data[0];
      return adapterBillingRate.updateOne(
        { id: payload.id, changes: payload },
        state
      );
    }
    default:
      return state;
  }
}
