export class UserModel {
  id: number;
  status: string;
  name: string;
  title: string;
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  phone: number;
  role: string;
  role_id: number;
  lastlogin: string;
  lastloginold: string;
  department: string;
  department_id: number;
  note: string;
  unionlocal: string;
  unionlocal_id: number;
  unionnum: string;
  ce_class_id: number;
  employee_num: string;
  vacrules: string;
  profilePic: string;
  union_id: number;
  class: string;
  company: number;
  permissions: string;
}
