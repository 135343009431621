import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as TotalTimeActions from './totalTime.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { TotalTimeService } from '../backend/totalTime/totalTime.service';
import { TotalTimeModel } from '../backend/totalTime/model/totalTime.model';
import { HideSpinner, ShowSpinner } from '../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../error/error.actions';
import { ErrorType } from '../error/model/error-type';

type showSpinnerTypes =
  | TotalTimeActions.TotalTimeRequest
  | TotalTimeActions.TotalTimeAddRequest;

const showSpinnerActions = [
  TotalTimeActions.TOTAL_TIME_REQUEST,
  TotalTimeActions.TOTAL_TIME_ADD_REQUEST,
];

type hideSpinnerTypes =
  | TotalTimeActions.TotalTimeReceive
  | TotalTimeActions.TotalTimeAddReceive;

const hideSpinnerActions = [
  TotalTimeActions.TOTAL_TIME_RECEIVE,
  TotalTimeActions.TOTAL_TIME_ADD_RECEIVE,
];

@Injectable()
export class TotalTimeEffects {
  constructor(
    private actions$: Actions,
    private totalTimeService: TotalTimeService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnTotalTimeRequest$ = this.actions$.pipe(
    ofType<TotalTimeActions.TotalTimeRequest>(
      TotalTimeActions.TOTAL_TIME_REQUEST
    ),
    throttleTime(2000),
    switchMap(() =>
      from(this.totalTimeService.getAllTotalTimeEntry()).pipe(
        mergeMap((totalTime_entry: TotalTimeModel[]) => [
          new TotalTimeActions.TotalTimeReceive(totalTime_entry),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnAddTotalTimeRequest$ = this.actions$.pipe(
    ofType<TotalTimeActions.TotalTimeAddRequest>(
      TotalTimeActions.TOTAL_TIME_ADD_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((totalTimeEntry: TotalTimeModel) =>
      from(this.totalTimeService.TotalTimeEntry(totalTimeEntry)).pipe(
        mergeMap(() => [
          new TotalTimeActions.TotalTimeAddReceive(totalTimeEntry),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
}
