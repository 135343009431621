import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TotalTimeModel } from '../backend/totalTime/model/totalTime.model';

export interface TotalTimeStateInterface extends EntityState<TotalTimeModel> {
  // additional entity state properties
  selectedTimeId: number | null;
  newTime: TotalTimeModel;
}

export const adapterTotalTime: EntityAdapter<TotalTimeModel> = createEntityAdapter<TotalTimeModel>();

export const initTotalTimeState: TotalTimeStateInterface = adapterTotalTime.getInitialState(
  {
    selectedTimeId: null,
    newTime: null,
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterTotalTime.getSelectors();
