import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { map } from 'rxjs/operators';
import { JobBackend } from './job.backend';
import { JobModel } from './model/job.model';
import { Observable } from 'rxjs';

import type { EquipmentType } from '../shared/model/equipment';
import type { FavoriteJobParams } from './model/favoriteJobParams';

@Injectable()
export class JobService extends BaseService {
  constructor(private jobBackend: JobBackend) {
    super();
  }

  getAllJobs(requestObj): Promise<JobModel[] | ErrorMessage> {
    return this.jobBackend
      .fetchAllJobs(requestObj)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  toggleJobFavorite(
    job: JobModel,
    employeeId: number
  ): Promise<any | ErrorMessage> {
    return this.jobBackend
      .fetchToggleJobFavorite(job, employeeId)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getCustomers(obj): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getCustomers(obj)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getCities(obj): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getCities(obj)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }
  getStates(obj): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getStates(obj)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getJobDepartments(obj): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getJobDepartments(obj)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getJobDetailsById(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getJobDetailsById(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getFilterPacks(): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getFilterPacks()
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  updateJobDetails(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .updateJobDetails(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getEmployeeDetailsByJob(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getEmployeeDetailsByJob(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  assignEmployeeToJob(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .assignEmployeeToJob(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getEmployeeDetailsByEmployeeId(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getEmployeeDetailsByEmployeeId(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  deleteEmployeeFromJob(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .deleteEmployeeFromJob(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  saveNotes(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .saveNotes(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getAllNotes(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getAllNotes(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getProjectManagers(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getProjectManagers(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getJobNotesByNoteId(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getJobNotesByNoteId(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  deleteJobNote(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .deleteJobNote(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  updateJobNotes(payload, id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .updateJobNotes(payload, id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getDailyProductions(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getDailyProductions(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getDailyProductionsById(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getDailyProductionsById(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  addDailyProductions(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .addDailyProductions(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  updateDailyProductions(payload, id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .updateDailyProductions(payload, id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getSiteContacts(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getSiteContacts(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getSiteContactById(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getSiteContactById(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  addSiteContact(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .addSiteContact(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  deleteSiteContact(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .deleteSiteContact(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  updateSiteContact(payload, id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .updateSiteContact(payload, id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAllWellLogs(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getAllWellLogs(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getWellLogsDropdowns(): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getWellLogsDropdowns()
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  copyWellLog(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .copyWellLog(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getWellLogDetailsById(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getWellLogDetailsById(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getWellLogSoilDropdown(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getWellLogSoilDropdown(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  createWellLog(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .createWellLog(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  updateWellLog(payload, id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .updateWellLog(payload, id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  uploadFile(formdata): Promise<any | ErrorMessage> {
    return this.jobBackend
      .uploadFile(formdata)
      .pipe(map((response) => response.body?.data))
      .toPromise();
  }

  getFiles(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getFiles(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getFilesById(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getFilesById(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  deleteFile(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .deleteFile(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }
  updateFileUpload(payload, id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .updateFileUpload(payload, id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getAllEquipmentsTimeEntry(postData) {
    return this.jobBackend
      .fetchAllEquipmentTimeEntry(postData)
      .pipe(map((response) => response.body));
  }

  getAllEquipments(postData): Promise<EquipmentType[]> {
    return this.jobBackend
      .getAllEquipments(postData)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  favoriteSingleEquipment(payload: FavoriteJobParams): Promise<unknown> {
    return this.jobBackend
      .favoriteSingleEquipment(payload)
      .pipe(map((res) => res.body.data))
      .toPromise();
  }

  assignEquipmentToJob(payload): Observable<any | ErrorMessage> {
    return this.jobBackend
      .assignEquipmentToJob(payload)
      .pipe(map((response) => response.body));
  }

  addEquipmentTimeEntry(payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .addEquipmentTimeEntry(payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getJobEquipmentTimeByEqupmentId(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getJobEquipmentTimeByEqupmentId(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  updateJobEquipmentTime(id, payload): Promise<any | ErrorMessage> {
    return this.jobBackend
      .updateJobEquipmentTime(id, payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  deleteJobEquipmentTime(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .deleteJobEquipmentTime(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  updateJobBudget(payload, id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .updateJobBudget(payload, id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getJobSummary(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getJobSummary(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  deleteWellLog(id): Promise<any | ErrorMessage> {
    return this.jobBackend
      .deleteWellLog(id)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  getEmployeeFavoriteJobs(
    employeeId: number,
    payload: object
  ): Promise<any | ErrorMessage> {
    return this.jobBackend
      .getEmployeeFavoriteJobs(employeeId, payload)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }
}
