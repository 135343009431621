import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { UserCredential } from '../../auth/model/user-credential.model';
import { UserForgotPassword } from '../../auth/model/user-forgot-password.model';
import { UserForgotPasswordMobile } from '../../auth/model/user-forgot-password-mobile.model';
import { UserResetPassword } from '../../auth/model/user-reset-password.model';
import { Observable } from 'rxjs';
import { AuthenticationToken } from './model/token.model';
import { UserBackendModel } from './model/UserBackend.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CustomerBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  fetchLoginToken(
    userCredential: UserCredential
  ): Observable<HttpResponse<AuthenticationToken>> {
    const url = `${this.API_ROOT}/login`;
    return this.http.post<AuthenticationToken>(
      url,
      userCredential,
      this.getOptions()
    );
  }

  fetchForgotPasswordEntry(
    userForgotPassword: UserForgotPassword
  ): Observable<HttpResponse<UserForgotPassword>> {
    const url = `${this.API_ROOT}/users/forgot_password`;
    return this.http.post<UserForgotPassword>(
      url,
      userForgotPassword,
      this.getOptions()
    );
  }

  fetchForgotPasswordMobileEntry(
    userForgotPasswordMobile: UserForgotPasswordMobile
  ): Observable<HttpResponse<UserForgotPasswordMobile>> {
    const url = `${this.API_ROOT}/users/temporary_password`;
    return this.http.post<UserForgotPasswordMobile>(
      url,
      userForgotPasswordMobile,
      this.getOptions()
    );
  }

  fetchResetPasswordEntry(
    userResetPassword: UserResetPassword
  ): Observable<HttpResponse<UserResetPassword>> {
    const url = `${this.API_ROOT}/users/reset_password`;
    return this.http.put<UserResetPassword>(
      url,
      userResetPassword,
      this.getOptions()
    );
  }

  fetchUserData(): Observable<HttpResponse<UserBackendModel>> {
    const url = `${this.API_ROOT}/authenticated-user`;
    return this.http.get<UserBackendModel>(url, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${new Date().getTimezoneOffset()}`,
      },
    };
  }
}
