import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WorkorderScheduleByemployeeReceiveListModel } from '../../backend/service-order/model/workorder-schedule-byemployee-receive-list.model';

export interface WorkorderScheduleByEmployeeStateInterface
  extends EntityState<WorkorderScheduleByemployeeReceiveListModel> {
  workorderScheduleByEmployee: any;
}

export const adapterWorkorderScheduleByday: EntityAdapter<WorkorderScheduleByemployeeReceiveListModel> = createEntityAdapter<WorkorderScheduleByemployeeReceiveListModel>();

export const initWorkorderScheduleByEmployeeState: WorkorderScheduleByEmployeeStateInterface = adapterWorkorderScheduleByday.getInitialState(
  {
    workorderScheduleByEmployee: null,
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterWorkorderScheduleByday.getSelectors();
