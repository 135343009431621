import { JobLocalModel } from './../backend/time/model/job-local.model';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as TimeActions from './time.actions';
import * as JobsByCompanyActions from './jobs-company/jobs-company.actions';
import * as CostCodeActions from './cost-code/cost-code.actions';
import * as ByEmployeeActions from './by-employee/by-employee.actions';
import { CDSAppState } from '../store/store.state';
import * as TimeSelectors from './time.selectors';
import * as CostCodeSelectors from './cost-code/cost-code.selectors';
import * as JobsByCompanySelectors from './jobs-company/jobs-company.selectors';
import * as ByEmployeeSelectors from './by-employee/by-employee.selectors';

import { TimeModel } from '../backend/time/model/time.model';
import { Observable } from 'rxjs';
import { CostCodeModel } from '../backend/time/model/cost-code.model';
import { Actions, ofType } from '@ngrx/effects';
import { JobModel } from '../backend/job/model/job.model';

@Injectable()
export class TimeStoreService {
  constructor(private store: Store<CDSAppState>, private _actions$: Actions) {}

  public getAllTimeEntry(): Observable<TimeModel[]> {
    return this.store.pipe(
      select((state) => TimeSelectors.getAllTimeEntry(state))
    );
  }

  public getLocals(): Observable<[]> {
    return this.store.pipe(
      select((state) => ByEmployeeSelectors.getLocals(state))
    );
  }

  public getClasses(): Observable<[]> {
    return this.store.pipe(
      select((state) => ByEmployeeSelectors.getClasses(state))
    );
  }

  public getSelectedCostCodes(): Observable<CostCodeModel[]> {
    return this.store.pipe(
      select((state) => CostCodeSelectors.getSelectedCostCodes(state))
    );
  }

  public getJobsByCompany(): Observable<JobModel[]> {
    return this.store.pipe(
      select((state) => JobsByCompanySelectors.getJobsByCompany(state))
    );
  }

  public getDepartments(): Observable<[]> {
    return this.store.pipe(
      select((state) => ByEmployeeSelectors.getDepartments(state))
    );
  }

  public requestCostCodesByDepartment(job_id: number, is_travel: any) {
    this.store.dispatch(
      new CostCodeActions.CostCodeRequest({
        job_id,
        is_travel,
      })
    );
  }

  public requestDepartment(company_number: number) {
    this.store.dispatch(
      new ByEmployeeActions.DepartmentsRequest({
        // department_id,
        // entry_type_id,
        company_number,
      })
    );
  }

  public requestLocals() {
    this.store.dispatch(new ByEmployeeActions.LocalsRequest());
  }

  public requestClasses() {
    this.store.dispatch(new ByEmployeeActions.ClassesRequest());
  }

  public requestLocalByJob(jobLocalModel: JobLocalModel) {
    this.store.dispatch(new TimeActions.LocalByJobRequest(jobLocalModel));
  }

  public requestJobsByCompany(company: number) {
    this.store.dispatch(new JobsByCompanyActions.JobsByCompanyRequest(company));
  }

  public getSelectedLocalByJob(): Observable<any> {
    return this.store.pipe(
      select((state) => TimeSelectors.getLocalByJobs(state))
    );
  }

  public requestTimeEntry(data) {
    this.store.dispatch(new TimeActions.TimeEntryRequest(data));
  }

  public requestAddTimeEntry(timeEntry: TimeModel) {
    this.store.dispatch(new TimeActions.TimeEntryAddRequest(timeEntry));
  }

  public requestEditTimeEntry(timeEntry: TimeModel) {
    this.store.dispatch(new TimeActions.TimeEntryEditRequest(timeEntry));
  }

  public requestDeleteTimeEntry(timeEntryId: string) {
    this.store.dispatch(new TimeActions.TimeEntryDeleteRequest(timeEntryId));
  }

  public requestDeleteTimeEntryContainer(timeEntry: any) {
    this.store.dispatch(
      new TimeActions.TimeEntryContainerDeleteRequest(timeEntry)
    );
  }

  public requestNoteEntry(noteEntry: any) {
    this.store.dispatch(new TimeActions.TimeEntryNoteRequest(noteEntry));
  }

  public requestNoteEntryRemove(noteEntry: any) {
    this.store.dispatch(new TimeActions.TimeEntryNoteRemoveRequest(noteEntry));
  }
}
