import { Action, createAction, props } from '@ngrx/store';
import { UserAccount } from '../../backend/customer/model/user-account.model';
import { createActionType } from '../../../shared/action/utils';

export const REGISTER_REQUEST = createActionType('[Register1] Request');
export const REGISTER_RECEIVE = createActionType('[Register1] Receive');

export class RegisterRequest implements Action {
  readonly type = REGISTER_REQUEST;
  constructor(public payload: UserAccount) {}
}
export class RegisterReceive implements Action {
  readonly type = REGISTER_RECEIVE;
  constructor(public isRegisterSuccess: boolean) {}
}
