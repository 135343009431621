import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CDSAppState } from '../../core/store/store.state';
import * as SpinnerSelector from '../../shared/spinner/spinner.selector';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  spinnerData: Observable<boolean>;
  isSpinnerAvailable = false;

  constructor(
    private store: Store<CDSAppState>,
    private spinner: NgxSpinnerService
  ) {}

  public getSpinnerState(): Observable<boolean> {
    return this.store.pipe(
      select((state) => SpinnerSelector.getSpinner(state))
    );
  }

  manageSpinnerState() {
    this.spinnerData = this.getSpinnerState();
    this.spinnerData.subscribe((data) => {
      if (data) {
        if (!this.isSpinnerAvailable) {
          this.spinner.show();
          this.isSpinnerAvailable = true;
        }
      } else {
        if (this.isSpinnerAvailable) {
          this.spinner.hide();
          this.isSpinnerAvailable = false;
        }
      }
    });
  }
}
