import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerService } from './core/backend/customer/customer.service';
import { Injectable } from '@angular/core';
import { BrowserStorageBackend } from './core/backend/browser-storage/browser-storage.backend';
import { AuthStoreService } from './core/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private browserStorageBackend: BrowserStorageBackend,
    private customerService: CustomerService,
    private router: Router,
    private authService: AuthStoreService,
    private authStoreService: AuthStoreService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let tokenExpiry;

    // TODO implement an API route to check if user is authenticated
    const localStorageTokenExpiry = this.browserStorageBackend.getAuthTokenExpiryFromLocalStorage();
    const sessionStorageTokenExpiry = this.browserStorageBackend.getAuthTokenExpiryFromSessionStorage();

    if (!!localStorageTokenExpiry) {
      tokenExpiry = localStorageTokenExpiry;
    }

    if (!!sessionStorageTokenExpiry) {
      tokenExpiry = sessionStorageTokenExpiry;
    }

    if (tokenExpiry && !!this.customerService.isAuthenticated(tokenExpiry)) {
      this.authService.requestCurrentUserInfo();
      return true;
    }

    this.routeToLoginPage();
    return;
  }

  routeToLoginPage() {
    // to clear userInfo and login token from store then route to login screen
    this.authStoreService.initLogout();
  }
}
