import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WorkorderScheduleReceiveListModel } from '../../backend/service-order/model/workorder-schedule-receive-list.model';

export interface WorkorderScheduleBydayStateInterface
  extends EntityState<WorkorderScheduleReceiveListModel> {
  workorderScheduleByday: any;
}

export const adapterWorkorderScheduleByday: EntityAdapter<WorkorderScheduleReceiveListModel> = createEntityAdapter<WorkorderScheduleReceiveListModel>();

export const initWorkorderScheduleBydayState: WorkorderScheduleBydayStateInterface = adapterWorkorderScheduleByday.getInitialState(
  {
    workorderScheduleByday: null,
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterWorkorderScheduleByday.getSelectors();
