import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as WellLogsActions from './well-logs.actions';
import { CDSAppState } from '../store/store.state';
import * as WellLogsSelectors from './well-logs.selectors';
import { JobModel } from '../backend/job/model/job.model';
import { Observable } from 'rxjs';
import { WellLogsModel } from '../backend/well-logs/model/well-logs.model';

@Injectable()
export class WellLogsStoreService {
  constructor(private store: Store<CDSAppState>) {}

  public getAllWellLogs(): Observable<WellLogsModel[]> {
    return this.store.pipe(
      select((state) => WellLogsSelectors.getAllWellLogs(state))
    );
  }

  public requestWellLogs(requestObj) {
    this.store.dispatch(new WellLogsActions.WellLogsRequest(requestObj));
  }

  public getAllWellLogsNotes(): Observable<any[]> {
    return this.store.pipe(
      select((state) => WellLogsSelectors.getAllWellLogsNotes(state))
    );
  }

  public requestWellLogsNotes(requestObj) {
    this.store.dispatch(new WellLogsActions.NotesRequest(requestObj));
  }
}
