import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoleModel } from '../backend/role/model/role.model';
import { select, Store } from '@ngrx/store';
import * as RoleSelectors from './role/role.selectors';
import * as RoleActions from './role/role.actions';
import { SynclogModel } from '../backend/synclog/model/synclog.model';
import * as SynclogSelectors from './synclog/synclog.selectors';
import * as SynclogActions from './synclog/synclog.actions';
import { UserModel } from '../backend/user/model/user.model';
import * as UserSelectors from './user/user.selectors';
import * as ManpowerSettingSelectors from './manpower/settingsmanpower.selectors';
import * as UserActions from './user/user.actions';
import { CDSAppState } from '../store/store.state';
import { UserEmployeeModel } from '../backend/user/model/userUpdate.model';
import * as SettingsManpowerActions from './manpower/settingsmanpower.actions';
import { PermissionModel } from '../backend/permission/model/permission.model';
import * as PermissionActions from './permission/permission.actions';
import * as PermissionSelectors from './permission/permission.selectors';

@Injectable()
export class SettingStoreService {
  constructor(private store: Store<CDSAppState>) {}

  public getAllRoles(): Observable<RoleModel[]> {
    return this.store.pipe(select((state) => RoleSelectors.getAllRoles(state)));
  }

  public requestRoles() {
    this.store.dispatch(new RoleActions.RolesRequest());
  }

  public getAllSynclogs(): Observable<SynclogModel[]> {
    return this.store.pipe(
      select((state) => SynclogSelectors.getAllSynclogs(state))
    );
  }

  public getManpowerSettings(): Observable<any> {
    return this.store.pipe(
      select((state) => ManpowerSettingSelectors.getManpowerSettings(state))
    );
  }

  public requestSynclogs() {
    this.store.dispatch(new SynclogActions.SynclogsRequest());
  }

  public getAllUsers(): Observable<UserModel[]> {
    return this.store.pipe(select((state) => UserSelectors.getAllUsers(state)));
  }

  public getUsersById(): Observable<UserModel> {
    return this.store.pipe(
      select((state) => UserSelectors.getUsersById(state))
    );
  }

  public requestUsers(getAllOverride = false) {
    this.store.dispatch(new UserActions.UsersRequest({ getAllOverride }));
  }

  public requestUser(id: number) {
    this.store.dispatch(new UserActions.UserRequest({ id }));
  }

  public requestUpdateUser(userModel: UserEmployeeModel) {
    this.store.dispatch(new UserActions.UserUpdateRequest(userModel));
  }

  public requestUpdateProfileUser(userModel: FormData) {
    this.store.dispatch(new UserActions.UserProfileUpdateRequest(userModel));
  }

  public saveManpowerSettings(project_assistants, payroll_managers) {
    this.store.dispatch(
      new SettingsManpowerActions.SaveManpowerSettingsRequest({
        project_assistants,
        payroll_managers,
      })
    );
  }

  public requestGetManpowerSettings() {
    this.store.dispatch(
      new SettingsManpowerActions.GetManpowerSettingsRequest()
    );
  }

  public getAllPermissions(): Observable<PermissionModel[]> {
    return this.store.pipe(
      select((state) => PermissionSelectors.getAllPermissions(state))
    );
  }

  public requestPermissions() {
    this.store.dispatch(new PermissionActions.PermissionsRequest());
  }
  public requestCreatePermission(postData: any) {
    this.store.dispatch(
      new PermissionActions.PermissionCreateRequest(postData)
    );
  }

  public requestGetPermission(postData: any) {
    this.store.dispatch(
      new PermissionActions.PermissionCreateReceive(postData)
    );
  }

  public requestUpdatePermission(postData: any, roleId) {
    this.store.dispatch(
      new PermissionActions.PermissionUpdateRequest({ postData, roleId })
    );
  }
}
