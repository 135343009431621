import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CustomerModule } from './customer/customer.module';
import { throwIfAlreadyLoaded } from '../module-import-guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/authentication.interceptor';
import { JobModule } from './job/job.module';
import { UserModule } from './user/user.module';

import { RoleModule } from './role/role.module';

import { SynclogModule } from './synclog/synclog.module';
import { TimeModule } from './time/time.module';
import { TotalTimeModule } from './totalTime/totalTime.module';
import { ManpowerModule } from './manpower/manpower.module';
import { PayperiodLockoutModule } from './payperiod-lockout/payperiod-lockout.module';
import { ServiceOrderModule } from './service-order/service-order.module';
import { BillingModule } from './billing/billing.module';
import { PermissionModule } from './permission/permission.module';
import { WellLogsModule } from './well-logs/well-logs.module';
import { ExpenseModule } from './expenses/expense.module';
import { ExpenseCategoryModule } from './expense-category/expense-category.module';

@NgModule({
  imports: [
    CustomerModule,
    JobModule,
    UserModule,
    RoleModule,
    SynclogModule,
    TimeModule,
    TotalTimeModule,
    ManpowerModule,
    PayperiodLockoutModule,
    ServiceOrderModule,
    BillingModule,
    PermissionModule,
    WellLogsModule,
    ExpenseModule,
    ExpenseCategoryModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class BackendModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: BackendModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'BackendModule');
  }
}
