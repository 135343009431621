import { NgModule } from '@angular/core';
import { ReceiptCaptureComponent } from './receipt-capture.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReceiptCaptureRoutingModule } from './receipt-capture-routing.module';
import { ReceiptCaptureDetailComponent } from './detail/receipt-capture-detail.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';

@NgModule({
  declarations: [ReceiptCaptureComponent, ReceiptCaptureDetailComponent],
  imports: [
    ReceiptCaptureRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgxMaskModule.forRoot(),
    DataTablesModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDividerModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    CurrencyMaskModule,
  ],
  exports: [],
})
export class ReceiptCaptureModule {}
