import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import type { ExpenseModel } from '../core/backend/expenses/model/expense.model';
import type { ExpenseService } from '../core/backend/expenses/expense.service';
import { JobModel } from '../core/backend/job/model/job.model';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { UserModel } from '../core/backend/user/model/user.model';
import { SettingStoreService } from '../core/setting/setting.service';
import { JobStoreService } from '../core/job/job.service';

type Filter = {
  start_date?: string;
  end_date?: string;
  employee_id?: number;
  job_id?: number;
};

const EMPTY_FILTER_OBJECT: Filter = {
  start_date: undefined,
  end_date: undefined,
  employee_id: null,
  job_id: null,
};

@Component({
  selector: 'app-receipt-capture',
  templateUrl: './receipt-capture.component.html',
  styleUrls: ['./receipt-capture.component.scss'],
})
export class ReceiptCaptureComponent implements OnInit {
  dropdownsData: unknown[] = [];
  entries: ExpenseModel[] = [];
  filteredEntries: ExpenseModel[] = [];

  users$: Observable<UserModel[]>;
  users: UserModel[];
  filteredUsers: UserModel[];

  filters = { ...EMPTY_FILTER_OBJECT };
  start_date: string = '';
  end_date: string = '';

  favoriteJobs: JobModel[] = [];
  filteredJobs: JobModel[] = [];
  filteredFavoriteJobs: JobModel[] = [];
  jobs$: Observable<JobModel[]>;
  jobs: JobModel[] = [];

  jobControl = new FormControl('');
  userControl = new FormControl('');

  loading$: Subject<boolean>;

  constructor(
    private router: Router,
    private expenseService: ExpenseService,
    private settingStoreService: SettingStoreService,
    private jobStoreService: JobStoreService
  ) {}

  ngOnInit(): void {
    this.loading$ = new Subject();
    this.getReceipts();
    this.initJobStoreService();
    this.populateJobDropdown();
    this.initUserStoreService();
    this.populateUserDropdown();
  }

  getPayloadForJobs() {
    const reqObj = {
      customer_id: null,
      department_id: null,
      state: null,
      city: null,
      search: null,
      status: null,
    };

    return reqObj;
  }

  async getReceipts() {
    this.entries = await this.expenseService.fetchAllExpenses();
    this.filteredEntries = this.entries;
  }

  initJobStoreService() {
    this.jobs$ = this.jobStoreService.getAllJobs();
    this.jobStoreService.requestJobs(this.getPayloadForJobs());
  }

  async populateJobDropdown() {
    this.jobs$.subscribe((fetchedJobs) => {
      this.favoriteJobs = fetchedJobs.filter((el) => el.is_job_favorite) ?? [];
      this.filteredFavoriteJobs = this.favoriteJobs;

      this.jobControl.valueChanges.subscribe((value) =>
        this._filter(value, 'jobs')
      );

      this.jobs = fetchedJobs;
      this.filteredJobs = fetchedJobs;
    });
  }

  initUserStoreService() {
    this.users$ = this.settingStoreService.getAllUsers();
    this.settingStoreService.requestUsers(true);
  }

  async populateUserDropdown() {
    this.users$.subscribe((fetchedUsers) => {
      //this.users = users;
      this.users = _.sortBy(fetchedUsers, 'lastname', 'firstname');

      this.userControl.valueChanges.subscribe((value) =>
        this._filter(value, 'employees')
      );

      this.filteredUsers = this.users;
    });
  }

  _filter(value: string, key: 'employees' | 'jobs') {
    if (!key) return;

    if (!value && key === 'jobs') {
      this.filteredFavoriteJobs = this.favoriteJobs;
      this.filteredJobs = this.jobs;
    } else if (key === 'jobs') {
      this.filteredFavoriteJobs =
        this.favoriteJobs.filter(
          (el) =>
            el.name.toLowerCase().includes(value.toLowerCase()) ||
            el.job_number.toString().toLowerCase().includes(value.toLowerCase())
        ) ?? [];
      this.filteredJobs =
        this.jobs.filter(
          (el) =>
            el.name.toLowerCase().includes(value.toLowerCase()) ||
            el.job_number.toString().toLowerCase().includes(value.toLowerCase())
        ) ?? [];
    } else if (!value && key === 'employees') {
      this.filteredUsers = this.users;
    } else if (key === 'employees') {
      this.filteredUsers =
        this.users.filter(
          (el) =>
            el.firstname.toLowerCase().includes(value.toLowerCase()) ||
            el.lastname.toLowerCase().includes(value.toLowerCase())
        ) ?? [];
    }
  }

  getJobLabel(job: JobModel) {
    return `${job.job_number} - ${job.name}`;
  }

  getUserLabel(user: UserModel) {
    return `${user.lastname},  ${user.firstname}`;
  }

  onSelect(key: 'employees' | 'jobs') {
    if (key === 'jobs') {
      this.filteredFavoriteJobs = this.favoriteJobs;
      this.filteredJobs = this.jobs;

      const x = this.jobs.find((el) => {
        const str = this.getJobLabel(el);
        return str.includes(this.jobControl.value);
      });

      if (!x?.id) return;

      this.filters.job_id = x.id;
      this.filterEntries();
    } else if (key === 'employees') {
      this.filteredUsers = this.users;

      const x = this.users.find((el) => {
        const str = this.getUserLabel(el);
        return str.includes(this.userControl.value);
      });

      if (!x?.id) return;

      this.filters.employee_id = x.id;
      this.filterEntries();
    }
  }

  filterByDate(key: 'start_date' | 'end_date', event: InputEvent) {
    if (!event?.target) return;

    const target = event.target as HTMLInputElement;
    this.filters[key] = target.value;

    this.filterEntries();
  }

  searchByKey(key: string, event: InputEvent) {
    if (!event?.target) return;

    const target = event.target as HTMLInputElement;

    this.filters[key] = target.value;

    this.filterEntries();
  }

  // TODO: Make this function more dynamic
  filter(entry: ExpenseModel, _: number) {
    const keep: boolean[] = [];

    if (this.filters['employee_id']) {
      const n = entry['employee_id'] == this.filters['employee_id'];
      keep.push(n);
    }
    if (this.filters['job_id']) {
      const n = entry['job_id'] == this.filters['job_id'];
      keep.push(n);
    }
    if (this.filters['end_date']) {
      const n = moment(entry.receipt_date) <= moment(this.filters['end_date']);
      keep.push(n);
    }
    if (this.filters['start_date']) {
      const n =
        moment(entry.receipt_date) >= moment(this.filters['start_date']);
      keep.push(n);
    }

    if (!keep.length) return false;

    return keep.every((el) => !!el);
  }

  filterEntries() {
    this.filteredEntries = this.entries.filter((el, index) =>
      this.filter(el, index)
    );
  }

  formatCurrency(amt: number) {
    if (typeof amt !== 'number') return '$0.00';

    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amt);
  }

  clearFilters() {
    this.filters = { ...EMPTY_FILTER_OBJECT };

    this.start_date = '';
    this.end_date = '';

    this.userControl.patchValue('');
    this.jobControl.patchValue('');

    this.getReceipts();
  }

  navigateToAdd() {
    this.router.navigate(['receipt-capture', 'detail'], {
      queryParams: { mode: 'add' },
    });
  }

  navigateToEdit(data: ExpenseModel) {
    this.router.navigate(['receipt-capture', 'detail'], {
      // skipLocationChange: false,
      queryParams: {
        id: data.id,
        mode: 'edit',
      },
    });
  }

  getHttpFilters() {
    const params: { end_date?: string; start_date?: string } = {
      ...this.filters,
      end_date: `${this.filters.end_date}`,
      start_date: `${this.filters.start_date}`,
    };

    if (this.filters.start_date) {
      params.start_date = moment(this.filters.start_date).format('YYYY-MM-DD');
    } else {
      delete params.start_date;
    }

    if (this.filters.end_date) {
      params.end_date = moment(this.filters.end_date).format('YYYY-MM-DD');
    } else {
      delete params.end_date;
    }

    return params;
  }

  async downloadPdf() {
    this.loading$.next(true);
    const params = this.getHttpFilters();
    await this.expenseService.downloadExpensePdf(params);

    this.loading$.next(false);
  }

  async downloadCsv() {
    this.loading$.next(true);
    const params = this.getHttpFilters();
    this.expenseService.downloadExpenseCsv(params);

    this.loading$.next(false);
  }
}
