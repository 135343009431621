import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ByEmployeeActions from './by-employee.actions';
import * as ErrorActions from '../../error/error.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { TimeService } from '../../backend/time/time.service';
import {
  HideSpinner,
  ShowSpinner,
} from '../../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import { ErrorType } from '../../error/model/error-type';

type showSpinnerTypes =
  | ByEmployeeActions.ClassesRequest
  | ByEmployeeActions.LocalsRequest;

const showSpinnerActions = [
  ByEmployeeActions.CLASSES_REQUEST,
  ByEmployeeActions.LOCALS_REQUEST,
];

type hideSpinnerTypes =
  | ByEmployeeActions.ClassesReceive
  | ByEmployeeActions.LocalsReceive;

const hideSpinnerActions = [
  ByEmployeeActions.CLASSES_RECEIVE,
  ByEmployeeActions.LOCALS_RECEIVE,
];
@Injectable()
export class ByEmployeeEffects {
  constructor(private actions$: Actions, private timeService: TimeService) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnDepartmentRequest$ = this.actions$.pipe(
    ofType<ByEmployeeActions.DepartmentsRequest>(
      ByEmployeeActions.DEPARTMENT_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap(({ company_number }) =>
      from(this.timeService.getDepartment(company_number)).pipe(
        mergeMap((departments: []) => [
          new ByEmployeeActions.DepartmentsReceive({ departments }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnClassesRequest$ = this.actions$.pipe(
    ofType<ByEmployeeActions.ClassesRequest>(ByEmployeeActions.CLASSES_REQUEST),
    throttleTime(2000),
    switchMap(() =>
      from(this.timeService.getClasses()).pipe(
        mergeMap((classes: []) => [
          new ByEmployeeActions.ClassesReceive({ classes }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnLocalsRequest$ = this.actions$.pipe(
    ofType<ByEmployeeActions.LocalsRequest>(ByEmployeeActions.LOCALS_REQUEST),
    throttleTime(2000),
    switchMap(() =>
      from(this.timeService.getLocals()).pipe(
        mergeMap((locals: []) => [
          new ByEmployeeActions.LocalsReceive({ locals }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
}
