<div class="page_inner_div">
  <div class="land_cnt_main">
    <a class="land_back_btn" routerLink="/instructions">&lt; Back</a>
    <div class="land_cnt_inner">
      <div class="row">
        <div class="col-sm-12">
          <p class="short_msg short_inner_msg">
            You must first allow installation from “Unknown Sources" then
            install the application from this
            <span
              ><a
                [href]="'../../assets/instructions/resources/android/MCM.apk'"
                download=""
                target="_blank"
                class="hdrtitle"
                >download link</a
              ></span
            >. <br />See instructions below.
          </p>
        </div>
      </div>
      <div class="inner_cnt_block">
        <div class="row">
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 1</h2>
              <p>Click <b>OK</b></p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step1.jpg'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 2</h2>
              <p>Click <b>Open</b></p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step2.jpg'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 3</h2>
              <p>Click <b>Settings</b></p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step3.jpg'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 4</h2>
              <p>Click the toggle next to "Allow from this source"</p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step4.jpg'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 5</h2>
              <p>Click the back arrow next to "Install unknown apps"</p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step5.jpg'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 6</h2>
              <p>Click <b>Install</b></p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step6.jpg'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 7</h2>
              <p>Click <b>Open</b></p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step7.jpg'" />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="inner_box">
              <h2>Step 8</h2>
              <p>Success! You're now ready to login and start using the app.</p>
              <div class="inner_img_block">
                <img [src]="'../../assets/instructions/images/Step8.jpg'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
