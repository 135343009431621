import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SynclogModel } from './model/synclog.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class SynclogBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  fetchAllSynclogs(): Observable<HttpResponse<SynclogModel[]>> {
    const url = `${this.API_ROOT}/synclogs`;
    return this.http.get<SynclogModel[]>(url, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${new Date().getTimezoneOffset()}`,
      },
    };
  }
}
