<div class="loginbg">
  <div class="login_wrap">
    <div class="login_right">
      <img src="../../assets/banner.png" />
    </div>
    <div class="login_left">
      <div class="login_box_euro">
        <div class="login_box_euro_inner">
          <div class="login_box_euro_oiner">
            <div class="logo text-center">
              <a href="javascript:void(0);">
                <img src="../../assets/cds-logo.png" class="" />
              </a>
            </div>
            <div class="login_eop_set">
              <h5>Reset your password</h5>
            </div>
            <div class="login_box_euro_inner_MX loginSec">
              <form action="" class="inner_pad">
                <div class="form-group mrbtm48">
                  <input
                    class="form-control valid"
                    name="new-password"
                    [formControl]="new_password"
                    id="new_password"
                    placeholder="New password"
                    type="password"
                    value=""
                    (keydown.enter)="onSubmit()"
                    [ngClass]="newPasswordError == 'error' ? 'error' : ''"
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control valid"
                    name="re-enter-password"
                    [formControl]="re_enter_password"
                    id="re_enter_password"
                    placeholder="Re-enter new password"
                    type="password"
                    value=""
                    (keydown.enter)="onSubmit()"
                    [ngClass]="reEnterPasswordError == 'error' ? 'error' : ''"
                  />
                </div>
                <div class="form-group marzero mar10">
                  <div class="row">
                    <div class="col-sm-6">
                      <input
                        type="button"
                        [routerLink]="'/forgot'"
                        class="btn_cls cancelbtn"
                        value="Cancel"
                      />
                    </div>
                    <div class="col-sm-6">
                      <input
                        type="button"
                        (click)="onSubmit()"
                        class="btn_cls"
                        value="Submit"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
