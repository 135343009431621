import { createSelector } from '@ngrx/store';
import { getWellLogState } from '../store/store.selectors';
import { WellLogsStateInterface, selectAll } from './well-logs.state';

export const getAllWellLogs = createSelector(
  getWellLogState,
  (state: WellLogsStateInterface) => selectAll(state)
);

export const getAllWellLogsNotes = createSelector(
  getWellLogState,
  (state: WellLogsStateInterface) => selectAll(state)
);
