import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as JobsByCompanyActions from './jobs-company.actions';
import * as ErrorActions from '../../error/error.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { TimeService } from '../../backend/time/time.service';
import { JobModel } from '../../backend/job/model/job.model';
import { ErrorType } from '../../error/model/error-type';

@Injectable()
export class JobsByCompanyEffects {
  constructor(private actions$: Actions, private timeService: TimeService) {}

  @Effect()
  OnJobsByCompanyRequest$ = this.actions$.pipe(
    ofType<JobsByCompanyActions.JobsByCompanyRequest>(
      JobsByCompanyActions.JOBS_BY_COMPANY_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((company) =>
      from(this.timeService.getJobsByCompany(company)).pipe(
        mergeMap((jobs: JobModel[]) => [
          new JobsByCompanyActions.JobsByCompanyReceive({ jobs }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
}
