import * as UserActions from './user.actions';
import { adapterUser, initUserState, UserStateInterface } from './user.state';

export function UserReducer(state = initUserState, action): UserStateInterface {
  switch (action.type) {
    case UserActions.USERS_RECEIVE: {
      const payload = action.payload;
      return adapterUser.addMany(payload, {
        ...state,
      });
    }

    case UserActions.USER_RECEIVE: {
      const payload = action.payload;

      return {
        ...state,
        user: payload,
      };
    }

    case UserActions.USER_UPDATE_RECEIVE: {
      const payload = action.payload.data;
      return adapterUser.updateOne({ id: payload.id, changes: payload }, state);
    }

    default:
      return state;
  }
}
