import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { UserPermissionsModel } from 'src/app/core/backend/user/model/userPermissions.model';
import { AuthStoreService } from '../../../core/auth/auth.service';
import { UserBackendModel } from '../../../core/backend/customer/model/UserBackend.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  activeTab$: BehaviorSubject<string>;
  currentUser$: Observable<UserBackendModel>;
  private ngUnsubscribe$: Subject<any> = new Subject<any>();
  userRolID: number;
  userPermissions: {};
  viewTime: Boolean;
  viewJobs: Boolean;
  viewWellLogs: Boolean;
  viewUsers: Boolean;
  viewRoles: Boolean;
  viewReceiptCapture: Boolean;

  @Input() pageUrl = '';

  constructor(
    private router: Router,
    private authStoreService: AuthStoreService
  ) {}

  ngOnInit(): void {
    const currentTab = localStorage.getItem('activeTab') || 'jobs/view-all';

    this.activeTab$ = new BehaviorSubject<string>(currentTab);

    this.activeTab$
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((url) => this.router.navigate([`/${url}`]));

    this.currentUser$ = this.authStoreService.getCurrentUserInfo();
    this.currentUser$
      .pipe(
        filter((data) => !!data),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((userData) => {
        this.userRolID = userData.employee_role_id;
        this.userPermissions = JSON.parse(userData.password);
        this.viewTime = this.viewJobs = this.viewWellLogs = this.viewUsers = false;
        for (let i = 0; i < Object.keys(this.userPermissions).length; i++) {
          const id: number = this.userPermissions[i].permission_id;
          const view: 'None' | 'Yes' = this.userPermissions[i]
            .permission_view_option;

          switch (id) {
            case 1:
              if (view !== 'None') {
                this.viewTime = true;
              }
              break;
            case 4:
              if (view !== 'None') {
                this.viewJobs = true;
              }
              break;
            case 16:
              if (view == 'Yes') {
                this.viewWellLogs = true;
              }
              break;
            case 18:
              if (view == 'Yes') {
                this.viewUsers = true;
              }
              break;
            case 20:
              if (view != 'None') {
                this.viewReceiptCapture = true;
              }
              break;
          }
        }
      });
  }

  onNavChange(navUrl: string) {
    localStorage.setItem('activeTab', navUrl);

    this.activeTab$.next(navUrl);
  }

  onLogout() {
    this.authStoreService.initLogout();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
