import * as JobsByCompanyActions from './jobs-company.actions';
import {
  initJobsByCompanyState,
  JobsByCompanyInterface,
} from './jobs-company.state';

export function jobsByCompanyReducer(
  state = initJobsByCompanyState,
  action
): JobsByCompanyInterface {
  switch (action.type) {
    case JobsByCompanyActions.JOBS_BY_COMPANY_RECEIVE: {
      const payload = action.payload;

      return {
        ...state,
        jobs: payload.jobs,
      };
    }

    default:
      return state;
  }
}
