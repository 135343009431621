import { JobLocalModel } from './../backend/time/model/job-local.model';
import { Action, createAction, props } from '@ngrx/store';
import { TimeModel } from '../backend/time/model/time.model';
import { createActionType } from '../../../app/shared/action/utils';

export const TIME_ENTRY_REQUEST = createActionType('[Time1] Request');
export const TIME_ENTRY_RECEIVE = createActionType('[Time1] Receive');

export const TIME_ENTRY_ADD_REQUEST = createActionType('[Time1] Add Request');
export const TIME_ENTRY_ADD_RECEIVE = createActionType('[Time1] Add Receive');
export const TIME_ENTRY_ADD_RECEIVE_ERROR = createActionType(
  '[Time1] Add Receive Error'
);

export const TIME_ENTRY_NOTE_REQUEST = createActionType('[Time1] Note Request');
export const TIME_ENTRY_NOTE_RECEIVE = createActionType('[Time1] Note Receive');

export const TIME_ENTRY_EDIT_REQUEST = createActionType('[Time1] Edit Request');
export const TIME_ENTRY_EDIT_RECEIVE = createActionType('[Time1] Edit Receive');

export const TIME_ENTRY_DELETE_REQUEST = createActionType(
  '[Time1] Delete Request'
);
export const TIME_ENTRY_DELETE_RECEIVE = createActionType(
  '[Time1] Delete Receive'
);

export const TIME_ENTRY_CONTAINER_DELETE_REQUEST = createActionType(
  '[Time Container1] Delete Request'
);
export const TIME_ENTRY_CONTAINER_DELETE_RECEIVE = createActionType(
  '[Time Container1] Delete Receive'
);

export const LOCAL_BY_JOB_REQUEST = createActionType('[Local By Job1] Request');
export const LOCAL_BY_JOB_RECEIVE = createActionType('[Local By Job1] Receive');

export const TIME_ENTRY_NOTE_REMOVE_REQUEST = createActionType(
  '[Time1] Note Remove Request'
);
export const TIME_ENTRY_NOTE_REMOVE_RECEIVE = createActionType(
  '[Time1] Note Remove Receive'
);

export class TimeEntryRequest implements Action {
  readonly type = TIME_ENTRY_REQUEST;
  constructor(public payload: any) {}
}
export class TimeEntryReceive implements Action {
  readonly type = TIME_ENTRY_RECEIVE;
  constructor(public payload: TimeModel[]) {}
}
export class TimeEntryAddRequest implements Action {
  readonly type = TIME_ENTRY_ADD_REQUEST;
  constructor(public payload: TimeModel) {}
}
export class TimeEntryAddReceive implements Action {
  readonly type = TIME_ENTRY_ADD_RECEIVE;
  constructor(public payload: TimeModel[]) {}
}

export class TimeEntryAddReceiveError implements Action {
  readonly type = TIME_ENTRY_ADD_RECEIVE_ERROR;
  constructor(public payload: any) {}
}

export class TimeEntryEditRequest implements Action {
  readonly type = TIME_ENTRY_EDIT_REQUEST;
  constructor(public payload: TimeModel) {}
}
export class TimeEntryEditReceive implements Action {
  readonly type = TIME_ENTRY_EDIT_RECEIVE;
  constructor(public payload: TimeModel[]) {}
}

export class TimeEntryDeleteRequest implements Action {
  readonly type = TIME_ENTRY_DELETE_REQUEST;
  constructor(public payload: string) {}
}
export class TimeEntryDeleteReceive implements Action {
  readonly type = TIME_ENTRY_DELETE_RECEIVE;
  constructor(public payload: string) {}
}
export class TimeEntryContainerDeleteRequest implements Action {
  readonly type = TIME_ENTRY_CONTAINER_DELETE_REQUEST;
  constructor(public payload: any) {}
}
export class TimeEntryContainerDeleteReceive implements Action {
  readonly type = TIME_ENTRY_CONTAINER_DELETE_RECEIVE;
  constructor(public payload: any) {}
}

export class TimeEntryNoteRequest implements Action {
  readonly type = TIME_ENTRY_NOTE_REQUEST;
  constructor(public payload: any) {}
}
export class TimeEntryNoteReceive implements Action {
  readonly type = TIME_ENTRY_NOTE_RECEIVE;
  constructor(public payload: any) {}
}
export class LocalByJobRequest implements Action {
  readonly type = LOCAL_BY_JOB_REQUEST;
  constructor(public payload: JobLocalModel) {}
}
export class LocalByJobReceive implements Action {
  readonly type = LOCAL_BY_JOB_RECEIVE;
  constructor(public payload: any) {}
}

export class TimeEntryNoteRemoveRequest implements Action {
  readonly type = TIME_ENTRY_NOTE_REMOVE_REQUEST;
  constructor(public payload: any) {}
}
export class TimeEntryNoteRemoveReceive implements Action {
  readonly type = TIME_ENTRY_NOTE_REMOVE_RECEIVE;
  constructor(public payload: string) {}
}
