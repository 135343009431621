<div class="workorder_right_Cls">
  <div class="left_tabs_workorder set_absoluteCommanforboth">
    <ul>
      <li [ngClass]="{ active: isHideDay }">
        <a href="javascript:void(0);" (click)="closeByEmployeeDay()">By Day</a>
      </li>
      <li [ngClass]="{ active: isHideEmployee }">
        <a href="javascript:void(0);" (click)="closeByEmployeeDay()"
          >By Employee</a
        >
      </li>
    </ul>
  </div>

  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>

  <!-- --By Day-- -->
  <!-- <perfect-scrollbar > -->
  <div *ngIf="isHideDay">
    <div class="workorder_right_Cls_top_section">
      <div class="left_tabs_workorder">
        <div class="calender_dateviewSection">
          <a href="javascript:void(0);" (click)="nextPrev(-1)">
            <img src="../../assets/left_arrow_slt.png" />
          </a>
          <a href="javascript:void(0);" (click)="nextPrev(1)">
            <img src="../../assets/right_arrow_slt.png" />
          </a>
          <span>{{ currentDate | date: "EE, MMM d, y" }}</span>
        </div>
      </div>
      <div class="right_tabs_bar">
        <a
          href="javascript:void(0);"
          class="form_brntnxd borbtn"
          data-toggle="modal"
          data-target="#timeoff_modal"
          >Add Time Off</a
        >
        &nbsp;
        <a
          href="javascript:void(0);"
          class="form_brntnxd borbtn"
          (click)="getPOListByDate()"
          >Daily PO Export</a
        >
      </div>
    </div>
    <div class="calender_view_custom">
      <div class="custom-theme-scroll">
        <div class="calender_view_custom_inner">
          <ul>
            <li *ngFor="let workOrder of workorderScheduleByDay">
              <div class="Inner_column_set">
                <div class="head_view">
                  <h3>{{ workOrder.employeeName }}</h3>
                </div>
                <div class="grid_body_event_Main custom-scroll-y">
                  <!-- <perfect-scrollbar> -->
                  <div *ngFor="let wo of workOrder.scheduledEntries">
                    <div
                      *ngIf="!wo.is_time_off"
                      class="box_event_view_lt"
                      data-toggle="modal"
                      data-target="#event_serviceorder"
                      [style.background-color]="wo.work_order_type_hex_code"
                      (click)="onEditWorkOrder(wo)"
                    >
                      <h5>{{ wo.ce_jobnum }}</h5>
                      <span>{{ wo.ceJob_customer_name }}</span>
                      <p>{{ wo.ceJob_Description }}</p>
                      <div
                        *ngIf="
                          wo.work_order_status == 2 || wo.work_order_status == 3
                        "
                        class="flag"
                      >
                        <svg
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            *ngIf="wo.work_order_type_hex_code == '#c0d8fc'"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.72218 14H12.6184C13.439 14 14.1042 13.3284 14.1042 12.5V1.5C14.1042 0.671562 13.439 0 12.6184 0H1.72218C0.901555 0 0.236328 0.671562 0.236328 1.5V12.5C0.236328 13.3284 0.901555 14 1.72218 14ZM11.9796 5.18642L6.28385 10.9364C6.09041 11.1317 5.77684 11.1317 5.5834 10.9364L2.36407 7.68639C2.17066 7.49114 2.17066 7.17454 2.36407 6.97929L3.06449 6.2722C3.25793 6.07695 3.57153 6.07695 3.76494 6.2722L5.93362 8.46154L10.5787 3.77223C10.7722 3.57695 11.0858 3.57698 11.2792 3.77223L11.9796 4.47932C12.173 4.67457 12.173 4.99117 11.9796 5.18642Z"
                            fill="#3077c5"
                          />

                          <path
                            *ngIf="wo.work_order_type_hex_code == '#ff9b9b'"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.72218 14H12.6184C13.439 14 14.1042 13.3284 14.1042 12.5V1.5C14.1042 0.671562 13.439 0 12.6184 0H1.72218C0.901555 0 0.236328 0.671562 0.236328 1.5V12.5C0.236328 13.3284 0.901555 14 1.72218 14ZM11.9796 5.18642L6.28385 10.9364C6.09041 11.1317 5.77684 11.1317 5.5834 10.9364L2.36407 7.68639C2.17066 7.49114 2.17066 7.17454 2.36407 6.97929L3.06449 6.2722C3.25793 6.07695 3.57153 6.07695 3.76494 6.2722L5.93362 8.46154L10.5787 3.77223C10.7722 3.57695 11.0858 3.57698 11.2792 3.77223L11.9796 4.47932C12.173 4.67457 12.173 4.99117 11.9796 5.18642Z"
                            fill="#ff5e00"
                          />

                          <path
                            *ngIf="wo.work_order_type_hex_code == '#ffceb2'"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.72218 14H12.6184C13.439 14 14.1042 13.3284 14.1042 12.5V1.5C14.1042 0.671562 13.439 0 12.6184 0H1.72218C0.901555 0 0.236328 0.671562 0.236328 1.5V12.5C0.236328 13.3284 0.901555 14 1.72218 14ZM11.9796 5.18642L6.28385 10.9364C6.09041 11.1317 5.77684 11.1317 5.5834 10.9364L2.36407 7.68639C2.17066 7.49114 2.17066 7.17454 2.36407 6.97929L3.06449 6.2722C3.25793 6.07695 3.57153 6.07695 3.76494 6.2722L5.93362 8.46154L10.5787 3.77223C10.7722 3.57695 11.0858 3.57698 11.2792 3.77223L11.9796 4.47932C12.173 4.67457 12.173 4.99117 11.9796 5.18642Z"
                            fill="#ff5e00"
                          />

                          <path
                            *ngIf="wo.work_order_type_hex_code == '#bcf2d6'"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.72218 14H12.6184C13.439 14 14.1042 13.3284 14.1042 12.5V1.5C14.1042 0.671562 13.439 0 12.6184 0H1.72218C0.901555 0 0.236328 0.671562 0.236328 1.5V12.5C0.236328 13.3284 0.901555 14 1.72218 14ZM11.9796 5.18642L6.28385 10.9364C6.09041 11.1317 5.77684 11.1317 5.5834 10.9364L2.36407 7.68639C2.17066 7.49114 2.17066 7.17454 2.36407 6.97929L3.06449 6.2722C3.25793 6.07695 3.57153 6.07695 3.76494 6.2722L5.93362 8.46154L10.5787 3.77223C10.7722 3.57695 11.0858 3.57698 11.2792 3.77223L11.9796 4.47932C12.173 4.67457 12.173 4.99117 11.9796 5.18642Z"
                            fill="#1ED175"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      *ngIf="wo.is_time_off"
                      class="box_event_view_lt darkgreyticket"
                      data-toggle="modal"
                      data-target="#event_serviceorder"
                    >
                      <h5>Unavailable</h5>
                      <p>{{ wo.time_off_note }}</p>
                      <div class="flag">
                        <svg
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            *ngIf="wo.work_order_type_hex_code == '#c0d8fc'"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.72218 14H12.6184C13.439 14 14.1042 13.3284 14.1042 12.5V1.5C14.1042 0.671562 13.439 0 12.6184 0H1.72218C0.901555 0 0.236328 0.671562 0.236328 1.5V12.5C0.236328 13.3284 0.901555 14 1.72218 14ZM11.9796 5.18642L6.28385 10.9364C6.09041 11.1317 5.77684 11.1317 5.5834 10.9364L2.36407 7.68639C2.17066 7.49114 2.17066 7.17454 2.36407 6.97929L3.06449 6.2722C3.25793 6.07695 3.57153 6.07695 3.76494 6.2722L5.93362 8.46154L10.5787 3.77223C10.7722 3.57695 11.0858 3.57698 11.2792 3.77223L11.9796 4.47932C12.173 4.67457 12.173 4.99117 11.9796 5.18642Z"
                            fill="#3077c5"
                          />

                          <path
                            *ngIf="wo.work_order_type_hex_code == '#ff9b9b'"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.72218 14H12.6184C13.439 14 14.1042 13.3284 14.1042 12.5V1.5C14.1042 0.671562 13.439 0 12.6184 0H1.72218C0.901555 0 0.236328 0.671562 0.236328 1.5V12.5C0.236328 13.3284 0.901555 14 1.72218 14ZM11.9796 5.18642L6.28385 10.9364C6.09041 11.1317 5.77684 11.1317 5.5834 10.9364L2.36407 7.68639C2.17066 7.49114 2.17066 7.17454 2.36407 6.97929L3.06449 6.2722C3.25793 6.07695 3.57153 6.07695 3.76494 6.2722L5.93362 8.46154L10.5787 3.77223C10.7722 3.57695 11.0858 3.57698 11.2792 3.77223L11.9796 4.47932C12.173 4.67457 12.173 4.99117 11.9796 5.18642Z"
                            fill="#ff5e00"
                          />

                          <path
                            *ngIf="wo.work_order_type_hex_code == '#bcf2d6'"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.72218 14H12.6184C13.439 14 14.1042 13.3284 14.1042 12.5V1.5C14.1042 0.671562 13.439 0 12.6184 0H1.72218C0.901555 0 0.236328 0.671562 0.236328 1.5V12.5C0.236328 13.3284 0.901555 14 1.72218 14ZM11.9796 5.18642L6.28385 10.9364C6.09041 11.1317 5.77684 11.1317 5.5834 10.9364L2.36407 7.68639C2.17066 7.49114 2.17066 7.17454 2.36407 6.97929L3.06449 6.2722C3.25793 6.07695 3.57153 6.07695 3.76494 6.2722L5.93362 8.46154L10.5787 3.77223C10.7722 3.57695 11.0858 3.57698 11.2792 3.77223L11.9796 4.47932C12.173 4.67457 12.173 4.99117 11.9796 5.18642Z"
                            fill="#1ED175"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <!-- </perfect-scrollbar> -->
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- </perfect-scrollbar> -->

  <!-- --By Employee-- -->
  <!-- <perfect-scrollbar> -->
  <div class="employee_set_inner_content_part" *ngIf="isHideEmployee">
    <div class="workorder_right_Cls_top_section">
      <div class="left_tabs_workorder">
        <div class="drop_employeefilter">
          <select
            class="custom-select form-control"
            [(ngModel)]="sortby"
            (change)="getByEmployeeWorkorder()"
          >
            <option value="asc">Sort by Name (A-Z)</option>
            <option value="desc">Sort by Name (Z-A)</option>
          </select>
        </div>
        <div class="drop_employeefilter">
          <input
            type="text"
            class="form-control"
            value="Tony"
            [(ngModel)]="employee_name"
            (input)="searchByname()"
          />
        </div>
        <div class="for_expand_all_chkck">
          <label class="checkbox checkbox-lg checkbox-square">
            <input
              type="checkbox"
              name=""
              (click)="expandAll()"
              [checked]="expandAllCkb"
            />
            <span></span>
            <div class="count_shtmtnl">Expand All</div>
          </label>
        </div>
      </div>
    </div>
    <div class="inner_collpasible_view_service">
      <div class="collpasible_view_serviceDX custom-scroll-y">
        <div id="accordion" role="tablist">
          <span
            *ngFor="
              let scheduleEmp of workorderScheduleByemployee;
              let i = index
            "
          >
            <div class="card" *ngIf="scheduleEmp.workorderlists.length > 0">
              <div class="card-header" role="tab" id="headingOne">
                <h5 class="mb-0">
                  <a
                    data-toggle="collapse"
                    href="#collapseOne{{ i }}"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    (click)="expandedIs(i)"
                    [class.closeA]="isExpanded == i || expanded == true"
                    [class.openA]="isExpanded != i"
                  ></a>
                  <div class="serviceTitlecollp">
                    <span>{{ scheduleEmp.employee_name }}</span>
                  </div>
                </h5>
              </div>
              <div
                id="collapseOne{{ i }}"
                class="collapse card_bodySO"
                role="tabpanel"
                aria-labelledby="headingOne"
                data-parent="#accordion"
                [ngClass]="{ show: expanded == true }"
              >
                <div class="card-body">
                  <perfect-scrollbar>
                    <table
                      width="100%"
                      class="table table-head-custom table-checkable nowrap foinnertable loadtablexs"
                    >
                      <thead>
                        <tr>
                          <th
                            [appSort]="scheduleEmp.workorderlists"
                            data-order="desc"
                            data-name="ceJob_jobNumber"
                          >
                            JOB NUMBER
                          </th>
                          <th
                            [appSort]="scheduleEmp.workorderlists"
                            data-order="desc"
                            data-name="ceJob_customer_name"
                          >
                            CUSTOMER NAME
                          </th>
                          <th
                            [appSort]="scheduleEmp.workorderlists"
                            data-order="desc"
                            data-name="ceJob_JobName"
                          >
                            JOB NAME
                          </th>
                          <th
                            [appSort]="scheduleEmp.workorderlists"
                            data-order="desc"
                            data-name="work_date_requested"
                          >
                            DATE REQUESTED
                          </th>
                          <th
                            [appSort]="scheduleEmp.workorderlists"
                            data-order="desc"
                            data-name="work_order_type_name"
                          >
                            TYPE
                          </th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          class="raw-pointer"
                          *ngFor="
                            let element of scheduleEmp.workorderlists;
                            let i = index
                          "
                        >
                          <td
                            (click)="
                              onEditWorkOrder(
                                element,
                                'technician',
                                element.ce_employee_id
                              )
                            "
                            data-toggle="modal"
                            data-target="#event_serviceorder"
                          >
                            {{ element.ceJob_jobNumber }}
                          </td>
                          <td
                            (click)="
                              onEditWorkOrder(
                                element,
                                'technician',
                                element.ce_employee_id
                              )
                            "
                            data-toggle="modal"
                            data-target="#event_serviceorder"
                          >
                            {{ element.ceJob_customer_name }}
                          </td>
                          <td
                            (click)="
                              onEditWorkOrder(
                                element,
                                'technician',
                                element.ce_employee_id
                              )
                            "
                            data-toggle="modal"
                            data-target="#event_serviceorder"
                          >
                            {{ element.ceJob_JobName }}
                          </td>
                          <td
                            (click)="
                              onEditWorkOrder(
                                element,
                                'technician',
                                element.ce_employee_id
                              )
                            "
                            data-toggle="modal"
                            data-target="#event_serviceorder"
                          >
                            {{ element.work_date_requested }}
                          </td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              class="typecsbtn"
                              [style.background-color]="
                                element.work_orderType_color_code
                              "
                              (click)="
                                onEditWorkOrder(
                                  element,
                                  'technician',
                                  element.ce_employee_id
                                )
                              "
                              data-toggle="modal"
                              data-target="#event_serviceorder"
                              >{{ element.work_order_type_name }}</a
                            >
                          </td>
                          <td>
                            <a
                              href="javascript:void(0);"
                              (click)="
                                onEditWorkOrder(
                                  element,
                                  'technician',
                                  element.ce_employee_id
                                )
                              "
                              ><img
                                src="../../assets/edit.png"
                                data-toggle="modal"
                                data-target="#event_serviceorder"
                            /></a>
                            <a
                              href="javascript:void(0);"
                              (click)="onRemoveSchedule(element)"
                              ><img src="../../assets/delete_icon.png"
                            /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- </perfect-scrollbar> -->
</div>
