import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as SaveManpowerSettingsRequestAction from './settingsmanpower.actions';
import * as ErrorActions from '../../error/error.actions';

import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { ManpowerService } from '../../backend/manpower/manpower.service';
import { AlertMessageCommonService } from '../../../shared/alert-message-common/alert-message-common.service';
import {
  HideSpinner,
  ShowSpinner,
} from '../../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import { ErrorType } from '../../error/model/error-type';

type showSpinnerTypes =
  | SaveManpowerSettingsRequestAction.SaveManpowerSettingsRequest
  | SaveManpowerSettingsRequestAction.GetManpowerSettingsRequest;

const showSpinnerActions = [
  SaveManpowerSettingsRequestAction.SAVE_MANPOWER_SETTINGS_REQUEST,
  SaveManpowerSettingsRequestAction.GET_MANPOWER_SETTINGS_REQUEST,
];

type hideSpinnerTypes =
  | SaveManpowerSettingsRequestAction.SaveManpowerSettingsReceive
  | SaveManpowerSettingsRequestAction.GetManpowerSettingsReceive;

const hideSpinnerActions = [
  SaveManpowerSettingsRequestAction.SAVE_MANPOWER_SETTINGS_RECEIVE,
  SaveManpowerSettingsRequestAction.GET_MANPOWER_SETTINGS_RECEIVE,
];
@Injectable()
export class ManpowerSettingsEffects {
  constructor(
    private actions$: Actions,
    private manpower: ManpowerService,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnSaveManpowerSettingsRequest$ = this.actions$.pipe(
    ofType<SaveManpowerSettingsRequestAction.SaveManpowerSettingsRequest>(
      SaveManpowerSettingsRequestAction.SAVE_MANPOWER_SETTINGS_REQUEST
    ),
    throttleTime(2000),
    switchMap((data) =>
      from(
        this.manpower.saveManpowerSettings(
          data.payload.project_assistants,
          data.payload.payroll_managers
        )
      ).pipe(
        mergeMap((res: any) => [
          new SaveManpowerSettingsRequestAction.SaveManpowerSettingsReceive(
            res
          ),
          new SaveManpowerSettingsRequestAction.GetManpowerSettingsRequest(),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnGetManpowerSettingsRequest$ = this.actions$.pipe(
    ofType<SaveManpowerSettingsRequestAction.GetManpowerSettingsRequest>(
      SaveManpowerSettingsRequestAction.GET_MANPOWER_SETTINGS_REQUEST
    ),
    throttleTime(2000),
    switchMap((data) =>
      from(this.manpower.getManpowerSettings()).pipe(
        mergeMap((res: any) => [
          new SaveManpowerSettingsRequestAction.GetManpowerSettingsReceive({
            payroll_managers: res.payroll_managers,
            project_assistants: res.project_assistants,
          }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnSaveManpowerSettingsReceiveAction$ = this.actions$.pipe(
    ofType<SaveManpowerSettingsRequestAction.SaveManpowerSettingsReceive>(
      SaveManpowerSettingsRequestAction.SAVE_MANPOWER_SETTINGS_RECEIVE
    ),
    map((action) => {
      if (action.payload.status && action.payload.status === 'success') {
        this.alertMessageCommonService.success(
          'Success',
          action.payload.message
        );
      } else {
        this.alertMessageCommonService.error('Error', action.payload.message);
      }
    })
  );
}
