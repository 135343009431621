import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { map } from 'rxjs/operators';

import type { ExpenseBackend } from './expense.backend';
import type { ExpenseBody } from './model/expense-body';
import type { ExpenseModel } from './model/expense.model';

@Injectable()
export class ExpenseService extends BaseService {
  constructor(private expenseBackend: ExpenseBackend) {
    super();
  }

  fetchAllExpenses(): Promise<ExpenseModel[]> {
    return this.expenseBackend
      .fetchAllExpenses()
      .pipe(map((res) => res.body.data))
      .toPromise();
  }

  fetchExpenseById(expenseId: number): Promise<ExpenseModel> {
    return this.expenseBackend
      .fetchExpenseById(expenseId)
      .pipe(map((res) => res.body.data))
      .toPromise();
  }

  async postNewExpense(url: string, params: RequestInit = {}) {
    return await this.expenseBackend.postNewExpense(url, params);
  }

  async removeExpenseById(id: number) {
    return await this.expenseBackend.removeExpenseById(id);
  }

  async downloadExpensePdf(params: ExpenseBody = {}) {
    return this.expenseBackend.downloadExpensePdf(params);
  }

  async downloadExpenseCsv(params: ExpenseBody = {}) {
    return this.expenseBackend.downloadExpenseCsv(params);
  }

  async convertPdfToPng(file: File) {
    return await this.expenseBackend.convertPdfToPng(file);
  }
}
