export class SiteInfo {
  name: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city?: string;
  code?: string;
  zipcode?: number;
  phone: number;
  notes: string;
}
