import { createSelector } from '@ngrx/store';
import { getLaborWorkorderState } from '../workorder.selectors';
import {
  LaborWorkorderStateInterface,
  selectAll,
} from '../labor-workorder/labor-workorder.state';

export const getLaborWorkorder = createSelector(
  getLaborWorkorderState,
  (state: LaborWorkorderStateInterface) => selectAll(state)
);
