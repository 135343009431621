<div class="setting_inner_pages">
  <div class="row mobilesetgeneral">
    <div class="col-sm-6 mobilesetgeneral_inner">
      <div class="box_innner_coolP">
        <h6>SMTP</h6>
        <div class="box_innner_coolP_inner">
          <div class="box_inner_body"></div>
        </div>
      </div>
    </div>
  </div>
</div>
