import { Action } from '@ngrx/store';
import { UserCredential } from '../auth/model/user-credential.model';
import { UserResetPassword } from '../auth/model/user-reset-password.model';
import { createActionType } from '../../shared/action/utils';

export const ROUTER_LOGIN_REQUEST = createActionType('[Router1] Login Request');
export const ROUTER_LOGIN_SUCCESS = createActionType('[Router1] Login Success');
export const ROUTER_LOGIN_FAIL = createActionType('[Router1] Login Fail');

export const ROUTER_LOGOUT_REQUEST = createActionType(
  '[Router1] Logout Request'
);
export const RESET_PASSWORD_REQUEST = createActionType(
  '[ResetPassword1] Request'
);
export const RESET_PASSWORD_RECEIVE = createActionType(
  '[ResetPassword1] Receive'
);

export class RouterLoginRequest implements Action {
  readonly type = ROUTER_LOGIN_REQUEST;
  constructor(public payload: UserCredential) {}
}
export class RouterLoginSuccess implements Action {
  readonly type = ROUTER_LOGIN_SUCCESS;
  constructor() {}
}
export class RouterLoginFail implements Action {
  readonly type = ROUTER_LOGIN_FAIL;
  constructor() {}
}
export class RouterLogoutRequest implements Action {
  readonly type = ROUTER_LOGOUT_REQUEST;
  constructor() {}
}
export class ResetPasswordRequest implements Action {
  readonly type = RESET_PASSWORD_REQUEST;
  constructor(public payload: UserResetPassword) {}
}
export class ResetPasswordReceive implements Action {
  readonly type = RESET_PASSWORD_RECEIVE;
  constructor(public payload: any) {}
}
