import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from '../../shared/action/utils';
import { WellLogsModel } from '../backend/well-logs/model/well-logs.model';

export const WELL_LOGS_REQUEST = createActionType('[WellLogs1] Request');
export const WELL_LOGS__RECEIVE = createActionType('[WellLogs1] Receive');

export const NOTES_REQUEST = createActionType('[WellLogsNotes1] Request');
export const NOTES_RECEIVE = createActionType('[WellLogsNotes1] Receive');

export class WellLogsRequest implements Action {
  readonly type = WELL_LOGS_REQUEST;
  constructor(public payload: any[]) {}
}
export class WellLogsReceive implements Action {
  readonly type = WELL_LOGS__RECEIVE;
  constructor(public payload: WellLogsModel[]) {}
}

export class NotesRequest implements Action {
  readonly type = NOTES_REQUEST;
  constructor(public payload: any[]) {}
}
export class NotesReceive implements Action {
  readonly type = NOTES_RECEIVE;
  constructor(public payload: any[]) {}
}
