<header>
  <div class="logo">
    <a>
      <img src="../assets/cds-logo.png" />
    </a>
  </div>
  <div class="head_right">
    <div class="menu">
      <ul>
        <li
          *ngIf="viewJobs"
          [ngClass]="{ active: (activeTab$ | async) === 'jobs/view-all' }"
        >
          <a (click)="onNavChange('jobs/view-all')">JOBS</a>
        </li>
        <li
          *ngIf="viewTime"
          [ngClass]="{ active: (activeTab$ | async) === 'time/by-employee' }"
        >
          <a (click)="onNavChange('time/by-employee')">TIME ENTRY</a>
        </li>

        <li
          *ngIf="viewWellLogs"
          [ngClass]="{ active: (activeTab$ | async) === 'well-logs' }"
        >
          <a (click)="onNavChange('well-logs')">WELL LOGS</a>
        </li>
        <li
          *ngIf="viewReceiptCapture"
          [ngClass]="{ active: (activeTab$ | async) === 'receipt-capture' }"
          class="nav-item"
        >
          <a (click)="onNavChange('receipt-capture')"> RECEIPT CAPTURE </a>
        </li>
        <li
          *ngIf="viewUsers"
          [ngClass]="{ active: (activeTab$ | async) === 'settings/users' }"
        >
          <a (click)="onNavChange('settings/users')">SETTINGS</a>
        </li>
        <li class="rgjh_right sfname">
          <div class="dropdown">
            <button
              type="button"
              class="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
            >
              {{ (currentUser$ | async)?.username }}
              <img src="../../assets/dwn_arrow.png" />
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                href="javascript:void(0);"
                (click)="onNavChange('my-account')"
              >
                My Account
              </a>

              <a class="dropdown-item" (click)="onLogout()">Sign Out</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</header>

<div class="mob_nav">
  <nav class="navbar navbar-light bg-light lighten-4">
    <button
      class="navbar-toggler toggler-example"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent1"
      aria-controls="navbarSupportedContent1"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <img src="../../../../assets/icon.png" height="20px" width="20px" />
    </button>

    <span class="page_title">{{ pageUrl }}</span>

    <div style="float: right">
      <div class="dropdown">
        <button
          type="button"
          class="dropdown-toggle"
          style="background: none; border: none; outline: none"
          data-toggle="dropdown"
        >
          <img
            src="../../../../assets/profile.png"
            height="25px"
            width="25px"
          />
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0);">
            {{ (currentUser$ | async)?.username }}
          </a>
          <a
            class="dropdown-item"
            href="javascript:void(0);"
            (click)="onNavChange('my-account')"
          >
            My Account
          </a>

          <a class="dropdown-item" (click)="onLogout()">Sign Out</a>
        </div>
      </div>
    </div>

    <div class="collapse navbar-collapse bg-light" id="navbarSupportedContent1">
      <ul class="navbar-nav mr-auto bg-light">
        <li
          *ngIf="viewJobs"
          [ngClass]="{ active: (activeTab$ | async) === 'jobs/view-all' }"
          class="nav-item active"
        >
          <a (click)="onNavChange('jobs/view-all')" class="nav-link">
            JOBS
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li
          *ngIf="viewTime"
          [ngClass]="{ active: (activeTab$ | async) === 'time/by-employee' }"
          class="nav-item"
        >
          <a (click)="onNavChange('time/by-employee')" class="nav-link">
            TIME ENTRY
          </a>
        </li>
        <li
          *ngIf="viewWellLogs"
          [ngClass]="{ active: (activeTab$ | async) === 'well-logs' }"
          class="nav-item"
        >
          <a (click)="onNavChange('well-logs')" class="nav-link">WELL LOGS</a>
        </li>
        <li
          *ngIf="viewReceiptCapture"
          [ngClass]="{ active: (activeTab$ | async) === 'receipt-capture' }"
          class="nav-item"
        >
          <a (click)="onNavChange('receipt-capture')" class="nav-link">
            RECEIPT CAPTURE
          </a>
        </li>
        <li
          *ngIf="viewUsers"
          [ngClass]="{ active: (activeTab$ | async) === 'settings/users' }"
          class="nav-item"
        >
          <a (click)="onNavChange('settings/users')" class="nav-link">
            SETTINGS
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
