import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-android-download',
  templateUrl: './android-download.component.html',
  styleUrls: ['./android-download.component.scss'],
})
export class AndroidDownloadComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
