import { ActionReducerMap, combineReducers, MetaReducer } from '@ngrx/store';
import { CDSAppState } from './store.state';
import * as fromAuth from '../auth/auth.reducer';
import { InjectionToken } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as fromError from '../error/error.reducer';
import { routerReducer } from '@ngrx/router-store';
import * as fromJob from '../job/job.reducer';
import * as fromSetting from '../setting/setting.reducer';
import * as fromTime from '../time/time.reducer';
import * as fromTotalTime from '../totalTime/totalTime.reducer';
import * as fromCostCode from '../time/cost-code/cost-code.reducer';
import * as fromJobsByCompany from '../time/jobs-company/jobs-company.reducer';
import * as fromByEmployee from '../time/by-employee/by-employee.reducer';
import * as fromManpower from '../manpower/manpower.reducer';
import * as fromSpinner from '../../shared/spinner/spinner.reducer';
import * as fromPayperiodLockout from '../payperiod-lockout/payperiod-lockout.reducer';
import * as fromWorkOrder from '../workorder/workorder.reducer';
import * as fromBillingRate from '../billing-rate/billing-rate.reducer';
import * as fromWellLogs from '../well-logs/well-logs.reducer';
import * as fromOverlay from '../overlay/overlay.reducer';
import * as fromExpenseCategories from '../expense-category/expense-category.reducer';

export const AppReducers: ActionReducerMap<CDSAppState> = {
  auth: fromAuth.reducers,
  error: fromError.ErrorReducer,
  router: routerReducer,
  job: fromJob.JobReducer,
  setting: fromSetting.reducers,
  time: fromTime.timeReducer,
  costCode: fromCostCode.costCodesReducer,
  totalTime: fromTotalTime.TotalTimeReducer,
  jobsByCompany: fromJobsByCompany.jobsByCompanyReducer,
  byEmployee: fromByEmployee.byEmployeeReducer,
  manpower: fromManpower.manpowerReducer,
  spinner: fromSpinner.spinnerReducer,
  payperiodLockout: fromPayperiodLockout.PayperiodLockoutReducer,
  workorder: fromWorkOrder.workorderReducers,
  billingRate: fromBillingRate.BillingRateReducer,
  wellLogs: fromWellLogs.WellLogsReducer,
  overlay: fromOverlay.OverlayReducer,
  expenseCategories: fromExpenseCategories.ExpenseCategoryReducer,
};

export const APP_REDUCER_TOKEN = new InjectionToken<
  ActionReducerMap<CDSAppState>
>('App Reducers');

// Can be used in the future if we want to sync with local storage
export const metaReducers: MetaReducer<any>[] = !environment.production
  ? []
  : [];
