import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as PayperiodLockoutActions from './payperiod-lockout.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { PayperiodLockoutService } from '../backend/payperiod-lockout/payperiod-lockout.service';
import { PayperiodLockoutModel } from '../backend/payperiod-lockout/model/payperiod-lockout.model';
import { HideSpinner, ShowSpinner } from '../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../error/error.actions';
import { ErrorType } from '../error/model/error-type';
import { AlertMessageCommonService } from '../../shared/alert-message-common/alert-message-common.service';
type showSpinnerTypes = PayperiodLockoutActions.PayPeriodLockoutUpsertRequest;

const showSpinnerActions = [
  PayperiodLockoutActions.PAY_PERIOD_LOCKOUT_UPSERT_REQUEST,
];

type hideSpinnerTypes = PayperiodLockoutActions.PayPeriodLockoutUpsertReceive;

const hideSpinnerActions = [
  PayperiodLockoutActions.PAY_PERIOD_LOCKOUT_UPSERT_RECEIVE,
];

@Injectable()
export class PayperiodLockoutEffects {
  constructor(
    private actions$: Actions,
    private payPeriodLockoutService: PayperiodLockoutService,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnPayPeriodLockoutUpsertRequest$ = this.actions$.pipe(
    ofType<PayperiodLockoutActions.PayPeriodLockoutUpsertRequest>(
      PayperiodLockoutActions.PAY_PERIOD_LOCKOUT_UPSERT_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((payloadData) =>
      from(
        this.payPeriodLockoutService.upsertPayPeriodEntry(payloadData.data)
      ).pipe(
        mergeMap((payPeriod) => [
          new PayperiodLockoutActions.PayPeriodLockoutUpsertReceive({
            data: payPeriod,
            isShowMsg: payloadData.isShowMsg,
          }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnPayPeriodLockoutUpsertReceive$ = this.actions$.pipe(
    ofType<PayperiodLockoutActions.PayPeriodLockoutUpsertReceive>(
      PayperiodLockoutActions.PAY_PERIOD_LOCKOUT_UPSERT_RECEIVE
    ),
    map((action) => {
      if (action.payload.data.status == 'success') {
        // Hide success message when pay period entry added on timeEntry add
        if (action.payload.isShowMsg) {
          this.alertMessageCommonService.success(
            'Success',
            action.payload.data.message
          );
        }
      } else {
        this.alertMessageCommonService.error(
          'Error',
          action.payload.data.message
        );
      }
    })
  );

  @Effect()
  OnPayPeriodLockoutStatusRequest$ = this.actions$.pipe(
    ofType<PayperiodLockoutActions.PayPeriodLockoutStatusRequest>(
      PayperiodLockoutActions.PAY_PERIOD_LOCKOUT_STATUS_REQUEST
    ),
    map((action) => action.payload),
    switchMap((payloadData) =>
      from(
        this.payPeriodLockoutService.getPayPeriodLockStatus(payloadData.data)
      ).pipe(
        mergeMap((lockStatus) => [
          new PayperiodLockoutActions.PayPeriodLockoutStatusReceive({
            data: lockStatus,
            isFromDate: payloadData.isFromDate,
          }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect({ dispatch: false })
  OnPayPeriodLockoutStatusReceive$ = this.actions$.pipe(
    ofType<PayperiodLockoutActions.PayPeriodLockoutStatusReceive>(
      PayperiodLockoutActions.PAY_PERIOD_LOCKOUT_STATUS_RECEIVE
    ),
    map((action) => {
      const isFromDate = action.payload?.isFromDate;
      // showing message only when is_locked is true
      if (action.payload.data.status == 'success') {
        if (isFromDate && action.payload.data.data.is_locked) {
          this.alertMessageCommonService.success(
            'Success',
            action.payload.data.message
          );
        }
      } else {
        this.alertMessageCommonService.error(
          'Error',
          action.payload.data.message
        );
      }
    })
  );
}
