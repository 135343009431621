import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HideSpinner } from '../../shared/spinner/spinner.action';
import * as ErrorActions from './error.actions';
import { map } from 'rxjs/operators';
import { AuthStoreService } from '../auth/auth.service';

type hideSpinnerTypes = ErrorActions.ErrorReceive;

const hideSpinnerActions = [ErrorActions.ERROR_RECEIVE];

@Injectable()
export class ErrorEffects {
  constructor(
    private actions$: Actions,
    private authStoreService: AuthStoreService
  ) {}

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect({ dispatch: false })
  onErrorReceive$ = this.actions$.pipe(
    ofType<ErrorActions.ErrorReceive>(ErrorActions.ERROR_RECEIVE),
    map((action) => {
      if (action.payload.statusCode == 401) {
        // to clear userInfo and login token from store then route to login screen
        this.authStoreService.initLogout();
      }
    })
  );
}
