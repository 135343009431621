import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as WorkorderScheduleActions from '../workorder-schedule/workorder-schedule.actions';
import * as WorkorderScheduleBydayActions from '../workorder-schedule-byday/workorder-schedule-byday.actions';
import * as WorkorderScheduleByEmployeeActions from '../workorder-schedule-byEmployee/workorder-schedule-byEmployee.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import {
  HideSpinner,
  ShowSpinner,
} from '../../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../../error/error.actions';
import { ErrorType } from '../../error/model/error-type';
import { AlertMessageCommonService } from '../../../shared/alert-message-common/alert-message-common.service';
import { ServiceOrderService } from '../../backend/service-order/service-order.service';
import { ExportService } from '../../../shared/export/export.service';
import * as ServiceOrderActions from '../service-order/service-order.actions';

type showSpinnerTypes = WorkorderScheduleActions.WorkorderScheduleGetRequest;

const showSpinnerActions = [
  WorkorderScheduleActions.WORKORDER_SCHEDULE_GET_REQUEST,
];

type hideSpinnerTypes = WorkorderScheduleActions.WorkorderScheduleGetReceive;

const hideSpinnerActions = [
  WorkorderScheduleActions.WORKORDER_SCHEDULE_GET_RECEIVE,
];

@Injectable()
export class WorkorderScheduleEffects {
  constructor(
    private actions$: Actions,
    private serviceOrderService: ServiceOrderService,
    private exportService: ExportService,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnWorkorderScheduleCreateRequest$ = this.actions$.pipe(
    ofType<WorkorderScheduleActions.WorkorderScheduleCreateRequest>(
      WorkorderScheduleActions.WORKORDER_SCHEDULE_CREATE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((payload) =>
      from(this.serviceOrderService.createWorkOrderSchedule(payload)).pipe(
        mergeMap((scheduledList) => [
          new WorkorderScheduleActions.WorkorderScheduleCreateReceive(
            scheduledList
          ),
          // on create wo schedule(if first entry) removing WO entry from store if unscheduled filter applied
          new ServiceOrderActions.WorkorderRemoveOnScheduleAction({
            res: scheduledList,
            from: 'createAction',
          }),
          new WorkorderScheduleBydayActions.WorkorderScheduleBydayGetRequest({
            date_scheduled: this.exportService.formatToYearMonthDay(
              localStorage.getItem('boardSelectedDate')
            ),
          }),
          new WorkorderScheduleByEmployeeActions.WorkorderScheduleByEmployeeGetRequest(
            {}
          ),
          new ServiceOrderActions.ServiceOrderUpdateReceive({
            updatedEntry: scheduledList,
            from: 'afterWOScheduled',
          }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnWorkorderScheduleGetRequest$ = this.actions$.pipe(
    ofType<WorkorderScheduleActions.WorkorderScheduleGetRequest>(
      WorkorderScheduleActions.WORKORDER_SCHEDULE_GET_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((payload) =>
      from(this.serviceOrderService.getWorkOrderSchedule(payload)).pipe(
        mergeMap((scheduledList) => [
          new WorkorderScheduleActions.WorkorderScheduleGetReceive({
            scheduleList: scheduledList,
            isScrolled: payload.isScrolled,
          }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnWorkorderScheduleDeleteRequest$ = this.actions$.pipe(
    ofType<WorkorderScheduleActions.WorkorderScheduleDeleteRequest>(
      WorkorderScheduleActions.WORKORDER_SCHEDULE_DELETE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((payload) =>
      from(this.serviceOrderService.deleteWorkOrderSchedule(payload)).pipe(
        mergeMap((deletedRes) => [
          new WorkorderScheduleActions.WorkorderScheduleDeleteReceive({
            deleteRes: deletedRes,
          }),
          // on delete wo schedule(if last entry) removing WO entry from store if scheduled filter applied
          new ServiceOrderActions.WorkorderRemoveOnScheduleAction({
            res: deletedRes,
            workorderId: payload.work_order_id,
            from: 'deleteAction',
          }),
          new WorkorderScheduleBydayActions.WorkorderScheduleBydayGetRequest({
            date_scheduled: this.exportService.formatToYearMonthDay(
              localStorage.getItem('boardSelectedDate')
            ),
          }),
          new WorkorderScheduleByEmployeeActions.WorkorderScheduleByEmployeeGetRequest(
            {}
          ),
          new ServiceOrderActions.ServiceOrderUpdateReceive({
            updatedEntry: deletedRes,
            workorderId: payload.work_order_id,
            from: 'afterWOScheduledDelete',
          }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
}
