import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from '../../../shared/action/utils';
import { AttachmentReceiveListModel } from '../../backend/service-order/model/attachment-receive-list.model';

export const ATTACHMENT_GET_REQUEST = createActionType(
  '[Attachment] Get Request'
);

export const ATTACHMENT_GET_RECEIVE = createActionType(
  '[Attachment] Get Receive'
);

export class AttachmentGetRequest implements Action {
  readonly type = ATTACHMENT_GET_REQUEST;
  constructor(public payload: any) {}
}
export class AttachmentGetReceive implements Action {
  readonly type = ATTACHMENT_GET_RECEIVE;
  constructor(
    public payload: {
      AttachmentList: AttachmentReceiveListModel;
      isScrolled: boolean;
    }
  ) {}
}
