import { NgModule } from '@angular/core';
import { ServicerderRoutingModule } from './serviceorder-routing.module';
import { ServiceorderComponent } from './serviceorder.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SortDirectiveModule } from '../shared/directives/sort.directive';
import { MultiDatePickerComponent } from './multi-date-picker/multi-date-picker.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleBoardComponent } from './schedule-board/schedule-board.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EditWorkorderComponent } from './edit-workorder/edit-workorder.component';
import { InvoiceComponent } from './edit-workorder/invoice/invoice.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [
    ServiceorderComponent,
    MultiDatePickerComponent,
    ScheduleBoardComponent,
    EditWorkorderComponent,
    InvoiceComponent,
  ],
  imports: [
    ServicerderRoutingModule,
    PerfectScrollbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    SortDirectiveModule,
    NgbModule,
    MatDialogModule,
    NgxSpinnerModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [EditWorkorderComponent],
  providers: [MatDatepickerModule],
})
export class ServiceorderModule {}
