import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as ErrorSelectors from './error.selectors';
import { CDSAppState } from '../store/store.state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorStoreService {
  constructor(private store: Store<CDSAppState>) {}

  public getErrorMessage(): Observable<string> {
    return this.store.pipe(
      select((state) => ErrorSelectors.getLatestErrorMessage(state))
    );
  }

  public getErrorTimeStamp(): Observable<number> {
    return this.store.pipe(
      select((state) => ErrorSelectors.getLatestErrorTimeStamp(state))
    );
  }
}
