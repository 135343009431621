import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as JobActions from './job.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { JobService } from '../backend/job/job.service';
import { JobModel } from '../backend/job/model/job.model';
import { HideSpinner, ShowSpinner } from '../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';
import * as ErrorActions from '../error/error.actions';
import { ErrorType } from '../error/model/error-type';

type showSpinnerTypes =
  | JobActions.JobsRequest
  | JobActions.JobToggleFavoriteRequest;

const showSpinnerActions = [
  JobActions.JOBS_REQUEST,
  JobActions.JOB_TOGGLE_FAVOURITE_REQUEST,
];

type hideSpinnerTypes =
  | JobActions.JobsReceive
  | JobActions.JobToggleFavoriteReceive;

const hideSpinnerActions = [
  JobActions.JOBS_RECEIVE,
  JobActions.JOB_TOGGLE_FAVOURITE_RECEIVE,
];
@Injectable()
export class JobEffects {
  constructor(private actions$: Actions, private jobService: JobService) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnJobsRequest$ = this.actions$.pipe(
    ofType<JobActions.JobsRequest>(JobActions.JOBS_REQUEST),
    map((action) => action.payload),
    // throttleTime(2000),
    switchMap((data) =>
      from(this.jobService.getAllJobs(data)).pipe(
        mergeMap((jobs: JobModel[]) => [new JobActions.JobsReceive(jobs)]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnToggleJobFavorite$ = this.actions$.pipe(
    ofType<JobActions.JobToggleFavoriteRequest>(
      JobActions.JOB_TOGGLE_FAVOURITE_REQUEST
    ),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap((data) =>
      from(this.jobService.toggleJobFavorite(data.job, data.employeeId)).pipe(
        mergeMap(() => [new JobActions.JobToggleFavoriteReceive(data.job)]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnJobDetailsUpdateRequest$ = this.actions$.pipe(
    ofType<JobActions.JobDetailsUpdateRequest>(
      JobActions.JOB_DETAILS_UPDATE_REQUEST
    ),
    map((action) => action.payload),
    switchMap((data, id) =>
      from(this.jobService.updateJobDetails(data)).pipe(
        mergeMap((jobs: JobModel[]) => [
          new JobActions.JobDetailsUpdateReceive(jobs),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  OnEmployeeDetailsAddRequest$ = this.actions$.pipe(
    ofType<JobActions.EmployeeDetailsAddRequest>(
      JobActions.EMPLOYEE_DETAILS_ADD_REQUEST
    ),
    map((action) => action.payload),
    switchMap((data, id) =>
      from(this.jobService.assignEmployeeToJob(data)).pipe(
        mergeMap((jobs: JobModel[]) => [
          new JobActions.EmployeeDetailsAddReceive(jobs),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  OnEmployeeNotesAddRequest$ = this.actions$.pipe(
    ofType<JobActions.EmployeeNotesAddRequest>(
      JobActions.EMPLOYEE_NOTES_ADD_REQUEST
    ),
    map((action) => action.payload),
    switchMap((data, id) =>
      from(this.jobService.saveNotes(data)).pipe(
        mergeMap((jobs: JobModel[]) => [
          new JobActions.EmployeeNotesAddReceive(jobs),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );

  @Effect()
  OnJobsEmployeeNotesRequest$ = this.actions$.pipe(
    ofType<JobActions.JobsEmployeeNotesRequest>(
      JobActions.JOBS_EMPLOYEE_NOTES_REQUEST
    ),
    map((action) => action.payload),
    // throttleTime(2000),
    switchMap((data) =>
      from(this.jobService.getAllNotes(data)).pipe(
        mergeMap((jobs: JobModel[]) => [
          new JobActions.JobsEmployeeNotesReceive(jobs),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
}
