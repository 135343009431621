import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from 'src/app/shared/action/utils';
import { ErrorType } from './model/error-type';

export const ERROR_RECEIVE = createActionType('[Error1] Receive');
export class ErrorReceive implements Action {
  readonly type = ERROR_RECEIVE;
  constructor(
    public payload: { type: ErrorType; message: string; statusCode: number }
  ) {}
}
