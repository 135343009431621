import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from '../../../shared/action/utils';
import { WorkorderScheduleReceiveListModel } from '../../backend/service-order/model/workorder-schedule-receive-list.model';
import { WorkOrderScheduleRequestListModel } from '../../backend/service-order/model/workorder-schedule-request-list.model';

export const WORKORDER_SCHEDULE_BYDAY_GET_REQUEST = createActionType(
  '[WorkorderScheduleByday] Get Request'
);

export const WORKORDER_SCHEDULE_BYDAY_GET_RECEIVE = createActionType(
  '[WorkorderScheduleByday] Get Receive'
);

export const WORKORDER_SCHEDULE_BYDAY_UPDATE_RECEIVE = createActionType(
  '[WorkorderScheduleByday] Update Receive'
);
export class WorkorderScheduleBydayGetRequest implements Action {
  readonly type = WORKORDER_SCHEDULE_BYDAY_GET_REQUEST;
  constructor(public payload: WorkOrderScheduleRequestListModel) {}
}
export class WorkorderScheduleBydayGetReceive implements Action {
  readonly type = WORKORDER_SCHEDULE_BYDAY_GET_RECEIVE;
  constructor(
    public payload: {
      scheduleList: WorkorderScheduleReceiveListModel;
      isScrolled: boolean;
    }
  ) {}
}
export class WorkorderScheduleBydayUpdateReceive implements Action {
  readonly type = WORKORDER_SCHEDULE_BYDAY_UPDATE_RECEIVE;
  constructor(
    public payload: {
      updatedWorkorder: any;
      workOrderScheduleId: number;
    }
  ) {}
}
