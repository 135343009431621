import * as SpinnerActions from './spinner.action';
import { SpinnerInterface, initSpinnerInterfaceState } from './spinner.state';

export function spinnerReducer(
  state = initSpinnerInterfaceState,
  action
): SpinnerInterface {
  switch (action.type) {
    case SpinnerActions.SPINNER_HIDE:
      return {
        ...state,
        showSpinner: false,
      };

    case SpinnerActions.SPINNER_SHOW:
      return {
        ...state,
        showSpinner: true,
      };

    default:
      return state;
  }
}
