export class UserEmployeeModel {
  id: number;
  employee_name: string;
  employee_name_suffix: string;
  employee_first_name: string;
  employee_last_name: string;
  employee_role_id: string;
  phone: number;
  email_address: string;
  username: string;
  status: number;
  ce_class_id: number;
  note: string;
}
