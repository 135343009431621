import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PurchaseOrderReceiveListModel } from '../../backend/service-order/model/purchase-order-receive-list.model';

export interface PurchaseOrderStateInterface
  extends EntityState<PurchaseOrderReceiveListModel> {
  purchaseOrder: PurchaseOrderReceiveListModel;
}

export function purchaseOrderId(a: PurchaseOrderReceiveListModel): number {
  return a.work_order_purchase_order_id;
}

export const adapterPurchaseOrder: EntityAdapter<PurchaseOrderReceiveListModel> = createEntityAdapter<PurchaseOrderReceiveListModel>(
  {
    selectId: purchaseOrderId,
  }
);

export const initPurchaseOrderState: PurchaseOrderStateInterface = adapterPurchaseOrder.getInitialState(
  {
    purchaseOrder: null,
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterPurchaseOrder.getSelectors();
