import { CDSAppState } from './store.state';

export const getAuthState = (state: CDSAppState) => state.auth;
export const getErrorState = (state: CDSAppState) => state.error;
export const getRouterState = (state: CDSAppState) => state.router;
export const getJobState = (state: CDSAppState) => state.job;
export const getSettingState = (state: CDSAppState) => state.setting;
export const getTimeState = (state: CDSAppState) => state.time;
export const getCostCodes = (state: CDSAppState) => state.costCode;
export const getTotalTimeState = (state: CDSAppState) => state.totalTime;
export const getJobsByCompanyNumber = (state: CDSAppState) =>
  state.jobsByCompany;
export const getDepartmentByEntryType = (state: CDSAppState) =>
  state.byEmployee;
export const getManpowerState = (state: CDSAppState) => state.manpower;
export const getSpinnerState = (state: CDSAppState) => state.spinner;
export const getPayPeriodLockState = (state: CDSAppState) =>
  state.payperiodLockout;
export const getWorkorderState = (state: CDSAppState) => state.workorder;
export const getBillingRateState = (state: CDSAppState) => state.billingRate;
export const getWellLogState = (state: CDSAppState) => state.wellLogs;
export const getOverlayState = (state: CDSAppState) => state.overlay;
export const getExpenseCategoryState = (state: CDSAppState) =>
  state.expenseCategories;
