<section class="setting_main_cls">
  <ul id="tabs" class="nav nav-tabs">
    <li class="nav-item">
      <a
        routerLink="/settings/users"
        data-target="#users"
        data-toggle="tab"
        class="nav-link small"
      >
        Users
      </a>
    </li>
    <li class="nav-item">
      <a
        routerLink="/settings/roles"
        data-target="#roles"
        data-toggle="tab"
        class="nav-link small"
      >
        Roles
      </a>
    </li>
  </ul>

  <div id="tabsContent" class="tab-content">
    <router-outlet></router-outlet>
  </div>
</section>
