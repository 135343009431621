import * as ErrorActions from './error.actions';
import { ErrorStateInterface, initErrorState } from './error.state';

export function ErrorReducer(
  state = initErrorState,
  action
): ErrorStateInterface {
  switch (action.type) {
    case ErrorActions.ERROR_RECEIVE: {
      const payload = action.payload;

      return {
        ...state,
        errorType: payload.type,
        errorMessage: payload.message,
        timestamp: Date.now(),
      };
    }

    default:
      return state;
  }
}
