import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { SettingStoreService } from 'src/app/core/setting/setting.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerService } from '../../shared/spinner/spinner.service';
@Component({
  selector: 'app-setting-manpower',
  templateUrl: './setting-manpower.component.html',
  styleUrls: ['./setting-manpower.component.scss'],
})
export class SettingManpowerComponent implements OnInit {
  manpowerSettings$: Observable<[]>;
  ngUnsubscribe$: Subject<any> = new Subject<any>();
  spinnerData: Observable<boolean>;
  emailForm: FormGroup;
  onSubmit = false;

  constructor(
    private settingStoreService: SettingStoreService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private spinnerStoreService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getData();
  }

  getData() {
    // Request for API call
    this.settingStoreService.requestGetManpowerSettings();
    this.spinnerStoreService.manageSpinnerState();

    // Get Value from store
    this.manpowerSettings$ = this.settingStoreService.getManpowerSettings();
    this.manpowerSettings$
      .pipe(
        filter((data) => !!data),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((settings) => {
        if (settings['payload']) {
          let value = settings['payload'];
          let projectManagers =
            value['payroll_managers'] && value['payroll_managers'] != ''
              ? value['payroll_managers'].split(',')
              : [];
          let projectAss =
            value['project_assistants'] && value['project_assistants'] != ''
              ? value['project_assistants'].split(',')
              : [];
          const data = {
            managersEmails: projectManagers,
            assEmails: projectAss,
          };
          this.createForm(data);
        }
      });
  }

  createForm(data?) {
    if (data) {
      this.emailForm = this.formBuilder.group({
        projectAssistants: [data.assEmails, this.commaSepEmail],
        payrollManagers: [data.managersEmails, this.commaSepEmail],
      });
    } else {
      this.emailForm = this.formBuilder.group({
        projectAssistants: [[], this.commaSepEmail],
        payrollManagers: [[], this.commaSepEmail],
      });
    }
  }

  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.map((e) => e.trim());
    const forbidden = emails.some(
      (email) => Validators.email(new FormControl(email)),
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
    );
    return forbidden ? { inValidEmailAddress: { value: control.value } } : null;
  };

  get formControls() {
    return this.emailForm.controls;
  }

  onSave() {
    this.onSubmit = true;
    setTimeout(() => {
      if (this.emailForm.invalid) {
        return;
      }
      let assistants = this.emailForm.value.projectAssistants
        ? this.emailForm.value.projectAssistants.toString()
        : '';
      let managers = this.emailForm.value.payrollManagers
        ? this.emailForm.value.payrollManagers.toString()
        : '';
      this.settingStoreService.saveManpowerSettings(assistants, managers);
    }, 1000);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
