import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserModel } from '../../backend/user/model/user.model';

export interface UserStateInterface extends EntityState<UserModel> {
  // additional entity state properties
  selectedUserId: number | null;
  newUser: UserModel;
  user: any;
}

export const adapterUser: EntityAdapter<UserModel> = createEntityAdapter<UserModel>();

export const initUserState: UserStateInterface = adapterUser.getInitialState({
  selectedUserId: null,
  newUser: null,
  user: null,
});

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterUser.getSelectors();
