import { AlertMessageCommonService } from 'src/app/shared/alert-message-common/alert-message-common.service';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UserModel } from '../../core/backend/user/model/user.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingStoreService } from '../../core/setting/setting.service';
import { ExportService } from '../../shared/export/export.service';
import { DatePipe, formatDate } from '@angular/common';
import { AuthStoreService } from './../../core/auth/auth.service';
import { UserBackendModel } from '../../core/backend/customer/model/UserBackend.model';
import { UserEmployeeModel } from '../../core/backend/user/model/userUpdate.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
declare var jQuery: any;
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { RoleModel } from '../../core/backend/role/model/role.model';
import { OverlayStoreService } from '../../core/overlay/overlay.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class SettingUsersComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = [
    'name',
    'role',
    'phone',
    'email',
    'lastLogin_filtered',
    'status',
  ];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngUnsubscribe$: Subject<any> = new Subject<any>();
  isUserDataTableInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  @Input()
  users$: Observable<UserModel[]>;
  users: UserModel[] = [];
  selectedUser: UserModel;
  userSubmitModel = new UserEmployeeModel();
  currentUser$: Observable<UserBackendModel>;
  userData: UserBackendModel = new UserBackendModel();
  userPermissions: {};
  userId: number;
  editUsers: Boolean;

  roles$: Observable<RoleModel[]>;
  configuredRoles: RoleModel[] = [];
  selectedRole: number;

  mode = 'Edit';
  title = '';
  userForm: FormGroup;
  currentDate = formatDate(new Date(), 'yyyy/MM/dd', 'en');

  constructor(
    private settingStoreService: SettingStoreService,
    private formBuilder: FormBuilder,
    private exportService: ExportService,
    private alertMessageCommonService: AlertMessageCommonService,
    private authStoreService: AuthStoreService,
    private datePipe: DatePipe,
    private spinnerService: SpinnerService,
    private overlayStoreService: OverlayStoreService
  ) {}

  ngOnInit() {
    this.createForm();
    this.currentUser$ = this.authStoreService.getCurrentUserInfo();
    this.currentUser$
      .pipe(
        filter((data) => !!data),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((fetchedData) => {
        this.userData = fetchedData;
        this.userPermissions = JSON.parse(this.userData.password);
        this.editUsers = false;
        for (let i = 0; i < Object.keys(this.userPermissions).length; i++) {
          var id = this.userPermissions[i].permission_id;
          var edit = this.userPermissions[i].permission_edit_option;

          switch (id) {
            case 18:
              if (edit === 'Yes') {
                this.editUsers = true;
              }
              break;
          }
        }
      });

    this.users$ = this.settingStoreService.getAllUsers();
    this.roles$ = this.settingStoreService.getAllRoles();
  }

  toggleUserEditModal(mode, selectedUserId) {
    this.mode = mode;
    this.selectedUser = this.users.find((user) => user.id === selectedUserId);
    this.selectedRole = 0;
    setTimeout(() => {
      this.selectedRole = this.selectedUser.role_id;
    }, 100);

    if (this.selectedUser) {
      this.createForm(this.selectedUser);
    }
  }

  createForm(data = null) {
    if (data != null) {
      this.userForm = this.formBuilder.group({
        title: [data.title, Validators.required],
        firstname: [data.firstname, Validators.required],
        lastname: [data.lastname, Validators.required],
        email: [data.email, [Validators.required, Validators.email]],
        username: [data.username, Validators.required],
        phone: [data.phone, Validators.required],
        role: [data.role, Validators.required],
        status: [data.status, Validators.required],
        class: [data.class],
        lastlogin: [data.lastlogin],
        note: [data.note],
        id: [data.id],
        updated_date: new Date()
          .toISOString()
          .replace(/T/, ' ')
          .replace(/\..+/, ''),
      });
    } else {
      this.userForm = this.formBuilder.group({
        title: ['', Validators.required],
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        username: ['', Validators.required],
        phone: ['', Validators.required],
        role: ['', Validators.required],
        status: ['', Validators.required],
        class: [''],
        lastlogin: [''],
        note: [''],
        id: [''],
      });
    }
  }

  ngAfterViewInit() {
    this.users$
      .pipe(
        filter((data) => !!data && data.length > 0),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((fetchedUsers) => {
        this.users = fetchedUsers;
        let newData = this.addKey(this.users);
        this.dataSource = new MatTableDataSource(newData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.filterPredicate = (data: any, filter: string) => {
          let matchFound = false;
          for (const column of this.displayedColumns) {
            if (column in data) {
              if (data[column]) {
                if (column)
                  matchFound =
                    matchFound ||
                    data[column]
                      .toString()
                      .trim()
                      .toLowerCase()
                      .indexOf(filter.trim().toLowerCase()) !== -1;
              }
            }
          }
          return matchFound;
        };
        this.isUserDataTableInitialized$.next(true);
      });

    this.settingStoreService.requestRoles();

    this.roles$
      .pipe(
        filter((data) => !!data && data.length > 0),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((roles) => {
        this.configuredRoles = roles;
      });

    let x = this.configuredRoles;
    this.settingStoreService.requestUsers();
    this.spinnerService.manageSpinnerState();
  }

  addKey(data) {
    return data.map((item) => ({
      ...item,
      lastLogin_filtered: this.filterDate(item['lastlogin']),
    }));
  }

  filterDate(date) {
    try {
      return this.datePipe.transform(date, 'short');
    } catch (error) {}
  }

  public doFilter = (event) => {
    let value = event.target.value;
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.exportService.closeAllModals();
  }

  updateForm(field, value) {
    this.userForm.get(field).setValue(value);
  }

  downloadCSV() {
    this.exportService.downloadFile(
      this.users,
      'MCM-Users-Export-' + this.currentDate,
      ['name', 'role', 'phone', 'email', 'lastlogin', 'status']
    );
  }

  onSubmit() {
    this.setupModel();
    this.settingStoreService.requestUpdateUser(this.userSubmitModel);
    jQuery('.close').click();
    this.alertMessageCommonService.success('success', 'User details saved.');
  }

  setupModel() {
    this.userSubmitModel = new UserEmployeeModel();
    this.userSubmitModel.id = this.selectedUser.id;
    this.userSubmitModel.status = Number(this.selectedUser.status);
    this.userSubmitModel.employee_name = this.selectedUser.name;
    this.userSubmitModel.employee_first_name = this.selectedUser.firstname;
    this.userSubmitModel.employee_last_name = this.selectedUser.lastname;
    this.userSubmitModel.employee_role_id = this.userForm.value.role;
    this.userSubmitModel.phone = this.selectedUser.phone;
    this.userSubmitModel.email_address = this.selectedUser.email;
    this.userSubmitModel.username = this.userForm.value.username;
    this.userSubmitModel.status = this.userForm.value.status;
    this.userSubmitModel.note = this.userForm.value.note;
    this.userSubmitModel.ce_class_id = this.selectedUser.ce_class_id;
  }

  sendByEmail() {
    this.authStoreService.requestForgotPassword({
      email_address: this.userForm.value.email,
      url: window.location.origin + '/login/',
      isFromUserList: true,
    });
  }

  triggerOverlay(status: string) {
    if (status === 'On') {
      this.overlayStoreService.triggerOverlayOn();
    } else {
      this.overlayStoreService.triggerOverlayOff();
    }
  }
}
