import { combineReducers } from '@ngrx/store';
import { ServiceOrderReducer } from './service-order/service-order.reducer';
import { WorkorderScheduleBydayReducer } from './workorder-schedule-byday/workorder-schedule-byday.reducer';
import { WorkorderScheduleReducer } from './workorder-schedule/workorder-schedule.reducer';
import { LaborWorkorderReducer } from './labor-workorder/labor-workorder.reducer';
import { PurchaseOrderReducer } from './purchase-orders/purchase-orders.reducer';
import { TruckStockReducer } from './truck-stock/truck-stock.reducer';
import { AttachmentReducer } from './attachments/attachments.reducer';
import { WorkorderScheduleByEmployeeReducer } from './workorder-schedule-byEmployee/workorder-schedule-byEmployee.reducer';
import { WorkorderStateInterface } from './workorder.state';

export const workorderReducers = combineReducers<WorkorderStateInterface>({
  serviceOrder: ServiceOrderReducer,
  workorderSchedule: WorkorderScheduleReducer,
  workorderScheduleByday: WorkorderScheduleBydayReducer,
  laborWorkorder: LaborWorkorderReducer,
  purchaseOrder: PurchaseOrderReducer,
  truckStock: TruckStockReducer,
  attachment: AttachmentReducer,
  workorderScheduleByEmployee: WorkorderScheduleByEmployeeReducer,
});
