import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AlertMessageCommonService {
  constructor(private toastr: ToastrService) {}

  success(title, message) {
    this.toastr.success(message, '', {
      timeOut: 5000,
      closeButton: true,
    });
  }

  error(title, message) {
    this.toastr.error(message, '', {
      timeOut: 5000,
      closeButton: true,
    });
  }

  warning(title, message) {
    this.toastr.warning(message, '', {
      timeOut: 5000,
      closeButton: true,
    });
  }

  info(title, message) {
    this.toastr.info(message, '', {
      timeOut: 5000,
      closeButton: true,
    });
  }
}
