import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LaborWorkorderReceiveListModel } from '../../backend/service-order/model/labor-workorder-receive-list.model';

export interface LaborWorkorderStateInterface
  extends EntityState<LaborWorkorderReceiveListModel> {
  laborWorkorder: LaborWorkorderReceiveListModel;
}

export function workOrderLaborId(a: LaborWorkorderReceiveListModel): number {
  return a.work_order_labor_id;
}

export const adapterLaborWorkorder: EntityAdapter<LaborWorkorderReceiveListModel> = createEntityAdapter<LaborWorkorderReceiveListModel>(
  {
    selectId: workOrderLaborId,
  }
);

export const initLaborWorkorderState: LaborWorkorderStateInterface = adapterLaborWorkorder.getInitialState(
  {
    laborWorkorder: null,
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterLaborWorkorder.getSelectors();
