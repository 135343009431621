import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ErrorStoreService } from '../../core/error/error.service';
import { Observable, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AlertMessageCommonService } from '../alert-message-common/alert-message-common.service';

@Component({
  selector: 'app-error-snack-bar',
  styleUrls: ['error-snack-bar.scss'],
  template: ``,
})
export class ErrorSnackBarComponent implements OnInit, OnDestroy {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  private ngUnsubscribe$: Subject<any> = new Subject<any>();

  private errorMessage$: Observable<string>;
  private errorTimestamp$: Observable<number>;

  constructor(
    private errorStoreService: ErrorStoreService,
    private alertMessageCommonService: AlertMessageCommonService
  ) {}

  ngOnInit() {
    this.errorMessage$ = this.errorStoreService.getErrorMessage();
    this.errorTimestamp$ = this.errorStoreService.getErrorTimeStamp();

    this.errorTimestamp$
      .pipe(
        withLatestFrom(this.errorMessage$),
        filter((data) => !!data[1]),
        switchMap((data) => of(data[1])),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((err) => this.openSnackBar(err));
  }

  openSnackBar(errorMessage: string) {
    this.alertMessageCommonService.error('error', errorMessage);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
