import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MyAccountComponent } from './my-account.component';

const authRoutes: Routes = [
  {
    path: '',
    component: MyAccountComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class MyAccountRoutingModule {}
