<section class="setting_main_cls service_order_main_page">
  <div class="workorder_left_Cls">
    <div class="workorder_left_Cls_inner">
      <div class="workorder_left_Cls_inner_top">
        <div class="workorder_left_Cls_title_row">
          <h3>Work Orders</h3>
          <a
            href="javascript:void(0);"
            class="w_o_btn_cls"
            data-toggle="modal"
            data-target="#createworkorder_modal"
            (click)="onCreateWorkorder()"
            >Create Work Order</a
          >
        </div>
        <div class="workorder_checkboxsecion">
          <label class="checkbox checkbox-lg checkbox-square">
            <input
              type="checkbox"
              name=""
              [(ngModel)]="unscheduledCheck"
              (change)="workOrderFilterChange($event, 0)"
            />
            <span></span>
            <div class="count_shtmtnl"><b>Unscheduled</b></div>
          </label>
          <label class="checkbox checkbox-lg checkbox-square">
            <input
              type="checkbox"
              name=""
              [(ngModel)]="ongoingCheck"
              (change)="workOrderFilterChange($event, 1)"
            />
            <span></span>
            <div class="count_shtmtnl"><b>Ongoing</b></div>
          </label>
          <label class="checkbox checkbox-lg checkbox-square">
            <input
              type="checkbox"
              name=""
              [(ngModel)]="completedCheck"
              (change)="workOrderFilterChange($event, 2)"
            />
            <span></span>
            <div class="count_shtmtnl"><b>Completed</b></div>
          </label>
          <label class="checkbox checkbox-lg checkbox-square">
            <input
              type="checkbox"
              name=""
              [(ngModel)]="closedCheck"
              (change)="workOrderFilterChange($event, 3)"
            />
            <span></span>
            <div class="count_shtmtnl"><b>Closed</b></div>
          </label>
        </div>
        <div class="search_order_work_cls">
          <input
            type="text"
            [(ngModel)]="workOrderSearchText"
            (input)="onJobNameNoEnter($event)"
            class="form-control"
            placeholder="Search by name, or order number."
          />
        </div>
      </div>
      <div class="workorder_left_Cls_inner_body scrollaxck">
        <table
          width="100%"
          class="table table-head-custom table-checkable nowrap foinnertable loadtablexs"
        >
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th
                [appSort]="updatedWorkOrders"
                data-order="desc"
                data-name="ceJob_jobNumber"
              >
                Job No
              </th>
              <th
                [appSort]="updatedWorkOrders"
                data-order="desc"
                data-name="ceSite_name"
              >
                Site Name
              </th>
              <th
                [appSort]="updatedWorkOrders"
                data-order="desc"
                data-name="work_order_type_name"
              >
                Type
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody (scroll)="onScrollWorkOrderTable($event)">
            <tr
              *ngFor="let element of updatedWorkOrders; let i = index"
              class="raw-pointer"
            >
              <td
                (click)="onEditWorkOrder(element)"
                data-toggle="modal"
                data-target="#event_serviceorder"
              >
                <div class="icon_st_ic {{ element.filterclass }}">
                  <img
                    *ngIf="
                      element.filterclass == CONSTANTS.FILTER_CLASS.UNSCHEDULED
                    "
                    src="../../assets/unscheduled.svg"
                    class="unscheduled"
                  />
                  <img
                    *ngIf="
                      element.filterclass == CONSTANTS.FILTER_CLASS.ONGOING
                    "
                    src="../../assets/ongoing.svg"
                    class="ongoing"
                  />
                  <img
                    *ngIf="
                      element.filterclass == CONSTANTS.FILTER_CLASS.COMPLETED
                    "
                    src="../../assets/completed.svg"
                    class="completed"
                  />
                  <img
                    *ngIf="element.filterclass == CONSTANTS.FILTER_CLASS.CLOSED"
                    src="../../assets/closed.svg"
                    class="closed"
                  />
                </div>
              </td>
              <td
                (click)="onEditWorkOrder(element)"
                data-toggle="modal"
                data-target="#event_serviceorder"
              >
                {{ element.ceJob_jobNumber }}
              </td>
              <td
                (click)="onEditWorkOrder(element)"
                data-toggle="modal"
                data-target="#event_serviceorder"
              >
                {{ element.ceSite_name }}
              </td>
              <td>
                <a
                  hre="javascript:void(0);"
                  class="typecsbtn {{ element.colorclass }}"
                  (click)="onEditWorkOrder(element)"
                  data-toggle="modal"
                  data-target="#event_serviceorder"
                  >{{ element.work_order_type_name }}</a
                >
              </td>
              <td>
                <a href="javascript:void(0);"
                  ><img
                    src="../assets/edit_lt.svg"
                    (click)="onEditWorkOrder(element)"
                    data-toggle="modal"
                    data-target="#event_serviceorder"
                /></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <app-schedule-board
    (uploaded)="onEditWorkOrder($event, 'scheduledboard')"
  ></app-schedule-board>
</section>

<!-- Modal For Create work order -->
<div
  id="createworkorder_modal"
  class="modal fade createworkorder_service"
  role="dialog"
>
  <div class="modal-dialog modal-dialog-centered">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Create Work Order</h4>
        <div class="forcloseserviceorder">
          <a href="javascript:void(0);" data-dismiss="modal" aria-label="Close"
            ><img src="../../assets/closeRx.png"
          /></a>
        </div>
      </div>
      <div class="modal-body">
        <div class="form_row_set">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group relative-form-group">
                <label>Job Number</label>
                <input
                  type="text"
                  class="form-control cursor"
                  placeholder="Select an option"
                  name="jobName"
                  readonly="true"
                  [(ngModel)]="jobName"
                />
                <div
                  [ngClass]="
                    isJobListShow == true ? 'show custom_drop' : 'hide'
                  "
                  (click)="$event.stopPropagation()"
                >
                  <input
                    #jobSearchTxt
                    type="text"
                    class="form-control searchboxcls cursor"
                    placeholder="Search Jobs..."
                    name="jobId"
                    autocomplete="off"
                    (input)="onJobSearch($event)"
                  />
                  <ul #scrollMe class="joblistcls">
                    <li
                      *ngFor="let job of jobs; index as i"
                      (click)="onJobChange(job)"
                    >
                      <div class="liInsideDiv">
                        <div class="divJob">
                          <div class="jobtitlecls">
                            {{ job.job_number }} {{ job.name }}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary cancel"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary saveRclose"
          (click)="createWorkOrder()"
          data-dismiss="modal"
          [disabled]="selectedJob == ''"
          data-toggle="modal"
          data-target="#event_serviceorder"
        >
          Create Work Order
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal For Add Time Off -->
<div
  id="timeoff_modal"
  class="modal fade createworkorder_service"
  role="dialog"
>
  <div class="modal-dialog modal-dialog-centered">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Schedule Time Off</h4>
        <div class="forcloseserviceorder">
          <a href="javascript:void(0);" data-dismiss="modal" aria-label="Close"
            ><img src="../../assets/closeRx.png"
          /></a>
        </div>
      </div>
      <div class="modal-body">
        <div class="for_inner_setClsQA">
          <div class="form_row_set">
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group innertachmode">
                  <label>Name</label>
                  <select
                    [ngClass]="{
                      'input-error': timeOffformSubmited && scheduleUser == ''
                    }"
                    class="custom-select form-control"
                    [(ngModel)]="scheduleUser"
                    (change)="addEmployeeScheduleEntry()"
                  >
                    <option value="">Select Technician</option>
                    <option
                      *ngFor="let user of users"
                      [value]="user.ce_employee_id"
                    >
                      {{ user.lastname }}, {{ user.firstname }}
                    </option>
                  </select>

                  <span
                    class="error-message"
                    *ngIf="timeOffformSubmited && scheduleUser == ''"
                    >This field is required.</span
                  >
                </div>
              </div>
              <div class="col-sm-4 innertachmode">
                <div
                  class="technician_col"
                  id="addToScheuleDiv"
                  (click)="onAddToSchedule()"
                >
                  <a href="javascript:void(0);">
                    <span><img src="../../assets/add.svg" /></span>
                    <span id="addToScheuleSpn">Add Unavailability</span>
                  </a>
                </div>
                <app-multi-date-picker
                  [style.top]="currentScheduleDivTop"
                  [style.left]="currentScheduleDivRight"
                  *ngIf="isDatePicker"
                  [datesSelected]="datesSelected"
                  (datesSelectedChange)="change($event)"
                ></app-multi-date-picker>
              </div>
            </div>
          </div>
          <div class="add_scheduleTable" *ngIf="scheduleUser != ''">
            <table>
              <thead>
                <tr>
                  <th>schedule</th>
                  <th>description</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let entry of workorderScheduleByEmployee?.scheduledEntries
                  "
                >
                  <td>
                    {{ formatDate(entry.date_scheduled) }}
                  </td>
                  <td>
                    {{ entry.time_off_note }}
                  </td>
                  <td>
                    <div class="setRYQw">
                      <a
                        href="javascript:void(0);"
                        (click)="onRemoveSchedule(entry, entry.date_scheduled)"
                      >
                        <span><img src="../../assets/remove.svg" /> </span>
                        <span> Delete</span>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="textareas_row_set">
          <label>Details</label>
          <textarea [(ngModel)]="time_off_note">Unavailable all day</textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary cancel"
          data-dismiss="modal"
          #closebutton
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary saveRclose"
          [disabled]="scheduledDates.length == 0"
          (click)="createWorkorderSchedule()"
        >
          Save & Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal For Details -->

<app-edit-workorder [fromBillingTab$]="false"></app-edit-workorder>
