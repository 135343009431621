import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { catchError, map, tap } from 'rxjs/operators';
import { PermissionBackend } from './permission.backend';
import { PermissionModel } from './model/permission.model';

@Injectable()
export class PermissionService extends BaseService {
  constructor(private permissionBackend: PermissionBackend) {
    super();
  }

  getAllPermissions(): Promise<PermissionModel[] | ErrorMessage> {
    return this.permissionBackend
      .fetchAllPermissions()
      .pipe(map((response) => response.body))
      .toPromise();
  }

  savePermission(jsonData: any): Promise<any | ErrorMessage> {
    return this.permissionBackend
      .savePermission(jsonData)
      .pipe(map((response) => response.body))
      .toPromise();
  }

  getPermissionsByRoleId(id: any): Promise<any | ErrorMessage> {
    var test = this.permissionBackend
      .fetchPermissionsByRoleId(id)
      .pipe(map((response) => response.body))
      .toPromise();

    var te2 = 1;
    return test;
  }

  updatePermission(postData: any, id): Promise<any | ErrorMessage> {
    return this.permissionBackend
      .updatePermission(postData, id)
      .pipe(map((response) => response.body))
      .toPromise();
  }
  //
}
