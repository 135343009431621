import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BillingRateModel } from '../backend/billing/model/billing-rate.model';

export interface BillingRateStateInterface
  extends EntityState<BillingRateModel> {
  BillingRate: BillingRateModel;
}

export const adapterBillingRate: EntityAdapter<BillingRateModel> = createEntityAdapter<BillingRateModel>();

export const initBillingRateState: BillingRateStateInterface = adapterBillingRate.getInitialState(
  {
    BillingRate: null,
  }
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterBillingRate.getSelectors();
