import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { map } from 'rxjs/operators';

import type { ExpenseCategoryBackend } from './expense-category.backend';
import type { ExpenseCategoryModel } from './model/expense-category.model';

@Injectable()
export class ExpenseCategoryService extends BaseService {
  constructor(private expenseCategoryBackend: ExpenseCategoryBackend) {
    super();
  }

  async getAllCategories(): Promise<ExpenseCategoryModel[]> {
    try {
      return await this.expenseCategoryBackend
        .fetchAllExpenseCategories()
        .pipe(map((r) => r.body.data))
        .toPromise();
    } catch (error) {
      console.error('GET ALL CATEGORIES ERROR:', error);
      return [];
    }
  }
}
