import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from '../../../shared/action/utils';

export const SAVE_MANPOWER_SETTINGS_REQUEST = createActionType(
  '[Save Manpower Settings1] Request'
);
export const SAVE_MANPOWER_SETTINGS_RECEIVE = createActionType(
  '[Save Manpower Settings1] Receive'
);

export const GET_MANPOWER_SETTINGS_REQUEST = createActionType(
  '[Get Manpower Settings1] Request'
);
export const GET_MANPOWER_SETTINGS_RECEIVE = createActionType(
  '[Get Manpower Settings1] Receive'
);

export class SaveManpowerSettingsRequest implements Action {
  readonly type = SAVE_MANPOWER_SETTINGS_REQUEST;
  constructor(
    public payload: {
      project_assistants: Array<string>;
      payroll_managers: Array<string>;
    }
  ) {}
}
export class SaveManpowerSettingsReceive implements Action {
  readonly type = SAVE_MANPOWER_SETTINGS_RECEIVE;
  constructor(public payload: any) {}
}

export class GetManpowerSettingsRequest implements Action {
  readonly type = GET_MANPOWER_SETTINGS_REQUEST;
  constructor() {}
}
export class GetManpowerSettingsReceive implements Action {
  readonly type = GET_MANPOWER_SETTINGS_RECEIVE;
  constructor(
    public payload: {
      project_assistants: Array<string>;
      payroll_managers: Array<string>;
    }
  ) {}
}
