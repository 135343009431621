import { Injectable } from '@angular/core';
import { TimeBackend } from 'src/app/core/backend/time/time.backend';
import Swal from 'sweetalert2';
import { TimeStoreService } from '../../core/time/time.service';
import { AlertMessageCommonService } from '../alert-message-common/alert-message-common.service';

@Injectable({ providedIn: 'root' })
export class sweetAlertService {
  constructor(
    private timeStoreService: TimeStoreService,
    private alertService: AlertMessageCommonService,
    private timeBackendService: TimeBackend
  ) {}

  deleteEntry(timeEntryId: string, weekStart: string, weekEnd: string, jobId) {
    Swal.fire({
      title: 'Delete Time Entry?',
      text: 'Are you sure you want to delete this time entry?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        this.timeStoreService.requestDeleteTimeEntry(timeEntryId);

        this.timeStoreService.requestTimeEntry(
          this.getStartEndDateObj(weekStart, weekEnd, jobId)
        );
        this.alertService.success(
          'success',
          'Time Entry deleted successfully.'
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  getStartEndDateObj(weekstart, weekend, jobid) {
    const dateObj = {
      start_date: weekstart,
      end_date: weekend,
      search: '',
      department_id: '',
      job_id: jobid ? jobid : '',
    };
    return dateObj;
  }

  deleteEntryContainer(
    employeeId: string,
    weekStart: string,
    weekEnd: string,
    timeIds?: string[]
  ) {
    Swal.fire({
      title: 'Delete All Time Entries?',
      text: 'Are you sure you want to delete the entire week of entries?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        this.timeStoreService.requestDeleteTimeEntryContainer({
          user_id: employeeId,
          start_date: weekStart,
          end_date: weekEnd,
          timeEntryIds: timeIds,
        });
        this.alertService.success(
          'success',
          'Time Entry deleted successfully.'
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  addEntry(timeEntryId?: any, notes?: any) {
    Swal.fire({
      title: 'Edit Note Entry',
      input: 'text',
      inputValue: notes ? notes : '',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Save',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        this.timeStoreService.requestNoteEntry({
          timeEntryId,
          notes: result.value,
        });
        this.alertService.success('success', 'Saved!');
      }
    });
  }

  saveEntry() {
    Swal.fire({
      title: 'Entry Successfully Saved',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  invalidForm() {
    Swal.fire({
      title: 'Please fill all required fields',
      icon: 'error',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  addAnother() {
    Swal.fire({
      title: 'Entry Successfully Saved',
      text: 'Add Another Entry!',
      icon: 'success',
      showConfirmButton: false,
      timer: 2100,
    });
  }

  common(title, message, icon) {
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
      showConfirmButton: false,
      timer: 2100,
    });
  }
}
