import { Action, createAction, props } from '@ngrx/store';
import { UserBackendModel } from '../../backend/customer/model/UserBackend.model';
import { createActionType } from '../../../shared/action/utils';

export const CURRENT_USER_INFO_REQUEST = createActionType(
  '[Auth1] Current User Info Request'
);
export const CURRENT_USER_INFO_RECEIVE = createActionType(
  '[Auth1] Current User Info Receive'
);
export const CURRENT_USER_INFO_DELETE = createActionType(
  '[Auth1] Delete Current User Info'
);

export class RequestCurrentUserInfo implements Action {
  readonly type = CURRENT_USER_INFO_REQUEST;
  constructor() {}
}
export class ReceiveCurrentUserInfo implements Action {
  readonly type = CURRENT_USER_INFO_RECEIVE;
  constructor(public payload: UserBackendModel) {}
}
export class DeleteCurrentUserInfo implements Action {
  readonly type = CURRENT_USER_INFO_DELETE;
  constructor() {}
}
