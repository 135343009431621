import { createSelector } from '@ngrx/store';
import { SettingStateInterface } from './setting.state';
import { getSettingState } from '../store/store.selectors';

export const getSynclogState = createSelector(
  getSettingState,
  (state: SettingStateInterface) => state.synclog
);

export const getRoleState = createSelector(
  getSettingState,
  (state: SettingStateInterface) => state.role
);

export const getUserState = createSelector(
  getSettingState,
  (state: SettingStateInterface) => state.user
);

export const getManpowerState = createSelector(
  getSettingState,
  (state: SettingStateInterface) => state.manpower
);

export const getPermissionState = createSelector(
  getSettingState,
  (state: SettingStateInterface) => state.permission
);
