import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as RegisterActions from './register.actions';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { UserAccount } from '../../backend/customer/model/user-account.model';
import { from, Observable, of } from 'rxjs';
import { CustomerService } from '../../backend/customer/customer.service';
import { CustomerBackend } from '../../backend/customer/customer.backend';
import {
  HideSpinner,
  ShowSpinner,
} from '../../../shared/spinner/spinner.action';
import { Action } from '@ngrx/store';

type showSpinnerTypes = RegisterActions.RegisterRequest;

const showSpinnerActions = [RegisterActions.REGISTER_REQUEST];

type hideSpinnerTypes = RegisterActions.RegisterReceive;

const hideSpinnerActions = [RegisterActions.REGISTER_RECEIVE];
@Injectable()
export class RegisterEffects {
  constructor(
    private actions$: Actions,
    private customerService: CustomerService,
    private customerBackend: CustomerBackend
  ) {}

  @Effect()
  showSpinner: Observable<Action> = this.actions$.pipe(
    ofType<showSpinnerTypes>(...showSpinnerActions),
    map(() => new ShowSpinner())
  );

  @Effect()
  hideSpinner: Observable<Action> = this.actions$.pipe(
    ofType<hideSpinnerTypes>(...hideSpinnerActions),
    map(() => new HideSpinner())
  );

  @Effect()
  OnRegisterRequest$ = this.actions$.pipe(
    ofType<RegisterActions.RegisterRequest>(RegisterActions.REGISTER_REQUEST),
    map((action) => action.payload),
    switchMap((user: UserAccount) =>
      from(this.customerService.registerUser(user)).pipe(
        mergeMap((isRegisterSuccess: boolean) => [
          new RegisterActions.RegisterReceive(isRegisterSuccess),
        ]),
        catchError(
          (error) => of()
          // implement error handling actions
        )
      )
    )
  );
}
