import { Injectable } from '@angular/core';
import { UserAccount } from '../backend/customer/model/user-account.model';
import { select, Store } from '@ngrx/store';
import * as RegisterActions from './register/register.actions';
import * as RegisterSelectors from './register/register.selectors';
import { CDSAppState } from '../store/store.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationToken } from '../backend/customer/model/token.model';
import * as TokenActions from './token/token.actions';
import * as TokenSelectors from './token/token.selectors';
import { UserCredential } from './model/user-credential.model';
import { UserForgotPassword } from './model/user-forgot-password.model';
import { UserForgotPasswordMobile } from './model/user-forgot-password-mobile.model';
import { UserResetPassword } from './model/user-reset-password.model';
import { getAuthToken } from './token/token.selectors';
import * as RouterActions from '../router/router.actions';
import * as AuthenticatedUserActions from './authenticated-user/authenticated-user.actions';
import * as AuthenticatedUserSelectors from './authenticated-user/authenticated-user.selectors';
import * as ForgotpasswordActions from '../../core/auth/forgotpassword/forgotpassword.actions';

@Injectable()
export class AuthStoreService {
  token$: BehaviorSubject<AuthenticationToken>;

  constructor(private store: Store<CDSAppState>) {
    this.token$ = new BehaviorSubject(null);
    this.store
      .pipe(select(getAuthToken))
      .subscribe((token) => this.token$.next(token));
  }

  public isRegisterSuccess(): Observable<boolean> {
    return this.store.pipe(
      select((state) => RegisterSelectors.getIsRegisterSuccess(state))
    );
  }
  public requestRegisterUser(userAccount: UserAccount) {
    this.store.dispatch(new RegisterActions.RegisterRequest(userAccount));
  }
  public initLogin(userCredential: UserCredential) {
    this.store.dispatch(new RouterActions.RouterLoginRequest(userCredential));
  }
  public requestToken(userCredential: UserCredential) {
    this.store.dispatch(new TokenActions.TokenRequest(userCredential));
  }
  public getTokenExpiration() {
    return this.store.pipe(
      select((state) => TokenSelectors.getAuthTokenExpiration(state))
    );
  }
  public requestForgotPassword(userForgotPassword: UserForgotPassword) {
    this.store.dispatch(
      new ForgotpasswordActions.ForgotPasswordRequest(userForgotPassword)
    );
  }
  public requestForgotPasswordMobile(
    userForgotPasswordMobile: UserForgotPasswordMobile
  ) {
    this.store.dispatch(
      new ForgotpasswordActions.ForgotPasswordMobileRequest(
        userForgotPasswordMobile
      )
    );
  }
  public requestResetPassword(userResetPassword: UserResetPassword) {
    this.store.dispatch(
      new RouterActions.ResetPasswordRequest(userResetPassword)
    );
  }
  public requestCurrentUserInfo() {
    this.store.dispatch(new AuthenticatedUserActions.RequestCurrentUserInfo());
  }
  public getCurrentUserInfo() {
    return this.store.pipe(
      select((state) => AuthenticatedUserSelectors.getCurrentUserInfo(state))
    );
  }
  public initLogout() {
    this.store.dispatch(new TokenActions.TokenDeleteRequest());
  }
  public getToken(): BehaviorSubject<AuthenticationToken> {
    return this.token$;
  }
  public decodePermissions(): Boolean {
    return false;
  }
}
