import * as RegisterActions from './register.actions';
import { initRegisterState, RegisterStateInterface } from './register.state';

export function RegisterReducer(
  state = initRegisterState,
  action
): RegisterStateInterface {
  switch (action.type) {
    case RegisterActions.REGISTER_RECEIVE: {
      const payload = action.payload;
      return {
        ...state,
        isRegisterSuccess: payload,
      };
    }

    default:
      return state;
  }
}
