import { Action } from '@ngrx/store';
import { createActionType } from '../../shared/action/utils';
import { BillingRateModel } from '../backend/billing/model/billing-rate.model';
import { BillingRateUpdateModel } from '../backend/billing/model/billing-rate-update.model';
import { ErrorMessage } from '../backend/shared/model/error-message';

export const BILLING_RATE_GET_REQUEST = createActionType(
  '[BillingRate] Get Request'
);

export const BILLING_RATE_GET_RECEIVE = createActionType(
  '[BillingRate] Get Receive'
);

export const BILLING_RATE_UPDATE_REQUEST = createActionType(
  '[BillingRate] Update Request'
);

export const BILLING_RATE_UPDATE_RECEIVE = createActionType(
  '[BillingRate] Update Receive'
);

export class BillingRateGetRequest implements Action {
  readonly type = BILLING_RATE_GET_REQUEST;
  constructor() {}
}
export class BillingRateGetReceive implements Action {
  readonly type = BILLING_RATE_GET_RECEIVE;
  constructor(public payload: BillingRateModel | ErrorMessage) {}
}
export class BillingRateUpdateRequest implements Action {
  readonly type = BILLING_RATE_UPDATE_REQUEST;
  constructor(public payload: BillingRateUpdateModel) {}
}
export class BillingRateUpdateReceive implements Action {
  readonly type = BILLING_RATE_UPDATE_RECEIVE;
  constructor(public payload: BillingRateModel | ErrorMessage) {}
}
