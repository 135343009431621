import * as OverlayActions from './overlay.actions';
import {
  initOverlayStateInterface,
  OverlayStateInterface,
} from './overlay.state';

export function OverlayReducer(
  state = initOverlayStateInterface,
  action
): OverlayStateInterface {
  switch (action.type) {
    case OverlayActions.OverlayOnAction.type: {
      return {
        ...state,
        isOverlayActive: true,
      };
    }

    case OverlayActions.OverlayOffAction.type: {
      return {
        ...state,
        isOverlayActive: false,
      };
    }

    default:
      return state;
  }
}
