import * as ByEmployeeActions from './by-employee.actions';
import {
  ByEmployeeInterface,
  initByEmployeeInterfaceState,
} from './by-employee.state';

export function byEmployeeReducer(
  state = initByEmployeeInterfaceState,
  action
): ByEmployeeInterface {
  switch (action.type) {
    case ByEmployeeActions.DEPARTMENT_RECEIVE: {
      const payload = action.payload.departments;

      return {
        ...state,
        departments: payload,
      };
    }

    case ByEmployeeActions.LOCALS_RECEIVE: {
      const payload = action.payload.locals;

      return {
        ...state,
        locals: payload,
      };
    }

    case ByEmployeeActions.CLASSES_RECEIVE: {
      const payload = action.payload.classes;

      return {
        ...state,
        classes: payload,
      };
    }

    default:
      return state;
  }
}
