import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import { AuthStoreService } from '../core/auth/auth.service';
import { UserBackendModel } from '../core/backend/customer/model/UserBackend.model';
import { UserModel } from '../core/backend/user/model/user.model';
import { SettingStoreService } from '../core/setting/setting.service';
import { AlertMessageCommonService } from '../shared/alert-message-common/alert-message-common.service';
import { SpinnerService } from '../shared/spinner/spinner.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  currentUser$: Observable<UserBackendModel>;
  ngUnsubscribe$: Subject<any> = new Subject<any>();
  userData: UserModel = new UserModel();
  selectedRole: number;
  loader: boolean = false;
  myForm: FormGroup;
  oldPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  oldPasswordError: string;
  newPasswordError: string;
  confirmPasswordError: string;
  imageName: string;
  imgURL: any;
  alertType: string;
  alertShape: string;
  alertMsg: string;
  isAlertShow: boolean;

  constructor(
    private authStoreService: AuthStoreService,
    private settingStoreService: SettingStoreService,
    private alertMessageCommonService: AlertMessageCommonService,
    private http: HttpClient,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.loader = true;
    this.currentUser$ = this.authStoreService.getCurrentUserInfo();
    this.currentUser$
      .pipe(
        filter((data) => !!data),
        delay(500),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((fetchedData) => {
        this.settingStoreService.requestUser(fetchedData.id);
        setTimeout(() => {
          this.getUserData();
          this.loader = false;
        }, 1500);
      });

    this.spinnerService.manageSpinnerState();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.unsubscribe();
  }

  getUserData() {
    this.settingStoreService
      .getUsersById()
      .pipe(take(1))
      .subscribe((data) => {
        this.userData = data;
        this.createForm(this.userData);
      });
  }

  get f() {
    return this.myForm.controls;
  }

  onSubmit() {
    this.loader = true;
    const formData = new FormData();
    formData.append('id', this.userData.id.toString());
    formData.append('profilePic', this.myForm.get('fileSource').value);
    formData.append('employee_first_name', this.userData.firstname);
    formData.append('employee_last_name', this.userData.lastname);
    formData.append('employee_name_suffix', this.userData.title);
    formData.append('email_address', this.userData.email);
    formData.append(
      'phone',
      this.userData.phone ? this.userData.phone.toString() : ''
    );
    formData.append(
      'employee_role_id',
      this.userData.role_id ? this.userData.role_id.toString() : ''
    );
    this.settingStoreService.requestUpdateProfileUser(formData);
    this.userData = JSON.parse(JSON.stringify(this.userData)) as UserModel;
    setTimeout(() => {
      this.userData.profilePic = null; // Existing image show when i set the null
      if (this.imageName != '') {
        this.createForm(this.userData);
        this.imageName = '';
        this.loader = false;
      }
    }, 2000);
  }

  onChangePassword() {
    if (this.isValidation()) {
      this.loader = true;
      const formData = new FormData();
      formData.append('id', this.userData.id.toString());
      formData.append('oldpassword', this.oldPassword);
      formData.append('newpassword', this.newPassword);
      formData.append('confirmpassword', this.confirmPassword);
      formData.append('employee_first_name', this.userData.firstname);
      formData.append('employee_last_name', this.userData.lastname);
      formData.append('employee_name_suffix', this.userData.title);
      formData.append('email_address', this.userData.email);
      formData.append(
        'phone',
        this.userData.phone ? this.userData.phone.toString() : ''
      );
      formData.append(
        'employee_role_id',
        this.userData.role_id ? this.userData.role_id.toString() : ''
      );

      this.settingStoreService.requestUpdateProfileUser(formData);
      setTimeout(() => {
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.loader = false;
      }, 2000);
    }
  }

  onDeleteImage() {
    this.loader = true;
    const formData = new FormData();
    formData.append('id', this.userData.id.toString());
    formData.append('profilePic', '');
    formData.append('delete_profilePic', 'true');
    formData.append('employee_first_name', this.userData.firstname);
    formData.append('employee_last_name', this.userData.lastname);
    formData.append('employee_name_suffix', this.userData.title);
    formData.append('email_address', this.userData.email);
    formData.append(
      'phone',
      this.userData.phone ? this.userData.phone.toString() : ''
    );
    formData.append(
      'employee_role_id',
      this.userData.role_id ? this.userData.role_id.toString() : ''
    );

    this.settingStoreService.requestUpdateProfileUser(formData);
    this.userData = JSON.parse(JSON.stringify(this.userData)) as UserModel;
    setTimeout(() => {
      this.loader = false;
      this.imgURL = null;
    }, 2000);
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.imageName = file.name;
      this.myForm.patchValue({
        fileSource: file,
      });

      // SELECTED IMAGE SHOW CODE
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
      };
    }
  }

  createForm(data = null) {
    if (data != null) {
      if (this.userData.profilePic != null && this.userData.profilePic != '') {
        this.imgURL = this.userData.profilePic;
      }
      this.myForm = new FormGroup({
        firstname: new FormControl(data.firstname, [Validators.required]),
        lastname: new FormControl(data.lastname, [Validators.required]),
        profilePic: new FormControl('', [Validators.required]),
        fileSource: new FormControl('', [Validators.required]),
        email: new FormControl(data.email, [Validators.required]),
        phone: new FormControl(data.phone, [Validators.required]),
        role_id: new FormControl(data.role_id, [Validators.required]),
      });
    } else {
      this.myForm = new FormGroup({
        firstname: new FormControl('', [Validators.required]),
        lastname: new FormControl('', [Validators.required]),
        profilePic: new FormControl('', [Validators.required]),
        fileSource: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
        phone: new FormControl('', [Validators.required]),
        role_id: new FormControl('', [Validators.required]),
      });
    }
  }

  showAlert(alertType, alertMessage) {
    this.alertMsg = alertMessage;
    if (alertType === 'error') {
      this.alertType = 'alert-danger';
      this.alertShape = 'fa-times-circle';
    }

    this.isAlertShow = true;

    setTimeout(() => {
      this.isAlertShow = false;
    }, 5000);
  }

  hideAlert() {
    this.isAlertShow = false; // Hide Alert Box
  }

  isValidation() {
    this.oldPasswordError = '';
    this.newPasswordError = '';
    this.confirmPasswordError = '';
    if (this.oldPassword === '') {
      this.oldPasswordError = 'error';
      this.showAlert('error', 'Old password is required.');
      return false;
    } else if (this.oldPassword.length < 6) {
      this.oldPasswordError = 'error';
      this.showAlert('error', 'Old password should be minimum 6 characters.');
      return false;
    }

    if (this.newPassword === '' && this.confirmPassword === '') {
      this.newPasswordError = 'error';
      this.confirmPasswordError = 'error';
      this.showAlert('error', 'Password and Re-enter password are required.');
      return false;
    }
    if (this.newPassword === '') {
      this.newPasswordError = 'error';
      this.showAlert('error', 'New password is required.');
      return false;
    }
    if (this.confirmPassword === '') {
      this.confirmPasswordError = 'error';
      this.showAlert('error', 'Re-enter password is required.');
      return false;
    } else if (this.newPassword.length < 6) {
      this.newPasswordError = 'error';
      this.showAlert('error', 'Passwords must be a minimum of 6 characters.');
      return false;
    } else if (this.newPassword != this.confirmPassword) {
      this.newPasswordError = 'error';
      this.confirmPasswordError = 'error';
      this.showAlert(
        'error',
        'New password and re-enter password do not match.'
      );
      return false;
    }
    return true;
  }
}
