import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as CostCodeActions from './cost-code.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { TimeService } from '../../backend/time/time.service';
import { CostCodeModel } from '../../backend/time/model/cost-code.model';
import * as ErrorActions from '../../error/error.actions';
import { ErrorType } from '../../error/model/error-type';

@Injectable()
export class CostCodeEffects {
  constructor(private actions$: Actions, private timeService: TimeService) {}

  @Effect()
  OnTimeEntryRequest$ = this.actions$.pipe(
    ofType<CostCodeActions.CostCodeRequest>(CostCodeActions.COST_CODE_REQUEST),
    map((action) => action.payload),
    throttleTime(2000),
    switchMap(({ job_id, is_travel }) =>
      from(this.timeService.getCostCodesByDepartment(job_id, is_travel)).pipe(
        mergeMap((costCodes: CostCodeModel[]) => [
          new CostCodeActions.CostCodeReceive({ costCodes }),
        ]),
        catchError((data) =>
          of(
            new ErrorActions.ErrorReceive({
              message: data.error.message,
              type: ErrorType.token,
              statusCode: data.status,
            })
          )
        )
      )
    )
  );
}
