import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { JobModel } from '../backend/job/model/job.model';

export interface JobStateInterface extends EntityState<JobModel> {
  // additional entity state properties
  selectedJobId: number | null;
  newJob: JobModel;
}

export const adapterJob: EntityAdapter<JobModel> = createEntityAdapter<JobModel>();

export const initJobState: JobStateInterface = adapterJob.getInitialState({
  selectedJobId: null,
  newJob: null,
});

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterJob.getSelectors();
