import { createSelector } from '@ngrx/store';
import { getTimeState } from '../store/store.selectors';
import { TimeStateInterface, selectAll } from './time.state';

export const getAllTimeEntry = createSelector(
  getTimeState,
  (state: TimeStateInterface) => selectAll(state)
);
//TODO: check if need to update id
export const getLocalByJobs = createSelector(
  getTimeState,
  (state: TimeStateInterface) => state.local
);
