import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { DashboardModule } from './dashboard/dashboard.module';
import { AuthGuard } from './auth-guard.service';
import { SettingsModule } from './setting/setting.module';
import { LoginGuard } from './login-guard.service';
import { InstructionsModule } from './instructions/instructions.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PopoverModule } from 'ngx-smart-popover';

import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { MyAccountModule } from './my-account/my-account.module';
import { DataTablesModule } from 'angular-datatables';
import { NgSelect2Module } from 'ng-select2';
import { ServiceorderModule } from './serviceorder/serviceorder.module';
import { ReceiptCaptureModule } from './receipt-capture/receipt-capture.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AuthModule,
    HttpClientModule,
    DashboardModule,
    MyAccountModule,
    SettingsModule,
    InstructionsModule,
    NgScrollbarModule,
    PopoverModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    DataTablesModule,
    NgSelect2Module,
    ReceiptCaptureModule,
    ServiceorderModule,
  ],
  providers: [AuthGuard, LoginGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
