import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BillingRateModel } from './model/billing-rate.model';
import { BillingRateUpdateModel } from './model/billing-rate-update.model';

@Injectable()
export class BillingBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private http: HttpClient) {}

  fetchInvoiceLists(postData): Observable<any> {
    const url = `${this.API_ROOT}/workOrder/getInvoiceLists`;
    return this.http.post<any>(url, postData, this.getOptions());
  }

  fetchBillingRateList(): Observable<HttpResponse<BillingRateModel>> {
    const url = `${this.API_ROOT}/workOrder/getBillingratelist`;
    return this.http.get<BillingRateModel>(url, this.getOptions());
  }

  updateBillingRate(postData): Observable<HttpResponse<BillingRateModel>> {
    const url = `${this.API_ROOT}/workOrder/updateBillingRate`;
    return this.http.post<any>(url, postData, this.getOptions());
  }
  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {},
    };
  }
}
