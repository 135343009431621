<div
  class="modal fade modalorder_service"
  id="event_serviceorder"
  tabindex="-1"
  role="dialog"
  aria-labelledby="event_serviceorderTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header setmodaltabbingcls">
        <div class="inner_setsectionfor_result">
          <div class="icon_st_ic {{ selectedWorkorder?.filterclass }}">
            <img
              *ngIf="
                selectedWorkorder?.filterclass ==
                CONSTANTS.FILTER_CLASS.UNSCHEDULED
              "
              src="../../assets/unscheduled.svg"
              class="unscheduled"
            />
            <img
              *ngIf="
                selectedWorkorder?.filterclass == CONSTANTS.FILTER_CLASS.ONGOING
              "
              src="../../assets/ongoing.svg"
              class="ongoing"
            />
            <img
              *ngIf="
                selectedWorkorder?.filterclass ==
                CONSTANTS.FILTER_CLASS.COMPLETED
              "
              src="../../assets/completed.svg"
              class="completed"
            />
            <img
              *ngIf="
                selectedWorkorder?.filterclass == CONSTANTS.FILTER_CLASS.CLOSED
              "
              src="../../assets/closed.svg"
              class="closed"
            />
          </div>
        </div>
        <nav>
          <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-details"
              role="tab"
              aria-controls="nav-details"
              aria-selected="true"
              #detailsTb
              >Details</a
            >
            <a
              class="nav-item nav-link"
              [ngClass]="{ disabled: fromBillingTab$ == true }"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-technicians"
              role="tab"
              aria-controls="nav-technicians"
              aria-selected="false"
              #techniciansTb
              >Technicians</a
            >

            <a
              class="nav-item nav-link"
              [ngClass]="{ disabled: fromBillingTab$ == true }"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-equipment"
              role="tab"
              aria-controls="nav-equipment"
              aria-selected="false"
              >Equipment</a
            >

            <a
              class="nav-item nav-link"
              [ngClass]="{ disabled: fromBillingTab$ == true }"
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-labor"
              role="tab"
              aria-controls="nav-labor"
              aria-selected="false"
              >Labor</a
            >
            <a
              class="nav-item nav-link"
              [ngClass]="{ disabled: fromBillingTab$ == true }"
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-purchaseorders"
              role="tab"
              aria-controls="nav-purchaseorders"
              aria-selected="false"
              >Purchase Orders</a
            >
            <a
              class="nav-item nav-link"
              [ngClass]="{ disabled: fromBillingTab$ == true }"
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-truckstock"
              role="tab"
              aria-controls="nav-truckstock"
              aria-selected="false"
              >Truck Stock</a
            >
            <a
              class="nav-item nav-link"
              [ngClass]="{ disabled: fromBillingTab$ == true }"
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-attachments"
              role="tab"
              aria-controls="nav-attachments"
              aria-selected="false"
              >Attachments</a
            >
            <a
              class="nav-item nav-link"
              [ngClass]="{ disabled: fromBillingTab$ == true }"
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-completedwo"
              role="tab"
              aria-controls="nav-completedwo"
              aria-selected="false"
              >Completed WO</a
            >
            <a
              class="nav-item nav-link"
              [ngClass]="{ disabled: fromBillingTab$ == true }"
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-exports"
              role="tab"
              aria-controls="nav-exports"
              aria-selected="false"
              >Exports</a
            >
          </div>
        </nav>
        <div class="forcloseserviceorder">
          <a
            href="javascript:void(0);"
            data-dismiss="modal"
            aria-label="Close"
            (click)="ngOnDestroy()"
            ><img src="../../assets/closeRx.png"
          /></a>
        </div>
      </div>
      <div class="modal-body">
        <div class="left_bar_set_cls_modal">
          <div class="left_bar_set_cls_modal_inner">
            <div class="left_bar_set_block">
              <h3>Job #</h3>
              <p>{{ selectedWorkorder?.ceJob_jobNumber }}</p>
            </div>
            <div class="left_bar_set_block">
              <h3>Customer Information</h3>
              <p>
                {{ selectedWorkorder?.ceJob_customer_name }}<br />
                {{ selectedWorkorder?.ceJob_customer_address1 }}<br />
                {{ selectedWorkorder?.ceJob_customer_address2 }}<br />
                <span *ngIf="selectedWorkorder?.ceJob_customer_address3"
                  >{{ selectedWorkorder?.ceJob_customer_address3 }}<br
                /></span>
                <span *ngIf="selectedWorkorder?.ceJob_customer_phone"
                  >Phone:</span
                >
                {{ selectedWorkorder?.ceJob_customer_phone }}
              </p>
            </div>
            <div class="left_bar_set_block">
              <h3>Site Information</h3>
              <p>
                {{ siteInfo?.name }}<br />
                {{ siteInfo?.address1 }}<br />
                {{ siteInfo?.address2 }}<br />
                <span *ngIf="siteInfo?.address3"
                  >{{ siteInfo?.address3 }}<br
                /></span>
                <span *ngIf="siteInfo?.address4"
                  >{{ siteInfo?.address4 }}<br
                /></span>
                <span *ngIf="siteInfo?.phone">Phone:</span>
                {{ siteInfo?.phone }}
              </p>
            </div>
            <div class="left_bar_set_block">
              <h3>Contact Info</h3>
              <div class="form_input_sectionblock">
                <input
                  #contact_name
                  type="text"
                  class="form-control"
                  placeholder="Contact name"
                  [(ngModel)]="contactName"
                />
              </div>
              <div class="form_input_sectionblock mt-1">
                <input
                  #contact_number
                  type="text"
                  class="form-control"
                  placeholder="Contact number"
                  [(ngModel)]="contactNumber"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="right_bar_set_cls_modal">
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-details"
              role="tabpanel"
              aria-labelledby="nav-details-tab"
            >
              <div class="form_row_set">
                <form [formGroup]="detailTabForm">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group relative-form-group">
                        <label>Site #</label>
                        <input
                          type="text"
                          class="form-control cursor"
                          placeholder="Select an option"
                          name="siteDes"
                          formControlName="siteDes"
                          readonly="true"
                        />
                        <div
                          [ngClass]="
                            isSiteListShow == true
                              ? 'show custom_drop select_site_drop'
                              : 'hide'
                          "
                          (click)="$event.stopPropagation()"
                        >
                          <input
                            #siteSearchTxt
                            type="text"
                            class="form-control searchboxcls cursor"
                            placeholder="Search Sites..."
                            name="siteId"
                            autocomplete="off"
                            (input)="onSiteSearch($event)"
                          />
                          <ul #scrollMe class="joblistcls">
                            <li
                              *ngFor="let site of sites; index as i"
                              (click)="updateWorkorderById('siteId', site)"
                            >
                              <div class="liInsideDiv">
                                <div class="divJob">
                                  <div class="jobtitlecls">
                                    {{ site.code }} - {{ site.name }}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Cost Code</label>
                        <select
                          class="custom-select form-control"
                          data-placeholder="Select Cost Code"
                          formControlName="costCodeId"
                          (change)="updateWorkorderById('costCodeId')"
                        >
                          <option [value]="undefined" [disabled]="true">
                            Select Cost Code
                          </option>
                          <option
                            *ngFor="let code of costCodes"
                            [value]="code.cost_code_id"
                          >
                            {{ code.catnum }} - {{ code.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Type</label>
                        <select
                          class="custom-select form-control"
                          data-placeholder="Select Type"
                          formControlName="workOrderTypeId"
                          (change)="updateWorkorderById('workOrderTypeId')"
                        >
                          <option [value]="undefined" [disabled]="true">
                            Select Type
                          </option>
                          <option
                            *ngFor="let type of workorderTypes"
                            [value]="type.id"
                          >
                            {{ type.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Job Description</label>
                        <p>
                          {{ selectedWorkorder?.ceJob_Description }}
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Order Received</label>
                        <p>{{ selectedWorkorder?.order_received_date }}</p>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Date Requested</label>
                        <div class="cal-wrp">
                          <div class="btnCal-wrp">
                            <input
                              formControlName="dateRequested"
                              type="date"
                              name="dateRequested"
                              class="form-control btnCal-click btn-radius"
                              (change)="updateWorkorderById('dateRequested')"
                            />
                          </div>
                          <button class="btnCal">
                            <span class="fa fa-calendar"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Site Notes</label>
                        <p>{{ siteInfo?.notes }}</p>
                      </div>
                      <div class="form-group">
                        <label>Status</label>
                        <select
                          class="custom-select form-control"
                          data-placeholder="Select Status"
                          formControlName="statusId"
                          (change)="updateWorkorderById('statusId')"
                        >
                          <option [value]="undefined" [disabled]="true">
                            Select New Status
                          </option>
                          <option [value]="0">Unscheduled</option>
                          <option [value]="1">Ongoing (Open)</option>
                          <option [value]="2">Completed</option>
                          <option [value]="3">Closed</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6 adFwidth">
                      <div class="form-group">
                        <label>Tech Notes</label>
                        <textarea
                          #techNote
                          placeholder="Add notes..."
                          formControlName="techNote"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-technicians"
              role="tabpanel"
              aria-labelledby="nav-technicians-tab"
            >
              <div class="form_row_set">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Assign Technician</label>
                      <select
                        class="custom-select form-control"
                        (change)="addEmployeeScheduleEntry($event.target.value)"
                        [(ngModel)]="selectedTechnician"
                      >
                        <option value="" [disabled]="true">
                          Select Technician
                        </option>
                        <option
                          *ngFor="let user of users"
                          [value]="user.ce_employee_id"
                        >
                          {{ user.lastname }}, {{ user.firstname }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div
                      class="technician_main"
                      (scroll)="onScrollTechnicianListDiv($event)"
                    >
                      <div class="technician_listing_main_row">
                        <div id="accordion" role="tablist">
                          <div
                            class="card"
                            *ngFor="
                              let workorderSchedule of workorderScheduleByEmployee;
                              let i = index
                            "
                          >
                            <div class="card-header" role="tab" id="headingtwo">
                              <h5 class="mb-0">
                                <a
                                  data-toggle="collapse"
                                  href="#collapseOne"
                                  aria-controls="collapseOne"
                                  (click)="
                                    isTechDivExpanded =
                                      workorderSchedule.employeeId
                                  "
                                  *ngIf="
                                    isTechDivExpanded !=
                                    workorderSchedule.employeeId
                                  "
                                  class="openA"
                                >
                                </a>
                                <a
                                  data-toggle="collapse"
                                  href="#collapseOne"
                                  aria-controls="collapseOne"
                                  (click)="isTechDivExpanded = null"
                                  *ngIf="
                                    isTechDivExpanded ==
                                    workorderSchedule.employeeId
                                  "
                                  class="closeA"
                                >
                                </a>
                                <div class="technician_col">
                                  {{ workorderSchedule.employeeName }}
                                </div>
                                <div class="set_right assign_tech_div">
                                  <div
                                    id="addToScheuleDiv{{
                                      workorderSchedule.employeeId
                                    }}"
                                    class="technician_col add_schedule"
                                  >
                                    <span
                                      ><img src="../../assets/add.svg"
                                    /></span>
                                    <mat-form-field>
                                      <mat-label>Add to Schedule</mat-label>
                                      <input
                                        matInput
                                        [matDatepicker]="datepicker"
                                        (click)="datepicker.open()"
                                        (dateChange)="
                                          onScheduleDate(
                                            $event,
                                            workorderSchedule
                                          )
                                        "
                                      />
                                      <mat-datepicker-toggle
                                        matSuffix
                                        [for]="datepicker"
                                      ></mat-datepicker-toggle>
                                      <mat-datepicker #datepicker>
                                      </mat-datepicker>
                                    </mat-form-field>
                                  </div>
                                  <div
                                    class="technician_col"
                                    (click)="
                                      onRemoveSchedule(workorderSchedule)
                                    "
                                  >
                                    <span
                                      ><img src="../../assets/remove.svg"
                                    /></span>
                                    <span>Remove Technician</span>
                                  </div>
                                </div>
                              </h5>
                            </div>
                            <div
                              *ngIf="
                                isTechDivExpanded ==
                                workorderSchedule.employeeId
                              "
                              id="collapsetwo"
                              class="collapse show in card_bodySO"
                              role="tabpanel"
                              aria-labelledby="headingtwo"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <div class="show_schedule_row_fm">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>SCHEDULE</th>
                                      </tr>
                                    </thead>
                                    <tbody
                                      *ngFor="
                                        let scheduleEntry of workorderSchedule.scheduledEntries
                                      "
                                    >
                                      <tr>
                                        <td>
                                          <div class="discell_hover">
                                            <span>{{
                                              formatDate(
                                                scheduleEntry.date_scheduled
                                              )
                                            }}</span>
                                            <span
                                              (click)="
                                                onRemoveSchedule(
                                                  workorderSchedule,
                                                  scheduleEntry.date_scheduled
                                                )
                                              "
                                              ><img
                                                src="../../assets/remove.svg"
                                            /></span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-equipment"
              role="tabpanel"
              aria-labelledby="nav-equipment-tab"
            >
              <div class="equipment_set_main">
                <div class="equipment_row_set euipmentSeRT">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="col-setlabor">
                        <div class="equipment_block_SO">
                          <label>Manufacterer</label>
                          <p>
                            {{
                              selectedWorkorder?.work_order_equipment
                                ?.manufacturer
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="col-setlabor">
                        <div class="labor_block_SO">
                          <label>Unit</label>
                          <p>
                            {{ selectedWorkorder?.work_order_equipment?.unit }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="col-setlabor">
                        <div class="labor_block_SO">
                          <label>M/N</label>
                          <p>
                            {{
                              selectedWorkorder?.work_order_equipment
                                ?.model_number
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="col-setlabor">
                        <div class="labor_block_SO">
                          <label>S/N</label>
                          <p>
                            {{
                              selectedWorkorder?.work_order_equipment
                                ?.serial_number
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-labor"
              role="tabpanel"
              aria-labelledby="nav-labor-tab"
            >
              <div
                class="labor_set_main"
                (scroll)="onScrollLaborWorkorder($event)"
              >
                <div class="labor_row_set" *ngFor="let labor of laborWorkorder">
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Date</label>
                      <p>{{ labor.date }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Technician</label>
                      <p>
                        {{ labor.ce_employee_last_name }},
                        {{ labor.ce_employee_first_name }}
                      </p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Cost Code</label>
                      <p>
                        {{ labor.ce_category_catnum }} -
                        {{ labor.ce_category_name }}
                      </p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Regular Time</label>
                      <p>{{ labor.reg_hrs }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Over Time</label>
                      <p>{{ labor.ovr_hrs }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Double Time</label>
                      <p>{{ labor.dbl_hrs }}</p>
                    </div>
                  </div>
                  <div class="col-setlaborfull mt-3">
                    <div class="labor_block_SO">
                      <label>Description</label>
                      <p>
                        {{ labor.description }}
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="laborWorkorder.length == 0">{{ noDataText }}</div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-purchaseorders"
              role="tabpanel"
              aria-labelledby="nav-purchaseorders-tab"
            >
              <div
                class="purchaseorders_set_main"
                (scroll)="onScrollPurchaseOrder($event)"
              >
                <div
                  class="purchaseorders_row_set"
                  *ngFor="let po of purchaseOrder"
                >
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Vendor</label>
                      <p>{{ po.vendor_name }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>PO #</label>
                      <p>{{ po.po_num }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Date</label>
                      <p>{{ po.used_date }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Completion Status</label>
                      <p *ngIf="po.used_status == 0">needs attention</p>
                      <p *ngIf="po.used_status == 1">completed</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Cost</label>
                      <p>$ {{ po.cost }}</p>
                    </div>
                  </div>
                  <div class="col-setlaborhlf mt-3">
                    <div class="labor_block_SO">
                      <label class="checkbox checkbox-lg checkbox-square">
                        <input
                          type="checkbox"
                          name=""
                          [checked]="po.used_po_material"
                          [disabled]="true"
                        />
                        <span></span>
                        <div class="count_shtmtnl">Tech Used PO Material</div>
                      </label>
                    </div>
                  </div>
                  <div class="col-setlaborhlfthrid mt-3">
                    <div class="labor_block_SO">
                      <label>Item Description</label>
                      <p>
                        {{ po.po_item_description }}
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="purchaseOrder.length == 0">{{ noDataText }}</div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-truckstock"
              role="tabpanel"
              aria-labelledby="nav-truckstock-tab"
            >
              <div
                class="purchaseorders_set_main"
                (scroll)="onScrollTruckStock($event)"
              >
                <div
                  class="purchaseorders_row_set"
                  *ngFor="let ts of truckStock"
                >
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Truck Stock</label>
                      <p>{{ ts.truck_name }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Cost Code</label>
                      <p>{{ ts.catnum }} - {{ ts.category_name }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Date</label>
                      <p>{{ ts.date }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Quantity</label>
                      <p>{{ ts.quantity }} lbs</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Cost</label>
                      <p>$ {{ ts.cost }}</p>
                    </div>
                  </div>
                  <div class="col-setlaborfull mt-3">
                    <div class="labor_block_SO">
                      <label>Notes</label>
                      <p>
                        {{ ts.notes }}
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="truckStock.length == 0">{{ noDataText }}</div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-attachments"
              role="tabpanel"
              aria-labelledby="nav-attachments-tab"
            >
              <div
                class="attachments_set_main"
                (scroll)="onScrollAttachment($event)"
              >
                <div class="attachments_row_set" *ngFor="let wa of attachment">
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Attachment Title</label>
                      <p>{{ wa.title }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Date</label>
                      <p>{{ wa.date }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Notes</label>
                      <p>
                        {{ wa.notes }}
                      </p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Attachments</label>
                      <ul>
                        <li>
                          <img
                            src="{{ wa.url }}"
                            (click)="downloadImg(wa.url, true)"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div *ngIf="attachment.length == 0">{{ noDataText }}</div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-completedwo"
              role="tabpanel"
              aria-labelledby="nav-completedwo-tab"
            >
              <div class="completeWO_main">
                <div class="completeWO_row">
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Name of Customer</label>
                      <p>{{ selectedWorkorder?.signature_name }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Date</label>
                      <p>{{ selectedWorkorder?.signature_date }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Time</label>
                      <p>{{ selectedWorkorder?.signature_time }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label class="checkbox checkbox-lg checkbox-square">
                        <input
                          type="checkbox"
                          name=""
                          [(ngModel)]="serviceFee"
                          (click)="onServiceFeeClick()"
                        /><span></span>
                        <div class="count_shtmtnl">Service Fee</div>
                      </label>
                    </div>
                  </div>
                  <div class="col-setlabor_flG signaturesetback">
                    <div class="labor_block_SO">
                      <label>Customer Signature</label>
                      <div class="signature_padF">
                        <img
                          [src]="signatureImg"
                          (click)="downloadImg(signatureImg, false)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-setlabor dfXC">
                    <div class="labor_block_SO">
                      <label>Follow-up/recommendations</label>
                      <p>{{ selectedWorkorder?.followup_recommendations }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor dfXCV">
                    <div class="labor_block_SO">
                      <label class="checkbox checkbox-lg checkbox-square">
                        <input
                          [disabled]="true"
                          type="checkbox"
                          name=""
                          [(ngModel)]="isQuoteRequested"
                        /><span></span>
                        <div class="count_shtmtnl">
                          Office follow-up with quote?
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-exports"
              role="tabpanel"
              aria-labelledby="nav-exports-tab"
            >
              <div class="purchaseorders_set_main">
                <div class="purchaseorders_row_set exports_clgh">
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Billing Group</label>
                      <p>{{ billingGroup }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor setwQwidth">
                    <div class="labor_block_SO">
                      <label>Date Created</label>
                      <p>{{ invoiceDetail?.created_date }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor">
                    <div class="labor_block_SO">
                      <label>Type</label>
                      <p>{{ invoiceDetail?.work_order_type_name }}</p>
                    </div>
                  </div>
                  <div class="col-setlabor setwQwidth_w">
                    <div class="labor_block_SO">
                      <div class="form-group">
                        <label>Invoice Status</label>
                        <select
                          class="custom-select form-control"
                          [(ngModel)]="invoiceStatus"
                          (change)="updateInvoiceStatus()"
                        >
                          <option [value]="undefined" [disabled]="true">
                            Select Status
                          </option>
                          <option
                            *ngFor="let status of invoiceStatusList"
                            [value]="status.id"
                          >
                            {{ status.status_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-setlabor setwQwidth">
                    <div class="labor_block_SO">
                      <label>Total</label>
                      <p>{{ invoiceDetail?.total_cost }}</p>
                    </div>
                  </div>
                  <div class="col_full_set_btn_row">
                    <button
                      (click)="generateInvoice()"
                      _ngcontent-jht-c169=""
                      type="button"
                      class="btn btn-primary saveRclose"
                    >
                      Generate Invoice
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          #closebutton
          type="button"
          class="btn btn-secondary cancel"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
