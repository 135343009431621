import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as BillingRateActions from './billing-rate.actions';
import { CDSAppState } from '../store/store.state';
import * as BillingRateSelectors from './billing-rate.selectors';
import { JobModel } from '../backend/job/model/job.model';
import { Observable } from 'rxjs';
import { BillingRateModel } from '../backend/billing/model/billing-rate.model';

@Injectable()
export class BillingRateStoreService {
  constructor(private store: Store<CDSAppState>) {}

  public getAllBillingRates(): Observable<BillingRateModel[]> {
    return this.store.pipe(
      select((state) => BillingRateSelectors.getBillingRate(state))
    );
  }

  public requestBillingRates() {
    this.store.dispatch(new BillingRateActions.BillingRateGetRequest());
  }

  public requestUpdateBillingRate(postData) {
    this.store.dispatch(
      new BillingRateActions.BillingRateUpdateRequest(postData)
    );
  }
}
