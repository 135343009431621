import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { map } from 'rxjs/operators';
import { ManpowerBackend } from './manpower.backend';

import { AssignJob } from './../../../manpower/model/assignJob.model';
import { EditJob } from '../../../../../src/app/manpower/model/editJob.model';
import { RemoveJob } from '../../../manpower/model/removeJob.model';
import { AddJobNote } from '../../../../../src/app/manpower/model/addJobNote.model';

@Injectable()
export class ManpowerService extends BaseService {
  constructor(private manpowerBackend: ManpowerBackend) {
    super();
  }

  fetchJobs(page, pageItem, jobFilter): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .fetchJobs(
        page,
        pageItem,
        jobFilter?.order,
        jobFilter?.foreman_ids,
        jobFilter?.project_manager_ids,
        jobFilter?.locations,
        jobFilter?.assigned,
        jobFilter?.search,
        jobFilter?.sheet_metal,
        jobFilter?.plumbing
      )
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise();
  }

  fetchUsers(filter: any): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .fetchUsers(filter)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  addJob(jobData: any): Promise<any | ErrorMessage> {
    return this.manpowerBackend
      .addJob(jobData)
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise();
  }

  assingJob(assignJob: AssignJob): Promise<any | ErrorMessage> {
    return this.manpowerBackend
      .assignJob(assignJob)
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise();
  }

  unAssingJob(
    job_id: number,
    employee_id: number,
    is_assigned: number
  ): Promise<any | ErrorMessage> {
    return this.manpowerBackend
      .unAssignJob(job_id, employee_id, is_assigned)
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise();
  }

  addPerformanceNote(
    employee_id: number,
    text: string
  ): Promise<any | ErrorMessage> {
    return this.manpowerBackend
      .addPerformanceNote(employee_id, text)
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise();
  }

  updateJob(editJobData: EditJob): Promise<any | ErrorMessage> {
    return this.manpowerBackend
      .updateJob(editJobData)
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise();
  }

  removeJob(removeJobData: RemoveJob): Promise<any | ErrorMessage> {
    return this.manpowerBackend
      .removeJob(removeJobData)
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise();
  }

  performanceNotes(emp_id): Promise<any | ErrorMessage> {
    return this.manpowerBackend
      .performanceNotes(emp_id)
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise();
  }

  fetchNonManpowerJobs(): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .fetchNonManpowerJobs()
      .pipe(map((response) => response.data))
      .toPromise();
  }

  // Fetch Project Managers
  fetchProjectManagers(): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .fetchProjectManagers()
      .pipe(map((response) => response.data))
      .toPromise();
  }

  // Fetch Foremans
  fetchForemans(type?): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .fetchForemans(type)
      .pipe(
        map((response) => {
          return response.body.data;
        })
      )
      .toPromise();
  }

  // Fetch Filters List
  fetchFilters(): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .fetchFilters()
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  // Fetch Job Filters List
  fetchJobFilters(): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .fetchJobFilters()
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  // fetch job notes for manpower

  fetchJobNotes(jobId, filter?): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .fetchJobNotes(jobId, filter)
      .pipe(
        map((response) => {
          return response.body.data;
        })
      )
      .toPromise();
  }

  // add job notes for manpower

  addJobNotes(addJobNote: AddJobNote): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .addJobNotes(addJobNote)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  // Resolve job notes for manpower

  resolveJobNote(noteId: number): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .resolveJobNote(noteId)
      .pipe(map((response) => response.data))
      .toPromise();
  }

  // Flag Employee notes

  flagEmployeeNote(note_id, flagged): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .flagEmployeeNote(note_id, flagged)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  saveManpowerSettings(
    project_assistants,
    payroll_managers
  ): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .saveManpowerSettings(project_assistants, payroll_managers)
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise();
  }

  getManpowerSettings(): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .getManpowerSettings()
      .pipe(map((response) => response.data))
      .toPromise();
  }

  // get employee assign-job details
  getEmployeeAssignJobDetails(data): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .getJobDetails(data)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  // send push notification
  sendPushNotificationRequest(data): Promise<[] | ErrorMessage> {
    return this.manpowerBackend
      .addPushNotification(data)
      .pipe(map((response) => response.body))
      .toPromise();
  }
}
