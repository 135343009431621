import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { catchError, map, tap } from 'rxjs/operators';
import { RoleBackend } from './role.backend';
import { RoleModel } from './model/role.model';

@Injectable()
export class RoleService extends BaseService {
  constructor(private roleBackend: RoleBackend) {
    super();
  }

  getAllRoles(): Promise<RoleModel[] | ErrorMessage> {
    return this.roleBackend
      .fetchAllRoles()
      .pipe(map((response) => response.body))
      .toPromise();
  }
}
