import { Action, createAction, props } from '@ngrx/store';
import { SynclogModel } from '../../backend/synclog/model/synclog.model';
import { createActionType } from '../../../shared/action/utils';

export const SYNC_LOG_REQUEST = createActionType('[synclog1] Request');
export const SYNC_LOG_RECEIVE = createActionType('[synclog1] Receive');
export class SynclogsRequest implements Action {
  readonly type = SYNC_LOG_REQUEST;
  constructor() {}
}
export class SynclogsReceive implements Action {
  readonly type = SYNC_LOG_RECEIVE;
  constructor(public payload: SynclogModel[]) {}
}
