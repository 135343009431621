import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { ServiceOrderService } from '../../../core/backend/service-order/service-order.service';
import { WorkorderStoreService } from '../../../core/workorder/workorder.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit {
  pdf = '';
  path = '';
  invoicePDFUrl = '';
  private API_ROOT = environment.apiUrl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private workorderStoreService: WorkorderStoreService,
    private serviceOrderService: ServiceOrderService
  ) {}

  ngOnInit(): void {
    this.pdf = this.data['html'];
    this.path = this.data['path'];
    this.invoicePDFUrl =
      this.API_ROOT + '/workOrder/downloadinvoice?file=' + this.path;
  }

  updateStatus() {
    // updating workorder status completed to closed on Invoice PDF Download
    let postData = {
      work_order_id: this.data['workorderId'],
      status: 3,
    };
    // API call
    this.workorderStoreService.requestUpdateServiceOrder(postData, true, false);
    this.createInvoice();
  }

  createInvoice() {
    let postData = {
      work_order_id: this.data['workorderId'],
    };
    this.serviceOrderService
      .createInvoice(postData)
      .then()
      .catch((error) => {
        console.log(error);
      });
  }
}
