import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { InstructionsComponent } from './instructions.component';
import { IosDownloadComponent } from './ios/ios-download.component';
import { AndroidDownloadComponent } from './android/android-download.component';

const instructionsRoutes: Routes = [
  {
    path: '',
    component: InstructionsComponent,
    pathMatch: 'full',
  },
  {
    path: 'ios',
    component: IosDownloadComponent,
    pathMatch: 'full',
  },
  {
    path: 'android',
    component: AndroidDownloadComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(instructionsRoutes)],
  exports: [RouterModule],
})
export class InstructionsRoutingModule {}
