import { Action, createAction, props } from '@ngrx/store';
import { UserForgotPasswordMobile } from '../model/user-forgot-password-mobile.model';
import { UserForgotPassword } from '../model/user-forgot-password.model';

import { createActionType } from '../../../shared/action/utils';

export const FORGOT_PASSWORD_REQUEST = createActionType(
  '[ForgotPassword1] Request'
);
export const FORGOT_PASSWORD_RECEIVE = createActionType(
  '[ForgotPassword1] Receive'
);

export const FORGOT_PASSWORD_USER_RECEIVE = createActionType(
  '[ForgotPasswordUser1] Request'
);

export const FORGOT_PASSWORD_MOBILE_REQUEST = createActionType(
  '[ForgotPasswordMobile1] Request'
);
export const FORGOT_PASSWORD_MOBILE_RECEIVE = createActionType(
  '[ForgotPasswordMobile1] Receive'
);

export class ForgotPasswordRequest implements Action {
  readonly type = FORGOT_PASSWORD_REQUEST;
  constructor(public payload: UserForgotPassword) {}
}
export class ForgotPasswordReceive implements Action {
  readonly type = FORGOT_PASSWORD_RECEIVE;
  constructor(public payload: any) {}
}

export class ForgotPasswordUserReceive implements Action {
  readonly type = FORGOT_PASSWORD_USER_RECEIVE;
  constructor(public payload: any) {}
}
export class ForgotPasswordMobileRequest implements Action {
  readonly type = FORGOT_PASSWORD_MOBILE_REQUEST;
  constructor(public payload: UserForgotPasswordMobile) {}
}
export class ForgotPasswordMobileReceive implements Action {
  readonly type = FORGOT_PASSWORD_MOBILE_RECEIVE;
  constructor(public payload: any) {}
}
