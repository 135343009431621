import { NgModule } from '@angular/core';
import { MyAccountComponent } from './my-account.component';
import { MyAccountRoutingModule } from './my-account-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [MyAccountComponent],
  imports: [
    MyAccountRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgxMaskModule.forRoot(),
    DataTablesModule,
    NgxSpinnerModule,
  ],
  exports: [],
})
export class MyAccountModule {}
