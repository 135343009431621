<section>
  <div class="container-fluid wrapper">
    <div class="container">
      <!-- Upload box section -->
      <div class="row">
        <div id="drop-area">
          <form>
            <p>
              Upload a file by pressing "Upload Image" or by dragging and
              dropping an image onto the dashed region
            </p>
            <input
              type="file"
              id="fileElem"
              accept="image/*, application/pdf"
              (change)="handleChange($event)"
              [disabled]="isSubmitting"
            />
            <label class="button" for="fileElem">Upload Image</label>
          </form>
        </div>
      </div>
      <form (ngSubmit)="onSubmit()" (ngReset)="onReset()">
        <div class="form-wrapper">
          <div id="preview-gallery" class="full-row gallery">
            <button
              id="badge-input"
              *ngIf="previewedFile"
              class="close"
              type="button"
              (click)="removeImage($event)"
              aria-label="Close"
            >
              <span aria-hidden="true">X</span>
            </button>

            <img
              id="previewed-file"
              (click)="handleImageClick()"
              *ngIf="previewedFile"
              [src]="previewedFile"
              height="300"
            />
          </div>
        </div>
        <div class="form-wrapper">
          <div class="tabs">
            <!-- Tabs  -->
            <mat-tab-group
              animationDuration="0ms"
              #tabGroup
              [selectedIndex]="selectedIndex"
              (selectedTabChange)="onTabClick($event)"
            >
              <mat-tab label="Credit Card"></mat-tab>
              <mat-tab label="Cash Reimbursement"></mat-tab>
            </mat-tab-group>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline">
              <mat-label>{{ getLabel() }}</mat-label>
              <input
                matInput
                placeholder="0.00"
                currencyMask
                required
                min="0"
                [formControl]="amount"
              />
            </mat-form-field>

            <mat-form-field id="datepicker-input" appearance="outline">
              <mat-label></mat-label>
              <input
                matInput
                required
                [matDatepicker]="picker"
                [formControl]="selectedDate"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="full-row" appearance="outline">
              <mat-label>Job</mat-label>
              <input
                matInput
                aria-label="Job Autocomplete"
                type="text"
                placeholder="Search Job..."
                [matAutocomplete]="auto"
                [formControl]="jobControl"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="onSelect($event)"
              >
                <mat-option class="hidden">--</mat-option>
                <p
                  class="autocomplete-label"
                  *ngIf="filteredFavoriteJobs.length"
                >
                  MY FAVORITES
                </p>
                <mat-option
                  *ngFor="let job of filteredFavoriteJobs"
                  [value]="getJobLabel(job)"
                >
                  {{ getJobLabel(job) }}
                </mat-option>
                <mat-divider [vertical]="false"></mat-divider>
                <mat-option
                  *ngFor="let job of filteredJobs"
                  [value]="getJobLabel(job)"
                >
                  {{ getJobLabel(job) }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="jobControl.hasError('required')">
                Please choose a job
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="full-row" appearance="outline">
              <mat-label>Expense Category</mat-label>
              <mat-select [formControl]="expenseControl" required>
                <mat-option>--</mat-option>
                <mat-option
                  *ngFor="let category of expenseCategories"
                  [value]="category.name"
                >
                  {{ category.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="expenseControl.hasError('required')">
                Please choose an expense
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="canShowFuelFields">
            <mat-form-field appearance="outline">
              <mat-label>Mileage</mat-label>
              <input matInput placeholder="0" min="0" [formControl]="mileage" />
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="canShowMaintFields || canShowFuelFields">
            <mat-form-field class="full-row" appearance="outline">
              <mat-label>Unit</mat-label>
              <input
                matInput
                aria-label="Equipment (unit) Autocomplete"
                type="text"
                placeholder="Search Units..."
                [matAutocomplete]="auto"
                [formControl]="equipmentControl"
                required
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="onSelect($event)"
              >
                <mat-option class="hidden">--</mat-option>
                <p
                  class="autocomplete-label"
                  *ngIf="filteredFavoriteEquipments.length"
                >
                  MY FAVORITES
                </p>
                <mat-option
                  *ngFor="let unit of filteredFavoriteEquipments"
                  [value]="getEquipmentLabel(unit)"
                >
                  {{ getEquipmentLabel(unit) }}
                </mat-option>
                <mat-divider [vertical]="false"></mat-divider>
                <mat-option
                  *ngFor="let unit of filteredEquipments"
                  [value]="getEquipmentLabel(unit)"
                >
                  {{ getEquipmentLabel(unit) }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="equipmentControl.hasError('required')">
                Please choose a unit
              </mat-error>
            </mat-form-field>

            <!-- Un/Favorite icon button-->
            <div id="favorite-container" *ngIf="equipmentControl.value">
              <button
                mat-flat-button
                type="button"
                (click)="onFavoritePress($event)"
              >
                <span><i [ngClass]="setFavoriteIconColor()"></i></span>
                <span>{{ getFavoriteLabel() }}</span>
              </button>
            </div>
          </div>

          <div class="form-row">
            <mat-form-field class="full" appearance="outline">
              <mat-label>Notes</mat-label>
              <textarea
                class="full"
                matInput
                placeholder="Comments about this receipt"
                [formControl]="notes"
              ></textarea>
            </mat-form-field>
          </div>

          <div class="form-row">
            <button class="button" [disabled]="isSubmitting" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
