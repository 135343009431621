import { Injectable } from '@angular/core';
import { AuthenticationToken } from '../customer/model/token.model';

@Injectable({
  providedIn: 'root',
})
export class BrowserStorageBackend {
  readonly LOGIN_TOKEN_KEY = 'LOGIN_TOKEN_KEY';
  readonly LOGIN_TOKEN_EXPIRATION = 'LOGIN_TOKEN_EXPIRATION';

  saveAuthToken(
    token: AuthenticationToken,
    expiration: number,
    rememberMe: boolean
  ) {
    if (rememberMe) {
      localStorage.setItem(this.LOGIN_TOKEN_KEY, JSON.stringify(token));
      localStorage.setItem(
        this.LOGIN_TOKEN_EXPIRATION,
        JSON.stringify(expiration)
      );
    } else {
      sessionStorage.setItem(this.LOGIN_TOKEN_KEY, JSON.stringify(token));
      sessionStorage.setItem(
        this.LOGIN_TOKEN_EXPIRATION,
        JSON.stringify(expiration)
      );
    }
  }

  getAuthTokenFromLocalStorage(): AuthenticationToken {
    let token;

    try {
      token = JSON.parse(localStorage.getItem(this.LOGIN_TOKEN_KEY));
      return token;
    } catch (e) {
      return null;
    }
  }

  getAuthTokenExpiryFromLocalStorage(): AuthenticationToken {
    let tokenExpiry;

    try {
      tokenExpiry = JSON.parse(
        localStorage.getItem(this.LOGIN_TOKEN_EXPIRATION)
      );
      return tokenExpiry;
    } catch (e) {
      return null;
    }
  }

  getAuthTokenFromSessionStorage(): AuthenticationToken {
    let token;

    try {
      token = JSON.parse(sessionStorage.getItem(this.LOGIN_TOKEN_KEY));
      return token;
    } catch (e) {
      return null;
    }
  }

  getAuthTokenExpiryFromSessionStorage(): AuthenticationToken {
    let tokenExpiry;

    try {
      tokenExpiry = JSON.parse(
        sessionStorage.getItem(this.LOGIN_TOKEN_EXPIRATION)
      );
      return tokenExpiry;
    } catch (e) {
      return null;
    }
  }

  deleteAuthToken() {
    localStorage.removeItem(this.LOGIN_TOKEN_KEY);
    localStorage.removeItem(this.LOGIN_TOKEN_EXPIRATION);
    sessionStorage.removeItem(this.LOGIN_TOKEN_KEY);
    sessionStorage.removeItem(this.LOGIN_TOKEN_EXPIRATION);
  }
}
