import { AlertMessageCommonService } from 'src/app/shared/alert-message-common/alert-message-common.service';
import { Component, OnInit } from '@angular/core';
import { AuthStoreService } from '../../core/auth/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  email: FormControl;
  phonenumber: FormControl;
  emailError: string;
  phoneError: string;

  constructor(
    private authStoreService: AuthStoreService,
    private alertMessageCommonService: AlertMessageCommonService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.email = new FormControl('', {
      validators: [Validators.required],
    });
    this.phonenumber = new FormControl('', {
      validators: [Validators.required],
    });
  }

  onSubmit() {
    if (this.isValidation()) {
      if (this.email.value != '') {
        this.authStoreService.requestForgotPassword({
          email_address: this.email.value,
          url: window.location.origin + '/login/',
        });
      } else if (this.phonenumber.value != '') {
        this.authStoreService.requestForgotPasswordMobile({
          phone: this.phonenumber.value,
        });
      }
      this.spinnerService.manageSpinnerState();
    }
  }

  isValidation() {
    this.emailError = '';
    this.phoneError = '';

    if (this.email.value === '' && this.phonenumber.value === '') {
      this.emailError = 'error';
      this.phoneError = 'error';
      this.alertMessageCommonService.error(
        'error',
        'Email or phone number required.'
      );
      return false;
    }
    return true;
  }

  onKey(event: any) {
    // without type info
    if (event.target.value.length <= 1) {
      event.target.value = '';
    }
  }
}
