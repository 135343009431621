import * as WorkorderScheduleActions from './workorder-schedule.actions';
import {
  initWorkorderScheduleState,
  WorkorderScheduleStateInterface,
  adapterWorkorderSchedule,
} from './workorder-schedule.state';

export function WorkorderScheduleReducer(
  state = initWorkorderScheduleState,
  action
): WorkorderScheduleStateInterface {
  switch (action.type) {
    case WorkorderScheduleActions.WORKORDER_SCHEDULE_CREATE_RECEIVE: {
      const payload = action.payload.data;
      return adapterWorkorderSchedule.addMany(payload, {
        ...state,
      });
    }

    case WorkorderScheduleActions.WORKORDER_SCHEDULE_GET_RECEIVE: {
      const payload = action.payload.scheduleList.data;
      const isScrolled = action.payload.isScrolled;
      if (isScrolled) {
        return adapterWorkorderSchedule.addMany(payload, {
          ...state,
        });
      } else {
        return adapterWorkorderSchedule.setAll(payload, state);
      }
    }

    case WorkorderScheduleActions.WORKORDER_SCHEDULE_DELETE_RECEIVE: {
      const payload = action.payload.deleteRes.data.deletedScheduleIds;
      return adapterWorkorderSchedule.removeMany(payload, {
        ...state,
      });
    }

    default:
      return state;
  }
}
