import { createSelector } from '@ngrx/store';
import { UserStateInterface, selectAll } from './user.state';
import { getUserState } from '../setting.selectors';

export const getAllUsers = createSelector(
  getUserState,
  (state: UserStateInterface) => selectAll(state)
);

export const getUsersById = createSelector(
  getUserState,
  (state: UserStateInterface) => state.user.data
);
