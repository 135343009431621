import { Action, createAction, props } from '@ngrx/store';
import { createActionType } from '../../../shared/action/utils';
import { ServiceOrderReceiveListModel } from '../../backend/service-order/model/service-order-receive-list.model';
import { ServiceOrderRequestListModel } from '../../backend/service-order/model/service-order-request-list.model';

export const SERVICE_ORDER_CREATE_REQUEST = createActionType(
  '[ServiceOrder] Create Request'
);

export const SERVICE_ORDER_CREATE_RECEIVE = createActionType(
  '[ServiceOrder] Create Receive'
);

export const SERVICE_ORDER_GET_REQUEST = createActionType(
  '[ServiceOrder] Get Request'
);

export const SERVICE_ORDER_GET_RECEIVE = createActionType(
  '[ServiceOrder] Get Receive'
);

export const SERVICE_ORDER_GET_SITES_REQUEST = createActionType(
  '[ServiceOrder] Get Sites Request'
);

export const SERVICE_ORDER_GET_SITE_INFO = createActionType(
  '[ServiceOrder] Get Site Info'
);

export const SERVICE_ORDER_GET_SITES_RECEIVE = createActionType(
  '[ServiceOrder] Get Sites Receive'
);

export const SERVICE_ORDER_GET_COSTCODES_REQUEST = createActionType(
  '[ServiceOrder] Get Costcodes Request'
);

export const SERVICE_ORDER_GET_COSTCODES_RECEIVE = createActionType(
  '[ServiceOrder] Get Costcodes Receive'
);

export const SERVICE_ORDER_GET_TYPES_REQUEST = createActionType(
  '[ServiceOrder] Get Types Request'
);

export const SERVICE_ORDER_GET_TYPES_RECEIVE = createActionType(
  '[ServiceOrder] Get Types Receive'
);

export const SERVICE_ORDER_UPDATE_REQUEST = createActionType(
  '[ServiceOrder] Update Request'
);

export const SERVICE_ORDER_UPDATE_RECEIVE = createActionType(
  '[ServiceOrder] Update Receive'
);

export const SERVICE_ORDER_DELETE_RECEIVE = createActionType(
  '[ServiceOrder] Delete Receive'
);

export const SERVICE_ORDER_SET_CURRENT_FILTER = createActionType(
  '[ServiceOrder] Set Filter Status'
);

export const WORKORDER_REMOVE_ON_SCHEDULE_ACTION = createActionType(
  '[ServiceOrder] Remove on Schedule Action '
);

export class ServiceOrderCreateRequest implements Action {
  readonly type = SERVICE_ORDER_CREATE_REQUEST;
  constructor(public payload: any) {}
}

export class ServiceOrderCreateReceive implements Action {
  readonly type = SERVICE_ORDER_CREATE_RECEIVE;
  constructor(public payload: any) {}
}

export class ServiceOrderGetRequest implements Action {
  readonly type = SERVICE_ORDER_GET_REQUEST;
  constructor(public payload: ServiceOrderRequestListModel) {}
}
export class ServiceOrderGetReceive implements Action {
  readonly type = SERVICE_ORDER_GET_RECEIVE;
  constructor(public payload: { workOrders: any; filter: any }) {}
}

export class ServiceOrderGetSitesRequest implements Action {
  readonly type = SERVICE_ORDER_GET_SITES_REQUEST;
  constructor(public payload: any) {}
}
export class ServiceOrderGetSitesReceive implements Action {
  readonly type = SERVICE_ORDER_GET_SITES_RECEIVE;
  constructor(public payload: any) {}
}

export class ServiceOrderGetSiteInfo implements Action {
  readonly type = SERVICE_ORDER_GET_SITE_INFO;
  constructor(public payload: any) {}
}
export class ServiceOrderGetTypesRequest implements Action {
  readonly type = SERVICE_ORDER_GET_TYPES_REQUEST;
  constructor() {}
}
export class ServiceOrderGetTypesReceive implements Action {
  readonly type = SERVICE_ORDER_GET_TYPES_RECEIVE;
  constructor(public payload: any) {}
}
export class ServiceOrderUpdateRequest implements Action {
  readonly type = SERVICE_ORDER_UPDATE_REQUEST;
  constructor(
    public payload: {
      postData: any;
      isWorkorderStatusUpdate?: boolean;
      isModalFromSchedule: boolean;
    }
  ) {}
}
export class ServiceOrderUpdateReceive implements Action {
  readonly type = SERVICE_ORDER_UPDATE_RECEIVE;
  constructor(
    public payload: { updatedEntry: any; workorderId?: number; from: string }
  ) {}
}

export class ServiceOrderDeleteReceive implements Action {
  readonly type = SERVICE_ORDER_DELETE_RECEIVE;
  constructor(public payload: { res: any; isWorkorderStatusUpdate: boolean }) {}
}
export class ServiceOrderSetCurrentFilter implements Action {
  readonly type = SERVICE_ORDER_SET_CURRENT_FILTER;
  constructor(public payload: any) {}
}
export class WorkorderRemoveOnScheduleAction implements Action {
  readonly type = WORKORDER_REMOVE_ON_SCHEDULE_ACTION;
  constructor(
    public payload: { res: any; workorderId?: number; from: string }
  ) {}
}
