import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { ErrorMessage } from '../shared/model/error-message';
import { catchError, map, tap } from 'rxjs/operators';
import { BillingBackend } from './billing.backend';
import { BillingRateModel } from './model/billing-rate.model';
import { BillingRateUpdateModel } from './model/billing-rate-update.model';

@Injectable()
export class BillingService extends BaseService {
  constructor(private billingBackend: BillingBackend) {
    super();
  }

  fetchInvoiceLists(postData?: any): Promise<[] | ErrorMessage> {
    return this.billingBackend
      .fetchInvoiceLists(postData)
      .pipe(map((response) => response.body.data))
      .toPromise();
  }

  fetchBillingRateList(): Promise<BillingRateModel | ErrorMessage> {
    return this.billingBackend
      .fetchBillingRateList()
      .pipe(map((response) => response.body))
      .toPromise();
  }

  updateBillingRate(
    postData: BillingRateUpdateModel
  ): Promise<BillingRateModel | ErrorMessage> {
    return this.billingBackend
      .updateBillingRate(postData)
      .pipe(map((response) => response.body))
      .toPromise();
  }
}
