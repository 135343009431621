<div class="page_inner_div">
  <div class="landing_main">
    <div class="laning_inner_main">
      <p class="short_msg">
        Select your device below to find instructions to download the Mall City
        Mechanical app.
      </p>
      <div class="landing_box_main">
        <div class="row">
          <div class="col-sm-6">
            <div class="land_box">
              <a routerLink="/instructions/android">
                <div class="download_btn">Download for Android</div>
                <div class="img_block">
                  <img
                    [src]="'../assets/instructions/images/device-android.png'"
                    [srcset]="
                      '../assets/instructions/images/device-android@2x.png'
                    "
                  />
                </div>
              </a>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="land_box">
              <a routerLink="/instructions/ios">
                <div class="download_btn">Download for Apple iPhone (iOS)</div>
                <div class="img_block">
                  <img
                    [src]="'../assets/instructions/images/device-iphone.png'"
                    [srcset]="
                      '../assets/instructions/images/device-iphone@2x.png'
                    "
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
