import * as LoginActions from './token.actions';
import { initTokenState, TokenStateInterface } from './token.state';

export function TokenReducer(
  state = initTokenState,
  action
): TokenStateInterface {
  switch (action.type) {
    case LoginActions.LOGIN_RECEIVE: {
      const payload = action.payload.data;

      return {
        ...state,
        token: payload.token,
        tokenExpiration: payload.tokenExpiry,
      };
    }

    case LoginActions.LOGOUT_REQUEST: {
      return {
        ...state,
        token: null,
        tokenExpiration: null,
      };
    }

    default:
      return state;
  }
}
